<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="AddGrossMargin" :documentTitle="updateMode ? (source == 'contract' ? GrossMargin__Invoice__invno : GrossMargin__Localsale__title) : ''"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <GrossMarginAlert
                    :alert="$t('message.closeAlert')"
                    mode="add"
                    type="warning"
                    class="mt-3"
                    v-if="addMode && newGrossMarginUnsaved"
                />
                <GrossMarginAlert
                    :alert="$t('message.closeAlert')"
                    mode="update"
                    type="warning"
                    class="mt-3"
                    v-if="updateMode && updatedGrossMarginUnsaved"
                />
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation ref="grossMarginForm" v-model="validForm">
                        <v-overlay
                            :value="loader"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                            color="primary"
                                            indeterminate
                                            size="40"
                                            width="6"
                                    />
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                                accordion
                                multiple
                                v-model="panel"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.invoiceDetails') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <GrossMarginGeneralInfo v-if="loader"/>
                                    <template v-if="!loader">
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.invoice') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>{{ document.GrossMargin.document_title }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.invoiceDate') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>{{ formatDate(document.GrossMargin.document_date) }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.contractSo') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 class="font-weight-bold">{{ document.GrossMargin.related_document }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>{{ document.GrossMargin.customer }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplier') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>{{ document.GrossMargin.supplier }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.agent') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 v-if="document.GrossMargin.main_supplier != null">{{ document.GrossMargin.main_supplier }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 v-else>{{ $t('message.na') }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.office') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>{{ document.GrossMargin.office }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.species') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 class="d-flex flex-column">
                                                <template v-if="GrossMargin__Species__names != []">
                                                    <span v-for="(species, index) in GrossMargin__Species__names" :key="index">{{ species }}</span>
                                                </template>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.volume') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>{{ document.GrossMargin.volume }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.paymentTerms') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>{{ document.GrossMargin.payment_term }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.eta') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 v-if="document.GrossMargin.etadate != null">{{ formatDate(document.GrossMargin.etadate) }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 v-else>{{ $t('message.na') }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.dueDate') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 class="d-flex flex-row align-center">
                                                {{ document.GrossMargin.due != null ? formatDate(document.GrossMargin.due) : $t('message.na') }}
                                                <v-tooltip bottom v-if="updateMode">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn :loading="loading.updatingDueDate" icon @click="updateDueDate()" v-on="on">
                                                            <v-icon color="success darken-1">refresh</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('message.updateDueDate') }}</span>
                                                </v-tooltip>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.received') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 v-if="document.GrossMargin.received != null">{{ formatDate(document.GrossMargin.received) }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 v-else>{{ $t('message.na') }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.overdueDays') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 v-if="document.GrossMargin.overdue != null">{{ document.GrossMargin.overdue }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 v-else>{{ $t('message.na') }}</v-flex>
                                        </v-layout>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.calculation') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <GrossMarginCalculation v-if="loader"/>
                                    <v-layout row pt-3 v-if="!loader">
                                        <v-simple-table
                                            class="pt-2 font-16-px"
                                            dense
                                        >
                                            <template v-slot:default>
                                                <tbody>
                                                    <tr>
                                                        <td><span>{{ $t('message.currency') }}</span></td>
                                                        <td class="width-10-pct">%</td>
                                                        <td class="pt-1 pb-2">
                                                            <v-select
                                                                    :items="allCurrencies"
                                                                    autocomplete="off"
                                                                    class="custom pa-1 bg-lightblue"
                                                                    dense
                                                                    hide-details="auto"
                                                                    item-text="Currency.code"
                                                                    item-value="Currency.id"
                                                                    v-model="GrossMargin__currency_id"
                                                            />
                                                        </td>
                                                        <td class="align-start">
                                                            <div class="d-flex flex-row align-center pt-5">
                                                                <span class="text-no-wrap">{{ allCurrencies.find(c => c.Currency.id == 2).Currency.code }} = </span>
                                                                <v-text-field
                                                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                                        :value="GrossMargin__currencyrate"
                                                                        autocomplete="off"
                                                                        class="custom pa-1 bg-lightblue ml-1"
                                                                        dense
                                                                        hide-details="auto"
                                                                        @change="updateGrossMarginField('GrossMargin.currencyrate',$event)"
                                                                />
                                                                <span class="ml-1">{{ getCurrencyCode(GrossMargin__currency_id) }}</span>
                                                                <v-tooltip bottom v-if="updateMode">
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn :loading="loading.updatingExRate" icon @click="updateExRate()" v-on="on">
                                                                            <v-icon color="success darken-1">refresh</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>{{ $t('message.updateExchangeRate') }}</span>
                                                                </v-tooltip>
                                                            </div>
                                                            <div class="d-flex flex-row py-2"><span class="mr-3">{{ $t('message.exRateDate') }}:</span>{{ formatDate(GrossMargin__currencydate) }}</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>{{ $t('message.sellingPrice') }}</span></td>
                                                        <td>100.0</td>
                                                        <td>
                                                            <span class="mr-2">{{ accountCurrency }}</span>
                                                            <span>{{ formatThisNumber(GrossMargin__selling,'0,0.00') }}</span>
                                                        </td>
                                                        <td>
                                                            {{ formatThisNumber(convertedAmounts.selling,'0,0.00') }}
                                                            <v-tooltip bottom v-if="updateMode">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn :loading="loading.updatingPrices" icon @click="updatePrices()" v-on="on">
                                                                        <v-icon color="success darken-1">refresh</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>{{ $t('message.updatePrices') }}</span>
                                                            </v-tooltip>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>{{ $t('message.purchasePrice') }}</span></td>
                                                        <td>{{ formatThisNumber(percentages.purchase,'0,0.00') }}</td>
                                                        <td>
                                                            <span class="mr-2">{{ accountCurrency }}</span>
                                                            <span>{{ formatThisNumber(GrossMargin__purchase,'0,0.00') }}</span>
                                                        </td>
                                                        <td>
                                                            {{ formatThisNumber(convertedAmounts.purchase,'0,0.00') }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>{{ $t('message.bankCharges') }}</span></td>
                                                        <td>{{ formatThisNumber(percentages.shortpayment,'0,0.00') }}</td>
                                                        <td class="pt-1 pb-2">
                                                            <div class="d-flex flex-row align-center">
                                                                <span class="mr-2">{{ accountCurrency }}</span>
                                                                <v-text-field
                                                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                                        :value="GrossMargin__shortpayment"
                                                                        autocomplete="off"
                                                                        class="custom pa-1 bg-lightblue"
                                                                        dense
                                                                        hide-details="auto"
                                                                        @change="updateGrossMarginField('GrossMargin.shortpayment',$event)"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>{{ formatThisNumber(convertedAmounts.shortpayment,'0,0.00') }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>{{ $t('message.freightInsuranceCost') }}</span></td>
                                                        <td>{{ formatThisNumber(percentages.freightstorage,'0,0.00') }}</td>
                                                        <td class="pt-1 pb-2">
                                                            <div class="d-flex flex-row align-center">
                                                                <span class="mr-2">{{ accountCurrency }}</span>
                                                                <v-text-field
                                                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                                        :value="GrossMargin__freightstorage"
                                                                        autocomplete="off"
                                                                        class="custom pa-1 bg-lightblue"
                                                                        dense
                                                                        hide-details="auto"
                                                                        @change="updateGrossMarginField('GrossMargin.freightstorage',$event)"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {{ formatThisNumber(convertedAmounts.freightstorage,'0,0.00') }}
                                                            <v-tooltip bottom v-if="updateMode && GrossMargin__source == 'whsales_invoice'">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn :loading="loading.updatingFreightInsuranceCost" icon @click="updateFreightInsuranceCost()" v-on="on">
                                                                        <v-icon color="success darken-1">refresh</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>{{ $t('message.updateFreightInsuranceCost') }}</span>
                                                            </v-tooltip>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>{{ $t('message.carriageOutwards') }}</span></td>
                                                        <td>{{ formatThisNumber(percentages.carriage,'0,0.00') }}</td>
                                                        <td class="pt-1 pb-2">
                                                            <div class="d-flex flex-row align-center">
                                                                <span class="mr-2">{{ accountCurrency }}</span>
                                                                <v-text-field
                                                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                                        :value="GrossMargin__carriage"
                                                                        autocomplete="off"
                                                                        class="custom pa-1 bg-lightblue"
                                                                        dense
                                                                        hide-details="auto"
                                                                        @change="updateGrossMarginField('GrossMargin.carriage',$event)"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>{{ formatThisNumber(convertedAmounts.carriage,'0,0.00') }}</td>
                                                    </tr>
                                                    <tr v-if="GrossMargin__source == 'whsales_invoice'">
                                                        <td><span>{{ $t('message.warehouseCost') }}</span></td>
                                                        <td>{{ formatThisNumber(percentages.warehousecost,'0,0.00') }}</td>
                                                        <td class="pt-1 pb-2">
                                                            <div class="d-flex flex-row align-center">
                                                                <span class="mr-2">{{ accountCurrency }}</span>
                                                                <v-text-field
                                                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                                        :value="GrossMargin__warehousecost"
                                                                        autocomplete="off"
                                                                        class="custom pa-1 bg-lightblue"
                                                                        dense
                                                                        hide-details="auto"
                                                                        @change="updateGrossMarginField('GrossMargin.warehousecost',$event)"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {{ formatThisNumber(convertedAmounts.warehousecost,'0,0.00') }}
                                                            <v-tooltip bottom v-if="updateMode">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn :loading="loading.updatingWareHouseCost" icon @click="updateWareHouseCost()" v-on="on">
                                                                        <v-icon color="success darken-1">refresh</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>{{ $t('message.updateWarehouseCost') }}</span>
                                                            </v-tooltip>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>{{ $t('message.commission') }}</span></td>
                                                        <td>{{ formatThisNumber(percentages.commission,'0,0.00') }}</td>
                                                        <td class="pt-1 pb-2">
                                                            <div class="d-flex flex-row align-center">
                                                                <span class="mr-2">{{ accountCurrency }}</span>
                                                                <v-text-field
                                                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                                        :value="GrossMargin__commission"
                                                                        autocomplete="off"
                                                                        class="custom pa-1 bg-lightblue"
                                                                        dense
                                                                        hide-details="auto"
                                                                        @change="updateGrossMarginField('GrossMargin.commission',$event)"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>{{ formatThisNumber(convertedAmounts.commission,'0,0.00') }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>{{ $t('message.paymentTermsSellingPrice') }}</span></td>
                                                        <td>{{ formatThisNumber(percentages.paymenttermcost,'0,0.00') }}</td>
                                                        <td class="pt-1 pb-2">
                                                            <div class="d-flex flex-row align-center">
                                                                <span class="mr-2">{{ accountCurrency }}</span>
                                                                <v-text-field
                                                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                                        :value="GrossMargin__paymenttermcost"
                                                                        autocomplete="off"
                                                                        class="custom pa-1 bg-lightblue"
                                                                        dense
                                                                        hide-details="auto"
                                                                        @change="updateGrossMarginField('GrossMargin.paymenttermcost',$event)"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {{ formatThisNumber(convertedAmounts.paymenttermcost,'0,0.00') }}
                                                            <v-tooltip bottom v-if="updateMode">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn :loading="loading.updatingPaymentTerm" icon @click="updatePaymentTermCost()" v-on="on">
                                                                        <v-icon color="success darken-1">refresh</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>{{ $t('message.updatePaymentTermCost') }}</span>
                                                            </v-tooltip>
                                                            <span class="ml-3" v-if="paymentTermVar != ''">( {{ paymentTermVar }} )</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>{{ $t('message.latePayment') }}</span></td>
                                                        <td>{{ formatThisNumber(percentages.latepaymentcost,'0,0.00') }}</td>
                                                        <td class="pt-1 pb-2">
                                                            <div class="d-flex flex-row align-center">
                                                                <span class="mr-2">{{ accountCurrency }}</span>
                                                                <v-text-field
                                                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                                        :value="GrossMargin__latepaymentcost"
                                                                        autocomplete="off"
                                                                        class="custom pa-1 bg-lightblue"
                                                                        dense
                                                                        hide-details="auto"
                                                                        @change="updateGrossMarginField('GrossMargin.latepaymentcost',$event)"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex flex-column">
                                                                <div>
                                                                    {{ formatThisNumber(convertedAmounts.latepaymentcost,'0,0.00') }}
                                                                    <v-tooltip bottom v-if="updateMode">
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn :loading="loading.updatingLatePayment" icon @click="updateLatePaymentCost()" v-on="on">
                                                                                <v-icon color="success darken-1">refresh</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>{{ $t('message.updateLatePaymentCost') }}</span>
                                                                    </v-tooltip>
                                                                    <span class="ml-3" v-if="document.GrossMargin.overdue > 0">( {{ latePaymentVar }} )</span>
                                                                </div>
                                                                <div class="text-no-wrap pb-2" v-if="newLatePaymentRates">( {{ $t('message.newRatesAfter') + ' ' + formatDate(AppicVars.gmLatePaymentCutoffDate) }} )</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>{{ $t('message.otherCosts') }}</span></td>
                                                        <td>{{ formatThisNumber(percentages.othercost,'0,0.00') }}</td>
                                                        <td class="pt-1 pb-2">
                                                            <div class="d-flex flex-row align-center">
                                                                <span class="mr-2">{{ accountCurrency }}</span>
                                                                <v-text-field
                                                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                                        :value="GrossMargin__othercost"
                                                                        autocomplete="off"
                                                                        class="custom pa-1 bg-lightblue"
                                                                        dense
                                                                        hide-details="auto"
                                                                        @change="updateGrossMarginField('GrossMargin.othercost',$event)"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>{{ formatThisNumber(convertedAmounts.othercost,'0,0.00') }}</td>
                                                    </tr>
                                                    <tr v-if="GrossMargin__Agent__has_commission == 0">
                                                        <td><span>{{ $t('message.grossMargin') }}</span></td>
                                                        <td>{{ formatThisNumber(percentages.grossmargin,'0,0.00') }}</td>
                                                        <td class="pt-1 pb-2">
                                                            <div class="d-flex flex-row align-center">
                                                                <span class="mr-2">{{ accountCurrency }}</span>
                                                                <span>{{ formatThisNumber(GrossMargin__grossmargin,'0,0.00') }}</span>
                                                            </div>
                                                        </td>
                                                        <td>{{ formatThisNumber(convertedAmounts.grossmargin,'0,0.00') }}</td>
                                                    </tr>
                                                    <template v-if="document.GrossMargin.agent_has_commission == 1 && updateMode == false">
                                                        <tr>
                                                            <td colspan="4">
                                                                {{ $t('message.hasAgentCommissionSaveFirst') }}
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <template v-if="GrossMargin__Agent__has_commission == 1 && updateMode">
                                                        <tr>
                                                            <td><span>{{ $t('message.margin') }}</span></td>
                                                            <td>{{ formatThisNumber(percentages.basicgrossmargin,'0,0.00') }}</td>
                                                            <td class="pt-1 pb-2">
                                                                <div class="d-flex flex-row align-center">
                                                                    <span class="mr-2">{{ accountCurrency }}</span>
                                                                    <span>{{ formatThisNumber(GrossMargin__basicgrossmargin,'0,0.00') }}</span>
                                                                </div>
                                                            </td>
                                                            <td>{{ formatThisNumber(convertedAmounts.basicgrossmargin,'0,0.00') }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4" class="grey lighten-2">
                                                                <span v-if="GrossMargin__Agent__CommissionValue__minbalance > 0">{{ $t('message.minimumCommission') }}</span>
                                                                <span v-else>{{ $t('message.commission') }}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>{{ appConstants.company }}</span></td>
                                                            <td class="pt-1 pb-2">
                                                                <v-text-field
                                                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                                        :value="GrossMargin__mincomhq"
                                                                        autocomplete="off"
                                                                        class="custom pa-1 bg-lightblue"
                                                                        clearable
                                                                        dense
                                                                        hide-details="auto"
                                                                        @change="updateGrossMarginField('GrossMargin.mincomhq',$event)"
                                                                />
                                                            </td>
                                                            <td>
                                                                <div class="d-flex flex-row align-center">
                                                                    <span class="mr-2">{{ accountCurrency }}</span>
                                                                    <span>{{ formatThisNumber(GrossMargin__Agent__CommissionValue__mincomhqamount,'0,0.00') }}</span>
                                                                </div>
                                                            </td>
                                                            <td>{{ formatThisNumber(convertedAmounts.mincomhqamount,'0,0.00') }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>{{ GrossMargin__Agent__title }}</span></td>
                                                            <td class="pt-1 pb-2">
                                                                <v-text-field
                                                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                                        :value="GrossMargin__mincomsub"
                                                                        autocomplete="off"
                                                                        class="custom pa-1 bg-lightblue"
                                                                        clearable
                                                                        dense
                                                                        hide-details="auto"
                                                                        @change="updateGrossMarginField('GrossMargin.mincomsub',$event)"
                                                                />
                                                            </td>
                                                            <td>
                                                                <div class="d-flex flex-row align-center">
                                                                    <span class="mr-2">{{ accountCurrency }}</span>
                                                                    <span>{{ formatThisNumber(GrossMargin__Agent__CommissionValue__mincomsubamount,'0,0.00') }}</span>
                                                                </div>
                                                            </td>
                                                            <td>{{ formatThisNumber(convertedAmounts.mincomsubamount,'0,0.00') }}</td>
                                                        </tr>
                                                        <template v-if="GrossMargin__Agent__CommissionValue__minbalance > 0">
                                                            <tr>
                                                                <td><span>{{ $t('message.balance') }}</span></td>
                                                                <td></td>
                                                                <td>
                                                                    <div class="d-flex flex-row align-center">
                                                                        <span class="mr-2">{{ accountCurrency }}</span>
                                                                        <span>{{ formatThisNumber(GrossMargin__Agent__CommissionValue__minbalance,'0,0.00') }}</span>
                                                                    </div>
                                                                </td>
                                                                <td>{{ formatThisNumber(convertedAmounts.minbalance,'0,0.00') }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="4" class="grey lighten-2"><span class="font-md">{{ $t('message.additionalCommission') }}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td><span>{{ appConstants.company }}</span></td>
                                                                <td>
                                                                    <v-text-field
                                                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                                            :value="GrossMargin__addcomhq"
                                                                            autocomplete="off"
                                                                            class="custom pa-1 bg-lightblue"
                                                                            clearable
                                                                            dense
                                                                            hide-details="auto"
                                                                            @change="updateGrossMarginField('GrossMargin.addcomhq',$event)"
                                                                            v-if="false"
                                                                    />
                                                                    {{ formatThisNumber(additionalCommissionHqPercentage,'0,0.00') }}
                                                                </td>
                                                                <td>
                                                                    <div class="d-flex flex-row align-center">
                                                                        <span class="mr-2">{{ accountCurrency }}</span>
                                                                        <span>{{ formatThisNumber(GrossMargin__Agent__CommissionValue__addcomhqamount,'0,0.00') }}</span>
                                                                    </div>
                                                                </td>
                                                                <td>{{ formatThisNumber(convertedAmounts.addcomhqamount,'0,0.00') }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><span>{{ GrossMargin__Agent__title }}</span></td>
                                                                <td>{{ formatThisNumber(additionalCommissionAgentPercentage,'0,0.00') }}</td>
                                                                <td>
                                                                    <div class="d-flex flex-row align-center">
                                                                        <span class="mr-2">{{ accountCurrency }}</span>
                                                                        <span>{{ formatThisNumber(GrossMargin__Agent__CommissionValue__addcomsubamount,'0,0.00') }}</span>
                                                                    </div>
                                                                </td>
                                                                <td>{{ formatThisNumber(convertedAmounts.addcomsubamount,'0,0.00') }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="4" class="grey lighten-2"><span>{{ $t('message.totalCommissionFull') }}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td><span>{{ appConstants.company }}</span></td>
                                                                <td>{{ formatThisNumber(percentages.grossmarginhq,'0,0.00') }}</td>
                                                                <td>
                                                                    <div class="d-flex flex-row align-center">
                                                                        <span class="mr-2">{{ accountCurrency }}</span>
                                                                        <span>{{ formatThisNumber(GrossMargin__Agent__CommissionValue__grossmarginhq,'0,0.00') }}</span>
                                                                    </div>
                                                                </td>
                                                                <td>{{ formatThisNumber(convertedAmounts.grossmarginhq,'0,0.00') }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><span>{{ GrossMargin__Agent__title }}</span></td>
                                                                <td>{{ formatThisNumber(percentages.grossmarginsub,'0,0.00') }}</td>
                                                                <td>
                                                                    <div class="d-flex flex-row align-center">
                                                                        <span class="mr-2">{{ accountCurrency }}</span>
                                                                        <span>{{ formatThisNumber(GrossMargin__Agent__CommissionValue__grossmarginsub,'0,0.00') }}</span>
                                                                    </div>
                                                                </td>
                                                                <td>{{ formatThisNumber(convertedAmounts.grossmarginsub,'0,0.00') }}</td>
                                                            </tr>
                                                        </template>
                                                        <template v-else>
                                                            <tr>
                                                                <td colspan="4" class="grey lighten-2"></td>
                                                            </tr>
                                                        </template>
                                                        <tr>
                                                            <td><span>{{ $t('message.grossMargin') }}</span></td>
                                                            <td>{{ formatThisNumber(percentages.grossmargin,'0,0.00') }}</td>
                                                            <td class="pt-1 pb-2">
                                                                <div class="d-flex flex-row align-center">
                                                                    <span class="mr-2">{{ accountCurrency }}</span>
                                                                    <span>{{ formatThisNumber(GrossMargin__grossmargin,'0,0.00') }}</span>
                                                                </div>
                                                            </td>
                                                            <td>{{ formatThisNumber(convertedAmounts.grossmargin,'0,0.00') }}</td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name === 'add_gross_margin')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <DocumentHistory asset="accounts" :asset_id="GrossMargin__id"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row>
                            <v-flex d-flex xs12>
                                <v-layout row px-3 pt-3 mb-3>
                                    <PrintGrossMarginButton
                                        :gross-margin-id="grossMarginId"
                                        :source="source"
                                        :update-mode="updateMode"
                                        :list-mode="false"
                                        v-if="updateMode"
                                    />
                                    <v-btn
                                        :loading="loading.reset"
                                        @click="resetGrossMarginForm()"
                                        class="ml-3"
                                        color="default"
                                        small
                                        v-if="addMode == true"
                                    >
                                        <v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                    </v-btn>
                                    <v-btn
                                        :loading="loading.add"
                                        class="ml-3"
                                        color="info"
                                        small
                                        @click="addGrossMarginCalculation()"
                                        v-if="addMode == true"
                                    >
                                        {{ $t('message.create') }}
                                    </v-btn>
                                    <v-btn
                                        :loading="loading.updatingPrices"
                                        class="ml-3"
                                        color="warning"
                                        small
                                        @click="updatePrices()"
                                        v-if="false"
                                    >
                                        {{ $t('message.updatePrices') }}
                                    </v-btn>
                                    <v-btn
                                        :loading="loading.save"
                                        class="ml-3"
                                        color="info"
                                        small
                                        @click="saveGrossMarginCalculation()"
                                        v-if="updateMode == true"
                                    >
                                        {{ $t('message.save') }}
                                    </v-btn>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {formatDate, log, numberFormat} from "Helpers/helpers";
    import router from "@/router";
    import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
    import GrossMarginAlert from "Components/Appic/GrossMarginAlert";
    import {mapFields} from "vuex-map-fields";
    import DocumentHistory from "Components/Appic/DocumentHistory";
    import {v4 as uuidv4} from "uuid";
    import {api} from "Api";
    import GrossMarginGeneralInfo from "Components/Appic/Loading/GrossMarginGeneralInfo";
    import GrossMarginCalculation from "Components/Appic/Loading/GrossMarginCalculation";

    const PrintGrossMarginButton = () => import('Components/Appic/Printing/PrintGrossMarginButton');

    export default {
        name: "AddGrossMargin",
        components: {GrossMarginCalculation, GrossMarginGeneralInfo, DocumentHistory, GrossMarginAlert, PrintGrossMarginButton},
        props: ['grossMarginId','source','sourceId'],
        // title(){
        //     return this.$t('message.titles.grossMargins')
        // },
        data(){
            return {
                convertedAmounts: {
                    addcomhqamount: 0,
                    addcomsubamount: 0,
                    basicgrossmargin: 0,
                    carriage: 0,
                    commission: 0,
                    freightstorage: 0,
                    grossmargin: 0,
                    grossmarginhq: 0,
                    grossmarginsub: 0,
                    latepaymentcost: 0,
                    minbalance: 0,
                    mincomhqamount: 0,
                    mincomsubamount: 0,
                    othercost: 0,
                    paymenttermcost: 0,
                    purchase: 0,
                    selling: 100,
                    shortpayment: 0,
                    warehousecost: 0
                },
                document: {
                    GrossMargin: {
                        agent: null,
                        agent_has_commission: null,
                        customer: null,
                        document_date: null,
                        document_title: null,
                        due: null,
                        etadate: null,
                        office: null,
                        overdue: 0,
                        payment_term: null,
                        payment_term_effect: null,
                        received: null,
                        related_document: null,
                        species: [],
                        supplier: null,
                        volume: 0,
                    }
                },
                loader: false,
                loading: {
                    add: false,
                    reset: false,
                    save: false,
                    updatingDueDate: false,
                    updatingExRate: false,
                    updatingLatePayment: false,
                    updatingPaymentTerm: false,
                    updatingPrices: false,
                    updatingWareHouseCost: false,
                    updatingFreightInsuranceCost: false,
                },
                newLatePaymentRates: false,
                pageKey: 0,
                panel: [0,1],
                percentages: {
                    addcomhqamount: 0,
                    addcomsubamount: 0,
                    basicgrossmargin: 0,
                    carriage: 0,
                    commission: 0,
                    freightstorage: 0,
                    grossmargin: 0,
                    grossmarginhq: 0,
                    grossmarginsub: 0,
                    latepaymentcost: 0,
                    minbalance: 0,
                    mincomhqamount: 0,
                    mincomsubamount: 0,
                    othercost: 0,
                    paymenttermcost: 0,
                    purchase: 0,
                    selling: 100,
                    shortpayment: 0,
                    warehousecost: 0,
                },
                validForm: false
            }
        },
        computed: {
            statePrefix() {
                if (this.updateMode) {
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('grossmargin',{
                GrossMargin__addcomhq: 'GrossMargin.addcomhq',
                GrossMargin__addcomsub: 'GrossMargin.addcomsub',
                GrossMargin__basicgrossmargin: 'GrossMargin.basicgrossmargin',
                GrossMargin__calculation_id: 'GrossMargin.calculation_id',
                GrossMargin__carriage: 'GrossMargin.carriage',
                GrossMargin__carriagecurrency_id: 'GrossMargin.carriagecurrency_id',
                GrossMargin__comcurrency_id: 'GrossMargin.comcurrency_id',
                GrossMargin__commission: 'GrossMargin.commission',
                GrossMargin__contract_id: 'GrossMargin.contract_id',
                GrossMargin__currency_id: 'GrossMargin.currency_id',
                GrossMargin__currencydate: 'GrossMargin.currencydate',
                GrossMargin__currencyrate: 'GrossMargin.currencyrate',
                GrossMargin__customer_id: 'GrossMargin.customer_id',
                GrossMargin__debitnote_id: ' GrossMargin.debitnote_id',
                GrossMargin__etadate: 'GrossMargin.etadate',
                GrossMargin__etddate: 'GrossMargin.etddate',
                GrossMargin__freightcurrency_id: 'GrossMargin.freightcurrency_id',
                GrossMargin__freightstorage: 'GrossMargin.freightstorage',
                GrossMargin__grossmargin: 'GrossMargin.grossmargin',
                GrossMargin__id: 'GrossMargin.id',
                GrossMargin__inputtype_id: 'GrossMargin.inputtype_id',
                GrossMargin__latepaycurrency_id: 'GrossMargin.latepaycurrency_id',
                GrossMargin__latepaymentcost: 'GrossMargin.latepaymentcost',
                GrossMargin__localsale_id: 'GrossMargin.localsale_id',
                GrossMargin__mincomhq: 'GrossMargin.mincomhq',
                GrossMargin__mincomsub: 'GrossMargin.mincomsub',
                GrossMargin__office_id: 'GrossMargin.office_id',
                GrossMargin__othercost: 'GrossMargin.othercost',
                GrossMargin__overdue: 'GrossMargin.overdue',
                GrossMargin__paymenttermcost: 'GrossMargin.paymenttermcost',
                GrossMargin__paytermcurrency_id: 'GrossMargin.paytermcurrency_id',
                GrossMargin__purchase: 'GrossMargin.purchase',
                GrossMargin__purchasediscount: 'GrossMargin.purchasediscount',
                GrossMargin__salescontact_id: 'GrossMargin.salescontact_id',
                GrossMargin__selling: 'GrossMargin.selling',
                GrossMargin__sellingdiscount: 'GrossMargin.sellingdiscount',
                GrossMargin__shortpaycurrency_id: 'GrossMargin.shortpaycurrency_id',
                GrossMargin__shortpayment: 'GrossMargin.shortpayment',
                GrossMargin__source: 'GrossMargin.source',
                GrossMargin__supplier_id: 'GrossMargin.supplier_id',
                GrossMargin__title: 'GrossMargin.title',
                GrossMargin__usdrate: 'GrossMargin.usdrate',
                GrossMargin__version: 'GrossMargin.version',
                GrossMargin__volume: 'GrossMargin.volume',
                GrossMargin__warehousecost: 'GrossMargin.warehousecost',
                GrossMargin__whcostcurrency_id: 'GrossMargin.whcostcurrency_id',
                GrossMargin__Agent__addcomhq: 'GrossMargin.Agent.addcomhq',
                GrossMargin__Agent__has_commission: 'GrossMargin.Agent.has_commission',
                GrossMargin__Agent__mincomhq: 'GrossMargin.Agent.mincomhq',
                GrossMargin__Agent__mincomsub: 'GrossMargin.Agent.mincomsub',
                GrossMargin__Agent__title: 'GrossMargin.Agent.title',
                GrossMargin__Agent__CommissionValue__mincomhq: 'GrossMargin.Agent.CommissionValue.mincomhq',
                GrossMargin__Agent__CommissionValue__mincomhqamount: 'GrossMargin.Agent.CommissionValue.mincomhqamount',
                GrossMargin__Agent__CommissionValue__mincomsubamount: 'GrossMargin.Agent.CommissionValue.mincomsubamount',
                GrossMargin__Agent__CommissionValue__mincomsub: 'GrossMargin.Agent.CommissionValue.mincomsub',
                GrossMargin__Agent__CommissionValue__minbalance: 'GrossMargin.Agent.CommissionValue.minbalance',
                GrossMargin__Agent__CommissionValue__gmpct: 'GrossMargin.Agent.CommissionValue.gmpct',
                GrossMargin__Agent__CommissionValue__grossmarginhq: 'GrossMargin.Agent.CommissionValue.grossmarginhq',
                GrossMargin__Agent__CommissionValue__grossmarginsub: 'GrossMargin.Agent.CommissionValue.grossmarginsub',
                GrossMargin__Agent__CommissionValue__addcomhqamount: 'GrossMargin.Agent.CommissionValue.addcomhqamount',
                GrossMargin__Agent__CommissionValue__addcomsubamount: 'GrossMargin.Agent.CommissionValue.addcomsubamount',
                GrossMargin__Contract__id: 'GrossMargin.Contract.id',
                GrossMargin__Contract__etadate: 'GrossMargin.Contract.etadate',
                GrossMargin__Contract__etddate: 'GrossMargin.Contract.etddate',
                GrossMargin__Contract__partial_no: 'GrossMargin.Contract.partial_no',
                GrossMargin__Contract__revision_no: 'GrossMargin.Contract.revision_no',
                GrossMargin__Contract__salestype_id: 'GrossMargin.Contract.salestype_id',
                GrossMargin__Contract__title: 'GrossMargin.Contract.title',
                GrossMargin__Contract__version: 'GrossMargin.Contract.version',
                GrossMargin__Invoice__id: 'GrossMargin.Invoice.id',
                GrossMargin__Invoice__invno: 'GrossMargin.Invoice.invno',
                GrossMargin__Localsale__id: 'GrossMargin.Localsale.id',
                GrossMargin__Localsale__title: 'GrossMargin.Localsale.title',
                GrossMargin__Payment__id: 'GrossMargin.Payment.id',
                GrossMargin__Paymentstatement__id: 'GrossMargin.Paymentstatement.id',
                GrossMargin__Paymentstatement__title: 'GrossMargin.Paymentstatement.title',
                GrossMargin__Species__names: 'GrossMargin.Species.names',
                GrossMargin__Var__banklendingratepa: 'GrossMargin.Var.banklendingratepa',
                GrossMargin__Var__latepayment_14_days: 'GrossMargin.Var.latepayment_14_days',
                GrossMargin__Var__latepayment_14_to_30_days: 'GrossMargin.Var.latepayment_14_to_30_days',
                GrossMargin__Var__latepayment_30_days: 'GrossMargin.Var.latepayment_30_days',
                GrossMargin__Var__paymentterm_30_days: 'GrossMargin.Var.paymentterm_30_days',
                GrossMargin__Var__paymentterm_45_days: 'GrossMargin.Var.paymentterm_45_days',
                GrossMargin__Var__paymentterm_60_days: 'GrossMargin.Var.paymentterm_60_days',
                GrossMargin__Var__warehousecost: 'GrossMargin.Var.warehousecost',
                GrossMargin__Var__latepayment_v2_14_days: 'GrossMargin.Var.latepayment_v2_14_days',
                GrossMargin__Var__latepayment_v2_15_30_days: 'GrossMargin.Var.latepayment_v2_15_30_days',
                GrossMargin__Var__latepayment_v2_31_60_days: 'GrossMargin.Var.latepayment_v2_31_60_days',
                GrossMargin__Var__latepayment_v2_61_days: 'GrossMargin.Var.latepayment_v2_61_days'

            }, 'statePrefix'),
            ...mapFields('grossmargin',{
                newGrossMarginSaved: 'newGrossMarginSaved',
                newGrossMarginUnsaved: 'newGrossMarginUnsaved',
                updatedGrossMarginUnsaved: 'updatedGrossMarginUnsaved',
                updatedGrossMarginSaved: 'updatedGrossMarginSaved'
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('user',{
                AppicVars : 'AppicVars'
            }),
            ...mapGetters([
                'appConstants',
                'currencies',
                'validationRules'
            ]),
            additionalCommissionAgentPercentage() {
                return (1 - this.GrossMargin__addcomhq) * 100
            },
            additionalCommissionHqPercentage() {
                return this.GrossMargin__addcomhq * 100
            },
            accountCurrency () {
                let currency = this.allCurrencies.find(c => c.Currency.id == this.GrossMargin__currency_id)
                return currency.Currency.code
            },
            addMode: function () {
                return this.$route.matched.some(({name}) => name === 'add_gross_margin')
            },
            latePaymentVar () {
                let variable = 0
                if(this.document.GrossMargin.overdue > 0) {
                    let latePaymentCutOffDate = new Date(this.AppicVars.gmLatePaymentCutoffDate).getTime()
                    let paymentReceivedDate = new Date(this.document.GrossMargin.received).getTime()
                    if(paymentReceivedDate >= latePaymentCutOffDate) {
                        this.newLatePaymentRates = true
                        if (this.document.GrossMargin.overdue > 0 && this.document.GrossMargin.overdue < 14) variable = this.GrossMargin__Var__latepayment_v2_14_days
                        if (this.document.GrossMargin.overdue >= 15 && this.document.GrossMargin.overdue <= 30) variable = this.GrossMargin__Var__latepayment_v2_15_30_days
                        if (this.document.GrossMargin.overdue >= 31 && this.document.GrossMargin.overdue <= 60) variable = this.GrossMargin__Var__latepayment_v2_31_60_days
                        if (this.document.GrossMargin.overdue >= 61) variable = this.GrossMargin__Var__latepayment_v2_61_days
                    } else {
                        if (this.document.GrossMargin.overdue > 0 && this.document.GrossMargin.overdue < 14) variable = this.GrossMargin__Var__latepayment_14_days
                        if (this.document.GrossMargin.overdue >= 14 && this.document.GrossMargin.overdue <= 30) variable = this.GrossMargin__Var__latepayment_14_to_30_days
                        if (this.document.GrossMargin.overdue > 30) variable = this.GrossMargin__Var__latepayment_30_days
                    }

                    variable = variable * 100
                    variable = variable.toString() + '%'
                }
                return variable
            },
            paymentTermVar () {
                let variable = 0

                if (this.document.GrossMargin.payment_term_effect == 1) variable = this.GrossMargin__Var__paymentterm_30_days
                if (this.document.GrossMargin.payment_term_effect == 2) variable = this.GrossMargin__Var__paymentterm_45_days
                if (this.document.GrossMargin.payment_term_effect == 3) variable = this.GrossMargin__Var__paymentterm_60_days

                variable = variable * 100
                variable = variable.toString() + '%'
                return variable
            },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_gross_margin')
            },
        },
        methods: {
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('grossmargin', {
                calculateAgentCommission: 'calculateAgentCommission',
                createGrossMargin: 'createGrossMargin',
                getGrossMarginToUpdateById: 'getGrossMarginToUpdateById',
                resetCurrentGrossMargin: 'resetCurrentGrossMargin',
                updateGrossMargin: 'updateGrossMargin'
            }),
            addGrossMarginCalculation(){
                if(this.$refs.grossMarginForm.validate()) {
                    this.loading.add = true
                    this.createGrossMargin(this.source)
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.grossMarginAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.add = false
                                this.newGrossMarginUnsaved = false
                                this.newGrossMarginSaved = true
                                //TODO reset new Gross Margin state
                                this.resetCurrentGrossMargin().then(() => {
                                    router.push({
                                        name: 'update_gross_margin',
                                        params: {grossMarginId: response.account_id}
                                    }).catch(err => {
                                    })
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.grossMarginNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.add = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.grossMarginNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        })
                        .finally(() => {
                            this.loading.add = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.grossMarginNotAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            formatDate,
            formatThisNumber (value,format) {
                return numberFormat(value,format)
            },
            getCurrencyCode(val) {
                let currency = this.allCurrencies.find(c => c.Currency.id == val)
                if(currency){
                    return currency.Currency.code
                } else {
                    return '';
                }
            },
            loadAnotherGrossMargin (val) {
                router.push({name: 'update_gross', params: { stockOrderId : val}}).catch(err => {})
            },
            loadDocumentDetailById (val){
                return new Promise((resolve, reject) => {
                    api
                        .get('/grossmargins/' + val + '/document-details')
                        .then(response => {
                            resolve(response.data.data[0])
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            loadSourceDetail (source, sourceId) {
                return new Promise((resolve, reject) => {
                    api.get('/grossmargins/source/' + source + '/' + sourceId + '/details')
                        .then(response => {
                            resolve(response.data.data[0])
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            loadGrossMarginById (val) {
                this.loader = true
                this.getGrossMarginToUpdateById(val)
                    .then(() => {
                        let payload = {
                            grossMarginId: this.grossMarginId,
                            prefix: this.statePrefix
                        }
                        this.calculateAgentCommission(payload)
                            .then(() => {
                                this.updateConvertedAmounts()
                                    .then(()=>{
                                        this.updatePercentages()
                                            .then(()=>{
                                                this.loadDocumentDetailById (val)
                                                    .then((response)=>{
                                                        // this.source = this.GrossMargin__source
                                                        this.document = response
                                                        this.loader = false
                                                        this.updatedGrossMarginUnsaved = false
                                                        this.$title = this.$t('message.titles.grossMargin') + ': ' + (this.GrossMargin__source == 'invoice' ? this.GrossMargin__Invoice__invno : this.GrossMargin__Localsale__title)
                                                    })
                                                    .catch(() => {
                                                        this.loader = false
                                                    })
                                            })
                                            .catch(() => {
                                                this.loader = false
                                            })
                                    })
                                    .catch(() => {
                                        this.loader = false
                                    })
                            })
                            .catch(() => {
                                this.loader = false
                            })
                    })
                    .catch(() => {
                        this.loader = false
                        router
                            .push({name: 'not_found_main', params: {resource: 'GM calculation'}})
                            .catch(() => { console.log('error') })
                    })
            },
            async resetGrossMarginForm() {
                if (await this.$root.$confirm(this.$t('message.resetGrossMarginForm'), this.$t('message.confirmations.continueGrossMarginFormReset'), {color: 'orange'})) {
                    this.loading.reset = true
                    this.resetCurrentGrossMargin()
                        .then(() => {
                            this.newGrossMarginUnsaved = false
                            this.loading.reset = false
                        })
                        .catch(()=>{
                            this.loading.reset = false
                        })
                }
            },
            saveGrossMarginCalculation(){
                if(this.$refs.grossMarginForm.validate()) {
                    this.loading.save = true
                    this.updateGrossMargin()
                        .then((status) => {
                            if (status == 'done') {
                                this.$toast.success(this.$t('message.successes.grossMarginUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.save = false
                                this.updatedGrossMarginUnsaved = false
                            } else {
                                this.$toast.error(this.$t('message.errors.grossMarginNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.save = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.grossMarginNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.grossMarginNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            },
            updateConvertedAmounts() {
                return new Promise((resolve, reject) => {
                    this.convertedAmounts.carriage = this.GrossMargin__carriage / this.GrossMargin__currencyrate
                    this.convertedAmounts.commission = this.GrossMargin__commission / this.GrossMargin__currencyrate
                    this.convertedAmounts.freightstorage = this.GrossMargin__freightstorage / this.GrossMargin__currencyrate
                    this.convertedAmounts.grossmargin = this.GrossMargin__grossmargin / this.GrossMargin__currencyrate
                    this.convertedAmounts.latepaymentcost = this.GrossMargin__latepaymentcost / this.GrossMargin__currencyrate
                    this.convertedAmounts.othercost = this.GrossMargin__othercost / this.GrossMargin__currencyrate
                    this.convertedAmounts.paymenttermcost = this.GrossMargin__paymenttermcost / this.GrossMargin__currencyrate
                    this.convertedAmounts.purchase = this.GrossMargin__purchase / this.GrossMargin__currencyrate
                    this.convertedAmounts.selling = this.GrossMargin__selling / this.GrossMargin__currencyrate
                    this.convertedAmounts.shortpayment = this.GrossMargin__shortpayment / this.GrossMargin__currencyrate
                    this.convertedAmounts.warehousecost = this.GrossMargin__warehousecost / this.GrossMargin__currencyrate
                    if(this.GrossMargin__Agent__has_commission == 1){
                        this.convertedAmounts.basicgrossmargin = this.GrossMargin__basicgrossmargin / this.GrossMargin__currencyrate
                        this.convertedAmounts.mincomhqamount = this.GrossMargin__Agent__CommissionValue__mincomhqamount / this.GrossMargin__currencyrate
                        this.convertedAmounts.mincomsubamount = this.GrossMargin__Agent__CommissionValue__mincomsubamount / this.GrossMargin__currencyrate
                        this.convertedAmounts.minbalance = this.GrossMargin__Agent__CommissionValue__minbalance / this.GrossMargin__currencyrate
                        this.convertedAmounts.addcomhqamount = this.GrossMargin__Agent__CommissionValue__addcomhqamount / this.GrossMargin__currencyrate
                        this.convertedAmounts.addcomsubamount = this.GrossMargin__Agent__CommissionValue__addcomsubamount / this.GrossMargin__currencyrate
                        this.convertedAmounts.grossmarginhq = this.GrossMargin__Agent__CommissionValue__grossmarginhq / this.GrossMargin__currencyrate
                        this.convertedAmounts.grossmarginsub = this.GrossMargin__Agent__CommissionValue__grossmarginsub / this.GrossMargin__currencyrate
                    }
                    resolve('done')
                })
            },
            updateDueDate () {
                this.loading.updatingDueDate = true
                let id = this.GrossMargin__contract_id
                let type = 'contracts'
                if(this.GrossMargin__localsale_id != null) {
                    id = this.GrossMargin__localsale_id
                    type = 'localsales'
                }

                let promise = new Promise((resolve, reject) => {
                    api
                        .put('/' + type + '/' + id + '/payment-due-dates',{
                            paymentIds: [ this.GrossMargin__Payment__id ]
                        })
                        .then(response =>{
                            if(response.data.status == 'success') {
                                resolve('success')
                            } else {
                                resolve('error')
                            }
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                promise
                    .then(status => {
                        if(status == 'success') {
                            //reload the gross margin data
                            this.loadGrossMarginById(this.grossMarginId)
                            this.updateLatePaymentCost()
                            this.$toast.success(this.$t('message.successes.dueDateUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.updatingDueDate = false
                        } else {
                            this.$toast.error(this.$t('message.errors.dueDateNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.updatingDueDate = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.dueDateNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.updatingDueDate = false
                    })
            },
            updateExRate () {
                this.loading.updatingExRate = true
                let promise = new Promise((resolve, reject) => {
                    api
                        .put('/grossmargins/' + this.grossMarginId + '/exrate')
                        .then(response =>{
                            if(response.data.status == 'success') {
                                resolve('success')
                            } else {
                                resolve('error')
                            }
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                promise
                    .then(status => {
                        if(status == 'success') {
                            //reload the gross margin data
                            this.loadGrossMarginById(this.grossMarginId)
                            this.$toast.success(this.$t('message.successes.grossMarginExRateUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.updatingExRate = false
                        } else {
                            this.$toast.error(this.$t('message.errors.grossMarginExRateNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.updatingExRate = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.grossMarginExRateNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.updatingExRate = false
                    })
            },
            updateGrossMarginField(field, val) {
                this.loader = true
                let payload = {
                    field: field,
                    prefix: this.statePrefix,
                    value: val,
                }
                this.$store.commit('grossmargin/updateGrossMarginField', payload)
                this.updateGrossMarginValue()
                    .then(() => {
                        if(this.GrossMargin__Agent__has_commission == 1 && this.updateMode){
                            this.updateGrossMargin()
                                .then(() => {
                                    let payload = {
                                        grossMarginId: this.grossMarginId,
                                        prefix: this.statePrefix
                                    }
                                    this.calculateAgentCommission(payload)
                                        .then(() => {
                                            this.updateConvertedAmounts()
                                                .then(() => {
                                                    this.updatePercentages()
                                                        .then(() => {
                                                            this.loader = false
                                                        })
                                                        .catch(() => {
                                                            this.loader = false
                                                        })
                                                })
                                                .catch(() => {
                                                    this.loader = false
                                                })
                                        })
                                        .catch(() => {
                                            this.loader = false
                                        })
                                })
                                .catch(() => {
                                    this.loader = false
                                })
                        } else {
                            this.updateConvertedAmounts()
                                .then(() => {
                                    this.updatePercentages()
                                        .then(() => {
                                            this.loader = false
                                        })
                                        .catch(() => {
                                            this.loader = false
                                        })
                                })
                                .catch(() => {
                                    this.loader = false
                                })
                        }
                    })
                    .catch(() => {
                        this.loader = false
                    })
            },
            updateGrossMarginValue() {
                return new Promise((resolve, reject) => {
                    let cost = parseFloat(this.GrossMargin__carriage ? this.GrossMargin__carriage : 0)
                        + parseFloat(this.GrossMargin__commission ? this.GrossMargin__commission : 0)
                        + parseFloat(this.GrossMargin__freightstorage ? this.GrossMargin__freightstorage : 0)
                        + parseFloat(this.GrossMargin__latepaymentcost ? this.GrossMargin__latepaymentcost : 0)
                        + parseFloat(this.GrossMargin__othercost ? this.GrossMargin__othercost : 0)
                        + parseFloat(this.GrossMargin__paymenttermcost ? this.GrossMargin__paymenttermcost : 0)
                        + parseFloat(this.GrossMargin__purchase ? this.GrossMargin__purchase : 0)
                        + parseFloat(this.GrossMargin__shortpayment ? this.GrossMargin__shortpayment : 0)
                        + parseFloat(this.GrossMargin__warehousecost ? this.GrossMargin__warehousecost : 0)
                    let grossMargin = parseFloat(this.GrossMargin__selling ? this.GrossMargin__selling : 0) - cost
                    this.GrossMargin__basicgrossmargin = grossMargin
                    this.GrossMargin__grossmargin = grossMargin
                    resolve('done')
                })
            },
            updateLatePaymentCost(){
                if(this.document.GrossMargin.overdue > 0){
                    this.loading.updatingLatePayment = true

                    let latePaymentCutOffDate = new Date(this.AppicVars.gmLatePaymentCutoffDate).getTime()
                    let paymentReceivedDate = new Date(this.document.GrossMargin.received).getTime()
                    if(paymentReceivedDate >= latePaymentCutOffDate) {
                        this.newLatePaymentRates = true
                        if (this.document.GrossMargin.overdue > 0 && this.document.GrossMargin.overdue < 14) this.GrossMargin__latepaymentcost = this.GrossMargin__selling * this.GrossMargin__Var__latepayment_v2_14_days
                        if (this.document.GrossMargin.overdue >= 15 && this.document.GrossMargin.overdue <= 30) this.GrossMargin__latepaymentcost = this.GrossMargin__selling * this.GrossMargin__Var__latepayment_v2_15_30_days
                        if (this.document.GrossMargin.overdue >= 31 && this.document.GrossMargin.overdue <= 60) this.GrossMargin__latepaymentcost = this.GrossMargin__selling * this.GrossMargin__Var__latepayment_v2_31_60_days
                        if (this.document.GrossMargin.overdue >= 61) this.GrossMargin__latepaymentcost = this.GrossMargin__selling * this.GrossMargin__Var__latepayment_v2_61_days
                    } else {
                        if (this.document.GrossMargin.overdue > 0 && this.document.GrossMargin.overdue < 14) this.GrossMargin__latepaymentcost = this.GrossMargin__selling * this.GrossMargin__Var__latepayment_14_days
                        if (this.document.GrossMargin.overdue >= 14 && this.document.GrossMargin.overdue <= 30) this.GrossMargin__latepaymentcost = this.GrossMargin__selling * this.GrossMargin__Var__latepayment_14_to_30_days
                        if (this.document.GrossMargin.overdue > 30) this.GrossMargin__latepaymentcost = this.GrossMargin__selling * this.GrossMargin__Var__latepayment_30_days
                    }

                    this.updateGrossMarginValue()
                        .then(() => {
                            this.updateConvertedAmounts()
                                .then(() => {
                                    this.updatePercentages()
                                        .then(() => {
                                            this.loader = false
                                            //save the gross margin
                                            this.updateGrossMargin()
                                                .then(() => {
                                                    // reload the data
                                                    this.loadGrossMarginById(this.grossMarginId)
                                                    this.$toast.success(this.$t('message.successes.grossMarginUpdated'),
                                                        {
                                                            classes: ['icon-float-left'],
                                                            icon: 'check_circle_outline'
                                                        }
                                                    )
                                                    this.loading.updatingLatePayment = false
                                                })
                                        })
                                })
                                .catch(() => {
                                    this.loader = false
                                    this.loading.updatingLatePayment = false
                                    this.$toast.error(this.$t('message.errors.grossMarginNotUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                })
                        })
                        .catch(() => {
                            this.loader = false
                            this.loading.updatingLatePayment = false
                            this.$toast.error(this.$t('message.errors.grossMarginNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                } else {
                    this.loading.updatingLatePayment = true
                    let _this = this
                    setTimeout(function(){
                        _this.loading.updatingLatePayment = false
                        _this.$toast.success(_this.$t('message.successes.nothingToUpdate'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    },500)
                }
            },
            updatePaymentTermCost(){
                if(this.document.GrossMargin.payment_term_effect >= 1) {
                    this.loading.updatingPaymentTerm = true

                    if (this.document.GrossMargin.payment_term_effect == 1) this.GrossMargin__paymenttermcost = this.GrossMargin__selling * this.GrossMargin__Var__paymentterm_30_days
                    if (this.document.GrossMargin.payment_term_effect == 2) this.GrossMargin__paymenttermcost = this.GrossMargin__selling * this.GrossMargin__Var__paymentterm_45_days
                    if (this.document.GrossMargin.payment_term_effect == 3) this.GrossMargin__paymenttermcost = this.GrossMargin__selling * this.GrossMargin__Var__paymentterm_60_days

                    this.updateGrossMarginValue()
                        .then(() => {
                            this.updateConvertedAmounts()
                                .then(() => {
                                    this.updatePercentages()
                                        .then(() => {
                                            this.loader = false
                                            //save the gross margin
                                            this.updateGrossMargin()
                                                .then(() => {
                                                    // reload the data
                                                    this.loadGrossMarginById(this.grossMarginId)
                                                    this.$toast.success(this.$t('message.successes.grossMarginUpdated'),
                                                        {
                                                            classes: ['icon-float-left'],
                                                            icon: 'check_circle_outline'
                                                        }
                                                    )
                                                    this.loading.updatingPaymentTerm = false
                                                })
                                        })
                                })
                                .catch(() => {
                                    this.loader = false
                                    this.loading.updatingPaymentTerm = false
                                    this.$toast.error(this.$t('message.errors.grossMarginNotUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                })
                        })
                        .catch(() => {
                            this.loader = false
                            this.loading.updatingPaymentTerm = false
                            this.$toast.error(this.$t('message.errors.grossMarginNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                } else {
                    this.loading.updatingPaymentTerm = true
                    let _this = this
                    setTimeout(function(){
                        _this.loading.updatingPaymentTerm = false
                        _this.$toast.success(_this.$t('message.successes.nothingToUpdate'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    },500)
                }
            },
            updatePercentages() {
                return new Promise((resolve, reject) => {
                    this.percentages.carriage = (this.GrossMargin__carriage/this.GrossMargin__selling) * 100
                    this.percentages.commission = (this.GrossMargin__commission/this.GrossMargin__selling) * 100
                    this.percentages.freightstorage = (this.GrossMargin__freightstorage/this.GrossMargin__selling) * 100
                    this.percentages.latepaymentcost = (this.GrossMargin__latepaymentcost/this.GrossMargin__selling) * 100
                    this.percentages.othercost = (this.GrossMargin__othercost/this.GrossMargin__selling) * 100
                    this.percentages.paymenttermcost = (this.GrossMargin__paymenttermcost/this.GrossMargin__selling) * 100
                    this.percentages.purchase = (this.GrossMargin__purchase/this.GrossMargin__selling) * 100
                    this.percentages.selling = (this.GrossMargin__selling/this.GrossMargin__selling) * 100
                    this.percentages.shortpayment = (this.GrossMargin__shortpayment/this.GrossMargin__selling) * 100
                    this.percentages.warehousecost = (this.GrossMargin__warehousecost/this.GrossMargin__selling) * 100
                    if(this.GrossMargin__Agent__has_commission == 1){
                        this.percentages.basicgrossmargin = (this.GrossMargin__basicgrossmargin / this.GrossMargin__selling) * 100
                        this.percentages.mincomhqamount = (this.GrossMargin__Agent__CommissionValue__mincomhqamount / this.GrossMargin__selling) * 100
                        this.percentages.mincomsubamount = (this.GrossMargin__Agent__CommissionValue__mincomsubamount / this.GrossMargin__selling) * 100
                        this.percentages.minbalance = (this.GrossMargin__Agent__CommissionValue__minbalance / this.GrossMargin__selling) * 100
                        this.percentages.addcomhqamount = (this.GrossMargin__Agent__CommissionValue__addcomhqamount / this.GrossMargin__selling) * 100
                        this.percentages.addcomsubamount = (this.GrossMargin__Agent__CommissionValue__addcomsubamount / this.GrossMargin__selling) * 100
                        this.percentages.grossmarginhq = (this.GrossMargin__Agent__CommissionValue__grossmarginhq / this.GrossMargin__selling) * 100
                        this.percentages.grossmarginsub = (this.GrossMargin__Agent__CommissionValue__grossmarginsub / this.GrossMargin__selling) * 100
                    }
                    this.percentages.grossmargin = (this.GrossMargin__grossmargin/this.GrossMargin__selling) * 100
                    resolve('done')
                })
            },
            updatePrices () {
                this.loading.updatingPrices = true
                let promise = new Promise((resolve, reject) => {
                    api
                        .put('/grossmargins/' + this.grossMarginId + '/prices')
                        .then(response =>{
                            if(response.data.status == 'success') {
                                resolve(response.data)
                            } else {
                                resolve('error')
                            }
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                promise
                    .then(response => {
                        if(response.status == 'success') {
                            this.GrossMargin__selling = response.prices.selling
                            this.GrossMargin__purchase = response.prices.purchase
                            //recalculate warehouse charges
                            if(this.GrossMargin__source == 'whsales_invoice'){
                                this.GrossMargin__warehousecost = this.GrossMargin__purchase * this.GrossMargin__Var__warehousecost
                            }
                            //recalculate late payment charges
                            if(this.document.GrossMargin.overdue){
                                if(this.document.GrossMargin.overdue > 0 && this.document.GrossMargin.overdue < 14) this.GrossMargin__latepaymentcost = this.GrossMargin__selling * this.GrossMargin__Var__latepayment_14_days
                                if(this.document.GrossMargin.overdue >= 14 && this.document.GrossMargin.overdue <= 30) this.GrossMargin__latepaymentcost = this.GrossMargin__selling * this.GrossMargin__Var__latepayment_14_to_30_days
                                if(this.document.GrossMargin.overdue > 30) this.GrossMargin__latepaymentcost = this.GrossMargin__selling * this.GrossMargin__Var__latepayment_30_days
                            }
                            //recalculate payment term charges
                            if(true){
                                if(this.document.GrossMargin.payment_term_effect == 1) this.GrossMargin__paymenttermcost = this.GrossMargin__selling * this.GrossMargin__Var__paymentterm_30_days
                                if(this.document.GrossMargin.payment_term_effect == 2) this.GrossMargin__paymenttermcost = this.GrossMargin__selling * this.GrossMargin__Var__paymentterm_45_days
                                if(this.document.GrossMargin.payment_term_effect == 3) this.GrossMargin__paymenttermcost = this.GrossMargin__selling * this.GrossMargin__Var__paymentterm_60_days
                            }

                            this.updateGrossMarginValue()
                                .then(() => {
                                    this.updateConvertedAmounts()
                                        .then(() => {
                                            this.updatePercentages()
                                                .then(() => {
                                                    this.loader = false
                                                    //save the gross margin
                                                    this.updateGrossMargin()
                                                        .then(() => {
                                                            // reload the data
                                                            this.loadGrossMarginById(this.grossMarginId)
                                                            this.$toast.success(this.$t('message.successes.grossMarginPricesUpdated'),
                                                                {
                                                                    classes: ['icon-float-left'],
                                                                    icon: 'check_circle_outline'
                                                                }
                                                            )
                                                            this.loading.updatingPrices = false
                                                        })
                                                })
                                        })
                                        .catch(() => {
                                            this.loader = false
                                            this.loading.updatingPrices = false
                                        })
                                })
                                .catch(() => {
                                    this.loader = false
                                    this.loading.updatingPrices = false
                                })
                        } else {
                            this.$toast.error(this.$t('message.errors.grossMarginPricesNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.updatingPrices = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.grossMarginPricesNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.updatingPrices = false
                    })
            },
            updateFreightInsuranceCost() {
                if(this.GrossMargin__source == 'whsales_invoice') {
                    this.loading.updatingFreightInsuranceCost = true

                    api
                        .get('/localsales/' + this.GrossMargin__localsale_id + '/costs/freight-storage-import')
                        .then((response)=>{
                            if(response.data.status == 'success') {
                                this.GrossMargin__freightstorage = response.data.data

                                this.updateGrossMarginValue()
                                    .then(() => {
                                        this.updateConvertedAmounts()
                                            .then(() => {
                                                this.updatePercentages()
                                                    .then(() => {
                                                        this.loader = false
                                                        //save the gross margin
                                                        this.updateGrossMargin()
                                                            .then(() => {
                                                                // reload the data
                                                                this.loadGrossMarginById(this.grossMarginId)
                                                                this.$toast.success(this.$t('message.successes.grossMarginUpdated'),
                                                                    {
                                                                        classes: ['icon-float-left'],
                                                                        icon: 'check_circle_outline'
                                                                    }
                                                                )
                                                                this.loading.updatingFreightInsuranceCost = false
                                                            })
                                                    })
                                            })
                                            .catch(() => {
                                                this.loader = false
                                                this.loading.updatingFreightInsuranceCost = false
                                                this.$toast.error(this.$t('message.errors.grossMarginNotUpdated'),
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                    })
                                    .catch(() => {
                                        this.loader = false
                                        this.loading.updatingFreightInsuranceCost = false
                                        this.$toast.error(this.$t('message.errors.grossMarginNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                            } else {
                                this.loader = false
                                this.loading.updatingFreightInsuranceCost = false
                                this.$toast.error(this.$t('message.errors.freightInsuranceNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(()=>{
                            this.$toast.error(this.$t('message.errors.freightInsuranceNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })

                } else {
                    this.loading.updatingFreightInsuranceCost = true
                    let _this = this
                    setTimeout(function(){
                        _this.loading.updatingWareHouseCost = false
                        _this.$toast.success(_this.$t('message.successes.nothingToUpdate'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    },500)
                }
            },
            updateWareHouseCost(){
                if(this.GrossMargin__source == 'whsales_invoice') {
                    this.loading.updatingWareHouseCost = true
                    if(this.GrossMargin__version == '3.0') {
                        api
                            .get('/localsales/' + this.GrossMargin__localsale_id + '/costs/warehouse')
                            .then((response)=>{
                                if(response.data.status == 'success') {
                                    this.GrossMargin__warehousecost = response.data.data

                                    this.updateGrossMarginValue()
                                        .then(() => {
                                            this.updateConvertedAmounts()
                                                .then(() => {
                                                    this.updatePercentages()
                                                        .then(() => {
                                                            this.loader = false
                                                            //save the gross margin
                                                            this.updateGrossMargin()
                                                                .then(() => {
                                                                    // reload the data
                                                                    this.loadGrossMarginById(this.grossMarginId)
                                                                    this.$toast.success(this.$t('message.successes.grossMarginUpdated'),
                                                                        {
                                                                            classes: ['icon-float-left'],
                                                                            icon: 'check_circle_outline'
                                                                        }
                                                                    )
                                                                    this.loading.updatingWareHouseCost = false
                                                                })
                                                        })
                                                })
                                                .catch(() => {
                                                    this.loader = false
                                                    this.loading.updatingWareHouseCost = false
                                                    this.$toast.error(this.$t('message.errors.grossMarginNotUpdated'),
                                                        {
                                                            classes: ['icon-float-left'],
                                                            icon: 'error_outline'
                                                        }
                                                    )
                                                })
                                        })
                                        .catch(() => {
                                            this.loader = false
                                            this.loading.updatingWareHouseCost = false
                                            this.$toast.error(this.$t('message.errors.grossMarginNotUpdated'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                } else {
                                    this.loading.updatingWareHouseCost = false
                                    this.$toast.error(this.$t('message.errors.freightInsuranceNotUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                            })
                            .catch(()=>{
                                this.loader = false
                                this.loading.updatingWareHouseCost = false
                                this.$toast.error(this.$t('message.errors.freightInsuranceNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                    } else {

                        this.GrossMargin__warehousecost = this.GrossMargin__purchase * this.GrossMargin__Var__warehousecost

                        this.updateGrossMarginValue()
                            .then(() => {
                                this.updateConvertedAmounts()
                                    .then(() => {
                                        this.updatePercentages()
                                            .then(() => {
                                                this.loader = false
                                                //save the gross margin
                                                this.updateGrossMargin()
                                                    .then(() => {
                                                        // reload the data
                                                        this.loadGrossMarginById(this.grossMarginId)
                                                        this.$toast.success(this.$t('message.successes.grossMarginUpdated'),
                                                            {
                                                                classes: ['icon-float-left'],
                                                                icon: 'check_circle_outline'
                                                            }
                                                        )
                                                        this.loading.updatingWareHouseCost = false
                                                    })
                                            })
                                    })
                                    .catch(() => {
                                        this.loader = false
                                        this.loading.updatingWareHouseCost = false
                                        this.$toast.error(this.$t('message.errors.grossMarginNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                            })
                            .catch(() => {
                                this.loader = false
                                this.loading.updatingWareHouseCost = false
                                this.$toast.error(this.$t('message.errors.grossMarginNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                    }
                } else {
                    this.loading.updatingWareHouseCost = true
                    let _this = this
                    setTimeout(function(){
                        _this.loading.updatingWareHouseCost = false
                        _this.$toast.success(_this.$t('message.successes.nothingToUpdate'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    },500)
                }
            },
            viewPdf () {
                let document = encodeURIComponent('GM ' + this.document.GrossMargin.document_title)
                // let user = JSON.parse(localStorage.getItem('user'))
                window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/gross-margins/print/'
                    + this.GrossMargin__source
                    + '/'
                    + this.grossMarginId
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                // this.openedTabs.push(tab)
            },
            viewPdfBlob () {
                window.open(
                    process.env.VUE_APP_BASE_URL
                    + '/v1/gross-margins/print-v2/'
                    + this.GrossMargin__source
                    + '/'
                    + this.grossMarginId
                    , "_blank");
            }
        },
        created () {
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
            if(this.$route.matched.some(({name}) => name === 'update_gross_margin')) {
                this.loadGrossMarginById(this.grossMarginId)
            } else if(this.$route.matched.some(({name}) => name === 'add_gross_margin')){
                this.loader = true
                this.loadSourceDetail(this.source, this.sourceId)
                    .then(response => {
                        if('GrossMargin' in response) {
                            this.document = response
                            this.GrossMargin__Species__names = response.GrossMargin.species
                            this.GrossMargin__selling = response.GrossMargin.selling
                            this.GrossMargin__purchase = response.GrossMargin.purchase
                            this.GrossMargin__latepaymentcost = response.GrossMargin.latepaymentcost
                            this.GrossMargin__paymenttermcost = response.GrossMargin.paymenttermcost
                            if (response.GrossMargin?.contract_id && response.GrossMargin.contract_id != null) {
                                this.GrossMargin__contract_id = response.GrossMargin.contract_id
                            }
                            if (response.GrossMargin?.localsale_id && response.GrossMargin.localsale_id != null) {
                                this.GrossMargin__localsale_id = response.GrossMargin.localsale_id
                            }
                            this.GrossMargin__currency_id = response.GrossMargin.currency_id
                            this.GrossMargin__currencydate = response.GrossMargin.currencydate
                            this.GrossMargin__currencyrate = response.GrossMargin.currencyrate
                            this.GrossMargin__usdrate = response.GrossMargin.usdrate
                            this.GrossMargin__volume = response.GrossMargin.volume
                            this.GrossMargin__warehousecost = response.GrossMargin.warehousecost
                            this.GrossMargin__freightstorage = response.GrossMargin.freightstorage
                            this.GrossMargin__source = this.source
                        } else {
                            this.$toast.error(this.$t('message.errors.sourceDetailCannotBeLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loader = false
                        }

                        this.updateGrossMarginValue()
                            .then(() => {
                                this.updateConvertedAmounts()
                                    .then(() => {
                                        this.newGrossMarginUnsaved = true;
                                        this.loader = false
                                    })
                                    .catch(error => {
                                        console.log(error)
                                        this.newGrossMarginUnsaved = true;
                                        this.loader = false
                                    })
                            })
                            .catch(error => {
                                console.log(error)
                                this.newGrossMarginUnsaved = true;
                                this.loader = false
                            })
                    })
                    .catch(error => {
                        console.log(error)
                        this.newGrossMarginUnsaved = true;
                        this.loader = false
                    })
            }
        },
        mounted(){
            this.$store.watch(
                function(state) {
                    return state.appic.grossmargin.update.GrossMargin
                },
                () => {
                    this.updatedGrossMarginUnsaved = true
                },
                { deep: true }
            )
            this.$store.watch(
                function(state) {
                    return state.appic.grossmargin.current.GrossMargin
                },
                () => {
                    this.newGrossMarginUnsaved = true
                },
                { deep: true }
            )
        }
    }
</script>

<style>
.bg-lightblue {
    background-color: lightblue;
}
.bg-lightgrey {
    background-color: #f5f5f5;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
.font-16-px td {
    font-size: 16px !important;
}
.dense-inputs .v-input {
    font-size: 16px !important;
}
</style>