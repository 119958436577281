<template>
    <div>
        <v-btn @click="checkerDialog = true">{{ $t('message.checkEudrDocuments') }}</v-btn>
        <v-dialog
            :width="dialogWidth"
            persistent
            v-model="checkerDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
        >
            <v-card>
                <v-card-title>{{ $t('message.checkEudrDocuments') + ': ' + contractNo }}</v-card-title>
                <v-card-text>
                    <v-overlay
                        :value="loading.get"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                />
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-simple-table
                        class="appic-table-light specification-tables"
                        dense
                    >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct">#</th>
                                    <th class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left">{{ headers[0].text }}</th>
                                    <th class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left">{{ headers[1].text }}</th>
                                    <th class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left">{{ headers[2].text }}</th>
                                    <th class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left">{{ headers[3].text }}</th>
                                    <th class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left">{{ headers[4].text }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(document, index) in documents" v-bind:key="'row_' + index.toString()">
                                    <td class="text-start">{{ index + 1 }}</td>
                                    <td class="text-start">{{ document.asset_title }}</td>
                                    <td class="text-start">
                                        <v-btn class="ml-0" icon @click="viewFile(document.location)">
                                            <v-icon style="color: darkgray !important;" small>fas fa-external-link-alt</v-icon>
                                        </v-btn>
                                    </td>
                                    <td class="text-start">
                                        <v-btn class="ml-0" :loading="document.status.verifying" small @click="verifyEudrDocument(index, document.id)">
                                            <span v-if="document.status.verified === false && document.status.verifying === false">{{ $t('message.verify') }}</span>
                                            <span v-if="document.status.verifying === true">{{ $t('message.verifying') }}</span>
                                            <span v-if="document.status.verified === true && document.status.verifying === false">{{ $t('message.verified') }}</span>
                                        </v-btn>
                                    </td>
                                    <td class="text-start" style="min-width: 100px">{{ document.result }}</td>
                                    <td class="text-start" style="min-width: 100px">

                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions class="pl-7">
                    <v-btn color="default" small @click="checkerDialog = false" >{{$t('message.dismiss')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";

export default {
    name: "EudrDocumentsChecker",
    props: [
        'contractId',
        'contractNo',
        'files',
        'updateMode'
    ],
    data() {
        return {
            checkerDialog: false,
            dialogWidth: '600px',
            documents: [],
            headers: [
                { id: 1, text: this.$t('message.name') },
                { id: 2, text: this.$t('message.file') },
                { id: 3, text: this.$t('message.action') },
                { id: 4, text: this.$t('message.result') },
                { id: 5, text: this.$t('message.resultDetail') },
            ],
            loading: {
                get: false
            },
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('shipment', {
            Shipment__documents: 'Shipment.documents'
        }, 'statePrefix'),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        })
    },
    methods: {
        loadDocuments() {
            if(this.files.length > 0) {
                this.files.forEach((file) => {
                    const assetDocument = this.Shipment__documents.find((d) => parseInt(d.AssetDocument.doctype_id) === parseInt(file.Document.doctype_id))
                    if(assetDocument) {
                        const document = {
                            id: assetDocument.AssetDocument.id,
                            asset_title: assetDocument.AssetDocument.asset_title,
                            doctype_id: assetDocument.AssetDocument.doctype_id,
                            location: assetDocument.AssetDocument.location,
                            status: {
                                verified: false,
                                verifying: false
                            },
                            result: null,
                            resultDetail: null
                        }
                        this.documents.push(document)
                    }
                })
            } else {
                this.$toast.error(this.$t('message.errors.documentsNotLoaded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        verifyEudrDocument(idx, documentId) {
            this.documents[idx]['status']['verifying'] = true;

            //temporary only
            const _this = this
            setTimeout(function(){
                _this.documents[idx]['status']['verifying'] = false;
                _this.dialogWidth = '1000px';
            }, 3000)
        },
        viewFile (file) {
            let tab = null;
            tab = window.open(file, '_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    watch: {
        checkerDialog(value) {
            if (!value) {
                this.documents = [];
                this.dialogWidth = '600px';
            } else {
                this.loadDocuments();
            }
        }
    },
    created() {

    }
}
</script>

<style scoped>
.v-dialog__content::v-deep .v-dialog {
    min-width: 600px !important;
}
</style>