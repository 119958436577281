<template>
	<div>
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? (Lca__Contract__version != null ? [Lca__Contract__title, Lca__Contract__revision_no , Lca__Contract__partial_no].filter(Boolean).join('-') : Lca__Contract__title) : ''" help-page-component="AddLca"></appic-page-title-bar>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <LcaAlert
                        :alert="$t('message.closeAlert')"
                        mode="add"
                        type="warning"
                        class="mt-3"
                        v-if="addMode && newLcaUnsaved"
                />
                <LcaAlert
                        :alert="$t('message.closeAlert')"
                        mode="update"
                        type="warning"
                        class="mt-3"
                        v-if="updateMode && updatedLcaUnsaved"
                />
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation ref="lcaForm" v-model="validForm">
                        <v-overlay
                                :value="loader"
                                absolute
                                opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                            color="primary"
                                            indeterminate
                                            size="40"
                                            width="6"
                                    />
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                            accordion
                            v-model="panel"
                            multiple
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-6 v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadActiveLca') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items="allActiveLcas"
                                                    :loading="loadingAnotherLca"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Lca.id"
                                                    item-text="Contract.title"
                                                    required
                                                    solo
                                                    @change="loadAnotherActiveLca($event)"
                                            >
                                                <template v-slot:item="{item}">
                                                    <div class="d-flex flex-column py-1">
                                                        <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                        <span class="font-xs"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                        <span class="font-xs"> {{ $t('message.supplier') + ': ' + (item.Supplier.code ? item.Supplier.code : item.Supplier.otsname) }} </span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row pt-3 v-if="addMode == true">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.relatedContract') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items="allLcContractsWithoutLca"
                                                    :placeholder="$t('message.startTypingToSearch')"
                                                    :value="Lca__contract_id"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Contract.id"
                                                    item-text="Contract.title"
                                                    required
                                                    return-object
                                                    solo
                                                    @change="loadContract($event)"
                                            >
                                                <template v-slot:item="{item}">
                                                    <div class="d-flex flex-column py-1">
                                                        <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                        <span class="font-sm"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                        <span class="font-sm"> {{ $t('message.supplier') + ': ' + item.Supplier.title }} </span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{item}">
                                                    <span>{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.beneficiaryName') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Lca__Contract__salestype_id == 2 ? Lca__Supplier__title : Lca__Office__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.bank') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-select
                                                    :items=Lca__Banks
                                                    :placeholder="$t('message.startTypingToSearch')"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    required
                                                    v-model="Lca__bank_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.file') }}</v-flex>
                                        <v-flex xs6 lg4 md8 py-1>{{ Lca__file }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.lcaDate') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-menu
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    max-width="290px"
                                                    min-width="290px"
                                                    ref="lcaDateMenu"
                                                    transition="scale-transition"
                                                    v-model="lcaDateMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            :label="Lca__lcadate ? '' : $t('message.required')"
                                                            :value="computedLcaDateFormatted"
                                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                            append-icon="event"
                                                            clearable
                                                            class="force-text-left"
                                                            hint="DD/MM/YY format"
                                                            hide-details="auto"
                                                            background-color="white"
                                                            persistent-hint
                                                            readonly
                                                            solo
                                                            v-on="on"
                                                            @click:clear="Lca__lcadate = null"
                                                            @click:append="lcaDateMenu = true"
                                                    />
                                                </template>
                                                <v-date-picker
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                        v-model="Lca__lcadate"
                                                        @input="lcaDateMenu = false"
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.amount') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <div>{{ Lca__Contract__currency + ' ' + formatThisNumber(Lca__Contract__value, currencyFormatPrecision) }}</div>
                                            <div class="d-flex flex-row no-gutters align-center">
                                                <span class="col-3">{{ $t('message.deposit') }}</span>
                                                <span class="col-9">
                                                    <v-text-field
                                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                            :value="Lca__Json__deposit"
                                                            autocomplete="new-password"
                                                            class="force-text-left"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            solo
                                                            @change="Lca__Json__deposit = $event"
                                                    />
                                                </span>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.latestShipmentDate') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-menu
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    max-width="290px"
                                                    min-width="290px"
                                                    ref="invoiceDateMenu"
                                                    transition="scale-transition"
                                                    v-model="lcaLatestShipmentDateMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            :label="Lca__latestshipdate ? '' : $t('message.required')"
                                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                            :value="computedLcaLatestShipmentDateFormatted"
                                                            append-icon="event"
                                                            clearable
                                                            class="force-text-left"
                                                            hint="DD/MM/YY format"
                                                            hide-details="auto"
                                                            background-color="white"
                                                            persistent-hint
                                                            readonly
                                                            solo
                                                            v-on="on"
                                                            @click:clear="Lca__latestshipdate = null"
                                                            @click:append="lcaLatestShipmentDateMenu = true"
                                                    />
                                                </template>
                                                <v-date-picker
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                        v-model="Lca__latestshipdate"
                                                        @input="lcaLatestShipmentDateMenu = false"
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.lcLatestReceivedDate') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-menu
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    max-width="290px"
                                                    min-width="290px"
                                                    ref="invoiceDateMenu"
                                                    transition="scale-transition"
                                                    v-model="lcaLatestReceivedDateMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            :label="Lca__lcalatestdate ? '' : $t('message.required')"
                                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                            :value="computedLcaLatestReceivedDateFormatted"
                                                            append-icon="event"
                                                            clearable
                                                            class="force-text-left"
                                                            hint="DD/MM/YY format"
                                                            hide-details="auto"
                                                            background-color="white"
                                                            persistent-hint
                                                            readonly
                                                            solo
                                                            v-on="on"
                                                            @click:clear="Lca__lcalatestdate = null"
                                                            @click:append="lcaLatestReceivedDateMenu = true"
                                                    />
                                                </template>
                                                <v-date-picker
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                        v-model="Lca__lcalatestdate"
                                                        @input="lcaLatestReceivedDateMenu = false"
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.expiryDate') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-menu
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    max-width="290px"
                                                    min-width="290px"
                                                    ref="invoiceDateMenu"
                                                    transition="scale-transition"
                                                    v-model="lcaExpiryDateMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            :label="Lca__expirydate ? '' : $t('message.required')"
                                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                            :value="computedLcaExpiryDateFormatted"
                                                            append-icon="event"
                                                            clearable
                                                            class="force-text-left"
                                                            hint="DD/MM/YY format"
                                                            hide-details="auto"
                                                            background-color="white"
                                                            persistent-hint
                                                            readonly
                                                            solo
                                                            v-on="on"
                                                            @click:clear="Lca__expirydate = null"
                                                            @click:append="lcaExpiryDateMenu = true"
                                                    />
                                                </template>
                                                <v-date-picker
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                        v-model="Lca__expirydate"
                                                        @input="lcaExpiryDateMenu = false"
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.partialShipment') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-radio-group
                                                    class="mt-1"
                                                    dense
                                                    hide-details
                                                    row
                                                    v-model="Lca__partialshipment"
                                            >
                                                <v-radio :label="$t('message.allowed')" value="Allowed"/>
                                                <v-radio :label="$t('message.notAllowed')" value="Not Allowed"/>
                                            </v-radio-group>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.transhipment') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-radio-group
                                                    class="mt-1"
                                                    dense
                                                    hide-details="auto"
                                                    row
                                                    v-model="Lca__transhipment"
                                            >
                                                <v-radio :label="$t('message.allowed')" value="Allowed"/>
                                                <v-radio :label="$t('message.notAllowed')" value="Not Allowed"/>
                                            </v-radio-group>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.portOfLoading') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Lca__Contract__port_of_loading }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.finalDestination') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Lca__Contract__final_destination }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.incotermOfLc') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Lca__Contract__incoterm }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.lcTerm') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :value="Lca__negotiable"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    required
                                                    @change="Lca__negotiable = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.itemDescriptions') }}</v-flex>
                                        <v-flex xs6 lg8 md9 py-1>
                                            <v-data-table
                                                    :headers="descriptionHeaders"
                                                    :items="ItemDescriptions"
                                                    calculate-widths
                                                    class="mt-3 appic-table-light specification-table"
                                                    dense
                                                    disable-pagination
                                                    disable-sort
                                                    hide-default-footer
                                                    item-key="index"
                                            >
                                                <template v-slot:item.lineNumber="{ item }">
                                                    <div class="text-center">{{ '#' + item.lineNumber }}</div>
                                                </template>
                                                <template v-slot:item.Salesdescription.title="{ item }">
                                                    <v-textarea
                                                            :value="item.Salesdescription.title"
                                                            class="custom pa-0 mb-1 bg-lightgrey"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            readonly
                                                            rows="3"
                                                    />
                                                </template>
                                                <template v-slot:item.Salesdescription.lcdescription="{ item }">
                                                    <v-textarea
                                                            :value="item.Salesdescription.lcdescription"
                                                            class="custom pt-0 mb-1 bg-lightgrey"
                                                            clearable
                                                            hide-details="auto"
                                                            dense
                                                            rows="3"
                                                            @change="updateDescriptionRowHandle( item.index, 'Salesdescription.lcdescription', $event)"
                                                    />
                                                </template>
                                            </v-data-table>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.description') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-textarea
                                                    :value="Lca__description"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    rows="3"
                                                    solo
                                                    @change="Lca__description = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.documents') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.noOfDocumentsRequired') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-radio-group
                                                    class="mt-1"
                                                    dense
                                                    hide-details="auto"
                                                    row
                                                    v-model="Lca__docrequired"
                                            >
                                                <v-radio
                                                    :label="$t('message.duplicate')"
                                                    value="duplicate"
                                                ></v-radio>
                                                <v-radio
                                                    :label="$t('message.triplicate')"
                                                    value="triplicate"
                                                ></v-radio>
                                            </v-radio-group>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs12 lg12 md12 pl-5 py-1>
                                            <LcaClauseList :update-mode="updateMode"/>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_lca')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <DocumentHistory asset="lcas" :asset_id="Lca__id"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row>
                            <v-flex d-flex xs12>
                                <v-layout row px-3 pt-3 mb-3>
                                    <PrintLetterOfCreditAdviceButton
                                        :letter-of-credit-advice-id="lcaId"
                                        :list-mode="false"
                                        :update-mode="updateMode"
                                    />
                                    <v-btn
                                        :loading="loading.reset"
                                        @click="resetLcaForm()"
                                        class="ml-3"
                                        color="default"
                                        small
                                        v-if="addMode == true"
                                    >
                                        <v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                    </v-btn>
                                    <v-btn
                                        :loading="loading.add"
                                        class="ml-3"
                                        color="info"
                                        small
                                        @click="addLca()"
                                        v-if="addMode == true"
                                    >{{ $t('message.create') }}
                                    </v-btn>
                                    <v-btn
                                        :loading="loading.save"
                                        class="ml-3"
                                        color="info"
                                        small
                                        @click="saveLca()"
                                        v-if="addMode == false"
                                    >
                                        {{ $t('message.save') }}
                                    </v-btn>
                                    <v-btn
                                        :loading="loading.cancel"
                                        class="ml-3"
                                        color="error"
                                        small
                                        v-if="addMode == false"
                                        @click="cancelLca(Lca__id)"
                                    >{{ $t('message.cancel') }}
                                    </v-btn>
                                    <v-btn
                                        class="ml-3"
                                        small
                                        v-if="addMode == false"
                                        @click="sendAdviceDialog = true"
                                    >{{ $t('message.sendToBuyer')}}
                                    </v-btn>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <v-dialog
            overlay=false
            persistent
            transition="dialog-bottom-transition"
            v-model="sendAdviceDialog"
            width="800px"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.sendThisLcAdviceToBuyer') }}</v-card-title>
                <v-card-text class="white">
                    <v-row no-gutters class="pt-3 align-center">
                        <v-col cols="12">
                            <h2>{{ $t('message.selectContacts') }}</h2>
                            <v-simple-table class="appic-table-light specification-tables" dense>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="grey lighten-3 pa-0 width-1-pct"></th>
                                        <th class="grey lighten-3 pa-0 text-left">{{ $t('message.name')}}</th>
                                        <th class="grey lighten-3 pa-0 text-left">{{ $t('message.email')}}</th>
                                        <th class="grey lighten-3 pa-0 text-left">{{ $t('message.position')}}</th>
                                        <th class="grey lighten-3 pa-0 text-left">{{ $t('message.receiveNotifications')}}</th>
                                    </tr>
                                    </thead>
                                    <tbody v-if="buyerAdviceContacts.length > 0">
                                    <tr v-for="contact in buyerAdviceContacts" v-bind:key="contact.Contact.id">
                                        <td>
                                            <v-checkbox
                                                :value="contact.Contact.email"
                                                class="mt-1"
                                                dense
                                                hide-details
                                                v-model="selectedBuyerContacts"
                                            ></v-checkbox>
                                        </td>
                                        <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.name }}</td>
                                        <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.email }}</td>
                                        <td class="pt-2 pl-0 text-left">{{ contact.Contact.position }}</td>
                                        <td class="pt-2 pl-0 text-center">{{ contact.Contact.otsnotification == 1 ? $t('message.yes') : $t('message.no') }}</td>
                                    </tr>
                                    </tbody>
                                    <tbody v-else>
                                    <tr>
                                        <td colspan="5">{{ $t('message.noContactsFound') }}</td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3 white">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="sendAdviceDialog = false"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.send"
                        class="ml-3"
                        color="info"
                        small
                        @click="sendAdviceToBuyer()"
                    >{{ $t('message.send') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
<!--        <PrintLcaBlob-->
<!--            :lca-id="lcaId"-->
<!--            :key="updateKey"-->
<!--            @pdf-uploading="lcaUploading"-->
<!--            @pdf-uploaded="lcaUploaded"-->
<!--            @pdf-not-uploaded="lcaNotUploaded()"-->
<!--            v-if="hasLca"-->
<!--        />-->
    </div>
</template>

<script>
import { api } from "../../../api";
import { mapFields, mapMultiRowFields } from "vuex-map-fields";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {formatDate, isObject, numberFormat, scrollToFirstFormValidationError} from "Helpers/helpers";

import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from 'Helpers/vuex-map-fields-prefixed';
import router from "../../../router";
import LcaClauseList from "Components/Appic/LcaClauseList";
import { v4 as uuidv4 } from 'uuid';
import {validationRules} from "@/store/modules/appic/constants";

const DocumentHistory = () => import("Components/Appic/DocumentHistory");
const LcaAlert = () => import("Components/Appic/LcaAlert");
// const PrintLcaBlob = () => import("Views/v1/lcas/PrintLcaBlob");
const PrintLetterOfCreditAdviceButton = () => import("Components/Appic/Printing/PrintLetterOfCreditAdviceButton");

export default {
    name: "AddLca",
    components: {
        DocumentHistory,
        LcaAlert,
        LcaClauseList,
        // PrintLcaBlob,
        PrintLetterOfCreditAdviceButton
    },
    props: ['lcaId','contractId'],
    title: '',
    data(){
        return {
            allUnShippedMyContracts: [],
            buyerContacts: [],
            dialogs: {
                error:  false,
                error_message: ""
            },
            hasLca: false,
            lcaDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
            lcaDateMenu: false,
            lcaExpiryDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
            lcaExpiryDateMenu: false,
            lcaLatestShipmentDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
            lcaLatestShipmentDateMenu: false,
            lcaLatestReceivedDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
            lcaLatestReceivedDateMenu: false,
            lcaUrl: null,
            loader: false,
            loading: {
                add: false,
                lca: false,
                reset: false,
                save: false,
                send: false,
            },
            loadingAnotherLca: false,
            panel: [0],
            selectedBuyerContacts: [],
            sendAdviceDialog: false,
            unShippedContract: null,
            updateKey: 0,
            validForm: true
        }
    },
    computed: {
        validationRules() {
            return validationRules
        },
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('lca',{
            Lca__bank_id: 'Lca.bank_id',
            Lca__contract_id: 'Lca.contract_id',
            Lca__description: 'Lca.description',
            Lca__docrequired: 'Lca.docrequired',
            Lca__expirydate: 'Lca.expirydate',
            Lca__file: 'Lca.file',
            Lca__id: 'Lca.id',
            Lca__incoterm: 'Lca.incoterm',
            Lca__latestshipdate: 'Lca.latestshipdate',
            Lca__lcadate: 'Lca.lcadate',
            Lca__lcalatestdate: 'Lca.lcalatestdate',
            Lca__negotiable: 'Lca.negotiable',
            Lca__partialshipment: 'Lca.partialshipment',
            Lca__title: 'Lca.title',
            Lca__transhipment: 'Lca.transhipment',
            Lca__Banks: 'Lca.Banks',
            Lca__Contract__currency: 'Lca.Contract.currency',
            Lca__Contract__currency_id: 'Lca.Contract.currency_id',
            Lca__Contract__final_destination: 'Lca.Contract.final_destination',
            Lca__Contract__incoterm: 'Lca.Contract.incoterm',
            Lca__Contract__incoterm_id: 'Lca.Contract.incoterm_id',
            Lca__Contract__loadingport_id: 'Lca.Contract.loadingport_id',
            Lca__Contract__office_id: 'Lca.Contract.office_id',
            Lca__Contract__partial_no: 'Lca.Contract.partial_no',
            Lca__Contract__port_of_loading: 'Lca.Contract.port_of_loading',
            Lca__Contract__revision_no: 'Lca.Contract.revision_no',
            Lca__Contract__salestype_id: 'Lca.Contract.salestype_id',
            Lca__Contract__shippingport_id: 'Lca.Contract.shippingport_id',
            Lca__Contract__title: 'Lca.Contract.title',
            Lca__Contract__version: 'Lca.Contract.version',
            Lca__Contract__value: 'Lca.Contract.value',
            Lca__Customer__company_id: 'Lca.Customer.company_id',
            Lca__Json__deposit: 'Lca.Json.deposit',
            Lca__Office__title: 'Lca.Office.title',
            Lca__Supplier__id: 'Lca.Supplier.id',
            Lca__Supplier__title: 'Lca.Supplier.title',
            ItemDescriptions: 'ItemDescriptions'
        },'statePrefix'),
        ...mapFields('lca',{
            newLcaSaved: 'newLcaSaved',
            newLcaUnsaved: 'newLcaUnsaved',
            updatedLcaUnsaved: 'updatedLcaUnsaved',
            updatedLcaSaved: 'updatedLcaSaved'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('bankaccount', {
            allBankAccounts: 'allBankAccounts',
            allSupplierBankAccounts: 'allSupplierBankAccounts',
            findBankAccountsByOfficeAndCurrency: 'findBankAccountsByOfficeAndCurrency',
            findBankAccountsBySupplier: 'findBankAccountsBySupplier',
        }),
        ...mapGetters('contract',{
            allLcContractsWithoutLca: 'allLcContractsWithoutLca'
        }),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('lca',{
            allActiveLcas: 'allActiveLcas'
        }),
        ...mapGetters('shippingport', {
            allShippingLoadingPorts: 'allShippingLoadingPorts',
            allShippingDischargePorts: 'allShippingDischargePorts'
        }),
        ...mapGetters([
            'appConstants',
            'currencies',
            'incoterms',
            'validationRules'
        ]),
        addMode: function () {
            return this.$route.matched.some(({name}) => name === 'add_lca')
        },
        buyerAdviceContacts () {
            return this.buyerContacts.filter(contact => {
                return contact?.Contact?.otsnotification == 1
            })
        },
        computedLcaDateFormatted () {
            return formatDate(this.Lca__lcadate)
        },
        computedLcaExpiryDateFormatted () {
            return formatDate(this.Lca__expirydate)
        },
        computedLcaLatestReceivedDateFormatted () {
            return formatDate(this.Lca__lcalatestdate)
        },
        computedLcaLatestShipmentDateFormatted () {
            return formatDate(this.Lca__latestshipdate)
        },
        currencyFormatPrecision () {
            return this.allCurrencies.find((currency) => currency.Currency.id === this.Lca__Contract__currency_id)?.Currency?.formatPrecision
        },
        descriptionHeaders(){
            return [
                {
                    id: 0,
                    text: this.$t('message.lineNo'),
                    value: 'lineNumber',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-center',
                },
                {
                    id: 1,
                    text: this.$t('message.standardDescription'),
                    value: 'Salesdescription.title',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    id: 2,
                    text: this.$t('message.lcDescription'),
                    value: 'Salesdescription.lcdescription',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                }
            ]
        },
        updateMode: function () {
            return this.$route.matched.some(({name}) => name === 'update_lca')
        },
    },
    methods: {
        ...mapActions('bankaccount',{
            getAllBankAccounts: 'getAllBankAccounts',
            getAllSupplierBankAccounts: 'getAllSupplierBankAccounts'
        }),
        ...mapActions('contract',{
            getAllActiveContracts: 'getAllActiveContracts'
        }),
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        ...mapActions('lca',{
            cancelLcaById: 'cancelLcaById',
            createLca: 'createLca',
            getAllActiveLcas: 'getAllActiveLcas',
            getItemDescriptionsByContract: 'getItemDescriptionsByContract',
            getLcaToUpdateById: 'getLcaToUpdateById',
            loadContractWithoutLca: 'loadContractWithoutLca',
            resetAllActiveLcas: 'resetAllActiveLcas',
            resetCurrentLca: 'resetCurrentLca',
            sendLetterOfCreditAdviceToBuyer: 'sendLetterOfCreditAdviceToBuyer',
            updateLca: 'updateLca'
        }),
        ...mapActions('lcaclause',{
            getClausesByLca: 'getClausesByLca',
            loadDefaultClauses: 'loadDefaultClauses',
            resetCurrentClauses: 'resetCurrentClauses'
        }),
        ...mapActions('shippingport', {
            getAllShippingPorts: 'getAllShippingPorts',
        }),
        addLca () {
            if(this.$refs.lcaForm.validate()) {
                this.loading.add = true
                this.createLca()
                    .then((response) => {
                        if (response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.lcaAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.newLcaUnsaved = false
                            this.newLcaSaved = true
                            this.resetCurrentLca().then(() => {
                                this.resetAllActiveLcas().then(() => {
                                    router.push({name: 'update_lca', params: {lcaId: response.lca_id}}).catch(err => {
                                    })
                                })
                            })
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.lcaNotAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.lcaNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.lcaNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
                    .finally(() => {
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.lcaNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
                scrollToFirstFormValidationError()
            }
        },
        async cancelLca (val) {
            if(await this.$root.$confirm(this.$t('message.cancelLca'), this.$t('message.confirmations.continueLcaCancelAction'), {color: 'orange'})){
                this.cancelLcaById(val)
                    .then((response) => {
                        if(response.status == 'success'){
                            this.$toast.success(this.$t('message.successes.lcaDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            router.push({name: 'shipping_documents_listing'}).catch(err => {})
                        } else {
                            this.$toast.error(this.$t('message.errors.lcaNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loading.cancel = false
                    })
                    .catch(()=>{
                        this.$toast.error(this.$t('message.errors.lcaNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.cancel = false
                    })
            }
        },
        formatDate,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getBuyerContacts () {
            let company_id = this.Lca__Customer__company_id
            return new Promise((resolve, reject) => {
                api
                    .get("/companies/" + company_id + "/contacts")
                    .then(response => {
                        this.buyerContacts = response.data.data
                        resolve('done');
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        resolve('done');
                    })
            })
        },
        lcaNotUploaded() {
            this.loading.send = false
        },
        lcaUploaded(url) {
            this.lcaUrl = url
            this.loading.lca = false
            this.loading.send = false
        },
        lcaUploading() {
            this.loading.send = true
        },
        loadAnotherActiveLca (val) {
            router.push({name: 'update_lca', params: { lcaId : val}}).catch(err => {})
        },
        loadDescriptionsByContract (contract_id) {
            return new Promise((resolve, reject) => {
                api
                    .get("/contracts/" + contract_id + "/salesdescriptions",{
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        loadContract (contract){
            this.loader = true
            let self = this
            this.loadContractWithoutLca(contract)
                .then(() => {
                    let port = this.allShippingLoadingPorts.find(port => port.Shippingport.id == this.Lca__Contract__shippingport_id)
                    if(port){
                        this.Lca__Contract__final_destination = port.Shippingport.title
                    }

                    let incoterm = this.incoterms.find(incoterm => incoterm.Incoterm.id == this.Lca__Contract__incoterm_id)
                    if(incoterm){
                        this.Lca__Contract__incoterm = incoterm.Incoterm.title
                    }

                    let pol = this.allShippingLoadingPorts.find(port => port.Shippingport.id == this.Lca__Contract__loadingport_id)
                    if(pol){
                        this.Lca__Contract__port_of_loading = pol.Shippingport.title
                    }

                    this.Lca__Banks = [];
                    //get bank accounts for APP Timber and supplier
                    this.Lca__Banks.push({'header': this.appConstants.company})
                    let appBanks = this.findBankAccountsByOfficeAndCurrency(this.Lca__Contract__office_id, this.Lca__Contract__currency_id)
                    appBanks.forEach(bank => {
                        this.Lca__Banks.push({
                            'text': bank.Bank.title + ' ' + bank.Bank.account,
                            'value': bank.Bank.id
                        })
                    })

                    this.Lca__Banks.push({'header': this.$t('message.supplier')})
                    let supplierBanks = this.findBankAccountsBySupplier(this.Lca__Supplier__id)
                    supplierBanks.forEach(bank => {
                        this.Lca__Banks.push({
                            'text': bank.Bank.title + ' ' + bank.Bank.account,
                            'value': bank.Bank.id
                        })
                    })

                    //Assign currency
                    this.Lca__Contract__currency = this.allCurrencies.find(currency => currency.Currency.id == this.Lca__Contract__currency_id)?.Currency?.code

                    //Get contract value
                    let promise = new Promise((resolve, reject) => {
                        api.get('/contracts/'+ this.Lca__contract_id + '/values')
                            .then(response => resolve(response.data.values))
                            .catch(error => reject(error))
                    })
                    promise
                        .then(values => {
                            this.Lca__Contract__value = values.contract
                        })
                        .catch((error) => {
                            console.log(error)
                            this.loader = false
                        })

                    //Get default clauses
                    this.loadDefaultClauses()

                    //Load contract item descriptions
                    let payload = {
                        contract_id: this.Lca__contract_id,
                        prefix: this.statePrefix
                    }
                    this.getItemDescriptionsByContract(payload)
                        .then(()=>{
                            this.Lca__description = null
                            let descriptions = []
                            this.ItemDescriptions.forEach(sd => {
                                descriptions.push(sd.Salesdescription.title)
                            })
                            if(descriptions.length > 0){
                                this.Lca__description = descriptions.filter(Boolean).join('-')
                            }
                            this.loader = false
                        })
                        .catch((error) => {
                            console.log(error)
                            this.loader = false
                        })

                    console.log(this.Lca__Banks)
                })
                .catch(()=>{
                    this.loader = false
                })
        },
        loadLcaById (val) {
            this.loader = true
            this.getLcaToUpdateById(val)
                .then(() => {
                    this.$title = this.$t('message.titles.lca') + ": " + (this.Lca__Contract__version != null ? [this.Lca__Contract__title, this.Lca__Contract__revision_no , this.Lca__Contract__partial_no].filter(Boolean).join('-') : this.Lca__Contract__title)

                    this.Lca__Contract__final_destination = this.allShippingLoadingPorts.find(port => port.Shippingport.id === this.Lca__Contract__shippingport_id)?.Shippingport.title
                    this.Lca__Contract__incoterm = this.incoterms.find(incoterm => incoterm.Incoterm.id === this.Lca__Contract__incoterm_id)?.Incoterm?.title,
                    this.Lca__Contract__port_of_loading = this.allShippingLoadingPorts.find(port => port.Shippingport.id === this.Lca__Contract__loadingport_id)?.Shippingport.title

                    //get bank accounts for APP Timber and supplier
                    this.Lca__Banks.push({'header': this.appConstants.company})
                    let appBanks = this.findBankAccountsByOfficeAndCurrency(this.Lca__Contract__office_id, this.Lca__Contract__currency_id)
                    appBanks.forEach(bank => {
                        this.Lca__Banks.push({
                            'text': bank.Bank.title + ' ' + bank.Bank.account,
                            'value': bank.Bank.id
                        })
                    })
                    let supplierBanks = this.findBankAccountsBySupplier(this.Lca__Supplier__id)
                    if( supplierBanks.length > 0 ) this.Lca__Banks.push({'header': this.$t('message.supplier')})
                    supplierBanks.forEach(bank => {
                        this.Lca__Banks.push({
                            'text': bank.Bank.title + ' ' + bank.Bank.account,
                            'value': bank.Bank.id
                        })
                    })
                    //assign currency
                    this.Lca__Contract__currency = this.allCurrencies.find(currency => currency.Currency.id == this.Lca__Contract__currency_id)?.Currency?.code
                    //get contract value
                    let promise = new Promise((resolve, reject) => {
                        api.get('/contracts/'+ this.Lca__contract_id + '/values')
                            .then(response => resolve(response.data.values))
                            .catch(error => reject(error))
                    })
                    promise.then(values => {
                        //load LCA clauses
                        this.getBuyerContacts()
                            .then(()=>{
                                this.buyerAdviceContacts.forEach(c => {
                                    this.selectedBuyerContacts.push(c.Contact.email)
                                })
                            })

                        this.Lca__Contract__value = values.contract
                        let payload = {
                            lca_id: this.Lca__id,
                            prefix: this.statePrefix
                        }
                        this.getClausesByLca(payload).then(()=>{
                            this.loader = false
                            this.updatedLcaUnsaved = false
                            //load contract item descriptions
                            let payload = {
                                contract_id: this.Lca__contract_id,
                                prefix: this.statePrefix
                            }
                            this.getItemDescriptionsByContract(payload)
                                .then(()=>{
                                    this.Lca__description = null
                                    let descriptions = []
                                    this.ItemDescriptions.forEach(sd => {
                                        descriptions.push(sd.Salesdescription.title)
                                    })
                                    if(descriptions.length > 0){
                                        this.Lca__description = descriptions.filter(Boolean).join('-')
                                    }
                                })
                        })
                    })
                })
                .catch(()=>{
                    this.loader = false
                    this.updatedLcaUnsaved = false
                    router
                        .push({name: 'not_found_main', params: {resource: 'LCA'}})
                        .catch(() => {
                            console.log('error')
                        })
                })
        },
        async resetLcaForm () {
            if (await this.$root.$confirm(this.$t('message.resetLcaForm'), this.$t('message.confirmations.continueLcaFormReset'), {color: 'orange'})) {
                this.loading.reset = true
                this.resetCurrentLca()
                    .then(() => {
                        this.resetCurrentClauses()
                        this.newLcaSaved = false
                        this.newLcaUnsaved = false
                        this.loading.reset = false
                        this.$toast.success(this.$t('message.lcaFormResetNotification'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                    })
                    .catch(()=>{
                        this.loading.reset = false
                    })
                    .then(()=>{
                        this.loading.reset = false
                    })
            }
        },
        saveLca () {
            if(this.$refs.lcaForm.validate()) {
                this.loading.save = true
                this.updateLca()
                    .then((response) => {
                        if (response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.lcaUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.updatedLcaUnsaved = false
                        } else {
                            this.$toast.error(this.$t('message.errors.lcaNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.lcaNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
                    .finally(() => {
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.lcaNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
                scrollToFirstFormValidationError()
            }
        },
        sendAdviceToBuyer () {
            if(this.selectedBuyerContacts.length == 0){
                this.dialogs.error_message = this.$t('message.errors.pleaseSelectOneContact');
                this.dialogs.error = true
            } else {
                this.loading.send = true
                let payload = {
                    contacts: this.selectedBuyerContacts,
                    lcaId: this.Lca__id,
                }
                this.sendLetterOfCreditAdviceToBuyer(payload)
                    .then((status)=>{
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.letterOfCreditAdviceSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                        } else {
                            this.$toast.error(this.$t('message.errors.letterOfCreditAdviceNotSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.letterOfCreditAdviceNotSent'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .finally(() => {
                        this.loading.send = false
                        this.selectedBuyerContacts = []
                        this.sendAdviceDialog = false
                    })
            }
        },
        updateDescriptionRowHandle ( idx,field,value ){
            let payload = {
                index: idx,
                field: field,
                prefix: this.statePrefix,
                value: value,
            }
            this.$store.commit('lca/updateDescription', payload)
        },
        viewPdf () {
            let document = encodeURIComponent((this.Lca__Contract__version != null ? [this.Lca__Contract__title, this.Lca__Contract__revision_no , this.Lca__Contract__partial_no].filter(Boolean).join('-') : this.Lca__Contract__title) + '_LCA')
            let user = JSON.parse(localStorage.getItem('user'))
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/lcas/print/'
                + this.lcaId
                + '/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank");
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    watch: {
        sendAdviceDialog(value) {
            // this.hasLca = value
            if(value){
                this.loading.send = true
                api
                    .put('/lcas/' + this.lcaId + '/pdf')
                    .then((response) => {
                        if (response.data.status == 'success') {
                            this.loading.send = false
                        } else {
                            this.$toast.error(this.$t('message.errors.pdfError'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                })
                            this.loading.send = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                        this.loading.send = false
                    })
            }
        }
    },
    created () {
        if(this.allBankAccounts.length == 0) this.getAllBankAccounts()
        if(this.allCurrencies.length == 0) this.getAllCurrencies()
        if(this.allSupplierBankAccounts.length == 0) this.getAllSupplierBankAccounts()
        if(this.allShippingDischargePorts.length == 0 || this.allShippingLoadingPorts.length == 0) this.getAllShippingPorts()
        if(this.allLcContractsWithoutLca.length == 0) this.getAllActiveContracts()
        if(this.allActiveLcas.length == 0) this.getAllActiveLcas()

        if(this.$route.matched.some(({name}) => name === 'update_lca')) {
            this.loadLcaById(this.lcaId)
        } else if(this.$route.matched.some(({name}) => name === 'add_lca')){
            this.newLcaUnsaved = true;
        }
    },
    mounted () {
        this.$store.watch(
            function(state) {
                return state.appic.lca.update.Lca
            },
            () => {
                this.updatedLcaUnsaved = true
            },
            { deep: true }
        )
        this.$store.watch(
            function(state) {
                return state.appic.lca.current.Lca
            },
            () => {
                this.newLcaUnsaved = true
            },
            { deep: true }
        )
        // if(this.$route.matched.some(({name}) => name === 'update_lca')){
        //     setInterval(() => {
        //         this.$title =  "LCA: " + (this.Lca__Contract__version != null ? [this.Lca__Contract__title, this.Lca__Contract__revision_no , this.Lca__Contract__partial_no].filter(Boolean).join('-') : this.Lca__Contract__title)
        //     }, 2000)
        // } else {
        //     this.$title =  this.$t('message.titles.newLca')
        // }
    }
}
</script>

<style>
    .bg-lightgrey {
        background-color: #F5F5F5;
    }
    .force-text-left .v-text-field__slot input {
        text-align: left !important;
    }
    .v-textarea__slot input {
        text-align: right;
    }
    .custom.v-textarea>.v-input__control>.v-input__slot:before {
        border-style: none;
    }
    .custom.v-textarea>.v-input__control>.v-input__slot:after {
        border-style: none;
    }
    .v-dialog {
        min-width: 200px !important;
    }
    .wrap-text {
        white-space: normal;
    }
    .font-xs {
        font-size: small;
    }
</style>