<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters class="d-flex flex-row">
                <div :style="this.$vuetify.breakpoint.md ? 'max-width: 95%' : 'max-width: 90%'">
                    <v-row no-gutters>
                        <div class="d-flex align-center pt-3 flex-wrap align-content-space-between">
                            <v-text-field
                                :placeholder="$t('message.no')"
                                :value="searchValues.title"
                                autocomplete="new-password"
                                class="filter-width-190 force-text-left mx-1"
                                clearable
                                dense
                                hide-details="auto"
                                append-icon="search"
                                solo
                                @change="searchValues.title = $event"
                                @click:append="searchShipments('title')"
                                @click:clear="resetSearch()"
                                @keyup.enter.prevent="searchShipments('title')"
                            />
                            <v-select
                                :items="filterItems.status"
                                :loading="loading.filterItems.status"
                                :placeholder="$t('message.status')"
                                class="filter-width-190 mx-1"
                                dense
                                hide-details="auto"
                                item-text="Status.title"
                                item-value="Status.id"
                                solo
                                v-model="filterValues.status"
                                @change="filterShipments()"
                            >
                                <template v-slot:item="{ item }">
                                    <span class="font-sm">{{ $t('message.' + item.Status.title) }}</span>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <span class="font-sm">{{ $t('message.' + item.Status.title) }}</span>
                                </template>
                            </v-select>
                            <v-autocomplete
                                :items="filterItems.buyer"
                                :loading="loading.filterItems.buyer"
                                :placeholder="$t('message.buyer')"
                                class="filter-width-190 mx-1"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Customer.title"
                                item-value="Customer.id"
                                solo
                                v-model="filterValues.buyer"
                                @change="filterShipments()"
                            />
                            <v-autocomplete
                                :items="filterItems.supplier"
                                :loading="loading.filterItems.supplier"
                                :placeholder="$t('message.spl')"
                                class="filter-width-190 mx-1"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Supplier.title"
                                item-value="Supplier.id"
                                solo
                                v-model="filterValues.supplier"
                                @change="filterShipments()"
                            />
                            <v-autocomplete
                                :items="filterItems.pol"
                                :loading="loading.filterItems.pol"
                                :placeholder="$t('message.pol')"
                                class="filter-width-190 mx-1"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="ShippingPort.title"
                                item-value="ShippingPort.id"
                                solo
                                v-model="filterValues.pol"
                                @change="filterShipments()"
                            />
                            <v-autocomplete
                                :items="filterItems.pod"
                                :loading="loading.filterItems.pod"
                                :placeholder="$t('message.pod')"
                                class="filter-width-190 mx-1"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="ShippingPort.title"
                                item-value="ShippingPort.id"
                                solo
                                v-model="filterValues.pod"
                                @change="filterShipments()"
                            />
                            <v-autocomplete
                                :items="filterItems.origin"
                                :loading="loading.filterItems.origin"
                                :placeholder="$t('message.origin')"
                                class="filter-width-190 mx-1"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Country.name"
                                item-value="Country.id"
                                solo
                                v-model="filterValues.origin"
                                @change="filterShipments()"
                            />
                            <v-autocomplete
                                :items="filterItems.destination"
                                :loading="loading.filterItems.destination"
                                :placeholder="$t('message.dest')"
                                class="filter-width-190 mx-1"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Country.name"
                                item-value="Country.id"
                                solo
                                v-model="filterValues.destination"
                                @change="filterShipments()"
                            />
                            <v-text-field
                                :placeholder="$t('message.supPiNo')"
                                :value="searchValues.supplierPi"
                                autocomplete="new-password"
                                class="filter-width-190 force-text-left mx-1"
                                clearable
                                dense
                                hide-details="auto"
                                append-icon="search"
                                solo
                                @change="searchValues.supplierPi = $event"
                                @click:append="searchShipments('supplierPi')"
                                @click:clear="resetSearch()"
                                @keyup.enter.prevent="searchShipments('supplierPi')"
                            />
                            <v-text-field
                                :placeholder="$t('message.invoice')"
                                :value="searchValues.invoice"
                                autocomplete="new-password"
                                class="filter-width-190 force-text-left mx-1"
                                clearable
                                dense
                                hide-details="auto"
                                append-icon="search"
                                solo
                                @change="searchValues.invoice = $event"
                                @click:append="searchShipments('invoice')"
                                @click:clear="resetSearch()"
                                @keyup.enter.prevent="searchShipments('invoice')"
                            />
                            <v-text-field
                                :placeholder="$t('message.pl')"
                                :value="searchValues.pl"
                                autocomplete="new-password"
                                class="filter-width-190 force-text-left mx-1"
                                clearable
                                dense
                                hide-details="auto"
                                append-icon="search"
                                solo
                                @change="searchValues.pl = $event"
                                @click:append="searchShipments('pl')"
                                @click:clear="resetSearch()"
                                @keyup.enter.prevent="searchShipments('pl')"
                            />
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                max-width="290px"
                                min-width="290px"
                                ref="wprDateMenu"
                                transition="scale-transition"
                                v-model="dateFilterMenus.osd"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        :value="computedFilterDates('osd')"
                                        :placeholder="$t('message.osd')"
                                        append-icon="event"
                                        clearable
                                        class="force-text-left filter-width-190 mx-1"
                                        hint="DD/MM/YY format"
                                        hide-details
                                        background-color="white"
                                        persistent-hint
                                        readonly
                                        solo
                                        v-on="on"
                                        @click:clear="resetSearch('osd')"
                                        @click:append="dateFilterMenus.osd = true"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    first-day-of-week="1"
                                    locale-first-day-of-year="4"
                                    range
                                    show-week
                                    v-model="searchValues.osd"
                                    @input="closeDateFilterMenu('osd')"
                                    @change="searchShipments('osd')"
                                ></v-date-picker>
                            </v-menu>
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                max-width="290px"
                                min-width="290px"
                                ref="wprDateMenu"
                                transition="scale-transition"
                                v-model="dateFilterMenus.ets"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        :value="computedFilterDates('ets')"
                                        :placeholder="$t('message.ets')"
                                        append-icon="event"
                                        clearable
                                        class="force-text-left filter-width-190 mx-1"
                                        hint="DD/MM/YY format"
                                        hide-details
                                        background-color="white"
                                        persistent-hint
                                        readonly
                                        solo
                                        v-on="on"
                                        @click:clear="resetSearch('ets')"
                                        @click:append="dateFilterMenus.ets = true"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    first-day-of-week="1"
                                    locale-first-day-of-year="4"
                                    range
                                    show-week
                                    v-model="searchValues.ets"
                                    @input="closeDateFilterMenu('ets')"
                                    @change="searchShipments('ets')"
                                ></v-date-picker>
                            </v-menu>
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                max-width="290px"
                                min-width="290px"
                                ref="wprDateMenu"
                                transition="scale-transition"
                                v-model="dateFilterMenus.etd"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        :value="computedFilterDates('etd')"
                                        :placeholder="$t('message.etd')"
                                        append-icon="event"
                                        clearable
                                        class="force-text-left filter-width-190 mx-1"
                                        hint="DD/MM/YY format"
                                        hide-details
                                        background-color="white"
                                        persistent-hint
                                        readonly
                                        solo
                                        v-on="on"
                                        @click:clear="resetSearch('etd')"
                                        @click:append="dateFilterMenus.etd = true"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    first-day-of-week="1"
                                    locale-first-day-of-year="4"
                                    range
                                    show-week
                                    v-model="searchValues.etd"
                                    @input="closeDateFilterMenu('etd')"
                                    @change="searchShipments('etd')"
                                ></v-date-picker>
                            </v-menu>
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                max-width="290px"
                                min-width="290px"
                                ref="wprDateMenu"
                                transition="scale-transition"
                                v-model="dateFilterMenus.eta"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        :value="computedFilterDates('eta')"
                                        :placeholder="$t('message.eta')"
                                        append-icon="event"
                                        clearable
                                        class="force-text-left filter-width-190 mx-1"
                                        hint="DD/MM/YY format"
                                        hide-details
                                        background-color="white"
                                        persistent-hint
                                        readonly
                                        solo
                                        v-on="on"
                                        @click:clear="resetSearch('eta')"
                                        @click:append="dateFilterMenus.eta = true"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    first-day-of-week="1"
                                    locale-first-day-of-year="4"
                                    range
                                    show-week
                                    v-model="searchValues.eta"
                                    @input="closeDateFilterMenu('eta')"
                                    @change="searchShipments('eta')"
                                ></v-date-picker>
                            </v-menu>
                            <v-btn
                                :loading="loading.clear"
                                class="mx-1 mt-1"
                                @click="clearFilters"
                            >
                                {{ $t('message.clear') }}
                            </v-btn>
                        </div>
                    </v-row>
                </div>
                <div class="d-flex flex-row align-start grow mt-1">
                    <v-spacer/>
                    <ExportTableJson
                            :export-conditions="exportConditions"
                            :export-data="Shipments"
                            :export-fields="headers"
                            :export-source="'shipments'"
                            class="ml-3"
                    />
                    <HelpPopUpV2 help-page-component="ShipmentsListing"/>
                </div>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.shipments"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
                showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="hideDefaultFooter"
            :items="Shipments"
            :options="tableOptions"
            :server-items-length="totalShipments"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            id="shipmentTable"
            item-key="Shipment.contract_id"
            @update:options="updateDataTable"
        >
            <template v-slot:top>
                <div class="d-flex flex-row pt-0 pb-2 align-center">
                    <div class="font-sm">
                        <v-icon small color="grey darken-1">fiber_manual_record</v-icon>{{ $t('message.new') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="orange lighten-1">fiber_manual_record</v-icon>{{ $t('message.onHold') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="yellow lighten-1">fiber_manual_record</v-icon>{{ $t('message.vesselOrEtdSet') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="blue lighten-1">fiber_manual_record</v-icon>{{ $t('message.inTransit') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="green lighten-1">fiber_manual_record</v-icon>{{ $t('message.arrived') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="purple lighten-1">fiber_manual_record</v-icon>{{ $t('message.closed') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="pink darken-1">fiber_manual_record</v-icon>{{ $t('message.draftDocsPending') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="red lighten-2">fiber_manual_record</v-icon>{{ $t('message.docsPending') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="teal lighten-1">fiber_manual_record</v-icon>{{ $t('message.docsCompleted') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="black lighten-1" class="mr-2">fas fa-code-branch</v-icon>{{ $t('message.contractSplit') }}
                    </div>
                </div>
            </template>
            <template v-slot:item.Contract.contractdate="{ item }">
                {{ item.Contract.contractdate != '0000-00-00' ? formatDate(item.Contract.contractdate) : '' }}
            </template>
            <template v-slot:item.Contract.title="{ item }">
                <div class="d-flex flex-row">
                    <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                    <v-icon small color="black lighten-1" class="ml-2" v-if="item.Contract.issplitcomplete == 1">fas fa-code-branch</v-icon>
                </div>
            </template>
            <template v-slot:item.Status.title="{ item }">
                <div class="text-center">
                    <v-icon small :color="item.Status.color" v-if="item.Contract.issplitcomplete != 1">fiber_manual_record</v-icon>
                    <v-icon small color="purple lighten-1" v-if="item.Contract.issplitcomplete == 1"></v-icon>
                </div>
            </template>
            <template v-slot:item.Invoice.invno="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="green darken-1 px-1 py-0 white--text"
                            small
                            v-if="item.Invoice.id == null && [1,5].includes(item.Shipment.salestype_id) && item.Shipment.etddate != null"
                            v-on="on"
                            @click="addInvoice(item.Shipment.contract_id)"
                        ><v-icon color="white" small style="color: white !important;">add</v-icon> {{$t('message.invoice')}}</v-btn>
                        <span v-if="[2,3,6].includes(item.Shipment.salestype_id)">{{ $t('message.na') }}</span>
                    </template>
                    <span>{{ $t('message.addInvoice') }}</span>
                </v-tooltip>
                <router-link :to="{name: 'update_invoice', params: { invoiceId: item.Invoice.id }}" v-if="item.Invoice.id != null">{{ item.Invoice.invno }}</router-link>
            </template>
            <template v-slot:item.Shipment.Customer.title="{ item }">
                <span class="font-weight-medium">{{ item.Shipment.Customer.otsname ? item.Shipment.Customer.otsname : item.Shipment.Customer.title }}</span>
            </template>
            <template v-slot:item.Pldo.title="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="green darken-1 px-1 py-0 white--text"
                            small
                            v-if="item.Pldo.id == null && [1,5].includes(item.Shipment.salestype_id) && item.Invoice.id != null"
                            v-on="on"
                            @click="addPackingList(item.Shipment.contract_id)"
                        ><v-icon color="white" small>add</v-icon> {{$t('message.pl').toUpperCase() }}</v-btn>
                        <span v-if="[2,3,6].includes(item.Shipment.salestype_id)">{{ $t('message.na') }}</span>
                    </template>
                    <span>{{ $t('message.addPackingList') }}</span>
                </v-tooltip>
                <router-link :to="{name: 'update_packinglist', params: { packingListId: item.Pldo.id }}" v-if="item.Pldo.id != null">{{ item.Pldo.title }}</router-link>
            </template>
            <template v-slot:item.Agent.title="{ item }">
                <div class="d-flex flex-column py-1">
                    <span class="font-weight-medium text-no-wrap">{{ item.Agent.title }}</span>
                    <span> {{ item.Shipment.blno }} </span>
                </div>
            </template>
            <template v-slot:item.Shipment.containersize_id="{ item }">
                <div class="text-no-wrap">
                    <span v-if=" [1,2,3,5].includes(item.Shipment.containersize_id) ">{{ item.Shipment.containercount }}</span>
                    <span v-if=" [1,2,3,5].includes(item.Shipment.containersize_id) "> X </span>
                    <span>{{ getContainerSize(item.Shipment.containersize_id) }}</span>
                </div>
                <div v-for="container in item.Containers" class="font-xs">{{ container.container_no }}</div>
            </template>
            <template v-slot:item.Shipment.documents_completed="{ item }">
                <div class="text-center" v-if="item.DraftDocument.status == 'pending'">
                    <v-icon small color="pink darken-1">fiber_manual_record</v-icon>
                </div>
                <div class="text-center" v-if="item.Invoice.id != null">
                    <v-icon :color="item.Shipment.documents_completed == null ? 'red lighten-2' : 'teal lighten-1'" small>fiber_manual_record</v-icon>
                </div>
            </template>
            <template v-slot:item.Shipment.shipweekdate="{ item }">
                {{ item.Shipment.shipweekdate != '0000-00-00' ? formatDate(item.Shipment.shipweekdate) : '' }}
            </template>
            <template v-slot:item.Shipment.etsdate="{ item }">
                {{ item.Shipment.etsdate != '0000-00-00' ? formatDate(item.Shipment.etsdate) : '' }}
            </template>
            <template v-slot:item.Shipment.etddate="{ item }">
                {{ item.Shipment.etddate != '0000-00-00' ? formatDate(item.Shipment.etddate) : '' }}
            </template>
            <template v-slot:item.Shipment.etadate="{ item }">
                {{ item.Shipment.etadate != '0000-00-00' ? formatDate(item.Shipment.etadate) : '' }}
            </template>
            <template v-slot:item.Shipment.vessel="{ item }">
                <div v-if="item.Contract.issplitcomplete != 1 && [1,7].includes(item.Shipmentstatus.id)">
                    {{ item.Status.title }}
                </div>
                <div v-if="item.Contract.issplitcomplete != 1 && [2,3,8,9,10].includes(item.Shipmentstatus.id)">
                    {{ $t('message.' + item.Status.title) }}
                </div>
                <div v-if="item.Shipment.vessel">{{ item.Shipment.vessel }}</div>
            </template>
            <template v-slot:item.Shipment.internalremark="{ item }">
                <span>{{ item.Shipment.internalremark }}</span>
            </template>
            <template v-slot:item.Originport.title="{ item }">
                <span class="font-xs">{{ item.Originport.title }}</span>
            </template>
            <template v-slot:item.Destinationport.title="{ item }">
                <span class="font-xs">{{ item.Destinationport.title }}</span>
            </template>
            <template v-slot:item.Shipment.lcno="{ item }">
                <div class="d-flex flex-column" v-if="item.Shipment.lcno !== null && item.Shipment.lcno != ''">
                    <span class="text-no-wrap">{{ $t('message.no') + ": " + ((item.Shipment.lcno !== null && item.Shipment.lcno != '') ? item.Shipment.lcno : '') }}</span>
                    <span class="text-no-wrap">{{ "S/D: " + ((item.Shipment.lclsdate !== null && item.Shipment.lclsdate != '0000-00-00') ? formatDate(item.Shipment.lclsdate) : '') }}</span>
                    <span class="text-no-wrap">{{ "E/D: " + ((item.Shipment.lcedate !== null && item.Shipment.lcedate != '0000-00-00') ? formatDate(item.Shipment.lcedate) : '') }}</span>
                </div>
            </template>
            <template v-slot:item.Shipment.delay="{ item }">
                <div class="red--text font-weight-bold" v-if="item.Contract.issplitcomplete != 1">{{ item.Shipment.delay > 0 ? item.Shipment.delay : '' }}</div>
            </template>
            <template v-slot:item.Shipment.contract_id="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.moreActions') }}</span>
                        </v-tooltip>
                    </template>
                    <v-list dense>
                        <v-list-item class="font-sm" @click="updateShipment(item.Shipment.contract_id)"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateShipment') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updateContract(item.Shipment.contract_id)"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateContract') }}</v-list-item>

                        <!--added 2021-08-18-->
                        <v-list-item class="font-sm" @click="updatePurchaseOrder(item.Purchaseorder.id)" v-if="item.Purchaseorder.id != null"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePurchaseOrder') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updateShippingInstruction(item.Sda.id)" v-if="item.Sda.id != null"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateShippingInstruction') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updatePackingList(item.Pldo.id)" v-if="item.Pldo.id != null"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePackingList') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updateInvoice(item.Invoice.id)" v-if="[1,5].includes(item.Shipment.salestype_id) && item.Invoice.id != null"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateInvoice') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updateDebitNote(item.Debitnote.id)" v-if="[2].includes(item.Shipment.salestype_id) && item.Debitnote.id != null"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateDebitNote') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updatePayments(item.Shipment.contract_id)"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePayments') }}</v-list-item>
                        <!---->

                        <v-list-item class="font-sm" @click="completeShipment(item.Shipment.contract_id)"><v-icon color="green lighten-1" small class="mr-1">done</v-icon>{{ $t('message.markComplete') }}</v-list-item>
                        <v-list-item class="font-sm"
                             @click="openShippingDocumentsDialog(item.Shipment.contract_id, (item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title))"
                             v-if="[1,2,3,5,6].includes(item.Shipment.salestype_id)"
                        >
                            <v-icon color="yellow darken-2" small class="mr-2">fas fa-folder-open</v-icon>
                            {{ $t('message.shippingDocuments') }}
                        </v-list-item>
                        <v-list-item class="font-sm" @click="openShippingInfoDialog(item.Shipment.contract_id, (item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title))" v-if="item.Contract.issplitcomplete != 1"><v-icon small color="blue lighten-1" class="mr-2">mdi-ferry</v-icon>{{ $t('message.shippingInfo') }}</v-list-item>

                        <!--added 2021-08-23-->
                        <v-list-item
                            class="font-sm"
                            @click="openNonShippingDocumentsDialog(item.Shipment.contract_id, (item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title))"
                            v-if="[1,2,3,5,6].includes(item.Shipment.salestype_id)"
                        >
                            <v-icon color="yellow darken-2" small class="mr-2">fas fa-folder-open</v-icon>
                            {{ $t('message.otherContractDocuments') }}
                        </v-list-item>
                        <!---->
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <SimpleAlert
                :alert_message.sync="dialogs.error_message"
                :dialog.sync="dialogs.error"
                @dialog-closed="dialogClosed"
        />
        <ShippingDocumentsList
            :main-document="shippingDocumentsTitle"
            :open-dialog="shippingDocumentsDialog"
            :shipping-documents="shippingDocuments"
            :app-only-documents="appOnlyDocuments"
            @dialog-closed="closeShippingDocumentsDialog()"
        />
        <ShippingInfoSummary
            :main-document="shippingInfoTitle"
            :open-dialog="shippingInfoDialog"
            :shipment-id="shippingInfoId"
            @dialog-closed="closeShippingInfoDialog()"
        />
        <NonShippingDocumentsList
            :main-document="nonShippingDocumentsTitle"
            :open-dialog="nonShippingDocumentsDialog"
            :non-shipping-documents="nonShippingDocuments"
            @dialog-closed="closeNonShippingDocumentsDialog()"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFields, mapMultiRowFields} from "vuex-map-fields";
import {formatDate, numberFormat} from "Helpers/helpers";
import {api} from "Api";
// import ShippingDocumentsList from "Components/Appic/ShippingDocumentsList";
    // import SimpleAlert from "./SimpleAlert";
    // import ShippingInfoSummary from "Components/Appic/ShippingInfoSummary";

    const NonShippingDocumentsList = () => import("Components/Appic/NonShippingDocumentsList");
    const ShippingDocumentsList = () => import("Components/Appic/ShippingDocumentsList");
    const ShippingInfoSummary = () => import("Components/Appic/ShippingInfoSummary");
    const SimpleAlert = () => import("./SimpleAlert");
    const ExportTableJson = () => import("./ExportTableJson");
    const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

    export default {
        name: "ShipmentsListingNew",
        components: {ExportTableJson, HelpPopUpV2, NonShippingDocumentsList, ShippingInfoSummary, ShippingDocumentsList, SimpleAlert},
        data() {
            return {
                appOnlyDocuments: [],
                currentFilter: null,
                currentSearch: null,
                dateFilterMenus: {
                    osd: false,
                    ets: false,
                    etd: false,
                    eta: false
                },
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                exportConditions: {},
                filterItems: {
                    buyer: [],
                    destination: [],
                    origin: [],
                    pod: [],
                    pol: [],
                    status: [],
                    supplier: []
                },
                // filterOptions: {
                //     status: {
                //         Status: {
                //             id: 20,
                //             title: 'new'
                //         }
                //     }
                // },
                filterValues: {
                    buyer: null,
                    destination: null,
                    origin: null,
                    pod: null,
                    pol: null,
                    status: 20,
                    supplier: null,
                },
                hideDefaultHeader: false,
                hideDefaultFooter: false,
                loading: {
                    clear: false,
                    filter: {
                        buyers: false,
                        destinations: false,
                        documents: false,
                        origins: false,
                        pod: false,
                        pol: false,
                        suppliers: false,
                        types: false,
                        statuses: false
                    },
                    filterItems: {
                        buyer: null,
                        destination: null,
                        origin: null,
                        pod: false,
                        pol: false,
                        status: null,
                        supplier: null,
                    },
                    search: false,
                    shipments: false
                },
                nonShippingDocuments: [],
                nonShippingDocumentsDialog: false,
                nonShippingDocumentsTitle: '',
                searchField: 'Contract.title', //default search field
                searchTerm: null,
                searchValues: {
                    invoice: null,
                    pl: null,
                    supplierPi: null,
                    title: null,
                    osd: [],
                    ets: [],
                    etd: [],
                    eta: []
                },
                shippingDocuments: [],
                shippingDocumentsDialog: false,
                shippingDocumentsTitle: '',
                shippingInfoDialog: false,
                shippingInfoId: null,
                shippingInfoTitle: '',
                tableOptions: {}
            }
        },
        computed: {
            ...mapMultiRowFields('shipment',{
                Shipments: 'current.Shipments',
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapFields('shipment',{
                totalShipments: 'current.totalShipments'
            }),
            ...mapGetters('shipment',{
                shipmentBuyers: 'shipmentBuyers',
                shipmentDestinations: 'shipmentDestinations',
                shipmentDocuments: 'shipmentDocuments',
                shipmentOrigins: 'shipmentOrigins',
                shipmentStatuses: 'shipmentStatuses',
                shipmentSuppliers: 'shipmentSuppliers',
                shipmentTypes: 'shipmentTypes'
            }),
            ...mapGetters([
                'containerSizes'
            ]),
            defaultItemsPerPage() {
                if(window.screen.height >= 800) return 15
                return 10
            },
            formatDate: () => formatDate,
            headers() {
                return [
                    {
                        id: 1,
                        text: this.$t('message.actions'),
                        value: 'Shipment.contract_id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        sortable: false
                    },
                    {
                        id: 2,
                        text: this.$t('message.date'),
                        value: 'Contract.contractdate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 3,
                        text: this.$t('message.contract'),
                        value: 'Contract.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
                        id: 4,
                        text: this.$t('message.status'),
                        value: 'Status.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
                        id: 5,
                        text: this.$t('message.buyer'),
                        value: 'Shipment.Customer.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 6,
                        text: this.$t('message.spl'),
                        value: 'Shipment.Supplier.code',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 7,
                        text: this.$t('message.supPiNo'),
                        value: 'Contract.supplier_pi_no',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left text-no-wrap',
                        sortable: false
                    },
                    {
                        id: 8,
                        text: this.$t('message.invoice'),
                        value: 'Invoice.invno',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
                        id: 9,
                        text: this.$t('message.pl').toUpperCase(),
                        value: 'Pldo.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
                        id: 10,
                        text: this.$t('message.agentAndBl'),
                        value: 'Agent.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    // {
                    //     id: 10,
                    //     text: this.$t('message.originAndDestination'),
                    //     value: 'Origin.name',
                    //     class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    //     sortable: false
                    // },
                    // {
                    //     id: 10,
                    //     text: this.$t('message.destination'),
                    //     value: 'Destination.name',
                    //     class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    //     searchable: false,
                    //     sortable: false
                    // },
                    {
                        id: 11,
                        text: this.$t('message.remarks'),
                        value: 'Shipment.internalremark',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
                        id: 12,
                        text: this.$t('message.docs'),
                        value: 'Shipment.documents_completed',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
                        id: 13,
                        text: this.$t('message.osd'),
                        value: 'Shipment.shipweekdate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 14,
                        text: this.$t('message.shpStatus'),
                        value: 'Shipment.vessel',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
                        id: 15,
                        text: this.$t('message.ets'),
                        value: 'Shipment.etsdate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 16,
                        text: this.$t('message.etd'),
                        value: 'Shipment.etddate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 17,
                        text: this.$t('message.eta'),
                        value: 'Shipment.etadate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 18,
                        text: this.$t('message.container'),
                        value: 'Shipment.containersize_id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
                        id: 19,
                        text: this.$t('message.pol'),
                        value: 'Originport.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
                        id: 20,
                        text: this.$t('message.pod'),
                        value: 'Destinationport.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    // {
                    //     id: 19,
                    //     text: this.$t('message.containers'),
                    //     value: 'Containers',
                    //     sortable: false,
                    //     class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    // },
                    {
                        id: 21,
                        text: this.$t('message.lcDetails'),
                        value: 'Shipment.lcno',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left text-no-wrap',
                        sortable: false
                    },
                    {
                        id: 22,
                        text: this.$t('message.delay'),
                        value: 'Shipment.delay',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    // {
                    //     id: 21,
                    //     text: this.$t('message.transitTime'),
                    //     value: 'Shipment.transit_time',
                    //     class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    //     sortable: false
                    // }
                ]
            },
            itemsPerPageOptions() {
                let options = [10,20,50,-1];
                if(window.screen.height >= 800){
                    options = [15,30,50,-1];
                }
                return options;
            },
            searchFields() {
                return this.headers.filter((header)=>header.searchable === true)
            }
        },
        methods: {
            ...mapActions('shipment',{
                completeShipmentById: 'completeShipmentById',
                getAllShipments: 'getAllShipments',
                getAllShipmentBuyers: 'getAllShipmentBuyers',
                getAllShipmentDestinations: 'getAllShipmentDestinations',
                getAllShipmentDocuments: 'getAllShipmentDocuments',
                getAllShipmentOrigins: 'getAllShipmentOrigins',
                getAllShipmentStatuses: 'getAllShipmentStatuses',
                getAllShipmentSuppliers: 'getAllShipmentSuppliers',
                getAllShipmentTypes: 'getAllShipmentTypes',
                resetAllShipments: 'resetAllShipments',
                searchAllShipments: 'searchAllShipments'
            }),
            addInvoice(val){
                let tab = window.open('/v1/invoices/add/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
                // router.push({name: 'add_invoice', params: { contractId : val}}).catch(err => {})
            },
            addPackingList(val){
                let tab = window.open('/v1/packinglists/add/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            async completeShipment(val) {
                if (await this.$root.$confirm(this.$t('message.markComplete'), this.$t('message.confirmations.continueShipmentMarkCompleteAction'), {color: 'orange'})) {
                    this.completeShipmentById(val).then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.shipmentCompleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loadShipments()
                        } else {
                            this.$toast.error(this.$t('message.errors.shipmentNotCompleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                }
            },
            clearFilters() {
                this.loading.clear = true
                this.filterValues.buyer = null
                this.filterValues.destination = null
                this.filterValues.origin = null
                this.filterValues.pod = null
                this.filterValues.pol = null
                // this.filterValues.status = 20
                this.filterValues.status = null
                this.filterValues.supplier = null
                this.searching = false
                this.searchValues.invoice = null
                this.searchValues.supplierPi = null
                this.searchValues.title = null
                this.searchValues.osd = []
                this.searchValues.ets = []
                this.searchValues.etd = []
                this.searchValues.eta = []
                this.tableOptions.page = 1
                this.tableOptions.sortBy[0] = 'Contract.contractdate'
                this.tableOptions.sortDesc[0] = true
                this.loading.clear = false
                this.filterShipments()
            },
            closeDateFilterMenu(filter) {
                //check if values are valid. don't allow reverse ranges where d1 > d2
                let d1 = new Date(this.searchValues[filter][0])
                let d2 = new Date(this.searchValues[filter][1])
                if(d1 > d2 && this.searchValues[filter].length == 2){
                    let newDate1 = this.searchValues[filter][1]
                    let newDate2 = this.searchValues[filter][0]
                    this.searchValues[filter] = [newDate1, newDate2]
                }
                if(this.searchValues[filter].length == 2) this.dateFilterMenus[filter] = false
            },
            closeNonShippingDocumentsDialog () {
                this.nonShippingDocumentsDialog = false
            },
            closeShippingDocumentsDialog () {
                this.shippingDocumentsDialog = false
            },
            closeShippingInfoDialog() {
                this.shippingInfoDialog = false
                this.shippingInfoId = null
                this.shippingInfoTitle = ''
            },
            computedFilterDates(date){
                let dates = ''
                if(this.searchValues[date] != null && this.searchValues[date][0] != null) dates = dates + formatDate(this.searchValues[date][0])
                if(this.searchValues[date] != null && this.searchValues[date][1] != null) dates = dates + " ~ " + formatDate(this.searchValues[date][1])
                return dates
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            filterShipments() {
                this.searchValues.title = null
                this.searchValues.osd = []
                this.searchValues.ets = []
                this.searchValues.etd = []
                this.searchValues.eta = []
                let noValues = true
                const keys = Object.keys(this.filterValues)
                keys.forEach((key, index) => {
                    if(this.filterValues[key] != null) noValues = false
                })
                if(noValues == false) {
                    let conditions = []
                    if(this.filterValues.buyer != null) conditions.push({field: 'Shipment.customer_id', value: this.filterValues.buyer})
                    if(this.filterValues.destination != null) conditions.push({field: 'Shipment.destinatione_id', value: this.filterValues.destination})
                    if(this.filterValues.origin != null) conditions.push({field: 'Shipment.productorigin_id', value: this.filterValues.origin})
                    if(this.filterValues.pod != null) conditions.push({field: 'Shipment.shippingport_id', value: this.filterValues.pod})
                    if(this.filterValues.pol != null) conditions.push({field: 'Shipment.loadingport_id', value: this.filterValues.pol})
                    if(this.filterValues.status != null) conditions.push({field: 'Shipment.status_id', value: this.filterValues.status})
                    if(this.filterValues.supplier != null) conditions.push({field: 'Shipment.supplier_id', value: this.filterValues.supplier})

                    if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                    if(_.isEqual(this.currentFilter,this.filterValues) === false) {
                        this.tableOptions.page = 1
                        this.currentFilter = _.cloneDeep(this.filterValues)
                    }

                    if(_.has(this.tableOptions, 'sortBy') == false) {
                        this.tableOptions['sortBy'] = ['Shipment.contractdate']
                    }
                    if(this.tableOptions.sortBy.length == 0){
                        this.tableOptions['sortBy'] = ['Shipment.contractdate']
                    }
                    if(_.has(this.tableOptions, 'sortDesc') == false){
                        this.tableOptions['sortDesc'] = [false]
                        if(this.tableOptions.sortBy[0] == 'Shipment.contractdate') {
                            this.tableOptions['sortDesc'] = [true]
                        }
                    }
                    if(this.tableOptions.sortBy[0] == 'Shipment.contractdate' && this.tableOptions.sortDesc.length == 0){
                        this.tableOptions['sortDesc'] = [true]
                    }

                    let order = [{field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'}]

                    let payload = {
                        tableOptions: this.tableOptions,
                        conditions: conditions,
                        order: order
                    }

                    this.hideDefaultFooter = false
                    this.loading.shipments = true;
                    this.searchAllShipments(payload)
                        .then(() => {
                            this.loading.shipments = false;
                        })
                        .catch(()=>{
                            this.loading.shipments = false;
                        })
                        .finally(()=>{
                            this.loading.shipments = false;
                        })
                } else {
                    // this.loadShipments()
                    this.reloadShipments()
                        .then(() => {
                            this.loading.shipments = false;
                        })
                        .catch(() => {
                            this.loading.shipments = false;
                        })
                }
            },
            formatThisNumber (value,format) {
                return numberFormat(value,format)
            },
            getContainerSize (containersize_id) {
                let container = this.containerSizes.find(containerSize => containerSize.Containersize.id == containersize_id)
                if( container ) {
                    return container?.Containersize.title
                } else {
                    return null
                }
            },
            loadFilterItems(filter) {
                return new Promise((resolve, reject) => {
                    if(filter && filter.length > 0) {
                        this.loading.filterItems[filter] = true
                        api
                            .get('/shipments/filter-options/' + filter)
                            .then(response => {
                                if(response.data.status == 'success') {
                                    this.filterItems[filter] = response.data.data

                                    //add All to some options
                                    // if(filter == 'status'){
                                    //    this.filterItems[filter].push({Status: {id: 0, title: 'all' }})
                                    // }

                                    this.loading.filterItems[filter] = false
                                    resolve('done')
                                } else {
                                    this.loading.filterItems[filter] = false
                                    reject('error')
                                }
                            })
                            .catch(error => {
                                this.loading.filterItems[filter] = false
                                reject(error)
                            })
                    } else {
                        reject('Filter empty')
                    }
                })
            },
            loadShipments () {
                this.hideDefaultFooter = false
                if(!this.loading.shipments) this.loading.shipments = true;

                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                const payload = {
                    tableOptions: this.tableOptions
                }

                this.getAllShipments(payload)
                    .then(() => {
                        // this.loading.shipments = false;
                    })
                    .catch(()=>{
                        this.loading.shipments = false;
                    })
            },
            reloadShipments () {
                return new Promise((resolve, reject) => {
                    try {
                        this.hideDefaultFooter = false
                        if(!this.loading.shipments) this.loading.shipments = true;

                        if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                        const payload = {
                            tableOptions: this.tableOptions
                        }

                        this.getAllShipments(payload)
                            .then(() => {
                                // this.loading.shipments = false;
                                resolve('done')
                            })
                            .catch(()=>{
                                // this.loading.shipments = false;
                                reject('error')
                            })
                    } catch (error){
                        reject('error')
                    }
                })
            },
            openNonShippingDocumentsDialog(documentId, documentTitle) {
                this.loading.shipments = true
                let promise = new Promise((resolve, reject) => {
                    api
                        .get("/other-contract-documents/" + documentId)
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        })
                })
                promise
                    .then( (data) => {
                        this.nonShippingDocuments = []
                        this.nonShippingDocumentsTitle = ''
                        this.nonShippingDocuments = data
                        this.nonShippingDocumentsTitle = documentTitle
                    })
                    .catch(() => {
                        this.loading.contracts = false;
                    })
                    .then(() => {
                        let self = this
                        setTimeout(function(){
                            self.loading.shipments = false;
                            self.nonShippingDocumentsDialog = true;
                        }, 100)
                    })
            },
            openShippingDocumentsDialog (documentId, documentTitle) {
                this.loading.shipments = true
                let promise = new Promise((resolve, reject) => {
                    api
                        .get("/shipping-documents/" + documentId)
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        })
                })

                let promise_2 = new Promise((resolve, reject) => {
                    api
                        .get("/app-only-documents/" + documentId)
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        })
                })

                promise
                    .then( (data) => {
                        this.shippingDocuments = []
                        this.shippingDocumentsTitle = ''
                        this.shippingDocuments = data
                        this.shippingDocumentsTitle = documentTitle

                        promise_2
                            .then((data_2) => {
                                this.appOnlyDocuments = []
                                this.appOnlyDocuments = data_2
                            })
                            .catch(() => {
                                this.loading.shipments = false;
                            })
                            .then(() => {
                                let self = this
                                setTimeout(function(){
                                    self.loading.shipments = false;
                                    self.shippingDocumentsDialog = true;
                                }, 100)
                            })
                    })
                    .catch(() => {
                        this.loading.shipments = false;
                    })
                    // .then(() => {
                    //     let self = this
                    //     setTimeout(function(){
                    //         self.loading.shipments = false;
                    //         self.shippingDocumentsDialog = true;
                    //     }, 100)
                    // })
            },
            openShippingInfoDialog( documentId, documentTitle ) {
                this.shippingInfoTitle = documentTitle
                this.shippingInfoId = documentId
                this.shippingInfoDialog = true
            },
            // resetSearch () {
            //     this.hideDefaultFooter = false
            //     this.loading.shipments = false
            //     this.searchField = 'Contract.title'; //default search field
            //     this.searchTerm = null;
            //     this.tableOptions.itemsPerPage = 10
            //     this.filterOptions = {};
            // },
            resetSearch (filter = null) {
                this.searching = false
                this.hideDefaultFooter = false
                // this.filterValues.status = 20
                this.filterValues.status = null
                this.tableOptions.page = 1
                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                switch(filter){
                    case 'eta':
                    case 'etd':
                    case 'ets':
                    case 'osd':
                        this.searchValues[filter] = []
                        break
                }

                this.tableOptions.sortBy = []
                this.tableOptions.sortDesc = []

                this.filterShipments()
            },
            searchShipments (field) {
                if(this.searchValues[field] == null || this.searchValues[field].length == 0){
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.errors.noSearchTerm')
                } else {
                    this.searching = true
                    this.filterValues.buyer = null
                    this.filterValues.destination = null
                    this.filterValues.origin = null
                    this.filterValues.pod = null
                    this.filterValues.pol = null
                    this.filterValues.status = null
                    this.filterValues.supplier = null

                    const keys = Object.keys(this.searchValues)
                    keys.forEach((key, index) => {
                        if(key != field) {
                            if(['osd','ets','etd','eta'].includes(key)){
                                this.searchValues[key] = []
                            } else {
                                this.searchValues[key] = null
                            }
                        }
                    })

                    let fieldName = null
                    let sortFieldName = null
                    switch(field){
                        case 'invoice':
                            fieldName = 'Invoice.invno'
                            sortFieldName = 'Shipment.contractdate'
                            break
                        case 'pl':
                            fieldName = 'Pldo.title'
                            sortFieldName = 'Shipment.contractdate'
                            break
                        case 'supplierPi':
                            fieldName = 'Shipment.supplier_pi_no'
                            sortFieldName = 'Shipment.contractdate'
                            break
                        case 'title':
                            fieldName = 'Shipment.title'
                            sortFieldName = 'Shipment.contractdate'
                            break
                        case 'osd':
                            fieldName = 'Shipment.shipweekdate'
                            sortFieldName = 'Shipment.shipweekdate'
                            break
                        case 'ets':
                            fieldName = 'Shipment.etsdate'
                            sortFieldName = 'Shipment.etsdate'
                            break
                        case 'etd':
                            fieldName = 'Shipment.etddate'
                            sortFieldName = 'Shipment.etddate'
                            break
                        case 'eta':
                            fieldName = 'Shipment.etadate'
                            sortFieldName = 'Shipment.etadate'
                            break
                    }

                    this.loading.shipments = true

                    let payload = {}
                    let order = []

                    if (_.isEqual(this.currentSearch, this.searchValues) === false) {
                        //new search = new sort
                        order.push({field: fieldName, direction: ['invoice','pl','supplierPi','title'].includes(field) ? 'ASC' : 'DESC'})
                        this.tableOptions.sortBy[0] = sortFieldName
                        this.tableOptions.sortDesc[0] = ['invoice','pl','supplierPi','title'].includes(field) ? false : true
                        this.currentSearch = _.cloneDeep(this.searchValues)
                        //new search new page
                        this.tableOptions.page = 1
                    } else {
                        //same search new sort
                        if(this.tableOptions.sortBy.length == 0){
                            this.tableOptions.sortBy[0] = sortFieldName
                        }
                        order.push({field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'})
                    }

                    if(fieldName != null) {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [
                                {
                                    field: fieldName,
                                    value: this.searchValues[field]
                                }
                            ],
                            order: order
                        }
                    } else {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [],
                            order: order
                        }
                    }
                    this.searchAllShipments(payload)
                        .then(() => {
                            this.loading.shipments = false;
                        })
                        .catch(()=>{
                            this.loading.shipments = false;
                        })
                        .finally(()=>{
                            this.loading.shipments = false;
                        })
                }
            },
            updateContract (val) {
                let tab = window.open('/v1/contracts/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateDebitNote(val) {
                let tab = window.open('/v1/debitnotes/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateInvoice(val) {
                let tab = window.open('/v1/invoices/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updatePackingList(val) {
                let tab = window.open('/v1/packinglists/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updatePayments(val) {
                let tab = window.open('/v1/payments/update/contracts/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updatePurchaseOrder(val) {
                let tab = window.open('/v1/purchaseorders/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateShippingInstruction(val) {
                let tab = window.open('/v1/sdas/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateShipment (val) {
                let tab = window.open('/v1/shipments/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateDataTable(options) {
                this.tableOptions = options
                if(this.searching === true)  {
                    // check which field is not empty
                    let field = null
                    if(this.searchValues.title != null) field = 'title'
                    if(this.searchValues.osd.length > 0) field = 'osd'
                    if(this.searchValues.ets.length > 0) field = 'ets'
                    if(this.searchValues.eta.length > 0) field = 'eta'
                    if(this.searchValues.etd.length > 0) field = 'etd'
                    this.searchShipments(field)
                } else {
                    this.filterShipments()
                }
            },
        },
        created ()  {
            this.loading.shipments = true;
            this.searchValues.invoice = null
            this.searchValues.pl = null
            this.searchValues.supplierPi = null
            this.searchValues.title = null
            this.searchValues.osd = []
            this.searchValues.ets = []
            this.searchValues.etd = []
            this.searchValues.eta = []
            this.filterValues.buyer = null
            this.filterValues.destination = null
            this.filterValues.origin = null
            this.filterValues.pod = null
            this.filterValues.pol = null
            // this.filterValues.status = 20
            this.filterValues.status = null
            this.filterValues.supplier = null

            if(this.filterValues.status == 20){
                this.filterShipments()
            } else {
                this.loadShipments()
            }

            if(this.filterItems.buyer.length == 0) this.loadFilterItems('buyer')
            if(this.filterItems.destination.length == 0) this.loadFilterItems('destination')
            if(this.filterItems.origin.length == 0) this.loadFilterItems('origin')
            if(this.filterItems.pod.length == 0) this.loadFilterItems('pod')
            if(this.filterItems.pol.length == 0) this.loadFilterItems('pol')
            if(this.filterItems.status.length == 0) this.loadFilterItems('status')
            if(this.filterItems.supplier.length == 0) this.loadFilterItems('supplier')
        },
        mounted() {
            const dataTableElement = document.querySelector('#shipmentTable > .v-data-table__wrapper > table > tbody')
            const resizeObserver = new ResizeObserver(entries => {
                console.log(Date.now())
                for (const entry of entries) {
                    const contentBoxSize = entry.contentBoxSize[0];
                    if(contentBoxSize.blockSize >= 40) {
                        if (this.loading.shipments) this.loading.shipments = false;
                        resizeObserver.disconnect();
                    }
                }
            });
            resizeObserver.observe(dataTableElement);
        }
    }
</script>

<style>
.filter-width-175 {
    width: 175px !important;
    max-width: 175px !important;
}
.filter-width-190 {
    width: 190px !important;
    max-width: 190px !important;
}
.filter-width-225 {
    width: 225px !important;
    max-width: 225px !important;
}
.font-xs {
    font-size: 0.65rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table-header th {
    white-space: nowrap;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}

.dense-inputs .v-input {
    padding-top: 5px !important;
}
</style>