<template>
    <v-dialog
        :max-width="'600px !important'"
        persistent
        v-model="newDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
    >
        <v-card>
            <v-card-title class="warning white--text justify-space-between">
                <span>{{ $t('message.newDebitNote') }}</span>
            </v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="newForm" v-model="validForm">
                    <v-layout row pt-3>
                        <p class="black--text pl-5 mt-3 pb-0">
                            {{ 'A debit note raised for an invoice not in the LHDN portal '
                                + 'will have the original e-invoice reference number set to "NA" in the portal.'
                            }}
                        </p>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.referenceInvoiceNumber') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :items="referenceInvoices"
                                :loading="loading.invoices"
                                autocomplete="new-password"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="invoice_number"
                                solo
                                required
                                return-object
                                v-model="reference_invoice_number"
                            >
                                <template v-slot:item="{item}">
                                    <div class="d-flex flex-column py-1" style="width: 100%">
                                        <div class="font-weight-bold">{{ item.invoice_number}}</div>
                                        <div class="d-flex flex-row">
                                            <span class="font-xs">{{ $t('message.reference') + ': ' + item.invoice_reference }}</span>
                                            <v-spacer></v-spacer>
                                            <span class="font-xs">{{ item.invoice_buyer }}</span>
                                        </div>
                                    </div>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.debitNoteNumber') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :loading="loading.debitNoteNumber"
                                :value="debit_note_number"
                                :label="debit_note_number ? '' : $t('message.required')"
                                :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                autocomplete="new-password"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                required
                                @change="debit_note_number = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.amount') + ' (USD)' }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :value="amountToAdd"
                                :label="amountToAdd ? '' : $t('message.required')"
                                :rules="[...validationRules.required, ...validationRules.number, ...validationRules.minimumOne]"
                                autocomplete="new-password"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                type="number"
                                solo
                                required
                                @change="amountToAdd = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.exchangeRate') + ' (MYR:USD)' }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :value="exchangeRate"
                                :label="exchangeRate ? '' : $t('message.required')"
                                :rules="[...validationRules.required, ...validationRules.number, ...validationRules.minimumOne]"
                                autocomplete="new-password"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                type="number"
                                solo
                                required
                                @change="exchangeRate = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.reasonForDebitNote') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-lazy>
                                <v-textarea
                                    :value="reasonToAdd"
                                    :placeholder="$t('message.freeText')"
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                    autocomplete="new-password"
                                    auto-grow
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    rows="1"
                                    solo
                                    required
                                    @change="reasonToAdd = $event"
                                />
                            </v-lazy>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="newDialog = false" >{{ $t('message.dismiss') }}</v-btn>
                <v-btn
                    :loading="loading.proceed"
                    color="info"
                    small
                    @click="createDocument()"
                >{{ $t('message.proceed') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {api} from "Api";
import {mapGetters} from "vuex";
import {numberFormat} from "Helpers/helpers";

export default {
    name: "NewDebitNote",
    props: ['dialog'],
    data() {
        return {
            amountToAdd: 0,
            debit_note_number: null,
            exchangeRate: 1,
            loading: {
                debitNoteNumber: false,
                invoices: false,
                items: false,
                proceed: false
            },
            new_dialog: false,
            reference_invoice_number: null,
            referenceInvoices: [],
            reasonToAdd: null,
            validForm: true
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        newDialog: {
            get() {
                return this.new_dialog;
            },
            set(value){
                this.new_dialog = value
                if(value === false) {
                    this.$emit('dialog-closed')
                }
            }
        },
        totalAmountValid() {
            return true;
        }
    },
    methods: {
        async createDocument() {
            if(this.totalAmountValid) {
                if (this.$refs.newForm.validate()) {
                    if (await this.$root.$confirm(this.$t('message.createLhdnDebitNote'), this.$t('message.confirmations.continueNewLhdnDebitNoteAction'), {color: 'orange'})) {
                        this.loading.proceed = true;
                        api
                            .post('/lhdn/document/manual-debit-note', {
                                debit_note_number: this.debit_note_number,
                                exchange_rate: this.exchangeRate,
                                reference_invoice_number: this.reference_invoice_number,
                                amount: this.amountToAdd,
                                reason: this.reasonToAdd
                            })
                            .then((response) => {
                                if (response.data.status === 'success') {
                                    this.$toast.success(this.$t('message.successes.lhdnDebitNoteCreated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                    this.loading.proceed = false;
                                    this.$emit('new-done')
                                    this.newDialog = false;
                                } else {
                                    this.$toast.error(this.$t('message.errors.lhdnDebitNoteNotCreated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    this.loading.proceed = false;
                                }
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('message.errors.lhdnDebitNoteNotCreated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.proceed = false;
                            })
                    }
                } else {
                    this.$toast.error(this.$t('message.errors.lhdnDebitNoteNotCreated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            } else {
                this.$toast.error(this.$t('message.errors.totalAmountMoreThanOriginalAmount'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        formatThisNumber(value, format) {
            return numberFormat(value, format);
        },
        getNextDebitNoteNumber() {
            this.loading.debitNoteNumber = true
            api
                .get('/lhdn/next-document-no/dn')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.debit_note_number = response.data.data
                    } else {
                        this.$toast.error(this.$t('message.errors.cannotGetNextDebitNoteNumber'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading.debitNoteNumber = false
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.cannotGetNextDebitNoteNumber'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.debitNoteNumber = false
                })
        },
        getReferenceInvoices() {
            this.loading.invoices = true
            api
                .get('/lhdn/reference-invoices')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.referenceInvoices = response.data.data
                    } else {
                        console.log('Reference invoices cannot be loaded')
                    }
                    this.loading.invoices = false
                })
                .catch(() => {
                    console.log('Reference invoices cannot be loaded')
                    this.loading.invoices = false
                })
        },
    },
    watch: {
        dialog(value) {
            if(value) {
                this.debit_note_number = null;
                this.reference_invoice_number = null;
                this.reasonToAdd = null;
                this.amountToAdd = 0;
                this.exchangeRate = 1;
                this.getReferenceInvoices();
                this.getNextDebitNoteNumber();
            } else {
                this.items = [];
            }
            this.new_dialog = value;
        }
    },
}
</script>

<style scoped>

</style>