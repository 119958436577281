<template>
    <v-dialog
        :max-width="'600px !important'"
        persistent
        v-model="adjustDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
    >
        <v-card>
            <v-card-title class="warning white--text justify-space-between">
                <span>{{ $t('message.adjustLhdnInvoice') + ': ' + invoiceNumber }}</span>
            </v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="adjustForm" v-model="validForm">
                    <v-layout row pt-3>
                        <p class="black--text pl-5 mt-3 pb-0">
                            {{ 'A credit note is required to decrease the amount of a previously issued '
                                + 'e-invoice which is already more than 72 hours old '
                                + 'since the date and time it was validated. '
                                + 'To increase the amount please issue a debit note.'
                            }}
                        </p>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.referenceInvoiceNumber') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1 pl-3 class="black--text text-darken-1">{{ invoiceNumber }}</v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.creditNoteNumber') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :loading="loading.creditNoteNumber"
                                :value="credit_note_number"
                                :label="credit_note_number ? '' : $t('message.required')"
                                :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                autocomplete="new-password"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                required
                                @change="credit_note_number = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.amountToDeduct') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :value="amountToDeduct"
                                :label="amountToDeduct ? '' : $t('message.required')"
                                :rules="[...validationRules.required, ...validationRules.number, ...validationRules.minimumOne]"
                                autocomplete="new-password"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                type="number"
                                solo
                                required
                                @change="amountToDeduct = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.reasonForDeduction') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-lazy>
                                <v-combobox
                                    :items="reasonOptions"
                                    :loading="loading.reasonOptions"
                                    :placeholder="$t('message.selectOptionOrFreeText')"
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                    :value="reasonToDeduct"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="reasonToDeduct = $event"
                                ></v-combobox>
                            </v-lazy>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="adjustDialog = false" >{{ $t('message.dismiss') }}</v-btn>
                <v-btn
                    :loading="loading.proceed"
                    color="info"
                    small
                    @click="adjustDocument()"
                >{{ $t('message.proceed') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {api} from "Api";
import {mapGetters} from "vuex";
import {numberFormat} from "Helpers/helpers";

// Version 2

export default {
    name: "AdjustDocument",
    props: ['dialog', 'invoiceNumber','jobId','documentUuid'],
    data() {
        return {
            adjust_dialog: false,
            credit_note_number: null,
            loading: {
                creditNoteNumber: false,
                items: false,
                proceed: false,
                reasonOptions: false
            },
            amountToDeduct: 0,
            newAmount: 0,
            originalAmount: 0,
            reasonOptions: [],
            reasonToDeduct: null,
            validForm: true
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        adjustDialog: {
            get() {
                return this.adjust_dialog;
            },
            set(value){
                this.adjust_dialog = value
                if(value === false) {
                    this.$emit('dialog-closed')
                }
            }
        },
        totalAmountValid() {
            return this.originalAmount >= this.newAmount;
        }
    },
    methods: {
        async adjustDocument() {
            if(this.totalAmountValid) {
                if (this.$refs.adjustForm.validate()) {
                    if (await this.$root.$confirm(this.$t('message.adjustLhdnInvoice') + ' ' + this.invoiceNumber, this.$t('message.confirmations.continueLhdnAdjustAction'), {color: 'orange'})) {
                        this.loading.proceed = true;
                        api
                            .put('/lhdn/document/adjust/' + this.jobId, {
                                credit_note_number: this.credit_note_number,
                                amount: this.amountToDeduct,
                                reason: this.reasonToDeduct
                            })
                            .then((response) => {
                                if (response.data.status === 'success') {
                                    this.$toast.success(this.$t('message.successes.lhdnAdjusted'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                    this.loading.proceed = false;
                                    this.$emit('adjust-done')
                                    this.adjustDialog = false;
                                } else {
                                    this.$toast.error(this.$t('message.errors.lhdnNotAdjusted'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    this.loading.proceed = false;
                                }
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('message.errors.lhdnNotAdjusted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.proceed = false;
                            })
                    }
                } else {
                    this.$toast.error(this.$t('message.errors.lhdnNotAdjusted'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            } else {
                this.$toast.error(this.$t('message.errors.totalAmountMoreThanOriginalAmount'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        formatThisNumber(value, format) {
            return numberFormat(value, format);
        },
        getNextCreditNoteNumber() {
            this.loading.creditNoteNumber = true
            api
                .get('/lhdn/next-document-no/cn')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.credit_note_number = response.data.data
                    } else {
                        this.$toast.error(this.$t('message.errors.cannotGetNextCreditNoteNumber'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading.creditNoteNumber = false
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.cannotGetNextCreditNoteNumber'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.creditNoteNumber = false
                })
        },
        getReasonOptions() {
            this.loading.reasonOptions = true
            api
                .get('/lhdn/reason-options')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.reasonOptions = response.data.data
                    } else {
                        console.log('Reason options cannot be loaded')
                    }
                    this.loading.reasonOptions = false
                })
                .catch(() => {
                    console.log('Reason options cannot be loaded')
                    this.loading.reasonOptions = false
                })
        }
    },
    watch: {
        dialog(value) {
            if(value) {
                this.credit_note_number = null;
                this.reasonToDeduct = null;
                this.amountToDeduct = 0;
                this.getNextCreditNoteNumber();
                this.getReasonOptions();
            } else {
                this.items = [];
            }
            this.adjust_dialog = value;
        }
    },
}
</script>

<style>
.bg-lightgrey {
    background-color: #F5F5F5;
}
.v-data-table td {
    padding: 0 3px !important;
    font-size: unset !important;
    line-height: unset !important;
}
table td.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
</style>