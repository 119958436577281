<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? (Supplier__otsname ? Supplier__otsname : Supplier__title) : ''" help-page-component="AddSupplier"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <SupplierAlert
                    :alert="$t('message.closeAlert')"
                    type="warning"
                    class="mt-3"
                    mode="add"
                    v-if="addMode && newSupplierUnsaved"
                />
                <SupplierAlert
                    :alert="$t('message.closeAlert')"
                    type="warning"
                    class="mt-3"
                    mode="update"
                    v-if="updateMode && updatedSupplierUnsaved"
                />
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation ref="supplierProfileForm" v-model="validForm">
                        <v-overlay
                            :value="loader"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    />
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                            accordion
                            v-model="panel"
                            multiple
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-6 v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadActiveSupplier') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items="allSuppliers"
                                                    :loading="loadingAnotherSupplier"
                                                    autocomplete="new-password"
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Supplier.id"
                                                    item-text="Supplier.title"
                                                    required
                                                    solo
                                                    @click="loadingAnotherSupplier = true"
                                                    @blur="loadingAnotherSupplier = false"
                                                    @change="loadAnotherSupplier($event)"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.name') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :label="Supplier__title ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                                    :value="Supplier__title"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    required
                                                    solo
                                                    @change="Supplier__title = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.abbreviation') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :label="Supplier__code ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                                    :value="Supplier__code"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    required
                                                    solo
                                                    @change="Supplier__code = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.otsName') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :label="Supplier__otsname ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                                    :value="Supplier__otsname"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    required
                                                    solo
                                                    @change="Supplier__otsname = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.type') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-select
                                                    :items="supplierTypes"
                                                    :value="Supplier__suppliertype_id"
                                                    :rules="[...validationRules.required]"
                                                    autocomplete="new-password"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Suppliertype.id"
                                                    item-text="Suppliertype.title"
                                                    required
                                                    solo
                                                    @change="Supplier__suppliertype_id = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="Supplier__suppliertype_id == 1">
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.representedBy') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-select
                                                    :items="allAgents"
                                                    :value="Supplier__subsupplier_id"
                                                    autocomplete="new-password"
                                                    clearable
                                                    hide-details="auto"
                                                    item-value="Supplier.id"
                                                    item-text="Supplier.title"
                                                    required
                                                    solo
                                                    @change="Supplier__subsupplier_id = $event"
                                            >
                                                <template v-slot:item="{ item }">
                                                    <span>{{ item.Supplier.otsname }}</span>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <span>{{ item.Supplier.otsname }}</span>
                                                </template>
                                            </v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.sameGroupAs') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <SupplierGroupList
                                                    :key="supplierGroupListKey"
                                                    :supplier-id="supplierId"
                                                    :supplier-name="Supplier__otsname"
                                                    :group-id="Supplier__group_id"
                                                    @added-to-group="addedToSupplierGroup"
                                                    @group-created="supplierGroupCreated"
                                                    @removed-from-group="removedFromSupplierGroup"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="Supplier__suppliertype_id == 2">
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.otherLocations') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items="allSuppliers"
                                                    chips
                                                    clearable
                                                    deletable-chips
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Supplier.title"
                                                    item-value="Supplier.id"
                                                    multiple
                                                    solo
                                                    v-model="Supplier__other_locations"
                                            >
                                                <template v-slot:selection="data">
                                                    <v-chip
                                                            :input-value="data.selected"
                                                            class="my-1"
                                                            close
                                                            small
                                                            v-bind="data.attrs"
                                                            @click="data.select"
                                                            @click:close="removeOtherLocation(data.item)"
                                                    >
                                                        {{ data.item.Supplier.otsname }}
                                                    </v-chip>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="[3,4].includes(Supplier__suppliertype_id)">
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.suppliersRepresented') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items="allSuppliers"
                                                    chips
                                                    clearable
                                                    deletable-chips
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Supplier.title"
                                                    item-value="Supplier.id"
                                                    multiple
                                                    solo
                                                    v-model="Supplier__suppliers_represented"
                                                    @change="saveSupplier"
                                            >
                                                <template v-slot:selection="data">
                                                    <v-chip
                                                            :input-value="data.selected"
                                                            class="my-1"
                                                            close
                                                            small
                                                            v-bind="data.attrs"
                                                            @click="data.select"
                                                            @click:close="removeSupplierRepresented(data.item)"
                                                    >{{ data.item.Supplier.otsname }}
                                                    </v-chip>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <!-- Malaysia companies only -->
                                    <template v-if="Company__country_id == 1">
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.taxNumber') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 class="d-flex flex-row align-center">
                                                <v-select
                                                    :label="$t('message.type')"
                                                    :placeholder="$t('message.na')"
                                                    :items="taxTypes"
                                                    class="field-width-150"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Company__taxno_type"
                                                />
                                                <v-text-field
                                                    :label="$t('message.number')"
                                                    :rules="[...validationRules.plainTextWithNumbers]"
                                                    :value="Company__gstno"
                                                    autocomplete="new-password"
                                                    class="force-text-left ml-3"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Company__gstno = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.brn') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                    :label="$t('message.number')"
                                                    :rules="[...validationRules.plainTextWithNumbers]"
                                                    :value="Company__brnno"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Company__brnno = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.sstNo') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                    :label="$t('message.number')"
                                                    :rules="[...validationRules.plainTextWithNumbers]"
                                                    :value="Company__sstno"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Company__sstno = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                    <!-- Malaysia companies only -->
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.address1') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :label="Company__address1 ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                                    :value="Company__address1"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    required
                                                    @change="Company__address1 = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.address2') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :rules="[...validationRules.plainTextWithNumbers]"
                                                    :value="Company__address2"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Company__address2 = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.address3') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :rules="[...validationRules.plainTextWithNumbers]"
                                                    :value="Company__address3"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Company__address3 = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.city') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :label="Company__city ? '' : $t('message.required')"
                                                    :rules="noCityCountries(Company__country_id) ? [...validationRules.plainText] : [...validationRules.required, ...validationRules.plainText]"
                                                    :value="Company__city"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    required
                                                    solo
                                                    @change="Company__city = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.postcode') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :rules="[...validationRules.plainTextWithNumbers]"
                                                    :value="Company__postcode"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Company__postcode = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.stateProvince') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :rules="[...validationRules.plainText]"
                                                    :value="Company__state"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Company__state = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.country') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items="allCountries"
                                                    :label="Company__country_id ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required]"
                                                    autocomplete="new-password"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Country.name"
                                                    item-value="Country.id"
                                                    solo
                                                    required
                                                    v-model="Company__country_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.officeTelephone') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :rules="[...validationRules.phoneNumber]"
                                                    :value="Company__tel"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Company__tel = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.website') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :rules="[...validationRules.url]"
                                                    :value="Company__website"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Company__website = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.contacts') }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <ContactListNew
                                                :update-mode="this.updateMode"
                                                :company-type="this.companyType"
                                                :validate.sync="validateContacts"
                                                @items-invalid="setItemsValidity(false)"
                                                @items-valid="setItemsValidity(true)"
                                                @reload-contact-list="reloadContactList()"
                                        />
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.marketing') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <SupplierProductsV2
                                        :update-mode="updateMode"
                                        :validate.sync="validateProducts"
                                        :changes-saved.sync="productChangesSaved"
                                        @fields-valid="setProductsValidity(true)"
                                        @fields-invalid="setProductsValidity(false)"
                                        @save-supplier="saveSupplier()"
                                        @fields-changed="updatedSupplierUnsaved = true"
                                        v-if="loader === false"
                                    />
                                    <hr/>
                                    <ProductTableListV3 :supplier-id="supplierId" v-if="$can('create','ExportProducts')"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.operations') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.portOfLoading') }}</v-flex>
                                        <v-flex xs6 lg4 md5 py-1>
                                            <v-autocomplete
                                                    :label="Supplier__loadingport_id ? null : $t('message.required')"
                                                    :items="allShippingLoadingPorts"
                                                    :item-text="item => item.Country.name ? item.Shippingport.title + '  -  ' + item.Country.name : item.Shippingport.title"
                                                    :item-value="'Shippingport.id'"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    required
                                                    solo
                                                    v-model="Supplier__loadingport_id"
                                            >
                                                <template v-slot:selection="data">
                                                    {{ data.item.Country.name ? data.item.Shippingport.title + '-' + data.item.Country.name : data.item.Shippingport.title }}
                                                </template>
                                                <template v-slot:item="data">
                                                    {{ data.item.Country.name ? data.item.Shippingport.title + '-' + data.item.Country.name : data.item.Shippingport.title }}
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row class="align-center">
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.supplierSpecificRequests') }}</v-flex>
                                        <v-flex xs6 lg4 md5 py-1>
                                            <v-lazy>
                                                <v-textarea
                                                    :value="Supplier__remarks"
                                                    auto-grow
                                                    clearable
                                                    dense
                                                    solo
                                                    hide-details="auto"
                                                    rows="1"
                                                    @change="Supplier__remarks = $event"
                                                />
                                            </v-lazy>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row pt-3 class="d-flex flex-row align-center">
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.certifications') }}</v-flex>
                                        <v-flex xs6 lg10 md9 py-1>
                                            <div class="d-flex flex-row align-start">
                                                <div style="min-width: 75px !important; vertical-align: top">
                                                    <v-checkbox
                                                            :label="$t('message.fsc')"
                                                            :true-value="'1'"
                                                            :false-value="'0'"
                                                            class="mt-6"
                                                            dense
                                                            hide-details="auto"
                                                            solo
                                                            v-model="Supplier__fsc"
                                                    />
                                                </div>
                                                <template v-if="Supplier__fsc == 1">
                                                    <div class="d-flex flex-row" style="width: 100% !important;">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-xs">{{ $t('message.fmCoc') }}</span>
                                                            <v-text-field
                                                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                                                    :value="Supplier__fsc_certificate"
                                                                    class="force-text-left field-width-200"
                                                                    clearable
                                                                    dense
                                                                    hide-details="auto"
                                                                    solo
                                                                    @change="Supplier__fsc_certificate = $event"
                                                            />
                                                        </div>
                                                        <div class="d-flex flex-column ml-3">
                                                            <span class="font-xs">{{ $t('message.expiryDate') }}</span>
                                                            <v-menu
                                                                    :close-on-content-click="false"
                                                                    :nudge-right="40"
                                                                    max-width="290px"
                                                                    min-width="290px"
                                                                    ref="fscExpiryDateMenu"
                                                                    transition="scale-transition"
                                                                    v-model="fscExpiryDateMenu"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-text-field
                                                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                                            :value="computedFscExpiryDateFormatted"
                                                                            append-icon="event"
                                                                            clearable
                                                                            class="force-text-left field-width-200"
                                                                            hint="DD/MM/YY format"
                                                                            hide-details="auto"
                                                                            background-color="white"
                                                                            persistent-hint
                                                                            solo
                                                                            v-on="on"
                                                                            @click:clear="Supplier__fsc_expiry_date = null"
                                                                            @click:append="fscExpiryDateMenu = true"
                                                                    />
                                                                </template>
                                                                <v-date-picker
                                                                        first-day-of-week="1"
                                                                        locale-first-day-of-year="4"
                                                                        show-week
                                                                        v-model="Supplier__fsc_expiry_date"
                                                                        @input="fscExpiryDateMenu = false"
                                                                />
                                                            </v-menu>
                                                        </div>
                                                        <div class="mt-5" style="width: 100%; max-width: 700px">
                                                            <FileUpload
                                                                    :file="file(301)"
                                                                    :module="module"
                                                                    :document="document(301)"
                                                                    :update-mode="updateMode"
                                                                    :stand-alone="true"
                                                                    :file-name-prefix="fileNamePrefix"
                                                                    @upload-file-done="addUploadedFile"
                                                                    @delete-file-done="deleteFile"
                                                                    style="margin-left: 2px; width: 98% !important;"
                                                            />
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                            <div :class="'d-flex flex-row align-start' + (Supplier__pefc == 1 ? ' my-2' : '')">
                                                <div style="min-width: 75px !important; vertical-align: top">
                                                    <v-checkbox
                                                            :label="$t('message.pefc')"
                                                            :true-value="'1'"
                                                            :false-value="'0'"
                                                            class="mt-6"
                                                            dense
                                                            hide-details="auto"
                                                            solo
                                                            v-model="Supplier__pefc"
                                                    />
                                                </div>
                                                <template v-if="Supplier__pefc == 1">
                                                    <div class="d-flex flex-row" style="width: 100% !important;">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-xs">{{ $t('message.number') }}</span>
                                                            <v-text-field
                                                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                                                    :value="Supplier__pefc_certificate"
                                                                    class="force-text-left field-width-200"
                                                                    clearable
                                                                    dense
                                                                    hide-details="auto"
                                                                    solo
                                                                    @change="Supplier__pefc_certificate = $event"
                                                            />
                                                        </div>
                                                        <div class="d-flex flex-column ml-3">
                                                            <span class="font-xs">{{ $t('message.expiryDate') }}</span>
                                                            <v-menu
                                                                    :close-on-content-click="false"
                                                                    :nudge-right="40"
                                                                    max-width="290px"
                                                                    min-width="290px"
                                                                    ref="pefcExpiryDateMenu"
                                                                    transition="scale-transition"
                                                                    v-model="pefcExpiryDateMenu"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-text-field
                                                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                                            :value="computedPefcExpiryDateFormatted"
                                                                            append-icon="event"
                                                                            clearable
                                                                            class="force-text-left field-width-200"
                                                                            hint="DD/MM/YY format"
                                                                            hide-details="auto"
                                                                            background-color="white"
                                                                            persistent-hint
                                                                            solo
                                                                            v-on="on"
                                                                            @click:clear="Supplier__pefc_expiry_date = null"
                                                                            @click:append="pefcExpiryDateMenu = true"
                                                                    />
                                                                </template>
                                                                <v-date-picker
                                                                    first-day-of-week="1"
                                                                    locale-first-day-of-year="4"
                                                                    show-week
                                                                    v-model="Supplier__pefc_expiry_date"
                                                                    @input="pefcExpiryDateMenu = false"
                                                                />
                                                            </v-menu>
                                                        </div>
                                                        <div class="mt-5" style="width: 100%; max-width: 700px">
                                                            <FileUpload
                                                                    :file="file(302)"
                                                                    :module="module"
                                                                    :document="document(302)"
                                                                    :update-mode="updateMode"
                                                                    :stand-alone="true"
                                                                    :file-name-prefix="fileNamePrefix"
                                                                    @upload-file-done="addUploadedFile"
                                                                    @delete-file-done="deleteFile"
                                                                    style="margin-left: 2px; width: 98% !important;"
                                                            />
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row pt-3 class="d-flex flex-row align-center">
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.logoForPrint') }}</v-flex>
                                        <v-flex xs6 lg10 md9 py-1>
                                            <template v-if="Supplier__logo != null">
                                                <img :src="'data:image/jpeg;base64,' + Supplier__logo" alt="avatar" height="120" width="120" class="img-responsive"/>
                                            </template>
                                            <template v-else>
                                                <div class="company-logo d-flex flex-row align-center justify-center">LOGO</div>
                                            </template>
                                            <v-btn @click="openUpdateLogoDialog()" icon class="grey--text darken-4">
                                                <v-icon>mdi-camera</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.finance') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3 class="align-center">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="">{{ $t('message.defaultPaymentTerms') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items="allSupplierPaymentTerms"
                                                    :label="'#1'"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Paymentterm.id"
                                                    item-text="Paymentterm.title"
                                                    required
                                                    solo
                                                    v-model="Supplier__paymentstatement_id "
                                            >
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout aria-rowcount="">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class=""></v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items="allSupplierPaymentTerms"
                                                    :label="'#2'"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Paymentterm.id"
                                                    item-text="Paymentterm.title"
                                                    required
                                                    solo
                                                    v-model="Supplier__wpr_paymentstatement_id"
                                            >
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <template v-if="[3,4].includes(Supplier__suppliertype_id)">
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.agentCommission') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-checkbox
                                                        :label="$t('message.yes')"
                                                        :true-value="1"
                                                        class="mt-0"
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Supplier__has_commission"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="Supplier__has_commission == 1">
                                            <v-flex xs6 lg2 md3 pl-5 py-1></v-flex>
                                            <v-flex xs6 lg5 md6 py-1>
                                                <AgentCommissions :supplier-id="supplierId" :update-mode="updateMode"></AgentCommissions>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout v-if="false">
                                            <v-flex xs6 lg3 md5 py-1 offset-lg-2 offset-md-3 offset-xs-6>
                                                <div class="d-flex flex-row align-center">
                                                    <span style="width: 95px">{{ $t('message.softwood') }}</span>
                                                    <v-text-field
                                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                            :value="Supplier__swcustomprice"
                                                            class="force-text-left"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            solo
                                                            @change="Supplier__swcustomprice = $event"
                                                    />
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout v-if="false">
                                            <v-flex xs6 lg3 md5 py-1 offset-lg-2 offset-md-3 offset-xs-6>
                                                <div class="d-flex flex-row align-center">
                                                    <span style="width: 95px">{{ $t('message.hardwood') }}</span>
                                                    <v-text-field
                                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                            :value="Supplier__hwcustomprice"
                                                            class="force-text-left"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            solo
                                                            @change="Supplier__hwcustomprice = $event"
                                                    />
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout v-if="false">
                                            <v-flex xs6 lg6 md8 py-1 offset-lg-2 offset-md-3 offset-xs-6 class="">
                                                {{ $t('message.note') + ': ' + $t('message.agentCommissionCalculation') }}
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_supplier')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <DocumentHistory asset="suppliers" :asset_id="Supplier__id"></DocumentHistory>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row>
                            <v-flex d-flex xs12>
                                <v-layout row px-3 pt-3 mb-3>
                                    <v-btn
                                            class="ml-3"
                                            small
                                            v-if="false"
                                            @click.stop="previewDialog = true"
                                    >{{ $t('message.previewProfile') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.add"
                                            class="ml-3"
                                            color="info"
                                            small
                                            @click="addSupplier()"
                                            v-if="addMode == true"
                                    >{{ $t('message.create') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.reset"
                                            @click="resetSupplierForm"
                                            class="ml-3"
                                            color="default"
                                            small
                                            v-if="addMode == true"
                                    ><v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.save"
                                            class="ml-3"
                                            color="info"
                                            small
                                            @click="saveSupplier()"
                                            v-if="addMode == false"
                                    >{{ $t('message.save') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.suspend"
                                            class="ml-3"
                                            color="error"
                                            small
                                            @click="suspendSupplier(Supplier__id)"
                                            v-if="addMode == false"
                                    >{{ $t('message.suspend') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.convert"
                                            class="ml-3"
                                            small
                                            @click="openConvertToBuyerDialog"
                                            v-if="updateMode == true && $can('convert','Supplier')"
                                    >{{ $t('message.copyBuyer') }}
                                    </v-btn>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <v-dialog
            overlay=false
            scrollable
            transition="dialog-bottom-transition"
            v-model="previewDialog"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.supplierPreview') }}</v-card-title>
                <v-card-text class="pa-3"></v-card-text>
                <v-card-actions class="pr-3 pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="ml-3"
                            color="default"
                            small
                            @click="previewDialog = false"
                    >{{ $t('message.backToEditing') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.add"
                            class="ml-3"
                            color="info"
                            small
                            @click="addSupplier()"
                            v-if="addMode == true"
                    >{{ $t('message.addSupplier') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.save"
                            class="ml-3"
                            color="info"
                            small
                            @click="saveSupplier()"
                            v-if="addMode == false"
                    >{{ $t('message.saveChanges') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            overlay=false
            scrollable
            transition="dialog-bottom-transition"
            width="600px"
            v-model="convertToBuyerDialog"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.copyBuyer') + ': ' + Supplier__title  }}</v-card-title>
                <v-card-text class="pa-3">
                    <v-alert dense>{{ $t('message.confirmations.continueConvertSupplierToBuyerAction') }}</v-alert>
                    <template v-if="sameNameBuyers.length > 0">
                        <div class="mx-4">
                            <h5>{{ $t('message.sameNameSuppliers') }}</h5>
                            <div v-for="buyer in sameNameBuyers" :key="buyer.Customer.id" class="font-md">{{ buyer.Customer.name }}</div>
                        </div>
                    </template>
                </v-card-text>
                <v-card-actions class="pr-3 pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="ml-3"
                            color="default"
                            small
                            @click="closeConvertToBuyerDialog"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.add"
                            class="ml-3"
                            color="info"
                            small
                            @click="convertSupplierToBuyer"
                            v-if="updateMode == true && $can('convert','Supplier')"
                    >{{ $t('message.copy') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            overlay=false
            scrollable
            transition="dialog-bottom-transition"
            v-model="updateLogoDialog"
            width="350"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.updateSupplierLogo') }}</v-card-title>
                <v-card-text>
                    <v-layout row>
                        <v-col cols="12" class="pt-6">
                            <vue-croppie-component
                                :enable-resize="false"
                                :enable-orientation="false"
                                :boundary="{ width: 310, height: 195}"
                                :viewport="{ width: 250, height: 125, type:'square' }"
                                ref="croppieRef"
                            />
                        </v-col>
                    </v-layout>
                    <v-row no-gutters>
                        <v-col cols="12" class="d-flex flex-row justify-center">
                            <input @change="croppie" type="file" accept="image/png, image/jpeg, image/jpg"/>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer/>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="updateLogoDialog = false"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.upload"
                        class="ml-3"
                        color="info"
                        small
                        @click="updateSupplierLogo()"
                    >{{ $t('message.upload') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <AlertMissingValues
                :alert_message="this.dialogs.error_message"
                :missing_values="this.errors.missing_values"
                :dialog.sync="dialogs.error"
                @dialog-closed="dialogClosed"
        />
    </div>
</template>
<script>
    import { api } from "Api";
    import {mapGetters, mapActions, mapState} from "vuex";
    import {mapFieldsPrefixed} from  'Helpers/vuex-map-fields-prefixed'
    import {mapFields} from "vuex-map-fields";
    import router from "../../../router";
    import {formatDate, isObject, isset, log, makeId, scrollToFirstFormValidationError} from "Helpers/helpers";
    import {VueCroppieComponent} from 'vue-croppie';
    import 'croppie/croppie.css';
    import Input from "Views/ui-elements/Input.vue";
    // import SupplierAlert from "../../../components/Appic/SupplierAlert";
    // import AlertMissingValues from "../../../components/Appic/AlertMissingValues";
    // import DocumentHistory from "../../../components/Appic/DocumentHistory";
    // import ContactList from "../../../components/Appic/ContactList";
    import SupplierProductsV2 from "Components/Appic/SupplierProductsV2";
    import AgentCommissions from "Components/Appic/AgentCommissions.vue";

    const AlertMissingValues = () => import("Components/Appic/AlertMissingValues");
    // const ContactList = () => import("../../../components/Appic/ContactList");
    const SupplierGroupList = () => import("Components/Appic/SupplierGroupList");
    const ContactListNew = () => import("Components/Appic/ContactListNew");
    const DocumentHistory = () => import("Components/Appic/DocumentHistory");
    const SupplierAlert = () => import("Components/Appic/SupplierAlert");
    const FileUpload = () => import("Components/Appic/FileUpload");
    // const SupplierProductsV2 = () => import("Components/Appic/SupplierProductsV2");
    const ProductTableListV3 = () => import("Components/Appic/ProductTableListV3");

    export default {
        name: "AddSupplier",
        components: {
            AgentCommissions,
            Input,
            ProductTableListV3,
            AlertMissingValues,
            ContactListNew,
            DocumentHistory,
            FileUpload,
            SupplierAlert,
            SupplierGroupList,
            SupplierProductsV2,
            VueCroppieComponent
        },
        props: ["supplierId"],
        title: '',
        data() {
            return {
                companyType: 'supplier',
                convertToBuyerDialog: false,
                croppieImage: '',
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                errors: {
                    missing_values: []
                },
                // filteredAllSuppliers: [],
                fscExpiryDateMenu: false,
                loader: false,
                loading: {
                    add: false,
                    reset: false,
                    suspend: false,
                    save: false
                },
                loadingAnotherSupplier: false,
                module: 'supplier',
                newLogoImage: null,
                panel: [0,1],
                pefcExpiryDateMenu: false,
                pefcMaterialCategories: [
                    {value: 1, text: this.$t('message.pefcCertified')},
                    {value: 2, text: this.$t('message.pefcControlledSources')}
                ],
                previewDialog: false,
                productChangesSaved: false,
                sameNameBuyers: [],
                supplierGroupListKey: 1,
                taxTypes: [
                    {value: 'EORI No.', text: 'EORI No.'},
                    {value: 'NPWP No.', text: 'NPWP No.'},
                    {value: 'Tax ID.', text: 'Tax ID.'},
                    {value: 'TIN', text: 'TIN'},
                    {value: 'USCC', text: 'USCC'},
                    {value: 'VAT', text: 'VAT'}
                ],
                updateLogoDialog: false,
                validContacts: false,
                validateContacts: false,
                validForm: false,
                validProducts: false,
                validateProducts: false
            }
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('supplier',{
                Company__id: 'Company.id',
                Company__address1: 'Company.address1',
                Company__address2: 'Company.address2',
                Company__address3: 'Company.address3',
                Company__brnno: 'Company.brnno',
                Company__city: 'Company.city',
                Company__country_id: 'Company.country_id',
                Company__email: 'Company.email',
                Company__fax: 'Company.fax',
                Company__GPCustomerNumber: 'Company.GPCustomerNumber',
                Company__gstno: 'Company.gstno',
                Company__latitude: 'Company.latitude',
                Company__longitude: 'Company.longitude',
                Company__name: 'Company.name',
                Company__override_ots_country: 'Company.override_ots_country',
                Company__postcode: 'Company.postcode',
                Company__sstno: 'Company.sstno',
                Company__state: 'Company.state',
                Company__taxno_type: 'Company.taxno_type',
                Company__tel: 'Company.tel',
                Company__uscc: 'Company.uscc',
                Company__website: 'Company.website',
                Product__origins: 'Product.origins',
                Supplier__id: 'Supplier.id',
                Supplier__account_name: 'Supplier.account_name',
                Supplier__auto_notifications: 'Supplier.auto_notifications',
                Supplier__code: 'Supplier.code',
                Supplier__company_id: 'Supplier.company_id',
                Supplier__customcommfunc: 'Supplier.customcommfunc',
                Supplier__documents: 'Supplier.documents',
                Supplier__fsc: 'Supplier.fsc',
                Supplier__fsc_certificate: 'Supplier.fsc_certificate',
                Supplier__fsc_expiry_date: 'Supplier.fsc_expiry_date',
                Supplier__fsc_material_category: 'Supplier.fsc_material_category',
                Supplier__fsc_product_type: 'Supplier.fsc_product_type',
                Supplier__group_id: 'Supplier.group_id',
                Supplier__has_commission: 'Supplier.has_commission',
                Supplier__has_ds_commission: 'Supplier.has_ds_commission',
                Supplier__hwcustomprice: 'Supplier.hwcustomprice',
                Supplier__ismainsupplier: 'Supplier.ismainsupplier',
                Supplier__loadingport_id: 'Supplier.loadingport_id',
                Supplier__logo: 'Supplier.logo',
                Supplier__other_locations: 'Supplier.other_locations',
                Supplier__otsname: 'Supplier.otsname',
                Supplier__paymentstatement_id: 'Supplier.paymentstatement_id',
                Supplier__pefc: 'Supplier.pefc',
                Supplier__pefc_certificate: 'Supplier.pefc_certificate',
                Supplier__pefc_claim: 'Supplier.pefc_claim',
                Supplier__pefc_expiry_date: 'Supplier.pefc_expiry_date',
                Supplier__pefc_material_category: 'Supplier.pefc_material_category',
                Supplier__pefc_product_type: 'Supplier.pefc_product_type',
                Supplier__productgroup_id: 'Supplier.productgroup_id',
                Supplier__productgroup2_id: 'Supplier.productgroup2_id',
                Supplier__productgroup3_id: 'Supplier.productgroup3_id',
                Supplier__remarks: 'Supplier.remarks',
                Supplier__species_id: 'Supplier.species_id',
                Supplier__species2_id: 'Supplier.species2_id',
                Supplier__species3_id: 'Supplier.species3_id',
                Supplier__species4_id: 'Supplier.species4_id',
                Supplier__subsupplier_id: 'Supplier.subsupplier_id',
                Supplier__suppliers_represented: 'Supplier.suppliers_represented',
                Supplier__suppliertype_id: 'Supplier.suppliertype_id',
                Supplier__swcustomprice: 'Supplier.swcustomprice',
                Supplier__title: 'Supplier.title',
                Supplier__wpr_paymentstatement_id: 'Supplier.wpr_paymentstatement_id'
            },'statePrefix'),
            ...mapGetters('certification',{
                allCertifications: 'allCertifications',
                allFscCertifications: 'allFscCertifications',
                allPefcCertifications: 'allPefcCertifications'
            }),
            ...mapFields('supplier',{
                options: 'options',
                newSupplierSaved: 'newSupplierSaved',
                newSupplierUnsaved: 'newSupplierUnsaved',
                updatedSupplierSaved: 'updatedSupplierSaved',
                updatedSupplierUnsaved: 'updatedSupplierUnsaved',
            }),
            ...mapGetters('supplier',{
                allAgents: 'allAgents',
                allPureSuppliers: 'allPureSuppliers',
                allSuppliers: 'allSuppliers',
            }),
            ...mapGetters('country',{
                allCountries: 'allCountries'
            }),
            ...mapGetters('document',{
                allSupplierDocuments: 'allSupplierDocuments'
            }),
            ...mapGetters('paymentterm', {
                allSupplierPaymentTerms: 'allSupplierPaymentTerms'
            }),
            ...mapGetters('productgroup',{
                allProductGroups: 'allProductGroups'
            }),
            ...mapGetters('species',{
                allSpecies: 'allSpecies'
            }),
            ...mapGetters('shippingport',{
                allShippingLoadingPorts: 'allShippingLoadingPorts'
            }),
            ...mapGetters([
                'supplierTypes',
                'validationRules'
            ]),
            addMode: function () {
                return this.$route.matched.some(({name}) => name === 'add_supplier')
            },
            computedFscExpiryDateFormatted() {
                return formatDate(this.Supplier__fsc_expiry_date)
            },
            computedPefcExpiryDateFormatted() {
                return formatDate(this.Supplier__pefc_expiry_date)
            },
            fileNamePrefix() {
                let prefix = ''
                if(this.Supplier__otsname != null && this.Supplier__otsname != ''){
                    prefix = this.Supplier__otsname.replace(" ","_")
                    prefix = prefix.replace("(","")
                    prefix = prefix.replace(")","")
                }
                return prefix
            },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_supplier')
            },
        },
        methods: {
            ...mapActions('certification',{
                getAllCertifications: 'getAllCertifications'
            }),
            ...mapActions('supplier',{
                createSupplier: 'createSupplier',
                getAllSuppliers: 'getAllSuppliers',
                getSupplierToUpdateById: 'getSupplierToUpdateById',
                resetCommissions: 'resetCommissions',
                resetCurrentSupplier: 'resetCurrentSupplier',
                resetAllSuppliers: 'resetAllSuppliers',
                setAgentCommissions: 'setAgentCommissions',
                suspendSupplierById: 'suspendSupplierById',
                updateSupplier: 'updateSupplier'
            }),
            ...mapActions('contact',{
                getActiveContactsByCompany: 'getActiveContactsByCompany',
                resetAllContacts: 'resetAllContacts'
            }),
            ...mapActions('country',{
                getAllCountries: 'getAllCountries'
            }),
            ...mapActions('paymentterm',{
                getAllPaymentTerms: 'getAllPaymentTerms'
            }),
            ...mapActions('productgroup', {
                getAllProductGroups: 'getAllProductGroups'
            }),
            ...mapActions('species',{
                getAllSpecies: 'getAllSpecies'
            }),
            ...mapActions('shippingport', {
                getAllShippingPorts: 'getAllShippingPorts',
            }),
            addedToSupplierGroup (groupId) {
                this.Supplier__group_id = groupId
                this.supplierGroupListKey += 1
            },
            addSupplier () {
                this.validateContacts = true
                let _this = this
                setTimeout(function() {
                    _this.validateProducts = true
                    setTimeout(function() {
                        if(_this.validContacts && _this.validProducts) {
                            if (_this.$refs.supplierProfileForm.validate()) {
                                _this.loading.add = true
                                _this.createSupplier()
                                    .then((response) => {
                                        if (response.status === 'success') {
                                            _this.$toast.success(_this.$t('message.successes.supplierAdded'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'check_circle_outline'
                                                }
                                            )
                                            _this.loading.add = false
                                            _this.newSupplierUnsaved = false
                                            _this.newSupplierSaved = true
                                            // reset new buyer state
                                            _this.resetCurrentSupplier().then(() => {
                                                //reset new contacts
                                                _this.resetAllContacts(_this.statePrefix)
                                                    .then(() => {
                                                        //reset all buyers to add new buyer to list
                                                        _this.resetAllSuppliers()
                                                            .then(() => {
                                                                // push new route
                                                                router.push({
                                                                    name: 'update_supplier',
                                                                    params: {supplierId: response.supplier_id}
                                                                }).catch(err => {
                                                                })
                                                            })
                                                    })
                                            })
                                        } else {
                                            if(isObject(response.message)){
                                                //extract validation errors
                                                const errors = Object.values(response.message)
                                                errors.forEach(errorObj => {
                                                    if(Array.isArray(errorObj)) {
                                                        errorObj.forEach(error => {
                                                            _this.$toast.error(error,
                                                                {
                                                                    classes: ['icon-float-left'],
                                                                    icon: 'error_outline'
                                                                }
                                                            )
                                                        })
                                                    } else {
                                                        _this.$toast.error(this.$t('message.errors.supplierNotAdded'),
                                                            {
                                                                classes: ['icon-float-left'],
                                                                icon: 'error_outline'
                                                            }
                                                        )
                                                    }
                                                })
                                            } else {
                                                _this.$toast.error(_this.$t('message.errors.supplierNotAdded'),
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                                _this.loading.add = false
                                            }
                                        }
                                    })
                                    .catch(() => {
                                        _this.$toast.error(_this.$t('message.errors.supplierNotAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                        _this.loading.add = false
                                    })
                            } else {
                                _this.$toast.error(_this.$t('message.errors.supplierNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                scrollToFirstFormValidationError()
                            }
                        } else {
                            _this.$toast.error(_this.$t('message.errors.supplierNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            scrollToFirstFormValidationError()
                        }
                    },800)
                },500)
            },
            addUploadedFile (fileObject){
                if(this.Supplier__documents.length === 0){
                    this.Supplier__documents.push({AssetDocument: fileObject.AssetDocument})
                    this.saveSupplier()
                } else {
                    //add/replace file in existing list
                    let doctype_id = fileObject.AssetDocument.doctype_id
                    let documents = []
                    this.Supplier__documents.forEach((item, index)=>{
                        //Add new check
                        if(doctype_id !== item.AssetDocument.doctype_id &&  item.AssetDocument.location !== fileObject.AssetDocument.location){
                            documents.push(item)
                        }
                    })
                    let newFile = {
                        AssetDocument: fileObject.AssetDocument
                    }
                    documents.push(newFile)
                    this.Supplier__documents = []
                    this.Supplier__documents = documents
                    this.saveSupplier()
                }
            },
            closeConvertToBuyerDialog() {
                this.sameNameBuyers = []
                this.convertToBuyerDialog = false
            },
            convertSupplierToBuyer() {
                let promise = new Promise( (resolve, reject) => {
                    api
                        .put('/suppliers/' + this.Supplier__id + '/convert-customer')
                        .then((response) => {
                            resolve(response.data.customer_id)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                promise
                    .then((response) => {
                        this.$toast.success(this.$t('message.successes.supplierConverted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.closeConvertToBuyerDialog()
                        router
                            .push({
                                name: 'update_customer',
                                params: {buyerId: response}
                            })
                            .catch(err => {})
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.supplierNotConverted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.closeConvertToBuyerDialog()
                    })
            },
            croppie (evt) {
                let files = evt.target.files || e.dataTransfer.files;
                if (!files.length) return;

                let reader = new FileReader();
                reader.onload = evt => {
                    this.$refs.croppieRef.bind({
                        url: evt.target.result
                    });
                };
                reader.readAsDataURL(files[0]);
            },
            deleteFile (assetDocumentId) {
                // this.$emit('delete-file-done', assetDocumentId)
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            document(doctype_id) {
                let document = this.Supplier__documents.find((d) => d.AssetDocument.doctype_id == doctype_id)
                if(document) return document
                return null
            },
            file(doctype_id) {
                let file = this.allSupplierDocuments.find(f => f.Document.doctype_id == doctype_id)
                if(file) return file
                return null
            },
            loadAnotherSupplier (val) {
                router.push({name: 'update_supplier', params: { supplierId : val}}).catch(err => {})
            },
            loadSupplierById (val) {
                let self = this
                this.loader = true
                this.getSupplierToUpdateById(val)
                    .then(()=>{
                        this.$title =  this.$t('message.titles.supplier') + ': ' + this.Supplier__otsname
                        let payload = {
                            company_id: this.Company__id,
                            prefix: self.statePrefix
                        }
                        this.getActiveContactsByCompany(payload)
                            .then(()=>{
                                this.updatedSupplierUnsaved = false;
                                this.loader = false;

                                //check if product origins is empty. if yes fill it
                                if(this.Product__origins == null){
                                    this.Product__origins = []
                                    if(this.Company__country_id != null){
                                        this.Product__origins.push(this.Company__country_id)
                                        this.saveSupplier()
                                    }
                                }

                                //reset product options
                                for(const option in this.options){
                                    this.options[option] = []
                                }
                            })
                            .catch(() => {
                                this.updatedSupplierUnsaved = false;
                                this.loader = false;
                            })
                    })
                    .catch(() => {
                        this.loader = false
                        this.updatedSupplierUnsaved = false
                        router
                            .push({name: 'not_found_main', params: {resource: 'supplier'}})
                            .catch(() => {
                                console.log('error')
                            })
                    })
            },
            noCityCountries(countryId) {
                const countriesWithoutCities = [12, 27, 84]
                if(countriesWithoutCities.includes(countryId)) return true
                return false
            },
            openConvertToBuyerDialog() {
                let promise = new Promise((resolve, reject) => {
                    api
                        .get('/suppliers/' + this.Supplier__id + '/check-customer')
                        .then(response => {
                            if(response.data.status === 'error'){
                                this.sameNameBuyers = response.data.customers
                            }
                            resolve('done')
                        })
                        .catch((error) => {
                            reject(error)
                        })
                })
                promise.then(() => {
                    this.convertToBuyerDialog = true
                })
            },
            openProductTable() {
                let tab = window.open('/v1/suppliers/' + this.supplierId + '/product-table', '_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            openUpdateLogoDialog() {
                this.updateLogoDialog = true
                this.croppieImage = ''
                this.newLogoImage = null
            },
            reloadContactList(){
                // this.pageKey = Math.floor(Math.random() * 100)
                let payload = {
                    company_id: this.Company__id,
                    prefix: this.statePrefix
                }
                this.getActiveContactsByCompany(payload)
            },
            removedFromSupplierGroup () {
                this.Supplier__group_id = null
                this.supplierGroupListKey += 1
            },
            removeOtherLocation (item) {
                const index = this.Supplier__other_locations.indexOf(item.Supplier.id)
                if(index >= 0) this.Supplier__other_locations.splice(index, 1)
            },
            removeSupplierRepresented (item) {
                const index = this.Supplier__suppliers_represented.indexOf(item.Supplier.id)
                if(index >= 0) this.Supplier__suppliers_represented.splice(index, 1)
            },
            async resetSupplierForm () {
                if (await this.$root.$confirm(this.$t('message.resetSupplierForm'), this.$t('message.confirmations.continueSupplierFormReset'), {color: 'orange'})) {
                    this.loading.reset = true
                    this.resetCurrentSupplier()
                        .then(()=>{
                            setTimeout(() => {
                                this.newSupplierSaved = false
                                this.newSupplierUnsaved = false
                                this.loading.reset = false
                                this.$toast.success(this.$t('message.supplierFormResetNotification'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                            }, 500)
                        })
                }
            },
            saveSupplier () {
                this.validateContacts = true
                if(this.productChangesSaved) this.productChangesSaved = false
                let _this = this
                setTimeout(function() {
                    _this.validateProducts = true
                    setTimeout(function() {
                        // console.log(_this.validContacts)
                        // console.log(_this.validProducts)
                        if(_this.validContacts && _this.validProducts) {
                            if (_this.$refs.supplierProfileForm.validate()) {
                                _this.loading.save = true
                                _this.updateSupplier(_this.Supplier__id)
                                    .then((response) => {
                                        if (response.status === 'success') {
                                            _this.$toast.success(_this.$t('message.successes.supplierUpdated'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'check_circle_outline'
                                                }
                                            )
                                            let payload = {
                                                company_id: _this.Company__id,
                                                prefix: _this.statePrefix
                                            }
                                            _this.getActiveContactsByCompany(payload)
                                                .then(() => {
                                                    _this.updatedSupplierUnsaved = false;
                                                    _this.loading.save = false
                                                })
                                                .catch(() => {
                                                    _this.updatedSupplierUnsaved = false;
                                                    _this.loading.save = false
                                                })
                                                .then(() => {
                                                    _this.updatedSupplierUnsaved = false;
                                                    _this.loading.save = false
                                                })

                                            // reload agent commissions 2024-09-12
                                            if(_this.Supplier__has_commission) {
                                                api
                                                    .get('/suppliers/' + _this.Supplier__id + '/agent-commissions')
                                                    .then((response) => {
                                                        if (response.data.status === 'success') {
                                                            const payload = {
                                                                prefix: _this.statePrefix,
                                                                data: response.data.data
                                                            }
                                                            _this.setAgentCommissions(payload);
                                                        }
                                                    })
                                                    .catch(err => console.log('error'))
                                            }
                                        } else {
                                            if(isObject(response.message)){
                                                //extract validation errors
                                                const errors = Object.values(response.message)
                                                errors.forEach(errorObj => {
                                                    if(Array.isArray(errorObj)) {
                                                        errorObj.forEach(error => {
                                                            _this.$toast.error(error,
                                                                {
                                                                    classes: ['icon-float-left'],
                                                                    icon: 'error_outline'
                                                                }
                                                            )
                                                        })
                                                    } else {
                                                        _this.$toast.error(this.$t('message.errors.supplierNotUpdated'),
                                                            {
                                                                classes: ['icon-float-left'],
                                                                icon: 'error_outline'
                                                            }
                                                        )
                                                    }
                                                })
                                            } else {
                                                _this.$toast.error(_this.$t('message.errors.supplierNotUpdated'),
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            }
                                            _this.loading.save = false
                                        }
                                        _this.productChangesSaved = true
                                    })
                                    .catch(() => {
                                        // console.log(0)
                                        _this.$toast.error(_this.$t('message.errors.supplierNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                        _this.loading.save = false
                                        _this.productChangesSaved = true
                                    })
                            } else {
                                // console.log(1)
                                _this.$toast.error(_this.$t('message.errors.supplierNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                _this.productChangesSaved = true
                                scrollToFirstFormValidationError()
                            }
                        } else {
                            // console.log(2)
                            _this.$toast.error(_this.$t('message.errors.supplierNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            _this.productChangesSaved = true
                            scrollToFirstFormValidationError()
                        }
                    },500)
                },500)
            },
            supplierGroupCreated (groupId) {
                this.Supplier__group_id = groupId
                this.supplierGroupListKey += 1
            },
            async suspendSupplier (val) {
                if (await this.$root.$confirm(this.$t('message.suspendSupplier'), this.$t('message.confirmations.continueSupplierSuspendAction'), {color: 'orange'})) {
                    this.loading.suspend = true
                    this.suspendSupplierById(val).then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.supplierSuspended'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetAllSuppliers()
                            this.getAllSuppliers()
                            router.push({name: 'supplier_listing'}).catch(err => {})
                            this.loading.suspend = false
                        } else {
                            this.$toast.error(this.$t('message.errors.supplierNotSuspended'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                }
            },
            setItemsValidity(val){
                this.validContacts = val
                this.validateContacts = false
            },
            setProductsValidity(val){
                this.validProducts = val
                this.validateProducts = false
            },
            updateSupplierLogo() {
                let options = {
                    type: 'base64',
                    size: 'viewport',
                    format: 'jpeg'
                };
                this.$refs.croppieRef.result(options, output => {
                    this.loading.upload = true
                    this.newLogoImage = this.croppieImage = output;Image
                    api
                        .put('/suppliers/' + this.supplierId + '/logo', {
                            logo: this.newLogoImage
                        })
                        .then((response) => {
                            if(response.data.status === 'success') {
                                this.Supplier__logo = response.data.photo
                                this.loading.upload = false
                                this.newLogoImage = null
                                this.croppieImage = ''
                                this.updateLogoDialog = false
                                this.$toast.success(this.$t('message.successes.supplierLogoUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                            } else {
                                this.loading.upload = false
                                this.$toast.error(this.$t('message.errors.supplierLogoNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.loading.upload = false
                            this.$toast.error(this.$t('message.errors.supplierLogoNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                });
            }
        },
        watch: {
            Supplier__title(val) {
                this.Company__name = val
            }
        },
        created(){
			if(this.allSpecies.length === 0) this.getAllSpecies()
            if(this.allCountries.length === 0) this.getAllCountries()
            if(this.allSupplierPaymentTerms.length === 0) this.getAllPaymentTerms()
            if(this.allShippingLoadingPorts.length === 0) this.getAllShippingPorts()
            if(this.allSuppliers.length === 0) this.getAllSuppliers()
            if(this.allProductGroups.length === 0) this.getAllProductGroups()
            if(this.allCertifications.length === 0) this.getAllCertifications()
            if(this.$route.matched.some(({name}) => name === 'update_supplier')){
                this.resetCommissions('update')
                this.loadSupplierById(this.supplierId)
            } else if(this.$route.matched.some(({name}) => name === 'add_supplier')){
                this.newSupplierUnsaved = false
            }
        },
        mounted(){
            this.$store.watch(
                function(state) {
                    return state.appic.supplier.update.Company
                },
                () => {
                    this.updatedSupplierUnsaved = true
                },
                { deep: true }
            )
            this.$store.watch(
                function(state) {
                    return state.appic.supplier.current.Company
                },
                () => {
                    this.newSupplierUnsaved = true
                },
                { deep: true }
            )
            this.$store.watch(
                function(state) {
                    return state.appic.supplier.update.Supplier
                },
                () => {
                    this.updatedSupplierUnsaved = true
                },
                { deep: true }
            )
            this.$store.watch(
                function(state) {
                    return state.appic.supplier.current.Supplier
                },
                () => {
                    this.newSupplierUnsaved = true
                },
                { deep: true }
            )
            if(isset(() => this.$route.query.panel)){
                switch(this.$route.query['panel']){
                    case 'operations':
                        this.panel = [1]
                        break
                }
            }
        }
    }
</script>

<style>
.field-width-200 {
    width: 200px !important;
    max-width: 200px !important;
}
.force-text-left .v-text-field__slot input {
    text-align: left !important;
}
.v-dialog {
    min-width: 200px !important;
}
.wrap-text {
    white-space: normal;
}
.font-xs {
    font-size: small;
}
.company-logo {
    width: 150px !important;
    height: 150px !important;;
    border-radius: 50%;
    border-style: solid;
    border-width: 8px;
    color: grey;
    font-weight: bolder;
    font-size: 3.2em;
}
</style>
