const hasScript = str => Boolean(
    new DOMParser().parseFromString(str, 'text/html').querySelector('script,img,a,input,select,audio,video,iframe,portal,embed,picture,source,svg,noscript,canvas,button,textarea')
)

export const appConstants = {
    appName: 'APPiC',
    company: 'APP Timber',
    companyShortName: 'APP'
}

export const appicUrls = [
    {key: 'maybankForexRates', url: 'https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/rates/forex_rates.page', icon: '/static/img/appic/maybank.png'}
]

export const buyerCaptionTypes = [
    {id: 0, title_first: 'message.buyer', title_last: 'message.deliveryAddressAndNotifyingParty'},
    {id: 1, title_first: 'message.buyerAndDeliveryAddress', title_last: 'message.notifyingParty'},
    {id: 2, title_first: 'message.buyerAndNotifyingParty', title_last: 'message.deliveryAddress'},
]

export const buyerContractOffices = [
    {country_id: 1, salestype_id: 1, office_id: 1, contract_prefix: 'DMY', default: true},  //MY
    {country_id: 1, salestype_id: 2, office_id: 1, contract_prefix: 'CMY', default: true},
    {country_id: 1, salestype_id: 3, office_id: 1, contract_prefix: 'WMY', default: true},
    {country_id: 1, salestype_id: 3, office_id: 3, contract_prefix: 'WMY', default: false},
    {country_id: 1, salestype_id: 5, office_id: 1, contract_prefix: 'SMY', default: true},
    {country_id: 1, salestype_id: 6, office_id: 3, contract_prefix: 'WEX', default: true},

    {country_id: 2, salestype_id: 1, office_id: 1, contract_prefix: 'DTH', default: true},  //TH
    {country_id: 2, salestype_id: 1, office_id: 3, contract_prefix: 'DTH', default: true},  //TH
    {country_id: 2, salestype_id: 2, office_id: 3, contract_prefix: 'CTH', default: true},
    {country_id: 2, salestype_id: 2, office_id: 1, contract_prefix: 'CTH', default: false},
    {country_id: 2, salestype_id: 3, office_id: 20, contract_prefix: 'WTH', default: true},
    {country_id: 2, salestype_id: 5, office_id: 20, contract_prefix: 'STH', default: true},
    {country_id: 2, salestype_id: 6, office_id: 3, contract_prefix: 'WEX', default: true},

    {country_id: 3, salestype_id: 1, office_id: 3, contract_prefix: 'DVN', default: true},  //VN
    {country_id: 3, salestype_id: 1, office_id: 1, contract_prefix: 'DVN', default: true},  //VN
    {country_id: 3, salestype_id: 2, office_id: 3, contract_prefix: 'CVN', default: true},
    {country_id: 3, salestype_id: 2, office_id: 1, contract_prefix: 'CVN', default: false},
    {country_id: 3, salestype_id: 3, office_id: 22, contract_prefix: 'WVN', default: true},
    {country_id: 3, salestype_id: 5, office_id: 22, contract_prefix: 'SVN', default: true},
    {country_id: 3, salestype_id: 6, office_id: 3, contract_prefix: 'WEX', default: true},

    {country_id: 4, salestype_id: 1, office_id: 1, contract_prefix: 'DID', default: true},  //ID
    {country_id: 4, salestype_id: 1, office_id: 3, contract_prefix: 'DID', default: true},  //ID
    {country_id: 4, salestype_id: 2, office_id: 3, contract_prefix: 'CID', default: true},
    {country_id: 4, salestype_id: 2, office_id: 1, contract_prefix: 'CID', default: false},
    {country_id: 4, salestype_id: 3, office_id: 21, contract_prefix: 'WID', default: true},
    {country_id: 4, salestype_id: 5, office_id: 21, contract_prefix: 'SID', default: true},
    {country_id: 4, salestype_id: 6, office_id: 3, contract_prefix: 'WEX', default: true},

    {country_id: 5, salestype_id: 1, office_id: 1, contract_prefix: 'DCN', default: true},  //CN
    {country_id: 5, salestype_id: 1, office_id: 3, contract_prefix: 'DCN', default: true},  //CN
    {country_id: 5, salestype_id: 2, office_id: 1, contract_prefix: 'DCN', default: true},  //CN
    {country_id: 5, salestype_id: 2, office_id: 3, contract_prefix: 'DCN', default: true},  //CN

    {country_id: 999, salestype_id: 1, office_id: 3, contract_prefix: 'DOT', default: true},
    {country_id: 999, salestype_id: 1, office_id: 1, contract_prefix: 'DOT', default: false},
    {country_id: 999, salestype_id: 2, office_id: 3, contract_prefix: 'DEX', default: true},
    {country_id: 999, salestype_id: 2, office_id: 1, contract_prefix: 'DEX', default: false},
]

export const calendarWeeks = [
    {Calendarweek: {id:1, title: 'WK 1'}},
    {Calendarweek: {id:2, title: 'WK 2'}},
    {Calendarweek:  {id:3, title: 'WK 3'}},
    {Calendarweek: {id:4, title: 'WK 4'}},
    {Calendarweek: {id:5, title: 'WK 5'}},
    {Calendarweek: {id:6, title: 'WK 6'}},
    {Calendarweek: {id:7, title: 'WK 7'}},
    {Calendarweek: {id:8, title: 'WK 8'}},
    {Calendarweek: {id:9, title: 'WK 9'}},
    {Calendarweek: {id:10, title: 'WK 10'}},
    {Calendarweek: {id:11, title: 'WK 11'}},
    {Calendarweek: {id:12, title: 'WK 12'}},
    {Calendarweek: {id:13, title: 'WK 13'}},
    {Calendarweek: {id:14, title: 'WK 14'}},
    {Calendarweek:  {id:15, title: 'WK 15'}},
    {Calendarweek: {id:16, title: 'WK 16'}},
    {Calendarweek: {id:17, title: 'WK 17'}},
    {Calendarweek: {id:18, title: 'WK 18'}},
    {Calendarweek: {id:19, title: 'WK 19'}},
    {Calendarweek: {id:20, title: 'WK 20'}},
    {Calendarweek: {id:21, title: 'WK 21'}},
    {Calendarweek: {id:22, title: 'WK 22'}},
    {Calendarweek: {id:23, title: 'WK 23'}},
    {Calendarweek: {id:24, title: 'WK 24'}},
    {Calendarweek: {id:25, title: 'WK 25'}},
    {Calendarweek: {id:26, title: 'WK 26'}},
    {Calendarweek: {id:27, title: 'WK 27'}},
    {Calendarweek: {id:28, title: 'WK 28'}},
    {Calendarweek: {id:29, title: 'WK 29'}},
    {Calendarweek: {id:30, title: 'WK 30'}},
    {Calendarweek: {id:31, title: 'WK 31'}},
    {Calendarweek: {id:32, title: 'WK 32'}},
    {Calendarweek: {id:33, title: 'WK 33'}},
    {Calendarweek: {id:34, title: 'WK 34'}},
    {Calendarweek: {id:35, title: 'WK 35'}},
    {Calendarweek: {id:36, title: 'WK 36'}},
    {Calendarweek: {id:37, title: 'WK 37'}},
    {Calendarweek: {id:38, title: 'WK 38'}},
    {Calendarweek: {id:39, title: 'WK 39'}},
    {Calendarweek: {id:40, title: 'WK 40'}},
    {Calendarweek: {id:41, title: 'WK 41'}},
    {Calendarweek: {id:42, title: 'WK 42'}},
    {Calendarweek: {id:43, title: 'WK 43'}},
    {Calendarweek: {id:44, title: 'WK 44'}},
    {Calendarweek: {id:45, title: 'WK 45'}},
    {Calendarweek: {id:46, title: 'WK 46'}},
    {Calendarweek: {id:47, title: 'WK 47'}},
    {Calendarweek: {id:48, title: 'WK 48'}},
    {Calendarweek: {id:49, title: 'WK 49'}},
    {Calendarweek: {id:50, title: 'WK 50'}},
    {Calendarweek: {id:51, title: 'WK 51'}},
    {Calendarweek: {id:52, title: 'WK 52'}},
]

export const certifications = [
    {Certification: {id: 0, title: 'None', cert_no: '', cert_expiry_date: ''}},
    {Certification: {id: 3, title: 'FSC CW', cert_no: 'CU-CW-844927', cert_expiry_date: '10 October 2025'}},
    {Certification: {id: 4, title: 'FSC 100% / Mix', cert_no: 'CU-COC-844927', cert_expiry_date: '10 October 2025'}},
    {Certification: {id: 2, title: 'PEFC', cert_no: 'CU-PEFC-844927', cert_expiry_date: '01 January 2026'}}
]

export const certificationsNew = [
    {Certification: {id: 0, title: 'None', cert_no: '', cert_expiry_date: ''}},
    {Certification: {id: 3, title: 'FSC CW', cert_no: 'CU-CW-844927', cert_expiry_date: '10 October 2025'}},
    {Certification: {id: 4, title: 'FSC 100%', cert_no: 'CU-COC-844927', cert_expiry_date: '10 October 2025'}},
    {Certification: {id: 6, title: 'FSC Mix Credit', cert_no: 'CU-COC-844927', cert_expiry_date: '10 October 2025'}},
    {Certification: {id: 2, title: '100% PEFC', cert_no: 'CU-PEFC-844927', cert_expiry_date: '01 January 2026'}},
    {Certification: {id: 5, title: '70% PEFC', cert_no: 'CU-PEFC-844927', cert_expiry_date: '01 January 2026'}},
    {Certification: {id: 8, title: 'NHLA NT', cert_no: '', cert_expiry_date: ''}},
    {Certification: {id: 7, title: 'SVLK', cert_no: '', cert_expiry_date: ''}},
    {Certification: {id: 9, title: 'NHLA NT, FSC CW', cert_no: 'CU-CW-844927', cert_expiry_date: '10 October 2025'}},
    {Certification: {id: 10, title: 'PEFC CW', cert_no: 'CU-PEFC-844927', cert_expiry_date: '01 Jan 2026'}},
]

export const companyDepartments = [
    {CompanyDepartment: {id: 1, name: 'Accounts', forbuyer: 0, forsupplier: 1}},
    {CompanyDepartment: {id: 2, name: 'Sales/Marketing', forbuyer: 1, forsupplier: 1}},
    {CompanyDepartment: {id: 3, name: 'Shipping', forbuyer: 0, forsupplier: 1}},
    {CompanyDepartment: {id: 4, name: 'Procurement', forbuyer: 1, forsupplier: 1}},
    {CompanyDepartment: {id: 5, name: 'Others', forbuyer: 0, forsupplier: 1}},
    {CompanyDepartment: {id: 6, name: 'Shipping/Accounts', forbuyer: 1, forsupplier: 1}},
    {CompanyDepartment: {id: 7, name: 'Owner/Director/GM', forbuyer: 1, forsupplier: 1}},
    {CompanyDepartment: {id: 8, name: 'Production', forbuyer: 0, forsupplier: 1}}
]

export const continents = [
    {value: 1, text: 'Asia'},
    {value: 2, text: 'North America'},
    {value: 3, text: 'Africa'},
    {value: 4, text: 'Europe'},
    {value: 5, text: 'South America'},
    {value: 6, text: 'Oceania'},
    {value: 8, text: 'Middle East'},
]

export const containerSizes = [
    {Containersize: {id: 1, title: '40\''}},
    {Containersize: {id: 3, title: '20\''}},
    {Containersize: {id: 4, title: 'LCL'}},
    // {Containersize: {id: 5, title: '40\' HC'}},
    {Containersize: {id: 7, title: 'By Air'}},
]

export const contractFooterNotes = [
    {id: 1, replace: null, text: "This Agreement has to be signed, stamped and returned by the Buyer to the Seller within 7 working days and upon receipt is subject to final acceptance by the Seller."},
    {id: 2, replace: null, text: "The Seller reserves the right to cancel this Agreement at any time in case the Buyer does not fulfill the financial obligations within 10 working days from the contract date."},
    {id: 3, replace: null, text: "Volume and value are approximate and can vary plus/minus 10%."},
    {id: 4, replace: null, text: "Shipment dates are estimated and subject to reconfirmation based on availability of containers and/or vessels."},
    {id: 5, replace: null, text: "General freight variations, BAF and/or EFF surcharges are not covered under this contract and for the Buyer's account."},
    {id: 6, replace: null, text: "The Seller cannot be held responsible for the delay or non-delivery due to Force Majeure."},
    {id: 7, replace: null, text: "It is the responsibility of the Buyer to immediately inform their forwarder of any damages to the container upon unloading of the above goods; the Seller cannot be held responsible for any financial claims resulting from such damages."},
    {id: 8, replace: null, text: "It is the obligation of the Buyer to inspect the goods upon arrival at his premises within 14 days from ETA; the Seller will not accept any claim after 14 days from ETA."},
    // {id: 9, replace: null, text: "Any dispute arising from this Agreement will be settled at the Kuala Lumpur Regional Centre for Arbitration according to the law of Malaysia and the award of such Arbitration shall be final and binding by both Parties"},
    {id: 10, replace: null, text: "The Malaysian DOA requires an import permit which the Buyer has to arrange, we reserve the right to cancel this order if this import permit is not arranged within 2 weeks from date of this contract."},
    {id: 9, replace: null, text: "This agreement is made under and shall be interpreted and governed by the laws of Malaysia and any dispute or question in connection with this agreement shall be referred to the courts in Malaysia."}
]

export const couriers = [
    {Courier: {id: 1, title: 'DHL'}},
    {Courier: {id: 2, title: 'TNT'}},
    {Courier: {id: 3, title: 'FedEx'}},
    {Courier: {id: 4, title: 'UPS'}},
    {Courier: {id: 5, title: 'Chronopost'}},
    {Courier: {id: 6, title: 'Nationwide'}},
    {Courier: {id: 7, title: 'SKYNET'}},
    {Courier: {id: 8, title: 'GDEX'}},
    {Courier: {id: 9, title: 'CITY-LINK'}},
    {Courier: {id: 10, title: 'By Hand'}}
]

export const coverNoteFooterNotes = [
    {id: 1, replace: null, text: 'Changes of ETA is without prior notice : subject to the carriers schedule in transhipment port / feeder vessels.'},
    {id: 2, replace: null, text: 'Please note that the payment has to be received latest (date) to avoid delays in release of  the original documents and/or incur demurrage & storage charges.'},
    {id: 3, replace: null, text: 'Please advise immediately if documents are not well received.'}
]

export const currencies = [
    {Currency: {id: 4, code: 'AUD', title: 'Australian Dollar', symbol: '$', formatPrecision: '0,0.00', decimals: 2}},
    {Currency: {id: 5, code: 'CAD', title: 'Canadian Dollar', symbol: '$', formatPrecision: '0,0.00', decimals: 2}},
    {Currency: {id: 3, code: 'EUR', title: 'Euro', symbol: '€', formatPrecision: '0,0.00', decimals: 2}},
    {Currency: {id: 10, code: 'IDR', title: 'Indonesian Rupiah', symbol: 'Rp', formatPrecision: '0,0', decimals: 0}},
    {Currency: {id: 1, code: 'MYR', title: 'Malaysian Ringgit', symbol: 'RM', formatPrecision: '0,0.00', decimals: 2}},
    {Currency: {id: 7, code: 'RMB', title: 'Chinese Yuan', symbol: '¥', formatPrecision: '0,0.00', decimals: 2}},
    {Currency: {id: 8, code: 'SGD', title: 'Singapore Dollar', symbol: '$', formatPrecision: '0,0.00', decimals: 2}},
    {Currency: {id: 6, code: 'THB', title: 'Thai Baht', symbol: '฿', formatPrecision: '0,0.00', decimals: 2}},
    {Currency: {id: 2, code: 'USD', title: 'US Dollar', symbol: '$', formatPrecision: '0,0.00', decimals: 2}},
    {Currency: {id: 9, code: 'VND', title: 'Vietnamese Dong', symbol: '₫', formatPrecision: '0,0', decimals: 0}},
]

export const defaultDeliveryAddresses = [
    {Deliveryaddress: {id: 1, title: 'APP Timber Sdn Bhd', address: "No.21, Jalan Utarid U5/11<br>" +
                "Mah Sing Industrial Park<br>" +
                "40150 Shah Alam<br>" +
                "Selangor, Malaysia<br>" +
                "Tel; 603-78474716<br>" +
                "Attn : Tee Siok Ching"}},
    {Deliveryaddress: {id: 8, title: 'APP Timber (Thailand) Co., Ltd.', address: "42/3 Phatthana Chonnabot 3 Road<br>" +
                "Klongsongtonnoon, Lat Krabang,<br>" +
                "Bangkok 10520 Thailand<br>" +
                "Tel: +662. 138.9765<br>" +
                "Fax: +662. 138.9766"}},
    {Deliveryaddress: {id: 4, title: 'PT APP Timber (Surabaya)', address: "Yenny - Surabaya<br>" +
                "Wisata bukit mas Blok H3-15 ,Jl. Raya Menganti -Wiyung Surabaya-Jawa Timur,<br>" +
                "Indonesia (post code: 60213)<br>" +
                "Attn: Mrs.Hiong Kiok (0822-1555-9787)"}},
    {Deliveryaddress: {id: 144, title: 'PT APP Timber (Semarang)', address: "PT. APP Timber<br>" +
                "Kawasan Industri Kendal<br>" +
                "Jl. Wanamarta Raya No. 03<br>" +
                "Ds. Brangsong Kec. Brangsong Kab. Kendal<br>" +
                "Jawa Tengah Indonesia 51371\n" +
                "Telp : (+62) 294-3691999/3691020"}},
    {Deliveryaddress: {id: 485, title: 'APP Timber (Vietnam) Company Limited', address: "Ms. Tu Thu Dung (APP Timber)<br>" +
                "114, Duong so 9, Khu dan cu Binh Hung, Binh Chanh, TP HCM, Vietnam<br>" +
                "HP: +84 91 3654103"}},
    {Deliveryaddress: {id: 612, title: 'PT APP Timber (Jakarta)', address: "Jakarta Office<br>" +
                "Ms.Jois Chantika<br>" +
                "Jl.Cilengsir no.14 - Cideng Barat<br>" +
                "Jakarta Pusat - DKI Jakarta -10150<br>" +
                "Indonesia<br>" +
                "Attn: Jois telp.+62 813 18086664"}},
]

export const deliveryAddressTypes = [
    {Deliveryaddress: {id: 999, title: '', address: 'message.tba'}},
    {Deliveryaddress: {id: 998, title: '', address: 'message.toFollow'}},
    {Deliveryaddress: {id: 0, title: '', address: 'message.sameAsBuyer'}},
]

export const dryingMethods = [
    {value: 1, text: 'AD'},
    {value: 2, text: 'Green'},
    {value: 3, text: 'Green, AST'},
    {value: 4, text: 'KD'},
    {value: 99, text: 'NA'}
]

export const eprStatuses = [
    {Eprstatus: {id: 1, title: 'Open'}},
    {Eprstatus: {id: 2, title: 'Submitted'}},
    {Eprstatus: {id: 3, title: 'Approved'}},
    {Eprstatus: {id: 4, title: 'Rejected'}},
    {Eprstatus: {id: 5, title: 'Cancelled'}}
]

export const gpTaxCodes = [
    {Taxcode: {id: 44, title: 'OS'}},
    {Taxcode: {id: 91, title: 'OV7'}},
    {Taxcode: {id: 92, title: 'OV10'}},
    {Taxcode: {id: 95, title: 'SR-0'}},
    {Taxcode: {id: 81, title: 'ZR-L'}},
]

export const grossMarginEffects = [
    {value: 0, title: 'N/A'},
    {value: 1, title: '1-30 days'},
    {value: 2, title: '31-45 days'},
    {value: 3, title: '46-60 days'},
]

export const gstRates = [
    {Gstrate: {value: 0, title: '0%'}},
    {Gstrate: {value: 6, title: '6%'}},
    {Gstrate: {value: 7, title: '7%'}},
    {Gstrate: {value: 10, title: '10%'}},
    {Gstrate: {value: 11, title: '11%'}}
]

export const incoterms = [
    {Incoterm: {id: 1, title: 'FOB'}},
    {Incoterm: {id: 2, title: 'CFR'}},
    {Incoterm: {id: 3, title: 'CIF'}},
    {Incoterm: {id: 12, title: 'CPT'}},
    {Incoterm: {id: 7, title: 'CIP'}},
    {Incoterm: {id: 4, title: 'EXW'}}, //ex works
    {Incoterm: {id: 5, title: 'Air Freight'}},
    {Incoterm: {id: 6, title: 'FCA'}},
    // {Incoterm: {id: 7, title: 'CIP'}},
    {Incoterm: {id: 8, title: 'Door to Door'}},
    {Incoterm: {id: 9, title: 'Ex Warehouse'}},
    {Incoterm: {id: 10, title: 'DAP'}} //Delivered at place
]

export const internalRemarks = [
    {
        Internalremark: {
            id: 1,
            title: 'Pending booking, expected ETD wk <xx>',
            freetext1: '<xx>',
            freetext1_type: 'week',
            freetext1_label: 'ETD Week',
            freetext2: null,
            freetext2_type: null,
            freetext2_label: null,
            freetext3: null,
            freetext3_type: null,
            freetext3_label: null,
            freetext4: null,
            freetext4_type: null,
            freetext4_label: null,
        }
    },
    {
        Internalremark: {
            id: 2,
            title: 'Rolled booking, expected ETD wk <xx>',
            freetext1: '<xx>',
            freetext1_type: 'week',
            freetext1_label: 'ETD Week',
            freetext2: null,
            freetext2_type: null,
            freetext2_label: null,
            freetext3: null,
            freetext3_type: null,
            freetext3_label: null,
            freetext4: null,
            freetext4_type: null,
            freetext4_label: null,
        }
    },
    {
        Internalremark: {
            id: 3,
            title: 'Inspection <date><week>, expected ETD wk <xx>',
            freetext1: '<date>',
            freetext1_type: 'date',
            freetext1_label: 'Inspection Date',
            freetext2: '<week>',
            freetext2_type: 'week',
            freetext2_label: 'Inspection Week',
            freetext3: '<xx>',
            freetext3_type: 'week',
            freetext3_label: 'ETD Week',
            freetext4: null,
            freetext4_type: null,
            freetext4_label: null,
        }
    },
    {
        Internalremark: {
            id: 4,
            title: 'Loading <date><week>, expected ETD wk <xx>',
            freetext1: '<date>',
            freetext1_type: 'date',
            freetext1_label: 'Loading Date',
            freetext2: '<week>',
            freetext2_type: 'week',
            freetext2_label: 'Loading Week',
            freetext3: '<xx>',
            freetext3_type: 'week',
            freetext3_label: 'ETD Week',
            freetext4: null,
            freetext4_type: null,
            freetext4_label: null,
        }
    },
    {
        Internalremark: {
            id: 5,
            title: 'Delay due to weather condition, expected ETD wk <xx>',
            freetext1: '<xx>',
            freetext1_type: 'week',
            freetext1_label: 'ETD Week',
            freetext2: null,
            freetext2_type: null,
            freetext2_label: null,
            freetext3: null,
            freetext3_type: null,
            freetext3_label: null,
            freetext4: null,
            freetext4_type: null,
            freetext4_label: null,
        }
    },
    {
        Internalremark: {
            id: 6,
            title: 'Delay due to strike, expected ETD wk <xx>',
            freetext1: '<xx>',
            freetext1_type: 'week',
            freetext1_label: 'ETD Week',
            freetext2: null,
            freetext2_type: null,
            freetext2_label: null,
            freetext3: null,
            freetext3_type: null,
            freetext3_label: null,
            freetext4: null,
            freetext4_type: null,
            freetext4_label: null,
        }
    },
    {
        Internalremark: {
            id: 7,
            title: 'Delay due to port congestion, expected ETD wk <xx>',
            freetext1: '<xx>',
            freetext1_type: 'week',
            freetext1_label: 'ETD Week',
            freetext2: null,
            freetext2_type: null,
            freetext2_label: null,
            freetext3: null,
            freetext3_type: null,
            freetext3_label: null,
            freetext4: null,
            freetext4_type: null,
            freetext4_label: null,
        }
    },
    {
        Internalremark: {
            id: 8,
            title: 'Delay due to production problems, expected ETD wk <xx>',
            freetext1: '<xx>',
            freetext1_type: 'week',
            freetext1_label: 'ETD Week',
            freetext2: null,
            freetext2_type: null,
            freetext2_label: null,
            freetext3: null,
            freetext3_type: null,
            freetext3_label: null,
            freetext4: null,
            freetext4_type: null,
            freetext4_label: null,
        }
    },
    {
        Internalremark: {
            id: 9,
            title: 'No raw materials, order on hold',
            freetext1: null,
            freetext1_type: null,
            freetext1_label: null,
            freetext2: null,
            freetext2_type: null,
            freetext2_label: null,
            freetext3: null,
            freetext3_type: null,
            freetext3_label: null,
            freetext4: null,
            freetext4_type: null,
            freetext4_label: null,
        }
    },
    {
        Internalremark: {
            id: 10,
            title: 'KD in progress, expected ETD wk <xx>',
            freetext1: '<xx>',
            freetext1_type: 'week',
            freetext1_label: 'ETD Week',
            freetext2: null,
            freetext2_type: null,
            freetext2_label: null,
            freetext3: null,
            freetext3_type: null,
            freetext3_label: null,
            freetext4: null,
            freetext4_type: null,
            freetext4_label: null,
        }
    },
    {
        Internalremark: {
            id: 11,
            title: 'Pending claim of <contract>',
            freetext1: '<contract>',
            freetext1_type: 'text',
            freetext1_label: 'Contract',
            freetext2: null,
            freetext2_type: null,
            freetext2_label: null,
            freetext3: null,
            freetext3_type: null,
            freetext3_label: null,
            freetext4: null,
            freetext4_type: null,
            freetext4_label: null,
        }
    },
    {
        Internalremark: {
            id: 12,
            title: 'Import permit revision pending',
            freetext1: null,
            freetext1_type: null,
            freetext1_label: null,
            freetext2: null,
            freetext2_type: null,
            freetext2_label: null,
            freetext3: null,
            freetext3_type: null,
            freetext3_label: null,
            freetext4: null,
            freetext4_type: null,
            freetext4_label: null,
        }
    },
    {
        Internalremark: {
            id: 15,
            title: 'Delay to wk <xx> due to COVID-19',
            freetext1: '<xx>',
            freetext1_type: 'week',
            freetext1_label: 'ETD Week',
            freetext2: null,
            freetext2_type: null,
            freetext2_label: null,
            freetext3: null,
            freetext3_type: null,
            freetext3_label: null,
            freetext4: null,
            freetext4_type: null,
            freetext4_label: null,
        }
    },
]

export const invoiceFooterNotes = [
    {id: 1, replace: null, text: "Preferred payment mode is by bank transfer, optionally current cheque to be bank in by Buyer; cash payments are strictly not accepted."},
    {id: 2, replace: null, text: "Please pay only to our Company name and Bank Account; never accept any different written and/or verbal payment instructions."},
    {id: 3, replace: null, text: "Contact our HQ if any questions, we will not be responsible for any incorrect payments due to phishing."},
    {id: 4, replace:'interest_charge_pct', text: "Late payments are subject to a compound interest charge of <<interest_charge_pct>> per month."}
]

export const altInvoiceFooterNotes = [
    {id: 1, replace: null, text: "Please pay ONLY to our Company Name and Bank Account; NEVER accept any other written or verbal payment instructions."},
    {id: 2, replace: null, text: "Contact our HQ if there are any questions as we will not be responsible for any incorrect payments due to phishing."},
    {id: 3, replace: null, text: "All payments are to be paid in full as per invoice amount and all bank charges are for customer's account."},
    {id: 4, replace: null, text: "Changes of ETA is without prior notice and subject to carriers' schedule in transhipment port / feeder vessels."},
    {id: 5, replace: null, text: "Please note that payment has to be received latest by due date to avoid delays in release of the original documents and/or incur demurrage and storage charges."},
    {id: 6, replace: null, text: "Please advise immediately if documents are not well received."},
]

export const ipaConstants = {
    IpaBopMy: "Putrajaya",
    IpaCategory: "Tanaman Hutan/Produk Kayu",
    IpaDeliveryMethod: "FULL CONTAINER LOAD",
    IpaGmo: "No",
    IpaPackType: "BUNDLE",
    IpaTransportMode: 'MARITIME TRANSPORT',
    IpaUnitOfDescription: 'MTQ',
    IpaDescriptionForm: 'Sawn Timber/LSS',
    IpaUnitOfMeasurement: 'MTQ',
    IpaPurpose: 'Business',
    IpaTariffDescription: 'Sawn Lengthwise',
    IpaCustomsStationCode: 'IBU PEJABAT KASTAM',
    IpaNote1: "Please use the following data to apply for your ePermit:",
    IpaNote2: "Please contact our sales colleague in charge or contact us at support@apptimber.com in case you have any queries."
}

export const lcaConstants = {
    LcaConditions: [
        {id: 0, title: "Documents to be presented within 21 days after B/L date but within the validity of the credit."},
        {id: 1, title: "Third party documents acceptable."},
        {id: 2, title: "10pct more or less for credit amount and quantity acceptable."},
        {id: 3, title: "All bank charges & commission outside the country of issuing bank are for the beneficiary."},
        {id: 4, title: "Description LC has to indicate the Bill of Lading, Invoice and Packing List."},
        {id: 5, title: "Please do not indicate unit price and total amount in the description LC."},
    ],
    LcaNote1: "This Letter of Credit should <u>be received latest dd-MM-YYYY</u> and advised directly through:",
    LcaNote2: "PLEASE DO NOT INCLUDE ANY OTHER DOCUMENTS/TERMS FOR NEGOTIATION (UNLESS APPROVED BY US) OR ELSE YOU MIGHT HAVE TO AMEND THE LC.",
    LcaNote3: "Please send us the draft LC for pre-checking before original LC is issued.",
    LcaNote4: "Please note that late receipt and/or an incorrect L/C might result in delay of the shipment or cancellation of the contract."
}

export const localSalesInvoiceFooterNotes = [
    {id: 1, replace: null, text: "Preferred payment mode is by bank transfer, optionally current cheque to be banked in by Buyer; cash payments are strictly not accepted."},
    {id: 2, replace: null, text: "Please pay only to our Company name and Bank Account; never accept any different written and/or verbal payment instructions."},
    {id: 3, replace: null, text: "Contact our HQ if any questions, we will not be responsible for any incorrect payments due to phishing."},
    {id: 4, replace:'interest_charge_pct', text: "Late payments are subject to a compound interest charge of <<interest_charge_pct>> per month."}
]

export const months = [
    {Month: {id: 1, code: 'Jan', text: 'January'}},
    {Month: {id: 2, code: 'Feb', text: 'February'}},
    {Month: {id: 3, code: 'Mar', text: 'March'}},
    {Month: {id: 4, code: 'Apr', text: 'April'}},
    {Month: {id: 5, code: 'May', text: 'May'}},
    {Month: {id: 6, code: 'Jun', text: 'June'}},
    {Month: {id: 7, code: 'Jul', text: 'July'}},
    {Month: {id: 8, code: 'Aug', text: 'August'}},
    {Month: {id: 9, code: 'Sep', text: 'September'}},
    {Month: {id: 10, code: 'Oct', text: 'October'}},
    {Month: {id: 11, code: 'Nov', text: 'November'}},
    {Month: {id: 12, code: 'Dec', text: 'December'}}
]

export const ncrStatuses = [
    {Ncrstatus: {id: 1, title: 'Open'}},
    {Ncrstatus: {id: 2, title: 'Submitted'}},
    {Ncrstatus: {id: 3, title: 'Approved'}},
    {Ncrstatus: {id: 4, title: 'Rejected'}},
    {Ncrstatus: {id: 5, title: 'Cancelled'}}
]

export const offices = [
    {
        Office: {
            id: 1,
            code: 'MY',
            title: 'APP Timber Sdn. Bhd.',
            country_id: 1,
            company_id: 3,
            currency_id: 1,
            customer_id: 100,
            customer_company_id: 1960,
            has_warehouse: true,
            taxcode: 'SR-0',
            taxrate: 0,
            taxterm: '',
            tax: false,
        }
    },
    {
        Office: {
            id: 3,
            code: 'LTD',
            title: 'APP Timber Ltd.',
            country_id: 1,
            company_id: 2,
            currency_id: 2,
            customer_id: null,
            customer_company_id: null,
            has_warehouse: false,
            taxcode: 'OS',
            taxrate: 0,
            taxterm: '',
            tax: false,
        }
    },
    {
        Office: {
            id: 20,
            code: 'TH',
            title: 'APP Timber (Thailand) Co., Ltd.',
            country_id: 2,
            company_id: 3139,
            currency_id: 6,
            customer_id: 894,
            customer_company_id: 1963,
            has_warehouse: false,
            taxcode: 'OV7',
            taxrate: 7,
            taxterm: 'VAT',
            tax: true,
        }
    },
    {
        Office: {
            id: 21,
            code: 'ID',
            title: 'PT. APP Timber',
            country_id: 4,
            company_id: 3138,
            currency_id: 10,
            customer_id: 1062,
            customer_company_id: 2887,
            has_warehouse: true,
            taxcode: 'OV11',
            taxrate: 11,
            taxterm: 'PPN',
            tax: true,
        }
    },
    {
        Office: {
            id: 22,
            code: 'VN',
            title: 'APP Timber (Vietnam) Co., Ltd.',
            country_id: 3,
            company_id: 4559,
            currency_id: 9,
            customer_id: 2079,
            customer_company_id: 4547,
            has_warehouse: false,
            taxcode: 'OV10',
            taxrate: 10,
            taxterm: 'VAT',
            tax: true,
        }
    }
]

export const otherSalesContacts = [14, 51]

export const payDateReferences = [
    {Paydateref: {id: 1, title: 'ETA' }},
    {Paydateref: {id: 2, title: 'ETD' }},
    {Paydateref: {id: 3, title: 'OSD' }},
    {Paydateref: {id: 4, title: 'Shipment Documents' }},
    {Paydateref: {id: 6, title: 'WH Sales Invoice Date' }},
    {Paydateref: {id: 7, title: 'Contract Date' }},
    {Paydateref: {id: 8, title: 'Pickup Date' }},
    {Paydateref: {id: 9, title: 'Delivery Date' }},
    {Paydateref: {id: 10, title: 'BL' }},
    {Paydateref: {id: 11, title: 'Invoice' }},
    {Paydateref: {id: 12, title: 'Contract Signed' }},
    {Paydateref: {id: 13, title: 'PO Signed' }}
]

export const productGroups = [
    {header: 'Logs'},
    {Productgroup: {id: 15, title: 'Logs', parent_id: 2, hscode: ''}},
    {header: 'Panels'},
    {Productgroup: {id: 8, title: 'MDF', parent_id: 3, hscode: '4411'}},
    {Productgroup: {id: 24, title: 'OSB', parent_id: 3, hscode: '4410'}},
    {Productgroup: {id: 17, title: 'Panels or table tops, solid or FJEG', parent_id: 3, hscode: '4411'}},
    {Productgroup: {id: 9, title: 'Particle board', parent_id: 3, hscode: '4410'}},
    {Productgroup: {id: 10, title: 'Plywood / LVL', parent_id: 3, hscode: '4412'}},
    {Productgroup: {id: 28, title: 'Softboard', parent_id: 3, hscode: ''}},
    {header: 'Products'},
    {Productgroup: {id: 18, title: 'Decking or Garden products', parent_id: 4, hscode: '4418'}},
    {Productgroup: {id: 20, title: 'Doors, solid or engineered', parent_id: 4, hscode: '4418'}},
    {Productgroup: {id: 19, title: 'Flooring, solid or engineered', parent_id: 4, hscode: '4418'}},
    {Productgroup: {id: 23, title: 'Mouldings or components, solid, FJ & FJEG', parent_id: 4, hscode: '4409'}},
    {header: 'Timber'},
    {Productgroup: {id: 11, title: 'Timber, sawn/dimension', parent_id: 5, hscode: '4407'}},
    {header: 'Veneer'},
    {Productgroup: {id: 29, title: 'Edgebanding', parent_id: 6, hscode: '44089015'}},
    {Productgroup: {id: 27, title: 'HPL', parent_id: 3, hscode: ''}},
    {Productgroup: {id: 13, title: 'Veneer, rotary', parent_id: 6, hscode: '4408'}},
    {Productgroup: {id: 14, title: 'Veneer, sliced', parent_id: 6, hscode: '4408'}},
    {Productgroup: {id: 999, title: 'na', parent_id: null, hscode: ''}}
]

export const productGroupsNew = [
    {header: 'Logs'},
    {Productgroup: {id: 15, title: 'Logs', parent_id: 2, hscode: ''}},
    {header: 'Panels'},
    {Productgroup: {id: 31, title: 'HDF', parent_id: 3, hscode: ''}},
    {Productgroup: {id: 8, title: 'MDF', parent_id: 3, hscode: '4411'}},
    {Productgroup: {id: 9, title: 'Particle board', parent_id: 3, hscode: '4410'}},
    {Productgroup: {id: 10, title: 'Plywood / LVL', parent_id: 3, hscode: '4412'}},
    {Productgroup: {id: 28, title: 'Softboard', parent_id: 3, hscode: ''}},
    {header: 'Products'},
    {Productgroup: {id: 50, title: 'Benches', parent_id: 4, hscode: ''}},
    {Productgroup: {id: 51, title: 'Chickenslat', parent_id: 4, hscode: ''}},
    {Productgroup: {id: 52, title: 'Components, FJ', parent_id: 4, hscode: ''}},
    {Productgroup: {id: 53, title: 'Components, FJLam', parent_id: 4, hscode: ''}},
    {Productgroup: {id: 54, title: 'Components, Solid', parent_id: 4, hscode: ''}},
    {Productgroup: {id: 55, title: 'Decking', parent_id: 4, hscode: '4418'}},
    {Productgroup: {id: 56, title: 'Door, Engineered', parent_id: 4, hscode: '4418'}},
    {Productgroup: {id: 57, title: 'Door, Solid', parent_id: 4, hscode: '4418'}},
    {Productgroup: {id: 58, title: 'Dowel, Solid', parent_id: 4, hscode: ''}},
    {Productgroup: {id: 59, title: 'Fences', parent_id: 4, hscode: ''}},
    {Productgroup: {id: 60, title: 'Fenceboard', parent_id: 4, hscode: ''}},
    {Productgroup: {id: 61, title: 'Flooring, Engineered', parent_id: 4, hscode: '4418'}},
    {Productgroup: {id: 62, title: 'Flooring, Solid', parent_id: 4, hscode: '4418'}},
    {Productgroup: {id: 63, title: 'Gates', parent_id: 4, hscode: ''}},
    {Productgroup: {id: 64, title: 'Mouldings, FJ', parent_id: 4, hscode: '4409'}},
    {Productgroup: {id: 65, title: 'Mouldings, FJEG', parent_id: 4, hscode: '4409'}},
    {Productgroup: {id: 66, title: 'Mouldings, Solid', parent_id: 4, hscode: '4409'}},
    {Productgroup: {id: 67, title: 'Panel, FJLam', parent_id: 4, hscode: ''}},
    {Productgroup: {id: 68, title: 'Panel, Laminated', parent_id: 4, hscode: ''}},
    {Productgroup: {id: 69, title: 'Scantling', parent_id: 4, hscode: ''}},
    {Productgroup: {id: 70, title: 'Solid Door, Solid', parent_id: 4, hscode: ''}},
    {Productgroup: {id: 71, title: 'Table top', parent_id: 4, hscode: ''}},
    {Productgroup: {id: 72, title: 'Truckflooring', parent_id: 4, hscode: ''}},
    {header: 'Sawn Timber'},
    {Productgroup: {id: 40, title: 'Rough sawn', parent_id: 5, hscode: '4407'}},
    {Productgroup: {id: 41, title: 'S2S', parent_id: 5, hscode: '4407'}},
    {Productgroup: {id: 42, title: 'Strips', parent_id: 5, hscode: '4407'}},
    {header: 'Veneer'},
    {Productgroup: {id: 29, title: 'Edgebanding', parent_id: 6, hscode: '44089015'}},
    {Productgroup: {id: 27, title: 'HPL', parent_id: 3, hscode: ''}},
    {Productgroup: {id: 13, title: 'Veneer, rotary', parent_id: 6, hscode: '4408'}},
    {Productgroup: {id: 14, title: 'Veneer, sliced', parent_id: 6, hscode: '4408'}},
    {Productgroup: {id: 999, title: 'na', parent_id: null, hscode: ''}}
]

export const purchaseOrderFooterNotes = [
    {id: 1, replace: null, text: "Volumes and values are approximate and can vary plus/minus 10%"},
    {id: 2, replace: null, text: "The Buyer reserves the right to cancel this Agreement if the Seller fails to ship the goods within 7 days from the shipment date"},
    {id: 3, replace: null, text: "The Seller agrees to submit a full and correct set of copy documents latest 14 days after ETD, if not the Buyer reserves the right charging demurrage costs if any"},
    {id: 4, replace: null, text: "Phytosanitary certificate and certificate of origin have to be provided"},
    {id: 5, replace: null, text: "ISPM 15 WPM regulations apply in all Asian countries and all packaging material (if any) has to be in full compliance"},
    {id: 6, replace: null, text: "European Union Timber Regulation (EUTR) due diligence assessment needs to be provided"},
    {id: 7, replace: null, text: "All bundles must have the APP Timber logo at both sides unless agreed differently"},
    // {id: 8, replace: null, text: "Any dispute arising from this Agreement will be settled at the Kuala Lumpur Regional Centre for Arbitration according to the law of Malaysia and the award of such Arbitration shall be final and binding by both Parties"},
    {id: 9, replace: null, text: "To check incoming containers for damage prior to loading and take photos proving there is no damage"},
    {id: 8, replace: null, text: "This agreement is made under and shall be interpreted and governed by the laws of Malaysia and any dispute or question in connection with this agreement shall be referred to the courts in Malaysia"}
]

export const salesTypes = [
    {Salestype: {id: 1, title: 'Direct Sales', code: 'DIR'}},
    {Salestype: {id: 2, title: 'Commission Sales', code: 'COM'}},
    {Salestype: {id: 3, title: 'PO for Stock/Pre-order', code: 'PO'}},
    {Salestype: {id: 5, title: 'Serviced Sales', code: 'SS'}},
    {Salestype: {id: 6, title: 'PO for Export', code: 'POEXP'}},
    {Salestype: {id: 99, title: 'Warehouse Sales', code: 'WS'}}
]

export const shipmentStatuses = [
    // {Shipmentstatus: {id: 9, hastext:null, title:  'Material ready, waiting for booking'}},
    // {Shipmentstatus: {id: 3, hastext:null, title:  'Pending deposit'}},
    // {Shipmentstatus: {id: 8, hastext:null, title:  'Deposit received, ETD to be advised once material is ready'}},
    // {Shipmentstatus: {id: 5, hastext:null, title:  'Pending Import Permit'}},
    // {Shipmentstatus: {id: 7, hastext:1, title:  'Import Permit done'}},
    // {Shipmentstatus: {id: 4, hastext:null, title:  'Pending valid LC'}},
    // {Shipmentstatus: {id: 1, hastext:1, title:  'ETD wk XX'}},
    // {Shipmentstatus: {id: 6, hastext:1, title:  'Vessel'}},
    // {Shipmentstatus: {id: 2, hastext:null, title:  'On hold'}},
    // {Shipmentstatus: {id: 10, hastext:null, title:  'Delay due to Covid-19'}}
    {Shipmentstatus: {id: 3, hastext:null, title:  'Pending deposit'}},
    {Shipmentstatus: {id: 5, hastext:null, title:  'Pending Import Permit'}},
    {Shipmentstatus: {id: 9, hastext:null, title:  'Material ready, waiting for booking'}},
    {Shipmentstatus: {id: 2, hastext:null, title:  'On hold'}},
    {Shipmentstatus: {id: 1, hastext:1, title:  'Scheduled ETD wk'}},
    {Shipmentstatus: {id: 6, hastext:1, title:  'Vessel'}},
]

export const salesOrderFooterNotes = [
    {id: 1, replace: ['central_bank','rate_time'], text: "The exchange rate might vary on the final invoice and is based on <<central_bank>>'s rate at <<rate_time>> on the date of invoice"},
    {id: 2, replace: null, text: "Preferred payment mode is by bank transfer, optionally current cheque to be banked in by Buyer; cash payments are strictly not accepted"},
    {id: 3, replace: null, text: "Please pay only to our Company name and Bank Account; never accept any different written and/or verbal payment instructions"},
    {id: 4, replace: null, text: "Contact our HQ if any questions, we will not be responsible for any incorrect payments due to phishing"},
    {id: 5, replace:'interest_charge_pct', text: "Late payments are subject to a compound interest charge of <<interest_charge_pct>> per month."}
]

export const speciesTypes = [
    { value: 'HW', text: 'Hardwood' },
    { value: 'SW', text: 'Softwood' },
    { value: 'PA', text: 'Panel' }
]

export const supplierTypes = [
    {Suppliertype: {id: 1, title: 'Supplier'}},
    {Suppliertype: {id: 2, title: 'Supplier w multiple locations/companies'}},
    {Suppliertype: {id: 3, title: 'Agent'}},
    {Suppliertype: {id: 4, title: 'Supplier & Agent'}},
    {Suppliertype: {id: 5, title: 'Potential Supplier'}},
]

export const statuses = [
    {Status: {id: 0, title: 'No'}},
    {Status: {id: 1, title: 'Yes'}}
]

export const stockFscTypes = [
    {value: 'FSCCW', text: 'FSCCW'},
    {value: 'FSCCOC', text: 'FSCCOC'},
    {value: 'PEFC', text: 'PEFC'},
]

export const taxCodes = [
    {value: 'OS', text: 'OS'},
    {value: 'OV7', text: 'OV7'},
    {value: 'OV10', text: 'OV10'},
    {value: 'OV11', text: 'OV11'},
    {value: 'SR-0', text: 'SR-0'}
]

export const territories = [
    {Territory: {id: 1, title: 'North Malaysia', country_id: 1}},
    {Territory: {id: 2, title: 'Central Malaysia', country_id: 1}},
    {Territory: {id: 3, title: 'South Malaysia', country_id: 1}},
    {Territory: {id: 9, title: 'East Malaysia', country_id: 1}},
    {Territory: {id: 4, title: 'Singapore', country_id: 1}},
    {Territory: {id: 5, title: 'Central Java', country_id: 4}},
    {Territory: {id: 6, title: 'East Java', country_id: 4}},
    {Territory: {id: 7, title: 'West Java', country_id: 4}},
    {Territory: {id: 8, title: 'Sumatra', country_id: 4}}
]

export const thicknessGroups = [
    {value: 1, text: '020 - 0.95', ms: 1},
    {value: 2, text: '1.0 - 9.9', ms: 1},
    {value: 3, text: '10 - 200', ms: 1}
]

export const tmsUrls = [

]

export const uofm = {
    areas: [
        // {Measurement:{id: 37 , title: "SFT", precision: 0, formatPrecision: '0,0'}},
        {Measurement:{id: 13 , title: "FT2", precision: 2, formatPrecision: '0,0.00', system: 2}},
        {Measurement:{id: 5 , title: "M2", precision: 2, formatPrecision: '0,0.000', system: 1}},
    ],
    dimensions: [
        {Measurement:{id: 51 , title: "CM", precision: 2, formatPrecision: '0,0.00', system: 1}},
        {Measurement:{id: 17 , title: "FT", precision: 2, formatPrecision: '0,0.00', system: 2}},
        {Measurement:{id: 15 , title: "IN", precision: 2, formatPrecision: '0,0.00', system: 2}},
        {Measurement:{id: 8 , title: "M", precision: 2, formatPrecision: '0,0.00', system: 1}},
        {Measurement:{id: 4 , title: "MM", precision: 1, formatPrecision: '0,0.0', system: 1}},
    ],
    qty: [
        // {Measurement:{id: 14 , title: "BF", precision: 3}, formatPrecision: '0,0.000'},
        // {Measurement:{id: 49 , title: "CNTR", precision: 0, formatPrecision: '0,0'}},
        {Measurement:{id: 17 , title: "FT1", precision: 2, formatPrecision: '0,0.00', system: 2}},
        {Measurement:{id: 13 , title: "FT2", precision: 2, formatPrecision: '0,0.00', system: 2}},
        {Measurement:{id: 50 , title: "FT3", precision: 3, formatPrecision: '0,0.000', system: 2}},
        // {Measurement:{id: 48 , title: "FR", precision: 0, formatPrecision: '0,0'}},
        // {Measurement:{id: 10 , title: "M1", precision: 0, formatPrecision: '0,0'}},
        {Measurement:{id: 56 , title: "M1", precision: 2, formatPrecision: '0,00', system: 1}},
        {Measurement:{id: 5 , title: "M2", precision: 2, formatPrecision: '0,0.000', system: 1}},
        {Measurement:{id: 1 , title: "M3", precision: 3, formatPrecision: '0,0.0000', system: 1}},
        {Measurement:{id: 2 , title: "MBF", precision: 3, formatPrecision: '0,0.000', system: 2}},
        {Measurement:{id: 1 , title: "M3", precision: 3, formatPrecision: '0,0.0000', system: 2}},
        {Measurement:{id: 12 , title: "PCS", precision: 0, formatPrecision: '0,0', system: null}},
        {Measurement:{id: 57 , title: "ROLL", precision: 2, formatPrecision: '0,0', system: null}},
        {Measurement:{id: 53 , title: "SET", precision: 0, formatPrecision: '0,0', system: null}},
        // s{Measurement:{id: 37 , title: "SFT", precision: 0, formatPrecision: '0,0'}},
        {Measurement:{id: 47 , title: "SHEET", precision: 0, formatPrecision: '0,0', system: null}},
        {Measurement:{id: 41 , title: "TON", precision: 0, formatPrecision: '0,0.0000', system: 1}},
        // {Measurement:{id: 11 , title: "TONS", precision: 0, formatPrecision: '0,0'}},
    ],
    volumes: [
        {Measurement:{id: 50 , title: "FT3", precision: 3, formatPrecision: '0,0.000', system: 2}},
        {Measurement:{id: 1 , title: "M3", precision: 3, formatPrecision: '0,0.0000', system: 1}},
    ],
    weights: [
        {Measurement:{id: 53 , title: "KGS", precision: 2, formatPrecision: '0,0.000', system: 'metric'}},
        {Measurement:{id: 54 , title: "LBS", precision: 2, formatPrecision: '0,0.000', system: 'imperial'}},
    ],
}

export const userRoles = [
    {value: 'ROLE_ADMINISTRATOR', text: 'Administrator'},
    {value: 'ROLE_COUNTRY_MANAGER', text: 'Country Manager'},
    {value: 'ROLE_DEFAULT_USER', text: 'Default User'},
    {value: 'ROLE_DEMO_OPERATIONS', text: 'Demo Operations User'},
    {value: 'ROLE_DEMO_SALES', text: 'Demo Sales & Marketing User'},
    {value: 'ROLE_DIRECTOR', text: 'Director'},
    {value: 'ROLE_EXPORT_QC', text: 'Export QC'},
    {value: 'ROLE_EXPORT_SALES', text: 'Export Sales'},
    {value: 'ROLE_FINANCE_CONTROLLER', text: 'Financial Controller'},
    {value: 'ROLE_FINANCE_MANAGER', text: 'Finance Manager'},
    {value: 'ROLE_FINANCE_STAFF', text: 'Finance Staff'},
    {value: 'ROLE_GUEST_USER', text: 'Guest User'},
    {value: 'ROLE_HR_MANAGER', text: 'HR Manager'},
    {value: 'ROLE_HR_STAFF', text: 'HR Staff'},
    {value: 'ROLE_IT_INTERN', text: 'IT Intern'},
    {value: 'ROLE_OPERATIONS_MANAGER', text: 'Operations Manager'},
    {value: 'ROLE_OPERATIONS_STAFF', text: 'Operations Staff'},
    {value: 'ROLE_SALES', text: 'Sales'},
    {value: 'ROLE_SALES_ADMIN', text: 'Sales Admin'},
    {value: 'ROLE_SUPERADMIN', text: 'System Administrator'},
    {value: 'ROLE_WAREHOUSE_SUPERVISOR', text: 'Warehouse Supervisor'}
]

export const wprStatuses = [
    {Wprstatus: {id: 1, title: 'Open'}},
    {Wprstatus: {id: 2, title: 'Submitted'}},
    {Wprstatus: {id: 3, title: 'Approved'}},
    {Wprstatus: {id: 4, title: 'Rejected'}},
    {Wprstatus: {id: 5, title: 'Cancelled'}}
]

export const validationRules = {
    amount: [
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Amount is invalid",
        v => (!v || !hasScript(v)) || "Amount is invalid",
        v => (!v || /^-?\d+(?:\.\d+)?$/.test(v)) || "Amount is invalid",
    ],
    currencySymbol: [
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Input is invalid",
        v => (!v || !hasScript(v)) || "Input is invalid",
        v => (!v || /^[^\w\s\d]+$/.test(v)) || "Input is invalid"
    ],
    date: [
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Date is invalid",
        v => (!v || !hasScript(v)) || "Date is invalid",
        v => (!v || /^(?!0000)(?:19|20)\d\d-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2[0-8])|(?:0[13-9]|1[0-2])-30|(?:0[13578]|1[02])-31)$/.test(v)) || "Date is invalid"
    ],
    email: [
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Email is invalid",
        v => (!v || !hasScript(v)) || "Email is invalid",
        v => (!v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || "Email is invalid"
    ],
    fileSizes: {
        size1Mb: v => v.size < 1048576 || 'File is > 1MB. Please reduce the file size and upload again.',
        size1_5Mb: v => v.size < 1048576 * 1.5 || 'File is > 1.5MB. Please reduce the file size and upload again.',
        size2Mb: v => v.size < 1048576 * 2 || 'File is > 2MB. Please reduce the file size and upload again.',
        size3Mb: v => v.size < 1048576 * 3 || 'File is > 3MB. Please reduce the file size and upload again.',
        size4Mb: v => v.size < 1048576 * 3 || 'File is > 4MB. Please reduce the file size and upload again.',
        size5Mb: v => v.size < 1048576 * 5 || 'File is > 5MB. Please reduce the file size and upload again.',
        size6Mb: v => v.size < 1048576 * 6 || 'File is > 6MB. Please reduce the file size and upload again.',
        size7Mb: v => v.size < 1048576 * 7 || 'File is > 7MB. Please reduce the file size and upload again.',
        size10Mb: v => v.size < 1048576 * 10 || 'File is > 10MB. Please reduce the file size and upload again.',
        size12Mb: v => v.size < 1048576 * 12 || 'File is > 12MB. Please reduce the file size and upload again.',
        size15Mb: v => v.size < 1048576 * 15 || 'File is > 15MB. Please reduce the file size and upload again.',
        size20Mb: v => v.size < 1048576 * 20 || 'File is > 20MB. Please reduce the file size and upload again.',
    },
    formattedDate: [
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Date is invalid",
        v => (!v || !hasScript(v)) || "Date is invalid",
        v => (!v || /^(?:(?:0[1-9]|[12][0-9]|3[01])\/(?:0[1-9]|1[0-2])\/\d{2})$/.test(v)) || "Date is invalid"
    ],
    integer: [
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Number is invalid",
        v => (!v || !hasScript(v)) || "Number is invalid",
        v => (!v || /^-?\d+$/.test(v)) || "Number is invalid",
    ],
    minimumOne: [
        v => (!v || v >= 1 || "Minimum value is 1")
    ],
    minimumZero: [
        v => (!v || v >= 0 || "Minimum value is 0")
    ],
    newPassword: [
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Password is invalid",
        v => (!v || !hasScript(v)) || "Password is invalid",
        v => (!v || /^(?!.*(?:test|123|pass|letmein|admin|qwerty|password|123456|123456789|welcome|iloveyou|111111|12345678|12345|1234567))(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%^&+=!])(?=.*[a-zA-Z09\[\]\(\)@#\,\?$%^&+=!]).{10,}$/.test(v)) || "Valid characters: a-z,A-Z,0-9,()[]@#?$%^&+=!",
    ],
    notRequired: [
        v => {
            if (v) return v.length <= 1000 || 'Maximum 1000 characters';
            else return true;
        }
    ],
    number: [
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Number is invalid",
        v => (!v || !hasScript(v)) || "Number is invalid",
        v => (!v || /^-?\d+(?:\.\d+)?$/.test(v)) || "Number is invalid",
    ],
    paragraph: [
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Input is invalid",
        v => (!v || !hasScript(v)) || "Input is invalid"
        // v => (!v || /^[A-Za-z0-9\s.,!&@?;+:'/"\(\)\[\]{}-]+$/.test(v)) || "Input is invalid",
    ],
    password: [
        // v => /^(?!.*<script\b)[\s\S]*$/.test(v) || "Password is invalid",
        v => (!v || !hasScript(v)) || "Password is invalid",
        v => /^(?!.*(temp123|test123|test|123|abc|letmein|admin|qwerty|password|123456|123456789|welcome|iloveyou|111111|12345678|12345|1234567))(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%^&+=!])(?=.*[a-zA-Z09\[\]\(\)@#\,\?$%^&+=!]).{10,}$/.test(v) || "Password is invalid",
    ],
    phoneNumber: [
        // v => (!v || /^(\+ ?)?\d[\d\s-]*$/.test(v)) || "Phone is invalid",
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Phone is invalid",
        v => (!v || !hasScript(v)) || "Phone is invalid"
    ],
    plainText: [
        // v => (!v || /^[A-Za-z\s().,-]+$/.test(v)) || "Input is invalid",
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Input is invalid",
        v => (!v || !hasScript(v)) || "Input is invalid"
    ],
    plainTextWithNumbers: [
        // v => (!v || /^[A-Za-z0-9#\s()'.,-]+$/.test(v)) || "Input is invalid",
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Input is invalid"
        v => (!v || !hasScript(v)) || "Input is invalid"
    ],
    plainTextWithNumberAndSymbols: [
        // v => (!v || /^[A-Za-z0-9#\s()'.,/&%+-]+$/.test(v)) || "Input is invalid",
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Input is invalid"
        v => (!v || !hasScript(v)) || "Input is invalid"
    ],
    required: [
        v => !!v || "Required"
    ],
    requiredWithZero: [
        v => (!!v || v === 0) || "Required"
    ],
    requiredMultiple: [
        v => !!(v && v.length) || "Required"
    ],
    validShippingFiles: [
        // v => !!v || "Please select a file",
        v => {
            const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document']
            if(v) {
                const fileType = v.type
                if (!allowedTypes.includes(fileType)) {
                    return 'PNG or JPG images, PDF, Excel or Word documents only.'
                }
                return true
            } else {
                return true
            }
        }
    ],
    validUploadFiles: [
        v => {
            const allowedTypes = [
                'image/png',
                'image/jpeg',
                'application/pdf',
                // 'application/vnd.ms-excel',
                // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                // 'application/msword',
                // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ]
            if(v) {
                const fileType = v.type
                if (!allowedTypes.includes(fileType) || fileType == null) {
                    return 'PNG or JPG images or PDF only.'
                    // return 'PNG or JPG images, PDF, Excel or Word documents only.'
                }
                return true
            } else {
                return true
            }
        }
    ],
    validExcelFiles : [
        v => {
            const allowedTypes = [
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]
            if(v) {
                const fileType = v.type
                if (!allowedTypes.includes(fileType) || fileType == null) {
                    return 'XLS or XLSX files only.'
                }
                return true
            } else {
                return true
            }
        }
    ],
    validPdfFiles: [
        v => {
            const allowedTypes = [
                'application/pdf',
            ]
            if(v) {
                const fileType = v.type
                if (!allowedTypes.includes(fileType) || fileType == null) {
                    return 'PDF only.'
                }
                return true
            } else {
                return true
            }
        }
    ],
    validExcelFileExtensions: /(\.xls|\.xlsx)$/i,
    validImageFileExtensions: /(\.jpg|\.jpeg|\.png|\.gif)$/i,
    validPdfFileExtensions: /(\.pdf|\.PDF)$/i,
    validUploadFileExtensions: /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i,
    url: [
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Input is invalid",
        v => (!v || !hasScript(v)) || "Input is invalid",
        v => (!v || /^(?:(?:https?:\/\/)?(?:www\.)?)?(?!:\/\/)([a-zA-Z0-9\-](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9\-])?\.)?[a-zA-Z0-9\-]{2,}(?:\.[a-zA-Z]{2,})(?:\/[^\s]*)?$/.test(v)) || "Input is invalid",
    ],
    variable: [
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Input is invalid",
        v => (!v || !hasScript(v)) || "Input is invalid",
        v => (!v || /^[A-Za-z\s().,_\[\]]+$/.test(v)) || "Input is invalid",
    ],
    website: [
        // v => (!v || /^(?!.*<script\b)[\s\S]*$/.test(v)) || "Input is invalid",
        v => (!v || !hasScript(v)) || "Input is invalid",
        v => (!v || /^(?:(?:https?:\/\/)?(?:www\.)?)?(?!:\/\/)([a-zA-Z0-9\-](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9\-])?\.)?[a-zA-Z0-9\-]{2,}(?:\.[a-zA-Z]{2,})?$/.test(v)) || "Input is invalid",
    ]
}