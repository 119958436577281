<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? SalesOrder__title : ''" help-page-component="AddSalesOrder"/>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <SalesOrderAlert
                        :alert="$t('message.closeAlert')"
                        mode="add"
                        type="warning"
                        class="mt-3"
                        v-if="addMode && newSalesOrderUnsaved"
                />
                <SalesOrderAlert
                        :alert="$t('message.closeAlert')"
                        mode="update"
                        type="warning"
                        class="mt-3"
                        v-if="updateMode && updatedSalesOrderUnsaved"
                />
                <app-card :fullBlock="true" colClasses="xl12 lg12 md12 sm12 xs12">
                    <v-form lazy-validation ref="salesOrderForm" v-model="validForm">
                        <v-overlay
                                :value="loader"
                                absolute
                                opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                            color="primary"
                                            indeterminate
                                            size="40"
                                            width="6"
                                    />
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                            accordion
                            multiple
                            v-model="panel"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <SalesOrderGeneralInfo v-if="loader"></SalesOrderGeneralInfo>
                                    <template v-if="!loader">
                                        <v-layout row pt-6 v-if="false">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadActiveSalesOrder') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items="allActiveSalesOrders"
                                                        :placeholder="$t('message.startTypingToSearch')"
                                                        dense
                                                        hide-details="auto"
                                                        item-value="SalesOrder.id"
                                                        item-text="SalesOrder.title"
                                                        required
                                                        solo
                                                        @change="loadAnotherActiveSalesOrder($event)"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-1">
                                                            <span class="font-weight-bold">{{ item.SalesOrder.title}}</span>
                                                            <span class="font-xs"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                            <span class="font-xs"> {{ $t('message.invoiceNo') + ': ' + item.LocalSalesInvoice.title }} </span>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row pt-6>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.date') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-menu
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        max-width="290px"
                                                        min-width="290px"
                                                        ref="salesOrderSodateMenu"
                                                        transition="scale-transition"
                                                        v-model="salesOrderSodateMenu"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                                :label="SalesOrder__sodate ? '' : $t('message.required')"
                                                                :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                                :value="computedSalesOrderSoDateFormatted"
                                                                append-icon="event"
                                                                clearable
                                                                class="force-text-left"
                                                                hint="DD/MM/YY format"
                                                                hide-details="auto"
                                                                background-color="white"
                                                                persistent-hint
                                                                readonly
                                                                solo
                                                                v-on="on"
                                                                @click:clear="SalesOrder__sodate = null"
                                                                @click:append="salesOrderSodateMenu = true"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                            first-day-of-week="1"
                                                            locale-first-day-of-year="4"
                                                            show-week
                                                            v-model="SalesOrder__sodate"
                                                            @input="salesOrderSodateMenu = false"
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.appTimberCompany') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="whOffices"
                                                        :label="SalesOrder__office_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Office.title"
                                                        item-value="Office.id"
                                                        required
                                                        solo
                                                        v-model="SalesOrder__office_id"
                                                        @change="officeChanged"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items=filteredBuyers
                                                        :label="SalesOrder__customer_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Customer.title"
                                                        item-value="Customer.id"
                                                        required
                                                        solo
                                                        v-model="SalesOrder__customer_id"
                                                        @change="buyerChanged()"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.attention') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="buyerContacts"
                                                        dense
                                                        hide-details
                                                        item-text="Contact.name"
                                                        item-value="Contact.id"
                                                        solo
                                                        v-model="SalesOrder__contact_id"
                                                >
                                                    <template v-slot:item="{ item }">
                                                        <div class="d-flex flex-column pt-3">
                                                            <span class="font-weight-bold">{{ (item.Contact.prefix ? item.Contact.prefix + " ": "") + item.Contact.name }}</span>
                                                            <span class="font-xs" v-if="item.Contact.position != null">{{ item.Contact.position }}</span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{ item }">
                                                        <div class="d-flex flex-column">
                                                            <span>{{ (item.Contact.prefix ? item.Contact.prefix + " ": "") + item.Contact.name }}</span>
                                                        </div>
                                                    </template>
                                                </v-select>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.salesOrderNo') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 :class="addMode ? 'font-italic' : 'font-weight-bold'">{{ updateMode ? SalesOrder__title : $t('message.autogenerated') }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.salesColleague') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items="allSalesColleagues"
                                                        :label="SalesOrder__salescontact_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Salesperson.name"
                                                        item-value="Salesperson.id"
                                                        solo
                                                        v-model="SalesOrder__salescontact_id"
                                                >
                                                    <template v-slot:item="data">
                                                        <template>
                                                            <v-list-item-avatar>
                                                                <img :src="data.item.User.avatar ? data.item.User.avatar : '/static/avatars/appic_user.jpg'">
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title v-html="data.item.Salesperson.name"></v-list-item-title>
                                                                <v-list-item-subtitle v-html="data.item.Country.name"></v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.destination') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="filteredCountries"
                                                        :label="SalesOrder__destinatione_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Country.name"
                                                        item-value="Country.id"
                                                        solo
                                                        v-model="SalesOrder__destinatione_id"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.collectionDate') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-menu
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        max-width="290px"
                                                        min-width="290px"
                                                        ref="salesOrderPickupDateMenu"
                                                        transition="scale-transition"
                                                        v-model="salesOrderPickupDateMenu"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                                :label="SalesOrder__pickup_date ? '' : $t('message.required')"
                                                                :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                                :value="computedSalesOrderPickupDateFormatted"
                                                                append-icon="event"
                                                                clearable
                                                                class="force-text-left"
                                                                hint="DD/MM/YY format"
                                                                hide-details="auto"
                                                                background-color="white"
                                                                persistent-hint
                                                                readonly
                                                                solo
                                                                v-on="on"
                                                                @click:clear="SalesOrder__pickup_date = null"
                                                                @click:append="salesOrderPickupDateMenu = true"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                            first-day-of-week="1"
                                                            locale-first-day-of-year="4"
                                                            show-week
                                                            v-model="SalesOrder__pickup_date"
                                                            @input="salesOrderPickupDateMenu = false"
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.deliveryTerms') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="deliveryTerms"
                                                        :label="SalesOrder__json__delivery_terms ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        autocomplete="new-password"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="SalesOrder__json__delivery_terms"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1  class="align-center">{{ $t('message.paymentTerms') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items="warehouseSalesPaymentTerms"
                                                        :label="SalesOrder__paymentstatement_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-value="Paymentterm.id"
                                                        item-text="Paymentterm.title"
                                                        required
                                                        solo
                                                        v-model="SalesOrder__paymentstatement_id"
                                                >
                                                    <template v-slot:item="{ item }">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                            <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{ item }">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                            <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1  class="align-center">{{ $t('message.bankAccount') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="filteredBankAccounts"
                                                        clearable
                                                        dense
                                                        item-value="Bank.id"
                                                        item-text="Bank.title"
                                                        hide-details
                                                        solo
                                                        v-model="SalesOrder__bank_id"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-2">
                                                            <span class="font-sm">{{ item.Bank.title }}</span>
                                                            <span class="font-weight-bold"> {{ item.Bank.account }} </span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{item}">
                                                        <div class="d-flex flex-column py-0">
                                                            <span class="font-sm">{{ item.Bank.title + ':' }}</span>
                                                            <span class="font-weight-bold">{{ item.Bank.account }} </span>
                                                        </div>
                                                    </template>
                                                </v-select>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="SalesOrder__item_list_version > 1.0">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.measurementSystem') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row">
                                                <v-radio-group
                                                        class="mt-0"
                                                        dense
                                                        hide-details="auto"
                                                        row
                                                        v-model="SalesOrder__ms_id"
                                                >
                                                    <v-radio :label="$t('message.metric')" :value="1"/>
                                                    <v-radio :label="$t('message.imperial')" :value="2"/>
                                                </v-radio-group>
                                                <div class="font-sm font-italic">({{ $t('message.switchToFilterThicknessAndUnits') }})</div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="updateMode">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.salesOrderSigned') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-checkbox
                                                        :false-value="0"
                                                        :label="$t('message.yes')"
                                                        :readonly="SalesOrder__issigned == 1"
                                                        :true-value="1"
                                                        :value="SalesOrder__issigned"
                                                        :input-value="SalesOrder__issigned"
                                                        class="mt-0"
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="signSalesOrder()"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row  v-if="SalesOrder__Invoice__id != null">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.relatedInvoice') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="font-weight-bold">{{ SalesOrder__Invoice__title }}</v-flex>
                                        </v-layout>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.specifications') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <Transition>
                                        <SoItemList v-if="loader && SalesOrder__item_list_version == 1.0"/>
                                        <SoItemListV2 v-if="loader && SalesOrder__item_list_version > 1.0"/>
                                    </Transition>
                                    <v-overlay :value="loading.items" opacity="0.15">
                                        <v-row>
                                            <v-col class="text-center">
                                                <v-progress-circular
                                                    color="primary"
                                                    indeterminate
                                                    size="40"
                                                    width="6"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-overlay>
                                    <SalesOrderItemList :update-mode="updateMode" v-if="!loader && SalesOrder__item_list_version == 1.0"/>
                                    <SalesOrderItemListV3
                                        :update-mode="updateMode"
                                        @reload-item-list="reloadItemList"
                                        v-if="!loader && SalesOrder__item_list_version > 1.0"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.marketingAndFinance') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplier') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                    :items="allActiveSuspendedSuppliers"
                                                    :label="SalesOrder__supplier_id ? '' : $t('message.required')"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Supplier.title"
                                                    item-value="Supplier.id"
                                                    required
                                                    solo
                                                    v-model="SalesOrder__supplier_id"
                                                    @change="supplierChanged()"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.product') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                    :items=productGroups
                                                    :label="SalesOrder__productgroup_id ? '' : $t('message.required')"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Productgroup.title"
                                                    item-value="Productgroup.id"
                                                    required
                                                    solo
                                                    v-model="SalesOrder__productgroup_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.remarks') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-textarea
                                                    :rules="[...validationRules.paragraph]"
                                                    :value="SalesOrder__remarks"
                                                    autocomplete="new-password"
                                                    clearable
                                                    dense
                                                    hide-details
                                                    solo
                                                    @change="SalesOrder__remarks = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1  class="align-center">{{ $t('message.gstVatPpnRate') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                    :items="gstRates"
                                                    clearable
                                                    dense
                                                    hide-details
                                                    item-value="Gstrate.value"
                                                    item-text="Gstrate.title"
                                                    solo
                                                    v-model="SalesOrder__gstpercent"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.printPreferences') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.printTwoPages') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-checkbox
                                                    class="mt-0"
                                                    dense
                                                    hide-details
                                                    required
                                                    solo
                                                    :true-value="1"
                                                    :false-value="null"
                                                    v-model="SalesOrder__longformat"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_salesorder')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <DocumentHistory asset="localsalesorders" :asset_id="SalesOrder__id"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row>
                            <v-flex d-flex xs12>
                                <v-layout row px-3 pt-3 mb-3>
                                    <v-btn
                                            class="ml-3"
                                            small
                                            v-if="false"
                                            @click.stop="previewDialog = true"
                                    >
                                        {{ $t('message.previewSalesOrder') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.add"
                                            class="ml-3"
                                            color="info"
                                            small
                                            @click="addSalesOrder()"
                                            v-if="addMode == true"
                                    >
                                        {{ $t('message.create') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.save"
                                            class="ml-3"
                                            color="info"
                                            small
                                            @click="saveSalesOrder()"
                                            v-if="updateMode == true && SalesOrder__orderstatus_id == 1 && SalesOrder__issigned == 0"
                                    >
                                        {{ $t('message.save') }}
                                    </v-btn>
                                    <template v-if="updateMode == true && SalesOrder__item_list_version > 1.0">
                                        <PrintSalesOrderButtons
                                            :sales-order-id="SalesOrder__id"
                                            :update-mode="updateMode"
                                            :list-mode="false"
                                            :version="SalesOrder__item_list_version"
                                        />
<!--                                        <v-btn-->
<!--                                                class="ml-3"-->
<!--                                                small-->
<!--                                                @click.stop="viewPdfV2('so')"-->
<!--                                        >-->
<!--                                            <v-icon small>far fa-file-pdf</v-icon>-->
<!--                                            <span class="ml-1">{{ $t('message.salesOrder') }}</span>-->
<!--                                        </v-btn>-->
<!--                                        <v-btn-->
<!--                                                class="ml-3"-->
<!--                                                small-->
<!--                                                @click.stop="viewPdfV2('pi')"-->
<!--                                        >-->
<!--                                            <v-icon small>far fa-file-pdf</v-icon>-->
<!--                                            <span class="ml-1">{{ $t('message.proformaInvoice') }}</span>-->
<!--                                        </v-btn>-->
                                    </template>
                                    <template v-if="updateMode == true && SalesOrder__item_list_version == 1.0">
                                        <v-btn
                                                class="ml-3"
                                                small
                                                @click.stop="viewPdf('so')"
                                        >
                                            <v-icon small>far fa-file-pdf</v-icon>
                                            <span class="ml-1">{{ $t('message.salesOrder') }}</span>
                                        </v-btn>
                                        <v-btn
                                                class="ml-3"
                                                small
                                                @click.stop="viewPdf('pi')"
                                        >
                                            <v-icon small>far fa-file-pdf</v-icon>
                                            <span class="ml-1">{{ $t('message.proformaInvoice') }}</span>
                                        </v-btn>
                                    </template>
                                    <v-btn
                                            :loading="loading.reset"
                                            @click="resetSalesOrderForm()"
                                            class="ml-3"
                                            color="default"
                                            small
                                            v-if="addMode == true"
                                    >
                                        <v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.cancel"
                                            class="ml-3"
                                            color="error"
                                            small
                                            v-if="updateMode == true && SalesOrder__orderstatus_id == 1 && SalesOrder__issigned == 0"
                                            @click="cancelSalesOrder(SalesOrder__id)"
                                    >{{ $t('message.cancel') }}
                                    </v-btn>
                                    <v-btn
                                            class="ml-3"
                                            color="info"
                                            small
                                            v-if="updateMode == true && SalesOrder__orderstatus_id == 1 && SalesOrder__issigned == 1"
                                            @click="convertSalesOrderToInvoice(SalesOrder__id)"
                                    >{{ $t('message.convertToInvoice') }}
                                    </v-btn>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <SimpleAlert
            :alert_message.sync="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        />
        <v-dialog
                overlay=false
                scrollable
                transition="dialog-bottom-transition"
                v-model="previewDialog"
                width="600px"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.salesOrderPreview') }}</v-card-title>
                <v-card-text class="pa-3">
                    <SalesOrderPreview/>
                </v-card-text>
                <v-card-actions>
                    <v-card-actions class="pr-3 pt-3">
                        <v-spacer/>
                        <v-btn
                                class="ml-3"
                                color="default"
                                small
                                @click="previewDialog = false"
                        >{{ $t('message.backToEditing') }}
                        </v-btn>
                        <v-btn
                                :loading="loading.add"
                                class="ml-3"
                                color="info"
                                small
                                v-if="addMode == true"
                                @click="addSalesOrder()"
                        >{{ $t('message.create') }}
                        </v-btn>
                        <v-btn
                                :loading="loading.save"
                                class="ml-3"
                                color="info"
                                small
                                v-if="addMode == false && SalesOrder__orderstatus_id == 1"
                                @click="saveSalesOrder()"
                        >{{ $t('message.save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                overlay=false
                scrollable
                transition="dialog-bottom-transition"
                v-if="SalesOrder__orderstatus_id == 1 && SalesOrder__issigned == 1"
                v-model="convertDialog"
                width="600px"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.salesOrderConvertThisToInvoice') }}</v-card-title>
                <v-card-text></v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer/>
                    <v-btn
                            class="ml-3"
                            color="default"
                            small
                            @click="convertDialog = false"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.convert"
                            class="ml-3"
                            color="info"
                            small
                            @click="convertSalesOrderToInvoice(SalesOrder__id)"
                    >{{ $t('message.convert') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {formatDate, isObject, log, mapOrder, numberFormat, scrollToFirstFormValidationError} from "Helpers/helpers";
// import SimpleAlert from "Components/Appic/SimpleAlert";
import router from "@/router";
// import SalesOrderAlert from "Components/Appic/SalesOrderAlert";
// import DocumentHistory from "../../../components/Appic/DocumentHistory";
import SalesOrderItemList from "Components/Appic/SalesOrderItemList";
import SalesOrderItemListV3 from "Components/Appic/SalesOrderItemListV3";
// import SalesOrderPreview from "Components/Appic/SalesOrderPreview";
import {api} from "Api/index";
import { v4 as uuidv4 } from 'uuid';
import PrintSalesOrderButtons from "Components/Appic/Printing/PrintSalesOrderButtons.vue";
// import {validationRules} from "@/store/modules/appic/constants";
const SalesOrderGeneralInfo = () => import("Components/Appic/Loading/SalesOrderGeneralInfo");
const SoItemList = () => import("Components/Appic/Loading/SoItemList");
const SoItemListV2 = () => import("Components/Appic/Loading/SoItemListV2");

const DocumentHistory = () => import("../../../components/Appic/DocumentHistory");
const SalesOrderAlert = () => import("Components/Appic/SalesOrderAlert");
const SalesOrderPreview = () => import("Components/Appic/SalesOrderPreview");
const SimpleAlert = () => import("Components/Appic/SimpleAlert");

export default {
    name: "AddSalesOrder",
    components: {
        PrintSalesOrderButtons,
        DocumentHistory,
        SalesOrderAlert,
        SalesOrderGeneralInfo,
        SalesOrderItemList,
        SalesOrderItemListV3,
        SalesOrderPreview,
        SimpleAlert,
        SoItemList,
        SoItemListV2
    },
    props: ['salesOrderId'],
    title: '',
    data () {
        return {
            allActiveSalesOrders: [],
            buyerContacts: [],
            buyerPaymentTerms: [],
            convertDialog: false,
            deliveryTerms: [
                {text: "Own collection", value: "Own collection"},
                {text: "By APP Timber transport", value: "By APP Timber transport"}
            ],
            dialogs: {
                error:  false,
                error_message: ""
            },
            filteredBuyers: [],
            loading: {
                add: false,
                items: false,
                reset: false,
                save: false,
                sign: false,
            },
            loader: false,
            loadingAnotherSalesOrder: false,
            panel: [0,1],
            pageKey: 0,
            previewDialog: false,
            salesOrderSodateFormatted: formatDate(new Date().toISOString().substr(0,10)),
            salesOrderSodateMenu: false,
            salesOrderPickupDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
            salesOrderPickupDateMenu: false,
            validForm: true,
            warehouseChanged: false
        }
    },
    computed: {
        // validationRules() {
        //     return validationRules
        // },
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('salesorder',{
            SalesOrder__bank_id: 'SalesOrder.bank_id',
            SalesOrder__customer_id: 'SalesOrder.customer_id',
            SalesOrder__currency_id: 'SalesOrder.currency_id',
            SalesOrder__contact_id: 'SalesOrder.contact_id',
            SalesOrder__destinatione_id: 'SalesOrder.destinatione_id',
            SalesOrder__gstpercent: 'SalesOrder.gstpercent',
            SalesOrder__json__delivery_terms: 'SalesOrder.json.delivery_terms',
            SalesOrder__id: 'SalesOrder.id',
            SalesOrder__item_list_version: 'SalesOrder.item_list_version',
            SalesOrder__issigned: 'SalesOrder.issigned',
            SalesOrder__longformat: 'SalesOrder.longformat',
            SalesOrder__marketsegment_id: 'SalesOrder.marketsegment_id',
            SalesOrder__ms_id: 'SalesOrder.ms_id',
            SalesOrder__office_id: 'SalesOrder.office_id',
            SalesOrder__orderstatus_id: 'SalesOrder.orderstatus_id',
            SalesOrder__origin_id: 'SalesOrder.origin_id',
            SalesOrder__paymentstatement_id: 'SalesOrder.paymentstatement_id',
            SalesOrder__paymentterm_id: 'SalesOrder.paymentterm_id',
            SalesOrder__paymentterm: 'SalesOrder.paymentterm',
            SalesOrder__pickup_date: 'SalesOrder.pickup_date',
            SalesOrder__productgroup_id: 'SalesOrder.productgroup_id',
            SalesOrder__remarks: "SalesOrder.remarks",
            SalesOrder__salescontact_id: "SalesOrder.salescontact_id",
            SalesOrder__sodate: 'SalesOrder.sodate',
            SalesOrder__supplier_id: 'SalesOrder.supplier_id',
            SalesOrder__title: 'SalesOrder.title',
            SalesOrder__Invoice__id: 'SalesOrder.Invoice.id',
            SalesOrder__Invoice__title: 'SalesOrder.Invoice.title'
        },'statePrefix'),
        ...mapFields('salesorder',{
            defaultPaymentStatementId: 'defaultPaymentstatementId',
            newSalesOrderSaved: 'newSalesOrderSaved',
            newSalesOrderUnsaved: 'newSalesOrderUnsaved',
            updatedSalesOrderUnsaved: 'updatedSalesOrderUnsaved',
            updatedSalesOrderSaved: 'updatedSalesOrderSaved'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('bankaccount', {
            allBankAccounts: 'allBankAccounts',
            findBankAccountById: 'findBankAccountById',
            findBankAccountsByOfficeAndCurrency: 'findBankAccountsByOfficeAndCurrency',
        }),
        ...mapGetters('buyer', {
            allBuyers: 'allBuyers',
            findBuyerById: 'findBuyerById'
        }),
        ...mapGetters('country',{
            allCountries: 'allCountries'
        }),
        ...mapGetters('paymentterm', {
            allBuyerPaymentTerms: 'allBuyerPaymentTerms',
            allBuyerLocalSalesPaymentTerms: 'allBuyerLocalSalesPaymentTerms'
        }),
        ...mapGetters('salescolleague', {
            allSalesColleagues: 'allSalesColleagues'
        }),
        ...mapGetters('supplier',{
            allActiveSuspendedSuppliers: 'allActiveSuspendedSuppliers',
            findSupplierById: 'findSupplierById'
        }),
        ...mapGetters('user',{
            User: 'User'
        }),
        ...mapGetters([
            'currencies',
            'gstRates',
            'offices',
            'productGroups',
            'validationRules'
        ]),
        addMode: function () {
            return this.$route.matched.some(({name}) => name === 'add_salesorder')
        },
        computedSalesOrderPickupDateFormatted () {
            return formatDate(this.SalesOrder__pickup_date)
        },
        computedSalesOrderSoDateFormatted () {
            return formatDate(this.SalesOrder__sodate)
        },
        filteredBankAccounts: function(){
            if(this.SalesOrder__office_id != null && this.SalesOrder__currency_id != null){
                return this.findBankAccountsByOfficeAndCurrency(this.SalesOrder__office_id, this.SalesOrder__currency_id)
            } else {
                return []
            }
        },
        filteredCountries () {
            return this.allCountries.filter(country => {
                return [1,2,3,4,12].includes(country.Country.id)
            })
        },
        updateMode: function () {
            return this.$route.matched.some(({name}) => name === 'update_salesorder')
        },
        warehouseSalesPaymentTerms: function () {
            const order = [4294, 4304, 4464, 4463, 4435, 4442]
            let paymentTerms = this.allBuyerPaymentTerms.filter((d) => d.Paymentterm.localsales_only == 1)
            mapOrder(paymentTerms, order, 'Paymentterm.id')
            return paymentTerms
        },
        whOffices: function () {
            return this.offices.filter(office => office.Office.has_warehouse == true)
        }
    },
    methods: {
        ...mapActions('bankaccount', {
            resetAllBankAccounts: 'resetAllBankAccounts',
            getAllBankAccounts: 'getAllBankAccounts'
        }),
        ...mapActions('buyer',{
            getAllActiveBuyers: 'getAllActiveBuyers'
        }),
        ...mapActions('country',{
            getAllCountries: 'getAllCountries'
        }),
        ...mapActions('paymentterm', {
            getAllPaymentTerms: 'getAllPaymentTerms',
            resetAllPaymentTerms: 'resetAllPaymentTerms'
        }),
        ...mapActions('salesorder',{
            cancelSalesOrderById: 'cancelSalesOrderById',
            createSalesOrder: 'createSalesOrder',
            getAllActiveSalesOrders: 'getAllActiveSalesOrders',
            getSalesOrderToUpdateById: 'getSalesOrderToUpdateById',
            resetAllActiveSalesOrders: 'resetAllActiveSalesOrders',
            resetCurrentSalesOrder: 'resetCurrentSalesOrder',
            salesOrderBuyerChanged: 'salesOrderBuyerChanged',
            updateSalesOrder: 'updateSalesOrder'
        }),
        ...mapActions('salescolleague', {
            getAllSalesColleagues: 'getAllSalesColleagues',
        }),
        ...mapActions('salesorderitem',{
            getItemsBySalesOrder: 'getItemsBySalesOrder',
            resetSalesOrderItems: 'resetSalesOrderItems',
            updateTotals: 'updateTotals'
        }),
        ...mapActions('supplier',{
            resetAllSuppliers: 'resetAllSuppliers',
            getAllActiveSuspendedSuppliers: 'getAllActiveSuspendedSuppliers'
        }),
        addSalesOrder () {
            if(this.$refs.salesOrderForm.validate()) {
                this.loading.add = true
                this.createSalesOrder()
                    .then((response) => {
                        if (response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.salesOrderAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.newSalesOrderUnsaved = false
                            this.newSalesOrderSaved = true
                            this.resetCurrentSalesOrder()
                                .then(() => {
                                    this.resetSalesOrderItems(this.statePrefix).then(() => {
                                        this.resetAllActiveSalesOrders().then(() => {
                                            router.push({
                                                name: 'update_salesorder',
                                                params: {salesOrderId: response.salesorder_id}
                                            }).catch(err => {
                                            })
                                        })
                                    })
                                })
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.salesOrderNotAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.salesOrderNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.salesOrderNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.salesOrderNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
                scrollToFirstFormValidationError()
            }
        },
        buyerChanged () {
            let buyer = this.findBuyerById(this.SalesOrder__customer_id)
            this.SalesOrder__destinatione_id = buyer.Company.country_id
            this.SalesOrder__marketsegment_id = buyer.Customer.marketsegment_id
            this.SalesOrder__salescontact_id = buyer.Customer.salescontact_id
            //this.SalesOrder__paymentstatement_id = buyer.Customer.ls_paymentstatement_id ? buyer.Customer.ls_paymentstatement_id : this.defaultPaymentStatementId
            let paymentTerm = this.allBuyerLocalSalesPaymentTerms.find(p => p.Paymentterm.id == this.SalesOrder__paymentstatement_id)
            this.SalesOrder__paymentterm_id = paymentTerm?.Paymentterm.paymentterm_id
            this.SalesOrder__paymentterm = paymentTerm?.Paymentterm.title

            //temporarily disabled 2023-04-03
            // if(false) {
                if (this.SalesOrder__paymentstatement_id != null) {
                    this.buyerPaymentTerms = this.allBuyerLocalSalesPaymentTerms.filter(paymentTerm => {
                        return [this.SalesOrder__paymentstatement_id, this.defaultPaymentStatementId].includes(paymentTerm.Paymentterm.id)
                    })
                } else {
                    this.buyerPaymentTerms = this.allBuyerLocalSalesPaymentTerms.filter(paymentTerm => {
                        return [this.defaultPaymentStatementId].includes(paymentTerm.Paymentterm.id)
                    })
                    this.SalesOrder__paymentstatement_id = this.defaultPaymentStatementId
                }
            // }

            const promise = new Promise((resolve, reject) => {
                api
                    .get("/companies/" + buyer.Customer.company_id + "/contacts")
                    .then(response => {
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
            promise
                .then(response => {
                    this.buyerContacts = response
                    if(this.addMode) {
                        this.SalesOrder__contact_id = this.buyerContacts[0]?.Contact.id
                    }
                })
                .finally(() => {
                    this.updatedSalesOrderUnsaved = false
                })
        },
        async cancelSalesOrder (val) {
            if(await this.$root.$confirm(this.$t('message.cancelSalesOrder'), this.$t('message.confirmations.continueSalesOrderCancelAction'), {color: 'orange'})) {
                this.cancelSalesOrderById(val)
                    .then(response => {
                        if (response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.salesOrderDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            router.push({name: 'salesorder_listing'}).catch(err => {
                                this.$toast.error(err,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                        } else {
                            this.$toast.error(this.$t('message.errors.salesOrderNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loading.cancel = false
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.salesOrderNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.cancel = false
                    })
                    // .finally(() => {
                    //     this.loading.cancel = false
                    // })
            }
        },
        convertSalesOrderToInvoice (val) {
            router.push({name: 'add_localsale', params: { salesOrderId : val}}).catch(err => {})
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        formatDate,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        loadAnotherActiveSalesOrder (val){
            router.push({name: 'update_salesorder', params: { salesOrderId : val}}).catch(err => {})
        },
        loadSalesOrderById (val) {
            this.loader = true
            this.getSalesOrderToUpdateById( val )
                    .then(() => {
                        this.$title = this.$t('message.titles.so') + ': ' + this.SalesOrder__title
                        const payload = {
                            salesOrderId: val,
                            prefix: this.statePrefix
                        }
                        this.getItemsBySalesOrder(payload)
                                .then(() => {
                                    this.updateTotals(this.statePrefix)
                                            .then(() => {
                                                this.officeChanged()
                                                if (this.buyerPaymentTerms.length == 0) {
                                                    this.buyerPaymentTerms = this.allBuyerLocalSalesPaymentTerms
                                                }
                                                if(this.SalesOrder__customer_id != null) this.buyerChanged()
                                                this.loader = false
                                                this.updatedSalesOrderUnsaved = false
                                            })
                                            .catch(()=>{
                                                this.loader = false
                                                this.updatedSalesOrderUnsaved = false
                                            })
                                })
                                .catch(()=>{
                                    this.loader = false
                                    this.updatedSalesOrderUnsaved = false
                                })
                    })
                    .catch(()=>{
                        this.loader = false
                        this.updatedSalesOrderUnsaved = false
                        router
                                .push({name: 'not_found_main', params: {resource: 'SO'}})
                                .catch(() => {
                                    console.log('error')
                                })
                    })
        },
        officeChanged () {
            // this.warehouseChanged = false
            let office = this.offices.find(office => office.Office.id == this.SalesOrder__office_id)
            this.filteredBuyers = this.allBuyers.filter( buyer => {
                // return buyer.Company.country_id == office?.Office.country_id
                return true
            })
            this.SalesOrder__destinatione_id = office.Office.country_id
            if(this.SalesOrder__currency_id == null) this.SalesOrder__currency_id = office.Office.currency_id
            this.SalesOrder__gstpercent = office.Office.taxrate
            // this.warehouseChanged = true
        },
        reloadItemList(value) {
            if(value.reloadList) {
                this.loading.items = true
                this.updateSalesOrder()
                    .then(() => {
                        this.loading.save = true
                        const payload = {
                            salesOrderId: this.salesOrderId,
                            prefix: this.statePrefix
                        }
                        this.getItemsBySalesOrder(payload)
                            .then(() => {
                                this.updateTotals(this.statePrefix)
                                    .then(() => {
                                        this.loading.items = false
                                        this.loading.save = false
                                        this.updatedSalesOrderUnsaved = false
                                    })
                                    .catch(()=>{
                                        this.loading.items = false
                                        this.loading.save = false
                                        this.updatedSalesOrderUnsaved = false
                                    })
                            })
                            .catch(()=>{
                                this.loading.items = false
                                this.loading.save = false
                                this.updatedSalesOrderUnsaved = false
                            })
                    })
                    .catch(()=>{
                        this.loading.items = false
                        this.loading.save = false
                        this.updatedSalesOrderUnsaved = false
                    })
            } else {
                if(this.updateMode) {
                    this.loading.items = true
                    this.updateSalesOrder()
                        .then(() => {
                            this.loading.items = false
                        })
                        .catch(() => {
                            this.loading.items = false
                        })
                } else {
                    this.updateTotals(this.statePrefix)
                }
            }
        },
        async resetSalesOrderForm () {
            if (await this.$root.$confirm(this.$t('message.resetSalesOrderForm'), this.$t('message.confirmations.continueSalesOrderFormReset'), {color: 'orange'})) {
                this.loading.reset = true
                this.resetCurrentSalesOrder()
                    .then(() => {
                        this.newSalesOrderSaved = false
                        this.newSalesOrderUnsaved = false
                        this.loading.reset = false
                        this.$toast.success(this.$t('message.salesOrderFormResetNotification'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                    })
                    .catch(()=>{
                        this.loading.reset = false
                    })
                    .then(()=>{
                        this.loading.reset = false
                    })
            }
        },
        saveSalesOrder () {
            if(this.$refs.salesOrderForm.validate()) {
                this.loading.save = true
                this.updateSalesOrder()
                    .then((response) => {
                        if (response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.salesOrderUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.updatedSalesOrderUnsaved = false
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.salesOrderNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.salesOrderNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.salesOrderNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.salesOrderNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
                scrollToFirstFormValidationError()
            }
        },
        async signSalesOrder () {
            if (await this.$root.$confirm(this.$t('message.signSalesOrder'), this.$t('message.confirmations.continueSalesOrderSign'), {color: 'orange'})) {
                api
                    .put('/localsalesorders/' + this.SalesOrder__id + '/sign')
                    .then(() => {
                        this.SalesOrder__issigned = 1
                        this.pageKey = Math.floor(Math.random() * 100)
                        this.$toast.success(this.$t('message.successes.salesOrderSigned'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .catch(() => {
                        this.pageKey = Math.floor(Math.random() * 100)
                        this.$toast.error(this.$t('message.errors.salesOrderNotSigned'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            } else {
                this.pageKey = Math.floor(Math.random() * 100)
            }
        },
        supplierChanged () {
            let supplier = this.findSupplierById(this.SalesOrder__supplier_id)
            if(supplier != null){
                this.SalesOrder__origin_id = supplier.Country.id
            }
        },
        viewPdf (docType) {
            let document = this.SalesOrder__title
            if(docType == 'pi') document.replace('SO','PI')
            // let user = JSON.parse(localStorage.getItem('user'))
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/salesorders/print/'
                + this.salesOrderId
                + '/'
                + docType
                + '/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank");
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        viewPdfV2 (docType) {
            let document = this.SalesOrder__title
            if(docType == 'pi') document.replace('SO','PI')
            // let user = JSON.parse(localStorage.getItem('user'))
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/salesorders/print-v2/'
                + this.salesOrderId
                + '/'
                + docType
                + '/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank");
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    // watch: {
    //     SalesOrder__office_id (newOffice, oldOffice) {
    //         if(newOffice != oldOffice) this.warehouseChanged = true
    //     }
    // },
    created () {
        this.resetAllPaymentTerms()

        if(this.allActiveSuspendedSuppliers.length == 0) this.getAllActiveSuspendedSuppliers()
        if(this.allBuyers.length == 0) this.getAllActiveBuyers()
        if(this.allBankAccounts.length == 0) this.getAllBankAccounts()
        if(this.allCountries.length == 0) this.getAllCountries()
        if(this.allSalesColleagues.length == 0) this.getAllSalesColleagues()
        if(this.allBuyerPaymentTerms.length == 0) this.getAllPaymentTerms()

        if(this.$route.matched.some(({name}) => name === 'update_salesorder')) {
            this.loadSalesOrderById(this.salesOrderId)
            // if(this.buyerPaymentTerms.length == 0){
            //     this.buyerPaymentTerms = this.allBuyerLocalSalesPaymentTerms
            // }

            //deactivated 2023-11-01
            // this.getAllActiveSalesOrders()
            //     .then(response => {
            //         this.allActiveSalesOrders = response
            //     })

        } else if(this.$route.matched.some(({name}) => name === 'add_salesorder')){
            if(this.filteredBuyers.length == 0){
                this.officeChanged()
            }
            // if(this.buyerPaymentTerms.length == 0){
            //    this.buyerPaymentTerms = this.allBuyerLocalSalesPaymentTerms
            // }
            if(this.User){
                if(this.User.role == 'ROLE_SALES_ADMIN'){
                    switch(this.User.country_id){
                        case 4:
                            this.SalesOrder__office_id = 21
                            break
                        case 1:
                        default:
                            this.SalesOrder__office_id = 1
                            break
                    }
                }
            } else {
                this.SalesOrder__office_id = 1
            }

            if(this.SalesOrder__sodate == null) this.Sales__sodate = new Date().toISOString().substr(0, 10)
            if(this.SalesOrder__customer_id != null) this.buyerChanged()
        }
    },
    mounted () {
        this.$store.watch(
            function (state) {
                return state.appic.salesorder.update.SalesOrder
            },
            () => {
                this.updatedSalesOrderUnsaved = true
            },
            {deep: true}
        )
        this.$store.watch(
            function (state) {
                return state.appic.salesorder.current.SalesOrder
            },
            () => {
                this.newSalesOrderUnsaved = true
            },
            {deep: true}
        )
        if(this.$route.matched.some(({name}) => name === 'add_salesorder')){
            this.resetCurrentSalesOrder();
            this.resetSalesOrderItems(this.statePrefix);
            this.SalesOrder__item_list_version = 1.1
            this.SalesOrder__sodate = new Date().toISOString().substr(0,10)
        }
    }
}
</script>

<style>
    .force-text-left .v-text-field__slot input {
        text-align: left !important;
    }
    .v-dialog {
        min-width: 200px !important;
    }
    .wrap-text {
        white-space: normal;
    }
    .font-xs {
        font-size: small;
    }
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }
    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
    .no-left-padding > div.v-expansion-panel-content__wrap{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
</style>