<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <div class="d-flex flex-row align-center pt-3 col-12">
                    <v-text-field
                        :placeholder="$t('message.no')"
                        :value="searchValue"
                        autocomplete="new-password"
                        class="filter-width-190 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchValue = $event"
                        @click:append="searchShipments()"
                        @click:clear="resetSearch()"
                        @keyup.enter.prevent="searchShipments()"
                    />
                    <v-autocomplete
                        :items="filterItems.buyer"
                        :loading="loading.filterItems.buyer"
                        :placeholder="$t('message.buyer')"
                        class="filter-width-190 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Customer.title"
                        item-value="Customer.id"
                        solo
                        v-model="filterValues.buyer"
                        @change="filterShipments()"
                    />
                    <v-autocomplete
                        :items="filterItems.supplier"
                        :loading="loading.filterItems.supplier"
                        :placeholder="$t('message.spl')"
                        class="filter-width-190 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Supplier.title"
                        item-value="Supplier.id"
                        solo
                        v-model="filterValues.supplier"
                        @change="filterShipments()"
                    />
                    <v-btn :loading="loading.clear" class="ml-3 mt-1" @click="clearFilters"><v-icon>refresh</v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <ExportTableJson
                        :export-conditions="[]"
                        :export-data="shipments"
                        :export-fields="headers"
                        :export-source="'document-status-report'"
                        class="mt-1"
                    />
                </div>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.shipments"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
                showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="hideDefaultFooter"
            :items="shipments"
            :options="tableOptions"
            :server-items-length="totalShipments"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            id="shipmentTable"
            item-key="Contract.id"
            @update:options="updateDataTable"
        >
            <template v-slot:item.Contract.id="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.moreActions') }}</span>
                        </v-tooltip>
                    </template>
                    <v-list dense>
                        <v-list-item class="font-sm" @click="updateShipment(item.Contract.id)" v-if="$can('update','Shipment')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateShipment') }}</v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <template v-slot:item.Contract.title="{ item }">
                <span class="font-weight-bold">{{ item.Contract.title }}</span>
            </template>
            <template v-slot:item.Contract.etddate="{ item }">
                {{ item.Contract.etddate !== '9999-99-99' ? formatDate(item.Contract.etddate) : '' }}
            </template>
            <template v-slot:item.Contract.etadate="{ item }">
                {{ item.Contract.etadate !== '9999-99-99' ? formatDate(item.Contract.etadate) : '' }}
            </template>
            <template v-slot:item.FullSet.uploaded="{item}">
                <div class="pl-0 text-center">
                    <div class="text-no-wrap" v-if="item.FullSet.uploaded !== '9999-99-99'">{{ formatDate(item.FullSet.uploaded) }}</div>
                    <div class="text-no-wrap font-weight-bold red--text text--darken-1" v-else>{{ $t('message.na') }}</div>
                </div>
            </template>
            <template v-slot:item.FullSet.duedate="{item}">
                <div class="pl-0 text-center">
                    <div class="text-no-wrap" :class="item.FullSet.overdue > 0 ? 'font-weight-bold red--text text--darken-1' : ''" v-if="item.FullSet.duedate !== '9999-99-99'">{{ formatDate(item.FullSet.duedate) }}</div>
                    <div class="text-no-wrap font-weight-bold red--text text--darken-1" v-else>{{ $t('message.na') }}</div>
                </div>
            </template>
            <template v-slot:item.FullSet.overdue="{item}">
                <div class="text-right pr-7 text-no-wrap font-weight-bold red--text text--darken-1" v-if="item.FullSet.overdue > 0">{{ item.FullSet.overdue }}</div>
            </template>
            <template v-slot:item.Awb.duedate="{item}">
                <div class="text-no-wrap" :class="item.Awb.overdue > 0 ? 'font-weight-bold red--text text--darken-1' : ''" v-if="item.Awb.duedate != '9999-99-99'">{{ formatDate(item.Awb.duedate) }}</div>
                <div class="text-no-wrap font-weight-bold red--text text--darken-1" v-else>{{ $t('message.na') }}</div>
            </template>
            <template v-slot:item.Awb.overdue="{item}">
                <div class="text-right pr-7 text-no-wrap font-weight-bold red--text text--darken-1" v-if="item.Awb.overdue > 0">{{ item.Awb.overdue }}</div>
            </template>
            <template v-slot:item.DocumentStatus.remarks="{ item }">
                <div class="d-flex flex-row justify-space-between align-center">
                    <template v-for="remark in item.DocumentStatus.remarks">
                        <div class="width-300-px ml-2">{{ remark.text + '     ' + '(' + formatDate(remark.date) + ')' }}</div>
                    </template>
                    <v-spacer v-if="item.DocumentStatus.remarks.length > 0"/>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <div class="no-of-remarks" v-if="item.DocumentStatus.noOfPreviousRemarks > 0" v-on="on">{{ item.DocumentStatus.noOfPreviousRemarks }}</div>
                        </template>
                        <span v-if="item.DocumentStatus.noOfPreviousRemarks > 1">{{ item.DocumentStatus.noOfPreviousRemarks + ' ' + $t('message.previousRemarks') }}</span>
                        <span v-else>{{ item.DocumentStatus.noOfPreviousRemarks + ' ' + $t('message.previousRemark') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="openRemarksDialog(item.Contract.id, item.Contract.title, item.Contract.type)" icon class="text-center ml-2" v-on="on">
                                <v-icon v-if="item.DocumentStatus.remarks.length === 0" class="grey--text text--darken-1">mdi mdi-plus</v-icon>
                                <v-icon v-else small class="grey--text text--darken-1">edit</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="item.DocumentStatus.remarks.length === 0">{{ $t('message.addRemarks') }}</span>
                        <span v-else>{{ $t('message.updateRemarks') }}</span>
                    </v-tooltip>
                </div>
            </template>
        </v-data-table>
        <DocumentStatusRemark
            :dialog.sync="remarksDialog"
            :contract-id="selectedContractId"
            :contract-no="selectedContractNo"
            :contract-type="selectedContractType"
            @dialog-closed="remarksDialogClosed"
            @update-done="remarksUpdated"
        />
        <SimpleAlert
            :alert_message.sync="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        ></SimpleAlert>
    </div>
</template>

<script>
import {formatDate, numberFormat} from "Helpers/helpers";
import {api} from "Api";
import {mapFields} from "vuex-map-fields";

const DocumentStatusRemark = () => import("Components/Appic/DocumentStatusRemark.vue");
const ExportTableJson = () => import("Components/Appic/ExportTableJson");
const SimpleAlert = () => import("Components/Appic/SimpleAlert");

export default {
    name: "DocumentStatusListV2",
    components: {DocumentStatusRemark, ExportTableJson, SimpleAlert},
    data() {
        return {
            currentFilterValues: null,
            currentSearchValue: null,
            dialogs: {
                error:  false,
                error_message: ""
            },
            filterItems: {
                buyer: [],
                supplier: []
            },
            filterValues: {
                buyer: null,
                supplier: null,
            },
            hideDefaultHeader: false,
            hideDefaultFooter: false,
            loading: {
                clear: false,
                filter: {
                    buyers: false,
                    suppliers: false,
                },
                filterItems: {
                    buyer: null,
                    supplier: null,
                },
                shipments: false,
            },
            remarksDialog: false,
            searching: false,
            searchValue: null,
            selectedContractNo: null,
            selectedContractId: null,
            selectedContractType: null,
            tableOptions: {
                sortBy: ['Contract.etddate','Contract.etadate'],
                sortDesc: [false, false]
            },
            totalShipments: 0,
            shipments: [],
        }
    },
    computed: {
        ...mapFields('runtime', {
            openedTabs: 'openedTabs'
        }),
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Contract.id',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    sortable: false,
                    searchable: true
                },
                {
                    id: 2,
                    text: this.$t('message.contract'),
                    value: 'Contract.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 5,
                    text: this.$t('message.type'),
                    value: 'Contract.type',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false,
                    searchable: true
                },
                {
                    id: 6,
                    text: this.$t('message.buyer'),
                    value: 'Customer.name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 7,
                    text: this.$t('message.supplier'),
                    value: 'Supplier.name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 8,
                    text: this.$t('message.origin'),
                    value: 'Origin.name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 9,
                    text: this.$t('message.destination'),
                    value: 'Destination.name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 11,
                    text: this.$t('message.invoice'),
                    value: 'Invoice.no',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 11,
                    text: this.$t('message.terms'),
                    value: 'Contract.type_insurance',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 11,
                    text: this.$t('message.etd'),
                    value: 'Contract.etddate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 12,
                    text: this.$t('message.eta'),
                    value: 'Contract.etadate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 13,
                    text: this.$t('message.docsCompleted'),
                    value: 'FullSet.uploaded',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 12,
                    text: this.$t('message.due'),
                    value: 'FullSet.duedate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 13,
                    text: this.$t('message.overdue'),
                    value: 'FullSet.overdue',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 14,
                    text: this.$t('message.awb'),
                    value: 'Awb.duedate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 15,
                    text: this.$t('message.overdue'),
                    value: 'Awb.overdue',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 16,
                    text: this.$t('message.remarks'),
                    value: 'DocumentStatus.remarks',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false,
                    searchable: false
                }
            ]
        },
        itemsPerPageOptions() {
            let options = [10,20,50,-1];
            if(window.screen.height >= 800){
                options = [15,30,50,-1];
            }
            return options;
        }
    },
    methods: {
        clearFilters() {
            this.loading.clear = true
            this.filterValues.buyer = null
            this.filterValues.supplier = null
            this.searching = false
            this.searchValue = null
            this.currentSearchValue = null
            this.tableOptions.page = 1
            this.tableOptions.sortBy[0] = 'Contract.etddate'
            this.tableOptions.sortBy[1] = 'Contract.etadate'
            this.tableOptions.sortDesc[0] = false
            this.tableOptions.sortDesc[1] = false
            this.loading.clear = false
            this.filterShipments()
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        filterShipments() {
            this.searchValues = null;

            let noValues = true;
            const keys = Object.keys(this.filterValues)
            keys.forEach((key, index) => {
                if(this.filterValues[key] != null) noValues = false
            })

            this.loading.shipments = true;

            if(noValues === false) {
                let conditions = []
                if(this.filterValues.buyer != null) conditions.push({field: 'Contract.buyer', value: this.filterValues.buyer})
                if(this.filterValues.supplier != null) conditions.push({field: 'Contract.supplier', value: this.filterValues.supplier})

                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                if(_.isEqual(this.currentFilterValues,this.filterValues) === false) {
                    this.tableOptions.page = 1
                    this.currentFilterValues = _.cloneDeep(this.filterValues)
                }

                if(_.has(this.tableOptions, 'sortBy') === false) {
                    this.tableOptions['sortBy'] = ['Contract.etddate','Contract.etadate']
                }
                if(this.tableOptions.sortBy.length === 0){
                    this.tableOptions['sortBy'] = ['Contract.etddate','Contract.etadate']
                }

                if(_.has(this.tableOptions, 'sortDesc') === false){
                    this.tableOptions['sortDesc'] = [false, false]
                }

                let order = [];

                this.tableOptions.sortBy.forEach((field, index) => {
                    order.push({field: field, direction: this.tableOptions.sortDesc[index] ? 'DESC' : 'ASC'})
                })

                const payload = {
                    tableOptions: this.tableOptions,
                    conditions: conditions,
                    order: order
                }

                this.searchAllShipments(payload)
                    .then(() => {
                        this.loading.shipments = false;
                    })
                    .catch(()=>{
                        this.loading.shipments = false;
                    })
            } else {
                this.reloadShipments()
                    .then(() => {
                        this.loading.shipments = false;
                    })
                    .catch(() => {
                        this.loading.shipments = false;
                    })
            }
        },
        formatDate,
        getAllShipments(payload) {
            return new Promise((resolve, reject) => {
                if(payload.tableOptions.page != null) {
                    api
                        .get("/reports/document-status", {
                            params: {
                                pagination: {
                                    skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                                    take: payload.tableOptions.itemsPerPage
                                },
                                order: payload.order
                            }
                        })
                        .then(response => {
                            this.shipments = response.data.data
                            this.totalShipments = response.data.totalRows
                            resolve('done')
                        })
                        .catch(error => {
                            reject(error);
                        });
                } else {
                    resolve('done')
                }
            })
        },
        loadShipments() {
            if(!this.loading.eudrShipments) this.loading.eudrShipments = true;

            if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

            const payload = {
                tableOptions: this.tableOptions
            }

            this.getAllShipments(payload)
                .then(() => {
                    this.loading.shipments = false;
                })
                .catch(() => {
                    this.loading.shipments = false;
                })
        },
        loadFilterItems(filter) {
            return new Promise((resolve, reject) => {
                if(filter && filter.length > 0) {
                    this.loading.filterItems[filter] = true
                    api
                        .get('/reports/document-status/filter-options/' + filter)
                        .then(response => {
                            if(response.data.status === 'success') {
                                this.filterItems[filter] = response.data.data
                                this.loading.filterItems[filter] = false
                                resolve('done')
                            } else {
                                this.loading.filterItems[filter] = false
                                reject('error')
                            }
                        })
                        .catch(error => {
                            this.loading.filterItems[filter] = false
                            reject(error)
                        })
                } else {
                    reject('Filter empty')
                }
            })
        },
        numberFormat,
        openRemarksDialog(contractId, contractNo, contractType) {
            this.remarksDialog = true
            this.selectedContractNo = contractNo
            this.selectedContractId = contractId
            this.selectedContractType = contractType
        },
        overDue (date) {
            const now = new Date()
            const testDate = new Date(date)
            if(now.getTime() > testDate.getTime()) return true
            return false
        },
        reloadShipments() {
            return new Promise((resolve, reject) => {
                try {
                    if(!this.loading.shipments) this.loading.shipments = true;

                    if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                    if(_.has(this.tableOptions, 'sortBy') === false) {
                        this.tableOptions['sortBy'] = ['Contract.etddate','Contract.etadate']
                    }
                    if(this.tableOptions.sortBy.length === 0){
                        this.tableOptions['sortBy'] = ['Contract.etddate','Contract.etadate']
                    }

                    if(_.has(this.tableOptions, 'sortDesc') === false){
                        this.tableOptions['sortDesc'] = [false, false]
                    }

                    let order = [];

                    this.tableOptions.sortBy.forEach((field, index) => {
                        order.push({field: field, direction: this.tableOptions.sortDesc[index] ? 'DESC' : 'ASC'})
                    })

                    const payload = {
                        tableOptions: this.tableOptions,
                        order: order
                    }

                    this.getAllShipments(payload)
                        .then(() => {
                            resolve('done')
                        })
                        .catch(() => {
                            reject('error')
                        })
                } catch (error) {
                    reject('error')
                }
            })
        },
        remarksDialogClosed() {
            this.remarksDialog = false;
        },
        remarksUpdated() {
            this.loadShipments()
        },
        resetSearch() {
            this.searching = false;
            this.hideDefaultFooter = false
            this.filterValues.buyer = null
            this.filterValues.supplier = null
            this.tableOptions.page = 1
            this.currentSearchValue = null;
            this.searchValue = null
            this.currentFilterValues = null

            this.tableOptions.sortBy = ['Contract.etddate','Contract.etadate']
            this.tableOptions.sortDesc = [false, false]

            if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage
            this.filterShipments();
        },
        searchAllShipments(payload) {
            return new Promise((resolve, reject) => {
                api
                    .get("/reports/document-status",{
                        params: {
                            conditions: payload.conditions,
                            pagination: {
                                skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                                take: payload.tableOptions.itemsPerPage
                            },
                            order: payload.order
                        }
                    })
                    .then(response => {
                        this.shipments = response.data.data
                        this.totalShipments = response.data.totalRows
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .then(()=>{
                        resolve('done')
                    });
            })
        },
        searchShipments() {
            if(this.searchValue == null || this.searchValue.length === 0){
                this.dialogs.error = true
                this.dialogs.error_message = this.$t('message.errors.noSearchTerm')
            } else {
                this.searching = true
                this.filterValues.buyer = null
                this.filterValues.supplier = null

                this.loading.shipments = true

                let payload = {}
                let order = []

                const fieldName = 'Contract.title';

                if (_.isEqual(this.currentSearchValue, this.searchValue) === false) {
                    //new search = new sort
                    this.tableOptions.sortBy[0] = fieldName
                    this.tableOptions.sortDesc[0] = false

                    order.push({field: fieldName, direction: 'ASC'})

                    this.currentSearchValue = _.cloneDeep(this.searchValue)

                    //new search new page
                    this.tableOptions.page = 1
                } else {
                    //same search new sort
                    if(this.tableOptions.sortBy.length === 0){
                        this.tableOptions.sortBy[0] = 'Contract.title'
                        this.tableOptions.sortBy[1] = 'Contract.etddate'
                        this.tableOptions.sortDesc[0] = false
                        this.tableOptions.sortDesc[1] = false
                    }

                    this.tableOptions.sortBy.forEach((field, index) => {
                        order.push({field: field, direction: this.tableOptions.sortDesc[index] ? 'DESC' : 'ASC'})
                    })
                }


                payload = {
                    tableOptions: this.tableOptions,
                    conditions: [
                        {
                            field: fieldName,
                            value: this.searchValue
                        }
                    ],
                    order: order
                }

                this.searchAllShipments(payload)
                    .then(() => {
                        this.loading.shipments = false;
                    })
                    .catch(()=>{
                        this.loading.shipments = false;
                    })
            }
        },
        updateDataTable(options) {
            this.tableOptions = options
            if(this.searching === true)  {
                this.searchShipments()
            } else {
                this.filterShipments()
            }
        },
        updateShipment (val) {
            let tab = window.open('/v1/shipments/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    mounted() {
        this.loadShipments()

        if(this.filterItems.buyer.length === 0) this.loadFilterItems('buyer')
        if(this.filterItems.supplier.length === 0) this.loadFilterItems('supplier')
    }
}
</script>

<style scoped>
.filter-width-150 {
    width: 150px !important;
    max-width: 150px !important;
}
.filter-width-190 {
    width: 190px !important;
    max-width: 190px !important;
}
</style>