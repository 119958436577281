<template>
    <v-dialog
        :width="600"
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ $t('message.remarks') + ": " + contractNo }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                        :value="loading.get"
                        absolute
                        opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="remarkForm" v-model="validForm">
                    <div class="d-flex flex-row align-center">
                        <div class="flex-grow-1">
                            <v-lazy>
                                <v-textarea
                                    :label="$t('message.newRemark')"
                                    :rules="[...validationRules.required, ...validationRules.paragraph]"
                                    :value="newRemark"
                                    auto-grow
                                    class="mt-3"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    rows="1"
                                    @change="newRemark = $event"
                                ></v-textarea>
                            </v-lazy>
                        </div>
                        <v-btn
                            :loading="loading.save"
                            class="ml-3 mt-3"
                            color="info"
                            small
                            @click="saveRemarks()"
                        >{{ $t('message.save') }}</v-btn>
                        <v-btn
                            class="ml-3 mt-3"
                            color="default"
                            small
                            @click="editDialog = false"
                        >{{$t('message.dismiss')}}</v-btn>
                    </div>
                </v-form>
                <v-data-table
                        :headers="headers"
                        :items="remarks"
                        :items-per-page="-1"
                        class="mt-3 appic-table-light specification-table"
                        hide-default-footer
                        disable-pagination
                        dense
                        v-if="remarks.length > 0"
                >
                    <template v-slot:item.Remark.date="{item}">
                        <div>{{ formatDate(item.Remark.date) }}</div>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="pl-7">
<!--                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>-->
<!--                <v-btn-->
<!--                        :loading="loading.save"-->
<!--                        color="info"-->
<!--                        small-->
<!--                        @click="saveRemarks()"-->
<!--                >{{ $t('message.save') }}</v-btn>-->
<!--                <v-spacer/>-->
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {api} from "Api";
import {formatDate} from "Helpers/helpers"
import {mapGetters} from "vuex";

export default {
    name: "AddAccountReceivableRemark",
    props: ['contractNo','contractId','contractType','dialog'],
    data(){
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            edit_dialog: false,
            loading: {
                get: false,
                save: false
            },
            newRemark: null,
            remarks: [],
            // rules: {
            //     remark: v => !!v || this.$t('message.required')
            // },
            validForm: true
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.previousRemark'),
                    value: 'Remark.text',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 2,
                    text: this.$t('message.date'),
                    value: 'Remark.date',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false,
                    searchable: false
                },
                // {
                //     id: 3,
                //     text: this.$t('message.action'),
                //     value: 'Remark.id',
                //     class: 'light-green lighten-3 pa-1 width-1-pct text-center',
                //     sortable: false,
                //     searchable: false
                // }
            ]
        }
    },
    methods: {
        async deleteRemark(val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteRemark') + ' ' +  title, this.$t('message.confirmations.continueRemarkDeleteAction'), {color: 'orange'})){
                let model = 'contracts'
                if(this.contractType == 'WH'){
                    model = 'localsales'
                }
                api.delete('/' + model + '/' + this.contractId + '/receivable-remark/' + val)
                    .then((response) => {
                        if(response.data.status == 'success'){
                            this.$toast.success(this.$t('message.successes.remarkDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.getAllRemarks()
                                .then(() => {
                                    if(this.remarks.length == 0){
                                        this.$emit('update-done')
                                        this.editDialog = false
                                    }
                                })
                        } else {
                            this.$toast.error(this.$t('message.errors.remarkNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.remarkNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        formatDate,
        getAllRemarks() {
            return new Promise((resolve, reject) => {
                let model = 'contracts'
                if(this.contractType == 'WH'){
                    model = 'localsales'
                }
                api
                    .get('/' + model + '/' + this.contractId + '/receivable-remark')
                    .then(response => {
                        if(response.data.status == 'success') {
                            this.remarks = response.data.data
                            resolve('done')
                        } else {
                            reject('error')
                        }
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        saveRemarks() {
            if(this.$refs.remarkForm.validate()) {
                this.loading.save = true
                let model = 'contracts'
                if(this.contractType == 'WH'){
                    model = 'localsales'
                }
                const remark = {
                    model: model,
                    model_id: this.contractId,
                    remark: this.newRemark
                }
                api
                    .post('/' + model + '/receivable-remark', {
                        remark: remark
                    })
                    .then(response => {
                        if(response.data.status == 'success') {
                            // const selectedBuyer = this.filteredBuyers.find(buyer => buyer.Customer.id == this.buyer_id)
                            this.$toast.success(this.$t('message.successes.remarkAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.$emit('update-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.remarkNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loading.save = false
                    })
                    .catch((e) => {
                        this.$toast.error(e,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.remarkNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
            return true
        }
    },
    watch: {
        dialog(value) {
            this.remarks = []
            this.newRemark = null
            if(value == true) {
                this.getAllRemarks()
            }
            this.edit_dialog = value
        }
    }
}
</script>

<style scoped>
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
    vertical-align: middle !important;
}
.v-data-table::v-deep th {
    white-space: nowrap;
}
</style>