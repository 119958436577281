<template>
    <div>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-tabs
                        v-model="shippingOverviewCurrentTab"
                        color="success"
                        background-color="grey lighten-2"
                        slider-color="appic-green"
                    >
                        <v-tab key="shp" @click="changeExportParams(0)">{{ $t('message.shipments') }}</v-tab>
                        <v-tab key="pl" @click="changeExportParams(1)">{{ $t('message.packingLists') }}</v-tab>
                        <v-tab key="si" @click="changeExportParams(2)">{{ $t('message.si') }}</v-tab>
                        <v-tab key="lca" @click="changeExportParams(3)">{{ $t('message.lcas') }}</v-tab>
                        <v-tab key="ipa" @click="changeExportParams(4)">{{ $t('message.ipas') }}</v-tab>
                        <v-tab key="ds" @click="changeExportParams(5)">{{ $t('message.shippingDocuments') }}</v-tab>
                        <v-tab key="eutr-eudr" @click="changeExportParams(5)">{{ $t('message.eutrEudr') }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="shippingOverviewCurrentTab">
                        <v-tab-item key="shp-list">
                            <v-card flat>
                                <ShipmentsListingNew/>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="pl-list">
                            <v-card flat>
                                <PackingListsListingNew/>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="si-list">
                            <v-card flat>
                                <ShippingInstructionsListingNew/>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="lca-list">
                            <v-card flat>
                                <LcasListingNew/>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="ipa-list">
                            <v-card flat>
                                <IpasListingNew/>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="ds-list">
                            <v-card flat>
                                <DocumentStatusListV2/>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="eutr-eudr-list">
                            <v-card flat>
                                <EudrDocumentStatusList/>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import {mapFields} from "vuex-map-fields";
    import LoadingComponent from "Components/Appic/LoadingComponent";

    const DocumentStatusListV2 = () => ({
        component: import("Components/Appic/DocumentStatusListV2"),
        loading: LoadingComponent
    });
    const ShipmentsListingNew = () => ({
        component: import("Components/Appic/ShipmentsListingNew"),
        loading: LoadingComponent
    });
    const PackingListsListingNew = () => ({
        component: import("Components/Appic/PackingListsListingNew"),
        loading: LoadingComponent
    });
    const IpasListingNew = () => ({
        component: import("Components/Appic/IpasListingNew"),
        loading: LoadingComponent
    });
    const LcasListingNew = () => ({
        component: import("Components/Appic/LcasListingNew"),
        loading: LoadingComponent
    });
    const ShippingInstructionsListingNew = () => ({
        component: import("Components/Appic/ShippingInstructionsListingNew"),
        loading: LoadingComponent
    });
    const EudrDocumentStatusList = () => ({
        component: import("Components/Appic/EudrDocumentStatusList.vue"),
        loading: LoadingComponent
    });

    export default {
        name: "ShipmentsOverview",
        components: {
            DocumentStatusListV2,
            EudrDocumentStatusList,
            IpasListingNew,
            LcasListingNew,
            PackingListsListingNew,
            ShipmentsListingNew,
            ShippingInstructionsListingNew
        },
        title: '',
        data() {
            return {
                loader: false,
                active: null,
                colsToDelete: {
                    start_col: 10,
                    ncols: 1
                },
                fileName: 'shipments',
                hasAddFunction: true,
                documentType: 'shp',
                document: 'shipments',
                tableId: 'shipmentsTable'
            }
        },
        computed: {
            ...mapFields('runtime',{
                shippingOverviewCurrentTab: 'shippingOverviewCurrentTab',
                openedTabs: 'openedTabs'
            })
        },
        methods: {
            changeExportParams(documentType) {
                switch (documentType) {
                    case 0:
                        this.colsToDelete = {start_col: 13, ncols: 1}
                        this.fileName = 'packing_lists'
                        this.hasAddFunction = true
                        this.document = 'packinglists'
                        this.documentType = 'pl'
                        this.tableId = 'packingListTable'
                        break;
                    case 1:
                        this.colsToDelete = {start_col: 13, ncols: 1}
                        this.fileName = 'packing_lists'
                        this.hasAddFunction = true
                        this.document = 'packinglists'
                        this.documentType = 'pl'
                        this.tableId = 'packingListTable'
                        break;
                    case 2:
                        this.colsToDelete = {start_col: 6, ncols: 1}
                        this.fileName = 'sdas'
                        this.hasAddFunction = true
                        this.document = 'sdas'
                        this.documentType = 'si'
                        this.tableId = 'shippingInstructionTable'
                        break;
                    case 3:
                        this.colsToDelete = {start_col: 9, ncols: 1}
                        this.fileName = 'lcas'
                        this.hasAddFunction = true
                        this.document = 'lcas'
                        this.documentType = 'lca'
                        this.tableId = 'letterOfCreditAdviceTable'
                        break;
                    case 4:
                        this.colsToDelete = {start_col: 8, ncols: 1}
                        this.fileName = 'ipas'
                        this.hasAddFunction = true
                        this.document = 'ipas'
                        this.documentType = 'ipa'
                        this.tableId = 'importPermitAdviceTable'
                        break;
                    case 5:
                        this.colsToDelete = {start_col: 9, ncols: 1}
                        this.fileName = 'document_status'
                        this.hasAddFunction = true
                        this.document = 'ds'
                        this.documentType = 'ds'
                        this.tableId = 'documentStatusTable'
                        break;
                }
            }
        },
        created(){
            this.changeExportParams(this.shippingOverviewCurrentTab)
        }
    }
</script>

<style scoped>
.v-tab {
    text-transform: none!important;
}
</style>