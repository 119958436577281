<template>
    <v-dialog
        width="400"
        max-width="400"
        persistent
        v-model="postDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ $t('message.uploadToXeroQueue') + ': ' + invoiceNumber }}</span>
            </v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="uploadForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs12 pl-3 py-1 class="align-center">
                            <v-checkbox
                                :label="$t('message.manualEntry')"
                                :true-value="true"
                                :false-value="false"
                                class="ml-1 black--text text--darken-1"
                                dense
                                hide-details="auto"
                                v-model="manualEntry"
                            ></v-checkbox>
                        </v-flex>
                    </v-layout>
                    <v-layout row v-if="!manualEntry">
                        <v-flex xs5 lg3 pl-5 py-1 class="align-center">{{ $t('message.accountNumber') }}</v-flex>
                        <v-flex xs7 lg9 py-1>
                            <v-select
                                :items="xeroAccounts"
                                :loading="loading.accounts"
                                :placeholder="$t('message.startTypingToSearch')"
                                :rules="[...validationRules.required]"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                item-text="title"
                                item-value="id"
                                v-model="xero_account_number"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row v-if="manualEntry">
                        <v-flex xs5 lg3 pl-5 py-1 class="align-center">{{ $t('message.accountNumber') }}</v-flex>
                        <v-flex xs7 lg9 py-1>
                            <v-text-field
                                :placeholder="$t('message.typeAccountNo')"
                                :rules="[...validationRules.required]"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                v-model="xero_account_number_manual"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg3 pl-5 py-1 class="align-center">{{ $t('message.journalSource') }}</v-flex>
                        <v-flex xs7 lg9 py-1>
                            <v-select
                                :items="xeroJournalSources"
                                :loading="loading.journalSources"
                                :placeholder="$t('message.startTypingToSearch')"
                                :rules="[...validationRules.required]"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                item-text="title"
                                item-value="id"
                                v-model="xero_journal_source_type"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="postDialog = false" >{{ $t('message.dismiss') }}</v-btn>
                <v-btn
                    :loading="loading.proceed"
                    color="info"
                    small
                    @click="postDocument()"
                >{{ $t('message.proceed') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {api} from "Api";

export default {
    name: "UploadDocumentToXero",
    props: ['dialog', 'invoiceNumber','jobId','documentUuid'],
    data() {
        return {
            loading: {
                accounts: false,
                journalSources: false,
                proceed: false
            },
            manualEntry: false,
            post_dialog: false,
            validForm: true,
            xeroAccounts: [],
            xero_account_number: null,
            xero_account_number_manual: null,
            xero_journal_source_type: null,
            xeroJournalSources: [],
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        postDialog: {
            get() {
                return this.post_dialog;
            },
            set(value){
                this.post_dialog = value
                if(value === false) {
                    this.$emit('dialog-closed')
                }
            }
        },
    },
    methods: {
        getXeroAccountNumbers() {
            this.loading.accounts = true;
            api
                .get('/xero/accounts', {
                    params: {
                        job_id: this.jobId
                    }
                })
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.xeroAccounts = response.data.data
                        this.xero_account_number = this.xeroAccounts[0]?.id
                    } else {
                        this.$toast.error(this.$t('message.errors.accountsNotLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading.accounts = false;
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.accountsNotLoaded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.accounts = false;
                })
        },
        getXeroJournalSources() {
            this.loading.journalSources = true;
            api
                .get('/xero/journal-sources', {
                    params: {
                        job_id: this.jobId
                    }
                })
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.xeroJournalSources = response.data.data
                        this.xero_journal_source_type = this.xeroJournalSources[0]?.id
                    } else {
                        this.$toast.error(this.$t('message.errors.sourcesNotLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading.journalSources = false;
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.sourcesNotLoaded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.journalSources = false;
                })
        },
        async postDocument() {
            if (this.$refs.uploadForm.validate()) {
                if (await this.$root.$confirm(this.$t('message.postToXero') + ' ' + this.invoiceNumber, this.$t('message.confirmations.continueUploadToXeroAction'), {color: 'orange'})) {
                    this.loading.proceed = true;
                    api
                        .put('/xero/post-document/' + this.jobId, {
                            xero_account_number: this.xero_account_number,
                            xero_account_number_manual: this.xero_account_number_manual,
                            xero_journal_source_type: this.xero_journal_source_type
                        })
                        .then((response) => {
                            if (response.data.status === 'success') {
                                this.$toast.success(this.$t('message.successes.documentUploaded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.$emit('posting-done')
                                this.postDialog = false;
                            } else {
                                this.$toast.error(this.$t('message.errors.documentNotUploaded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.proceed = false;
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.documentNotUploaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.proceed = false;
                        })
                }
            } else {
                this.$toast.error(this.$t('message.errors.documentNotUploaded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog(value) {
            if(value) {
                this.xero_account_number = null;
                this.getXeroAccountNumbers()
                this.getXeroJournalSources()
            }
            this.post_dialog = value;
        },
        manualEntry(value) {
            if(!value) this.xero_account_number_manual = null;
            if(value) this.xero_account_number = null;
        }
    },
}
</script>

<style scoped>

</style>