<template>
    <div style="position: relative">
        <v-overlay
            :value="loadingItems"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    />
                </v-col>
            </v-row>
        </v-overlay>
        <v-row no-gutters>
            <v-col cols="12" class="d-flex flex-row mt-3">
                <v-text-field
                    :label="$t('message.filterResults')"
                    :value="searchTerm"
                    autocomplete="off"
                    class="mt-2 mb-0 force-text-left"
                    prepend-inner-icon="mdi-filter-outline"
                    style="max-width: 300px !important;"
                    @change="searchTerm = $event"
                />
                <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
            </v-col>
        </v-row>
        <v-form lazy-validation ref="bundlesForm">
            <v-data-table
                :disable-pagination="true"
                :items-per-page="-1"
                :hide-default-footer="true"
                :headers="headers"
                :items="Bundles"
                :search="searchTerm"
                calculate-widths
                class="appic-table-light specification-table"
                dense
                disable-sort
                hide-default-header
                item-key="index"
                show-select
                v-model="allSelectedItems"
            >
                <template v-slot:header="{ props: { headers, everyItem, someItems }, on: headerOn }">
                    <tr>
                        <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[0].text }}</th>
                        <th class="grey lighten-3 pa-1 text-left">{{ headers[1].text }}</th>
                        <th class="grey lighten-3 pa-1 text-left">
                            <v-row no-gutters>
                                <v-col cols="6">{{ $t('message.product') }}</v-col>
                                <v-col cols="6" class="pl-1">{{ $t('message.spec') }}</v-col>
                            </v-row>
                        </th>
                        <th class="grey lighten-3 pa-1 text-left">
                            <v-row no-gutters>
                                <v-col cols="6">{{ $t('message.size') }}</v-col>
                                <v-col cols="6" class="pl-1">{{ $t('message.quantity') }}</v-col>
                            </v-row>
                        </th>
                        <th class="grey lighten-3 pa-1 text-center width-1-pct">
                            <template>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-simple-checkbox
                                            :value="everyItem"
                                            :indeterminate="someItems && !everyItem"
                                            v-on="on"
                                            v-bind="attrs"
                                            @input="headerOn['toggle-select-all']"
                                        />
                                    </template>
                                    <span>{{ $t('message.selectDeselectPriceEdit') }}</span>
                                </v-tooltip>
                            </template>
                        </th>
                        <th class="grey lighten-3 pa-1 text-left width-18-pct">
                            {{ headers[5].text }}
                            <v-btn small class="ml-6" @click.stop="openEditPricesDialog">{{ $t('message.editPrices') }}</v-btn>
                        </th>
                        <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[6].text }}</th>
                    </tr>
                </template>
                <template v-slot:item.lineNumber="{ item }">
                    <div class="text-center mt-2 font-16px">{{ item.lineNumber }}</div>
                </template>
                <template v-slot:item.Stock.bundleno="{ item }">
                    <div class="font-weight-bold mt-2 text-no-wrap font-16px">{{ item.Stock.bundleno }}</div>
                    <v-btn small @click="openEditItemDialog( item.index )" v-if="[3,2].includes(item.Stock.stockstate_id)">{{ $t('message.editItemInfo') }}</v-btn>
                    <span class="font-32px red--text text--darken-1 font-weight-bold" v-if="item.Stock.stockstate_id == 1">{{ $t('message.sold') }}</span>
                </template>
                <template v-slot:item.Stock.productgroup_id="{ item }">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.product') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Product.title }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.species') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Species.title }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1 mb-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.certification') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Stock.certification }}</v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6" class="pl-1">
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.grade') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Grade.title }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.spec') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Spec.title }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1 mb-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.mc') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Mc.title }}</v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.Stock.size_id="{ item }">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.thickness') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ getThickness(item.Stock.thickness_id) }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.width') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Stock.width }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1 mb-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.length') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Stock.length }}</v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px pl-1">{{ $t('message.pcs') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box text-right">{{ item.Stock.piece }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px pl-1">{{ $t('message.qty') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box text-right">{{ item.Stock.volume }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1 mb-1">
                                <v-col cols="5" class="font-16px pl-1">{{ $t('message.unit') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box text-right">{{ item.Soldmeasurement.title }}</v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.data-table-select="{ item, isSelected, select }">
                    <template v-if="[3,2].includes(item.Stock.stockstate_id)">
                        <v-simple-checkbox :value="isSelected" @input="select($event)"/>
                    </template>
                    <template v-else>
                        <div class="ml-2 mt-1 font-16px">{{ $t('message.na') }}</div>
                    </template>
                </template>
                <template v-slot:item.Stock.costprice="{ item }">
                    <v-row no-gutters class="pt-1">
                        <v-col cols="5" class="font-16px">{{ $t('message.buyingPrice') + ":" }}</v-col>
                        <v-col cols="5" class="font-16px input-box text-right">{{ formatThisNumber(item.Stock.costprice, currencyFormatPrecision) }}</v-col>
                        <v-col cols="2" class="font-16px input-box">{{ getCurrencyCode(StockOrder__currency_id)  }}</v-col>
                    </v-row>
<!--                    <v-row no-gutters class="pt-1">-->
<!--                        <v-col cols="5" class="font-16px">{{ $t('message.importDuty') + ":" }}</v-col>-->
<!--                        <v-col cols="5" class="font-16px input-box text-right">{{ formatThisNumber(item.Stock.importduty, currencyFormatPrecision) }}</v-col>-->
<!--                        <v-col cols="2" class="font-16px input-box">{{ getCurrencyCode(StockOrder__currency_id)  }}</v-col>-->
<!--                    </v-row>-->
<!--                    <v-row no-gutters class="pt-1">-->
<!--                        <v-col cols="5" class="font-16px">{{ $t('message.storageCost') + ":" }}</v-col>-->
<!--                        <v-col cols="5" class="font-16px input-box text-right">{{ formatThisNumber(item.Stock.warehousecost, currencyFormatPrecision) }}</v-col>-->
<!--                        <v-col cols="2" class="font-16px input-box">{{ getCurrencyCode(StockOrder__currency_id)  }}</v-col>-->
<!--                    </v-row>-->
<!--                    <v-row no-gutters class="pt-1 mb-1">-->
<!--                        <v-col cols="5" class="font-16px">{{ $t('message.freightCost') + ":" }}</v-col>-->
<!--                        <v-col cols="5" class="font-16px input-box text-right">{{ formatThisNumber(item.Stock.forwardingcharge, currencyFormatPrecision) }}</v-col>-->
<!--                        <v-col cols="2" class="font-16px input-box">{{ getCurrencyCode(StockOrder__currency_id)  }}</v-col>-->
<!--                    </v-row>-->
                </template>
                <template v-slot:item.Stock.id="{ item }">
                    <div class="d-flex flex-column">
                        <template v-if="[3,2].includes(item.Stock.stockstate_id)">
                            <v-tooltip bottom v-if="item.Stock.id == null">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon @click="deleteBundle(item.index, item.Stock.id, item.Stock.bundleno)" v-on="on">
                                        <v-icon color="grey">delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.deleteThisItem') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="item.Stock.id != null">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon @click="openDeleteBundleDialog(item.index, item.Stock.id, item.Stock.bundleno)" v-on="on">
                                        <v-icon color="grey">delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.deleteThisItem') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon @click="copyBundle(item.index)" v-on="on">
                                        <v-icon color="grey" class="mdi mdi-content-duplicate"></v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.copyThisItem') }}</span>
                            </v-tooltip>
                        </template>
                    </div>
                </template>
                <template v-slot:footer>
                    <div class="pt-0 d-flex justify-space-between">
                        <div class="col-7 col-xs-12 pb-0 px-0 d-flex flex-row align-centers align-end">
                            <v-btn
                                color="success"
                                small
                                @click="addBundleRow(statePrefix)"
                            >{{ Bundles.length === 0 ? $t('message.addBundle') : $t('message.addMore') }}
                            </v-btn>
                            <v-btn
                                :loading="resettingStockOrderBundles"
                                class="ml-2"
                                color="default"
                                small
                                v-if="updateMode === false"
                                @click="resetBundles()"
                            ><v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                            </v-btn>
                            <div class="d-flex flex-row">
                                <v-radio-group
                                    class="mt-0 ml-5"
                                    dense
                                    hide-details="auto"
                                    row
                                    v-model="aiUpload"
                                >
                                    <v-radio :label="'AI'" :value="true"/>
                                    <v-radio :label="'Excel'" :value="false"/>
                                </v-radio-group>
                            </div>
                            <template v-if="aiUpload">
                                <v-btn
                                    class="ml-2"
                                    color="blue lighten-4"
                                    small
                                    @click="openUploadByTallySheetDialog"
                                ><v-icon color="black darken-1">mdi-upload</v-icon> {{ $t('message.uploadBundlesByTallySheet') }}
                                </v-btn>
                            </template>
                            <template v-if="aiUpload === false">
                                <v-btn small class="ml-2" :href="stockUploadTemplateFile">
                                    <v-icon color="black darken-1">mdi-download</v-icon> {{ $t('message.getExcelFileSample') }}
                                </v-btn>
                                <v-btn
                                    class="ml-2"
                                    color="blue lighten-4"
                                    small
                                    @click="uploadByExcelDialog = true"
                                ><v-icon color="black darken-1">mdi-upload</v-icon> {{ $t('message.uploadBundlesByExcelFile') }}
                                </v-btn>
                            </template>
                        </div>
                        <div class="dense-inputs col-5 col-xs-12 pa-0">
                            <v-row class="justify-end">
                                <v-col cols="2" lg="2" md="2" xs="6" class="pb-0">
                                    <div class="text-right font-sm font-weight-black">{{ $t('message.currency') }}</div>
                                    <v-select
                                        :items="allCurrencies"
                                        autocomplete="off"
                                        class="custom pt-0 pb-2 mt-0 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="Currency.code"
                                        item-value="Currency.id"
                                        reverse
                                        v-model="StockOrder__currency_id"
                                    ></v-select>
                                </v-col>
                                <v-col cols="2" lg="2" md="2" xs="6" class="pb-0">
                                    <div class="text-right font-sm font-weight-black">
                                        <template v-if="stockOrderCurrency != null && stockOrderCurrency.Currency.code !== officeCurrency.Currency.code">
                                            {{ stockOrderCurrency.Currency.code + " : " + officeCurrency.Currency.code + " " + $t('message.exRate') }}
                                        </template>
                                        <template v-else-if="stockOrderCurrency != null && stockOrderCurrency.Currency.code === officeCurrency.Currency.code">
                                            {{ "USD : " + stockOrderCurrency.Currency.code + " " + $t('message.exRate') }}
                                        </template>
                                        <template v-else>
                                            {{ "USD : " + officeCurrency.Currency.code + " " + $t('message.exRate') }}
                                        </template>
                                    </div>
                                    <v-text-field
                                        :value="StockOrder__currencyrate"
                                        autocomplete="off"
                                        class="custom pt-0 pb-2 mt-0 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        type="number"
                                        v-if="stockOrderCurrency != null && stockOrderCurrency.Currency.code !== officeCurrency.Currency.code"
                                        @change="StockOrder__currencyrate = $event"
                                    >
                                    </v-text-field>
                                    <v-text-field
                                        :value="StockOrder__currencyrate_alt"
                                        autocomplete="off"
                                        class="custom pt-0 pb-2 mt-0 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        type="number"
                                        v-else-if="stockOrderCurrency != null && stockOrderCurrency.Currency.code === officeCurrency.Currency.code"
                                        @change="StockOrder__currencyrate_alt = $event"
                                    >
                                    </v-text-field>
                                    <v-text-field
                                        :value="StockOrder__currencyrate"
                                        autocomplete="off"
                                        class="custom pt-0 pb-2 mt-0 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        type="number"
                                        v-else
                                        @change="StockOrder__currencyrate = $event"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </template>
            </v-data-table>
        </v-form>
        <TallySheetUpload
            :purchase-order-id="StockOrder__purchaseorder_id"
            :stock-order-id="StockOrder__id"
            :dialog.sync="uploadByTallySheetDialog"
            :office-id="StockOrder__office_id"
            :update-mode="updateMode"
            @dialog-closed="uploadByTalySheetDialogClosed"
            @upload-done="bundlesUploaded"
        />
        <BundleExcelUploadV2
            :stock-order-id="StockOrder__id"
            :dialog.sync="uploadByExcelDialog"
            :update-mode="updateMode"
            @dialog-closed="uploadByExcelDialogClosed"
            @upload-done="bundlesUploaded"
        />
        <StockBundlePriceUpdate
            :currency-id="StockOrder__currency_id"
            :dialog.sync="editPricesDialog"
            :selected-items="selectedItems"
            :update-mode="updateMode"
            @dialog-closed="editPricesDialogClosed"
            @update-done="bundlePriceUpdated"
        />
        <StockBundleUpdate
            :currency-id="StockOrder__currency_id"
            :dialog.sync="editItemDialog"
            :item-index="selectedItemIndex"
            :update-mode="updateMode"
            :ms-id="StockOrder__ms_id"
            @dialog-closed="editBundleDialogClosed"
            @update-done="bundleUpdated"
        />
        <DeleteBundle
            :bundle-id="selectedBundleId"
            :bundle-no="selectedBundleNo"
            :dialog.sync="deleteDialog"
            @dialog-closed="deleteDialogClosed"
            @delete-done="bundleDeleted"
        />
    </div>
</template>

<script>

import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {api} from "Api";
import {mapFields} from "vuex-map-fields";
import {numberFormat} from "Helpers/helpers";

const BundleExcelUploadV2 = () => import("Components/Appic/BundleExcelUploadV2");
const DeleteBundle = () => import("Components/Appic/DeleteBundle.vue");
const StockBundlePriceUpdate = () => import("Components/Appic/StockBundlePriceUpdate");
const StockBundleUpdate = () => import("Components/Appic/StockBundleUpdate");
const TallySheetUpload = () => import("Components/Appic/TallySheetUpload");

export default {
    name: "BundleListV3",
    components: {
        BundleExcelUploadV2,
        DeleteBundle,
        StockBundlePriceUpdate,
        StockBundleUpdate,
        TallySheetUpload
    },
    props: ['updateMode','loadOptions','validate'],
    data() {
        return {
            aiUpload: true,
            allItemsSelected: false,
            allSelectedItems: [],
            deleteDialog: false,
            editItemDialog: false,
            editPricesDialog: false,
            editBundleDialog: false,
            filteredThickness: [],
            loading: {
                filterResults: false
            },
            loadingItems: false,
            uploadByExcelDialog: false,
            uploadByTallySheetDialog: false,
            warehouseVars: {
                importDuties: {},
                storageCost: 0
            },
            resettingStockOrderBundles: false,
            searchTerm: null,
            selectedBundleIndex: null,
            selectedItemIndex: null,
            selectedBundleId: null,
            selectedBundleNo: null
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('stockorder',{
            StockOrder__currency_id: 'StockOrder.currency_id',
            StockOrder__currencyrate: 'StockOrder.currencyrate',
            StockOrder__currencyrate_alt: 'StockOrder.currencyrate_alt',
            StockOrder__currencyratedate: 'StockOrder.currencyratedate',
            StockOrder__forwardingcost: 'StockOrder.forwardingcost',
            StockOrder__id: 'StockOrder.id',
            StockOrder__lmeasurement_id: 'StockOrder.lmeasurement_id',
            StockOrder__manual_entry: 'StockOrder.manual_entry',
            StockOrder__ms_id: 'StockOrder.ms_id',
            StockOrder__office_id: 'StockOrder.office_id',
            StockOrder__purchaseorder_id: 'StockOrder.purchaseorder_id',
            StockOrder__smeasurement_id: 'StockOrder.smeasurement_id',
            StockOrder__vmeasurement_id: 'StockOrder.vmeasurement_id',
            StockOrder__warehousecost: 'StockOrder.warehousecost',
            StockOrder__wmeasurement_id: 'StockOrder.wmeasurement_id',
            StockOrder__Contract__productorigin_id: 'StockOrder.Contract.productorigin_id',
        }, 'statePrefix'),
        ...mapFields('stockorder',{
            newStockOrderSaved: 'newStockOrderSaved',
            newStockOrderUnsaved: 'newStockOrderUnsaved',
            updatedStockOrderSaved: 'updatedStockOrderSaved',
            updatedStockOrderUnsaved: 'updatedStockOrderUnsaved',
        }),
        ...mapMultiRowFieldsPrefixed('stock',{
            Bundles: 'Bundles'
        }, 'statePrefix'),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('productgroup', {
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters('thickness', {
            allThickness: 'allThickness'
        }),
        ...mapGetters([
            'offices',
            'uofmQty',
            'uofmDimensions',
            'validationRules'
        ]),
        currencyFormatPrecision () {
            return this.allCurrencies.find((currency) => currency.Currency.id === this.StockOrder__currency_id)?.Currency?.formatPrecision
        },
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.no'),
                    value: 'lineNumber'
                },
                {
                    id: 2,
                    text: this.$t('message.bundle'),
                    value: 'Stock.bundleno'
                },
                {
                    id: 3,
                    text: this.$t('message.product'),
                    value: 'Stock.productgroup_id'
                },
                {
                    id: 4,
                    text: this.$t('message.size'),
                    value: 'Stock.size_id'
                },
                {
                    id: 5,
                    text: '',
                    value: 'data-table-select'
                },
                {
                    id: 6,
                    text: this.$t('message.pricing'),
                    value: 'Stock.costprice'
                },
                {
                    id: 7,
                    text: this.$t('message.action'),
                    value: 'Stock.id'
                }
            ]
        },
        officeCurrency () {
            let currency = {
                Currency: {
                    id: 1,
                    code: 'MYR',
                    title: 'Malaysian Ringgit',
                    symbol: 'RM',
                    formatPrecision: '0,0.00',
                    decimals: 2
                }
            }

            const office = this.offices.find(o => o.Office.id === this.StockOrder__office_id)
            if(this.allCurrencies.length > 0) {
                if (office) {
                    currency = this.allCurrencies.find(c => c.Currency.id === office.Office.currency_id)
                } else {
                    currency = this.allCurrencies.find(c => c.Currency.id === 1)
                }
            }
            return currency;
        },
        selectedItems() {
            let indexArray = []
            this.allSelectedItems.forEach(item => {
                indexArray.push(item.index)
            })
            return indexArray
        },
        stockOrderCurrency () {
            let currency = {Currency: {id: 1, code: 'MYR', title: 'Malaysian Ringgit', symbol: 'RM', formatPrecision: '0,0.00', decimals: 2}}
            if(this.StockOrder__currency_id) {
                currency = this.allCurrencies.find(c => c.Currency.id === this.StockOrder__currency_id)
            } else {
                currency = this.allCurrencies.find(c => c.Currency.id === 1)
            }
            return currency
        },
        stockUploadTemplateFile() {
            let ms = 'metric'
            if(this.StockOrder__ms_id === 2){
                ms = 'imperial'
            }
            if(this.StockOrder__purchaseorder_id) {
                return process.env.VUE_APP_API_BASE_URL + '/stockorders/stock-upload-template/' + ms + '/' + this.StockOrder__purchaseorder_id
            } else {
                return process.env.VUE_APP_API_BASE_URL + '/stockorders/stock-upload-template/' + ms
            }
        }
    },
    methods: {
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        ...mapActions('productgroup', {
            getAllProductGroups: 'getAllProductGroups'
        }),
        ...mapActions('stock',{
            addBundleRow: 'addBundleRow',
            deleteStockOrderBundle: 'deleteStockOrderBundle',
            getBundlesByStockOrder: 'getBundlesByStockOrder',
            resetStockOrderBundles: 'resetStockOrderBundles',
            updateBundleStatus: 'updateBundleStatus'
        }),
        ...mapMutations('stock',{
            deleteStockOrderBundleRow: 'deleteStockOrderBundleRow',
            copyStockOrderBundleRow: 'copyStockOrderBundleRow'
        }),
        ...mapActions('thickness', {
            getAllThickness: 'getAllThickness'
        }),
        bundleDeleted() {
            this.deleteDialog = false;
            const payload = {
                prefix: this.statePrefix,
                stockOrderId: this.StockOrder__id
            }
            this.getBundlesByStockOrder(payload)
                .then( () => {
                    this.updatedStockOrderUnsaved = false
                    this.loadingItems = false
                })
                .catch(() => {
                    this.updatedStockOrderUnsaved = false
                    this.loadingItems = false
                })
        },
        bundlesUploaded () {
            this.uploadByExcelDialog = false;
            this.uploadByTallySheetDialog = false;
            this.$emit('bundles-uploaded')
        },
        copyBundle(itemIndex) {
            const promise = new Promise((resolve, reject) => {
                try {
                    let payload = {
                        index: itemIndex,
                        prefix: this.statePrefix
                    }
                    this.copyStockOrderBundleRow(payload)
                    resolve('ok')
                } catch(err) {
                    reject(err)
                }
            });
            this.loadingItems = true
            promise
                .then(() => {
                    this.loadingItems = false
                })
                .catch(() => {
                    this.loadingItems = false
                })
        },
        async deleteBundle( itemIndex, itemId, bundleNo ) {
            if (await this.$root.$confirm(this.$t('message.delete') + " " + bundleNo, this.$t('message.confirmations.continueDeleteBundleAction'), {color: 'red'})) {
                const promise = new Promise((resolve, reject) => {
                    const payload = {
                        index: itemIndex,
                        prefix: this.statePrefix
                    }
                    this.deleteStockOrderBundleRow(payload)
                    resolve('ok')
                });
                this.loadingItems = true
                promise
                    .then(() => {
                        if(itemId != null){
                            this.deleteStockOrderBundle({
                                    stockOrderId: this.StockOrder__id,
                                    itemId: itemId
                                })
                                .then(()=>{
                                    let payload = {
                                        prefix: this.statePrefix,
                                        stockOrderId: this.StockOrder__id
                                    }
                                    this.getBundlesByStockOrder(payload)
                                        .then( () => {
                                            this.updatedStockOrderUnsaved = false
                                            this.loadingItems = false
                                        })
                                        .catch(() => {
                                            this.updatedStockOrderUnsaved = false
                                            this.loadingItems = false
                                        })
                                })
                                .catch(() => {
                                    this.updatedStockOrderUnsaved = false
                                    this.loadingItems = false
                                })
                        } else {
                            this.loadingItems = false
                        }
                    })
                    .catch(() => {
                        this.updatedStockOrderUnsaved = false
                        this.loadingItems = false
                    })
            }
        },
        deleteDialogClosed() {
            this.deleteDialog = false;
        },
        editBundleDialogClosed () {
            this.editItemDialog = false;
        },
        editPricesDialogClosed () {
            this.editPricesDialog = false;
        },
        bundleUpdated (value) {
            this.editItemDialog = false;
            this.$emit('reload-item-list', value)
        },
        bundlePriceUpdated (value) {
            this.editPricesDialog = false;
            this.$emit('reload-item-list', value)
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        filterThickness(ms) {
            let various = this.allThickness.find(t => {
                return t.Thickness.ms == ms && t.Thickness.title == 'various'
            })
            let thicknesses = this.allThickness.filter(t => {
                return t.Thickness.ms == ms && t.Thickness.title != 'various'
            })
            thicknesses.sort((a,b) => {
                let strA = a + ''
                let strB = b + ''
                return strA.localeCompare(strB, undefined, { numeric: true })
            })
            if(various){
                this.filteredThickness = [various,...thicknesses]
            } else {
                this.filteredThickness = thicknesses
            }
        },
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getCurrencyCode (currency_id) {
            let code = this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
            return code !== undefined ? code : ''
        },
        getThickness (thicknessId) {
            let thickness = ''
            const obj = this.filteredThickness.find(t => t.Thickness.id == thicknessId)
            if(obj){
                thickness = obj.Thickness?.title
            }
            return thickness
        },
        getWarehouseVars() {
            return new Promise((resolve, reject) => {
                api
                    .get('/warehouse-vars/' + this.StockOrder__office_id + '/all')
                    .then((response) => {
                        if(response.data.status === 'success'){
                            const vars = response.data.data
                            if(vars){
                                this.warehouseVars.importDuties = vars.import_duties
                                this.warehouseVars.storageCost = vars.storage_cost
                            }
                            resolve('done')
                        } else {
                            reject(response.data.status)
                        }
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        itemPricesUpdated (value) {
            this.editPricesDialog = false;
            this.$emit('reload-item-list', value)
        },
        itemUpdated (value) {
            this.editItemDialog = false;
            this.$emit('reload-item-list', value)
        },
        openDeleteBundleDialog(bundleIndex, bundleId, bundleNo) {
            this.selectedBundleId = bundleId
            this.selectedBundleNo = bundleNo
            this.deleteDialog = true
        },
        openEditItemDialog(itemIndex) {
            this.selectedItemIndex = itemIndex
            this.editItemDialog = true
        },
        openEditPricesDialog() {
            if(this.allSelectedItems.length > 0) {
                this.editPricesDialog = true
            } else {
                this.$toast.error(this.$t('message.errors.noItemsSelected'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        openUploadByTallySheetDialog() {
            if(this.StockOrder__manual_entry == null && this.StockOrder__manual_entry.toString() !== '1') {
                if (this.StockOrder__purchaseorder_id) {
                    this.uploadByTallySheetDialog = true;
                } else {
                    this.$toast.error(this.$t('message.errors.noPuchaseOrderSelected'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            } else {
                this.uploadByTallySheetDialog = true;
            }
        },
        async resetBundles () {
            if (await this.$root.$confirm(this.$t('message.resetStockArrivalBundles'), this.$t('message.confirmations.continueStockArrivalBundlesReset'), {color: 'orange'})) {
                this.resettingStockOrderBundles = true
                this.resetStockOrderBundles(this.statePrefix)
                    .then(() => {
                        this.resettingStockOrderBundles = false
                    })
            }
        },
        uploadByExcelDialogClosed() {
            this.uploadByExcelDialog = false;
        },
        uploadByTalySheetDialogClosed() {
            this.uploadByTallySheetDialog = false;
        }
    },
    watch: {
        StockOrder__ms_id(value) {
            this.filteredUnits = this.uofmQty.filter(u => {
                return u.Measurement.system == value || u.Measurement.system == null
            })
            this.filterThickness(value)
        },
        validate(value) {
            if(value){
                // let valid = this.$refs.bundlesForm.validate()
                // if(valid) {
                //     this.$emit('items-valid')
                // } else {
                //     this.$emit('items-invalid')
                // }
                this.$emit('items-valid')
            }
        }
    },
    created () {
        // if(this.allSpecies.length == 0) this.getAllSpecies()
        // if(this.allGrades.length == 0) this.getAllGrades()
        // if(this.allCertifications.length == 0) this.getAllCertifications()
        if(this.allCurrencies.length === 0) this.getAllCurrencies()
        // if(this.allMoistureContent.length == 0) this.getAllMoistureContent()
        if(this.allProductGroups.length === 0) this.getAllProductGroups()
        // if(this.allCleanedSpecs.length == 0) this.getAllCleanedSpecs()
        // if(this.allSizes.length == 0) this.getAllSizes()
        if(this.allThickness.length === 0) this.getAllThickness()
        this.getWarehouseVars()
    },
    mounted() {
        this.$refs.bundlesForm.resetValidation()
        this.$store.watch(
            function (state) {
                return state.appic.stock.update.Bundles
            },
            () => {
                this.updatedStockOrderUnsaved = true
            },
            {deep: true}
        )
        this.$store.watch(
            function (state) {
                return state.appic.stock.current.Bundles
            },
            () => {
                this.newStockOrderUnsaved = true
            },
            {deep: true}
        )
        this.$nextTick(function () {
            this.filteredUnits = this.uofmQty.filter(u => {
                return u.Measurement.system == this.StockOrder__ms_id || u.Measurement.system == null
            })
            this.filterThickness(this.StockOrder__ms_id)
            this.$emit('options-reloaded')
        })
    },
    updated() {
        this.filteredUnits = this.uofmQty.filter(u => {
            return u.Measurement.system == this.StockOrder__ms_id || u.Measurement.system == null
        })
        this.filterThickness(this.StockOrder__ms_id)
        this.$emit('options-reloaded')
    }
}
</script>

<style>
.width-12-pct {
    width: 12%;
}
.width-18-pct {
    width: 18%;
}
.width-7-pct {
    width: 7%;
}
.width-25-pct {
    width: 25%;
}
.width-20-pct {
    width: 20%;
}
.width-40-pct {
    width: 40%;
}
.width-45-pct {
    width: 45%;
}
.bg-lightgrey {
    background-color: #F5F5F5;
}
.col-lg-4-5 {
    flex: 0 0 37.5;
    max-width: 37.5% !important;
}
.col-lg-7-5 {
    flex: 0 0 62.5;
    max-width: 62.5% !important;
}
.v-text-field__slot input {
    text-align: right;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
.v-data-table td {
    padding: 0 5px;
}
.font-16px {
    font-size: 16px !important;
    line-height: 20px;
}
.font-32px {
    font-size: 32px !important;
    line-height: 40px;
}
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.custom.v-select .v-icon {
    font-size: 18px !important;
}
.truncate {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table tbody td {
    vertical-align: top !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px !important;
}
.dense-inputs .v-input {
    font-size: 16px !important;
}
.input-box {
    background-color: #EEEEEE;
    padding-left: 5px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    min-height: 26px !important;
}
</style>