<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? (Contract__version != null ? [Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-') : Contract__title) : ''" help-page-component="AddContract"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <ContractAlert
                        :alert="$t('message.closeAlert')"
                        type="warning"
                        class="mt-3"
                        mode="add"
                        v-if="addMode && newContractUnsaved"
                />
                <ContractAlert
                        :alert="$t('message.closeAlert')"
                        type="warning"
                        class="mt-3"
                        mode="update"
                        v-if="updateMode && updatedContractUnsaved"
                />
                <app-card
                        :fullBlock="true"
                        colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation ref="contractForm" v-model="validForm">
                        <v-overlay
                                :value="loader"
                                absolute
                                opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                            color="primary"
                                            indeterminate
                                            size="40"
                                            width="6"
                                    />
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                                accordion
                                v-model="panel"
                                multiple
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <ContractGeneralInfo v-if="loader"/>
                                    <template v-if="!loader">
                                        <v-layout row pt-6 v-if="false">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadActiveContract') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items="allActiveContracts"
                                                        :loading="loadingActiveContract"
                                                        :placeholder="$t('message.startTypingToSearch')"
                                                        dense
                                                        hide-details="auto"
                                                        item-value="Contract.id"
                                                        item-text="Contract.title"
                                                        solo
                                                        @click="loadingActiveContract = true"
                                                        @blur="loadingActiveContract = false"
                                                        @change="loadAnotherContract($event)"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-1">
                                                            <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                            <span class="font-xs"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                            <span class="font-xs"> {{ $t('message.supplier') + ': ' + item.Supplier.title }} </span>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row pt-3 pb-0>
                                            <v-flex xs6 lg2 md3 pl-5 pt-1 pb-0 class="align-center">{{ $t('message.date') }}</v-flex>
                                            <v-flex xs6 lg4 md6 pt-1 pb-0>
                                                <v-menu
                                                        ref="contractDateMenu"
                                                        v-model="contractDateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        max-width="290px"
                                                        min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                                :label="Contract__contractdate ? '' : $t('message.required')"
                                                                :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                                :value="computedContractDateFormatted"
                                                                append-icon="event"
                                                                background-color="white"
                                                                class="force-text-left mb-0"
                                                                clearable
                                                                hide-details="auto"
                                                                hint="DD/MM/YY format"
                                                                persistent-hint
                                                                readonly
                                                                solo
                                                                v-on="on"
                                                                @click:clear="Contract__contractdate = null"
                                                                @click:append="contractDateMenu = true"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                            v-model="Contract__contractdate"
                                                            @input="contractDateMenu = false"
                                                            locale-first-day-of-year="4"
                                                            show-week
                                                            first-day-of-week="1"
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.company') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="offices"
                                                        :rules="[...validationRules.required]"
                                                        :label="Contract__office_id ? '' : $t('message.required')"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Office.title"
                                                        item-value="Office.id"
                                                        solo
                                                        v-model="Contract__office_id"
                                                        @change="officeChanged"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.salesColleague') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items="allSalesColleagues"
                                                        :rules="[...validationRules.required]"
                                                        :label="Contract__salescontact_id ? '' : $t('message.required')"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Salesperson.name"
                                                        item-value="Salesperson.id"
                                                        solo
                                                        v-model="Contract__salescontact_id"
                                                >
                                                    <template v-slot:item="data">
                                                        <template>
                                                            <v-list-item-avatar>
                                                                <img :src="data.item.User.photo ? ('data:image/jpeg;base64,' + data.item.User.photo) : '/static/avatars/appic_user.jpg'" alt="avatar" height="35" width="35" class="img-responsive rounded-circle">
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title v-html="data.item.Salesperson.name"></v-list-item-title>
                                                                <v-list-item-subtitle v-html="data.item.Country.name"></v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.type') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items=filteredSalesTypes
                                                        :rules="[...validationRules.required]"
                                                        :label="Contract__salestype_id ? '' : $t('message.required')"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Salestype.title"
                                                        item-value="Salestype.id"
                                                        solo
                                                        v-model="Contract__salestype_id"
                                                        @change="salesTypeChanged"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="[2].includes(Contract__salestype_id)">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.seller') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items=allSuppliers
                                                        :label="Contract__supplier_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Supplier.title"
                                                        item-value="Supplier.id"
                                                        solo
                                                        v-model="Contract__supplier_id"
                                                        @change="supplierChanged"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items=allBuyers
                                                        :label="Contract__customer_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Customer.title"
                                                        item-value="Customer.id"
                                                        solo
                                                        v-model="Contract__customer_id"
                                                        @change="buyerChanged"
                                                >
                                                    <template v-slot:selection="{ item }">
                                                        <div>{{ item.Customer.otsname != null ? item.Customer.otsname : item.Customer.title }}</div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center" v-if="[1,2,5].includes(Contract__salestype_id)">{{ $t('message.buyerTerms') }}</v-flex>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center" v-if="[3,6].includes(Contract__salestype_id)">{{ $t('message.supplierTerms') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 v-if="[1,2,5].includes(Contract__salestype_id)">
                                                <v-autocomplete
                                                        :items="allBuyerPaymentTerms"
                                                        :label="Contract__paymentstatement_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-value="Paymentterm.id"
                                                        item-text="Paymentterm.title"
                                                        solo
                                                        v-model="Contract__paymentstatement_id"
                                                >
                                                    <template v-slot:item="{ item }">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                            <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{ item }">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                            <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                            <v-flex xs6 lg4 md6 py-1 v-if="[3,6].includes(Contract__salestype_id)">
                                                <v-autocomplete
                                                        :items="allSupplierPaymentTerms"
                                                        :label="Contract__Purchaseorder__paymentstatement_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-value="Paymentterm.id"
                                                        item-text="Paymentterm.title"
                                                        solo
                                                        v-model="Contract__Purchaseorder__paymentstatement_id"
                                                >
                                                    <template v-slot:item="{ item }">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                            <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{ item }">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                            <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="Contract__salestype_id == 2">
                                            <v-flex xs6 lg2 md3 pl-5 py-1  class="align-center">{{ $t('message.commissionTerms') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="commissionTerms"
                                                        :label="Contract__commission_term_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.requiredWithZero]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Contract__commission_term_id"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="Contract__salestype_id == 2">
                                            <v-flex xs6 lg2 md3 pl-5 py-1  class="align-center">{{ $t('message.commissionSource') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="commissionSources"
                                                        :label="Contract__commission_term_against_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.requiredWithZero]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Contract__commission_term_against_id"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="[1,5].includes(Contract__salestype_id)">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.bankAccount') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="filteredBankAccounts"
                                                        clearable
                                                        dense
                                                        item-value="Bank.id"
                                                        item-text="Bank.title"
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Contract__bank_id"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-2">
                                                            <span class="font-sm">{{ item.Bank.title }}</span>
                                                            <span class="font-weight-bold"> {{ item.Bank.account }} </span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{item}">
                                                        <div class="d-flex flex-column py-0">
                                                            <span class="font-sm">{{ item.Bank.title + ':' }}</span>
                                                            <span class="font-weight-bold">{{ item.Bank.account }} </span>
                                                        </div>
                                                    </template>
                                                </v-select>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.number') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-text-field
                                                        :background-color="[1,3,5].includes(Contract__salestype_id) ? 'green lighten-4' : ''"
                                                        :clearable="Contract__Invoice__id == null"
                                                        :label="Contract__title ? '' : $t('message.required')"
                                                        :readonly="Contract__Invoice__id != null"
                                                        :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                                        :value="Contract__title"
                                                        class="force-text-left"
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Contract__title = $event"
                                                >
                                                    <template v-slot:append>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon
                                                                        class="orange--text text--darken-3"
                                                                        v-on="on"
                                                                        @click="getNewContractNumber"
                                                                >mdi-download</v-icon>
                                                            </template>
                                                            <span>{{ $t('message.getNewContractNumber') }}</span>
                                                        </v-tooltip>
                                                    </template>
                                                </v-text-field>
                                                <v-alert
                                                        class="py-0 mt-0 mb-2"
                                                        color="error"
                                                        dense
                                                        outlined
                                                        text
                                                        v-if="Contract__Invoice__id != null"
                                                >{{ $t('message.cannotUpdateContractNumber') }}</v-alert>
                                            </v-flex>
                                            <v-flex md1 pa-0 mt-2 v-if="loading.newContractNumber">
                                                <v-progress-circular
                                                        color="orange"
                                                        indeterminate
                                                        size="24"
                                                />
                                            </v-flex>
                                            <v-flex xs6 md3 py-1 class="font-xs font-italic align-center" v-if="enterContractNumberManually">
                                                {{ $t('message.pleaseTypeContractNo') }}
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.revision') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="revisionNumbers"
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Contract__revision_no"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.partialContract') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="partialContractNumbers"
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Contract__partial_no"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="[1,3,5].includes(Contract__salestype_id)">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplier') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items=allSuppliers
                                                        :label="Contract__supplier_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Supplier.title"
                                                        item-value="Supplier.id"
                                                        solo
                                                        v-model="Contract__supplier_id"
                                                        @change="supplierChanged"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.origin') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items=allCountries
                                                        :label="Contract__productorigin_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        item-text="Country.name"
                                                        item-value="Country.id"
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Contract__productorigin_id"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.destination') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items=allCountries
                                                        :label="Contract__destinatione_id ? '' : $t('message.required')"
                                                        clearable
                                                        dense
                                                        item-text="Country.name"
                                                        item-value="Country.id"
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Contract__destinatione_id"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.eutrComplianceRequired') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-checkbox
                                                        :true-value="1"
                                                        class="mt-0"
                                                        dense
                                                        hide-details="auto"
                                                        v-model="Contract__eutr_compliance_required"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.incoterm') }}</v-flex>
                                            <v-flex xs6 lg2 md3 py-1>
                                                <v-select
                                                        :items="contractIncoTerms"
                                                        :label="Contract__incoterm_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-value="Incoterm.id"
                                                        item-text="Incoterm.title"
                                                        solo
                                                        v-model="Contract__incoterm_id"
                                                        @change="incoTermChanged($event)"
                                                />
                                            </v-flex>
                                            <v-flex xs6 lg2 md3 py-1 pl-0 v-if="[4,5].includes(Contract__incoterm_id) == false">
                                                <v-select
                                                        :items=incotermports
                                                        :label="Contract__incotermport ? '' : $t('message.required')"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-value="value"
                                                        item-text="title"
                                                        solo
                                                        v-model="Contract__incotermport"
                                                        @change="portChanged($event)"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.pol') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items=allShippingLoadingPorts
                                                        :item-text="item => item.Country.name ? item.Shippingport.title + '  -  ' + item.Country.name : item.Shippingport.title"
                                                        :item-value="'Shippingport.id'"
                                                        :label="Contract__loadingport_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Contract__loadingport_id"
                                                >
                                                    <template v-slot:selection="data">
                                                        {{ data.item.Country.name ? data.item.Shippingport.title + '  -  ' + data.item.Country.name : data.item.Shippingport.title }}
                                                    </template>
                                                    <template v-slot:item="data">
                                                        {{ data.item.Country.name ? data.item.Shippingport.title + '  -  ' + data.item.Country.name : data.item.Shippingport.title }}
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.pod') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items=allShippingDischargePorts
                                                        :item-text="item => item.Country.name ? item.Shippingport.title + '  -  ' + item.Country.name : item.Shippingport.title"
                                                        :item-value="'Shippingport.id'"
                                                        :label="Contract__shippingport_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Contract__shippingport_id"
                                                >
                                                    <template v-slot:selection="data">
                                                        {{ data.item.Country.name ? data.item.Shippingport.title + '  -  ' + data.item.Country.name : data.item.Shippingport.title }}
                                                    </template>
                                                    <template v-slot:item="data">
                                                        {{ data.item.Country.name ? data.item.Shippingport.title + '  -  ' + data.item.Country.name : data.item.Shippingport.title }}
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.osd') }}</v-flex>
                                            <v-flex xs6 lg4 md6 pt-1 pb-0>
                                                <v-menu
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        ref="originalShippingDateMenu"
                                                        v-model="originalShippingDateMenu"
                                                        transition="scale-transition"
                                                        max-width="290px"
                                                        min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                                :label="Contract__shipweekdate ? '' : $t('message.required')"
                                                                :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                                :value="computedOriginalShippingDateFormatted"
                                                                append-icon="event"
                                                                background-color="white"
                                                                class="force-text-left"
                                                                clearable
                                                                hide-details="auto"
                                                                hint="DD/MM/YY format"
                                                                persistent-hint
                                                                readonly
                                                                solo
                                                                v-on="on"
                                                                @click:clear="Contract__shipweekdate = null"
                                                                @click:append="originalShippingDateMenu = true"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                            v-model="Contract__shipweekdate"
                                                            @input="originalShippingDateMenu = false"
                                                            first-day-of-week="1"
                                                            locale-first-day-of-year="4"
                                                            show-week
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.shippingWeek') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-combobox
                                                        :items=sortedCalendarWeeks
                                                        :return-object="false"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-value="Calendarweek.title"
                                                        item-text="Calendarweek.title"
                                                        solo
                                                        v-model="Contract__shippingweekstatement"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <template v-if="item.Calendarweek.id === getCurrentWeek()">
                                                            <div class="d-flex flex-column" style="width: 100% !important;">
                                                                <div class="font-weight-bold my-3">{{ getCurrentYear() }}</div>
                                                                <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                                            </div>
                                                        </template>
                                                        <template v-if="item.Calendarweek.id > getCurrentWeek()">
                                                            <template v-if="[52,53].includes(item.Calendarweek.id)">
                                                                <div class="d-flex flex-column" style="width: 100% !important;">
                                                                    <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                                                    <v-divider></v-divider>
                                                                    <div class="font-weight-bold mb-3">{{ getNextYear() }}</div>
                                                                </div>
                                                            </template>
                                                            <template v-else>
                                                                <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                                                            </template>
                                                        </template>
                                                        <template v-if="item.Calendarweek.id < getCurrentWeek()">
                                                            <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                                                        </template>
                                                    </template>
                                                </v-combobox>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="false">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.product') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items=productGroups
                                                        :label="Contract__productgroup_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Productgroup.title"
                                                        item-value="Productgroup.id"
                                                        solo
                                                        v-model="Contract__productgroup_id"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.measurementSystem') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row">
                                                <v-radio-group
                                                        class="mt-0"
                                                        dense
                                                        hide-details="auto"
                                                        row
                                                        v-model="Contract__ms_id"
                                                >
                                                    <v-radio
                                                        :label="$t('message.metric')"
                                                        :value="1"
                                                    ></v-radio>
                                                    <v-radio
                                                        :label="$t('message.imperial')"
                                                        :value="2"
                                                    ></v-radio>
                                                </v-radio-group>
                                                <div class="font-sm font-italic">({{ $t('message.switchToFilterThickness') }})</div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.project') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row">
                                                <v-checkbox
                                                        :label="$t('message.export')"
                                                        :true-value="1"
                                                        autocomplete="new-password"
                                                        class="mt-0"
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Contract__is_export"
                                                />
                                                <v-checkbox
                                                        :label="$t('message.others')"
                                                        :true-value="1"
                                                        autocomplete="new-password"
                                                        class="mt-0 ml-4"
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Contract__is_others"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="false">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.certification') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items=certifications
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Certification.title"
                                                        item-value="Certification.id"
                                                        solo
                                                        v-model="Contract__fsc"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.internalReference') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>N/A</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyerSpecificRequests') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="red--text darken-1">{{ Contract__Customer__doc_setting != null ? Contract__Customer__doc_setting : 'N/A' }}</v-flex>
                                        </v-layout>
                                        <v-layout row v-if="updateMode">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.status') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="font-weight-bold">{{ contractStatus }}</v-flex>
                                        </v-layout>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.specifications') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <ContractItemList v-if="loader"/>
                                    <SalesItemListNewV3 :update-mode="updateMode" v-if="!loader"/>
                                    <v-layout row pt-3 v-if="[2].includes(Contract__salestype_id)">
                                        <v-flex xs6 lg6 md6 py-1>
                                            <span>{{ $t('message.commissionDetails') }}</span>
                                            <v-textarea
                                                    :rules="[...validationRules.paragraph]"
                                                    :value="Contract__commission_detail"
                                                    class="elevation-0 pt-3"
                                                    dense
                                                    hide-details="auto"
                                                    outlined
                                                    rows="5"
                                                    @change="Contract__commission_detail = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.confirmation') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3 v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.export') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-checkbox
                                                    :true-value="1"
                                                    autocomplete="new-password"
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Contract__is_export"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.tagAsOthers') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-checkbox
                                                    :true-value="1"
                                                    autocomplete="new-password"
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Contract__is_others"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.contractSigned') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-checkbox
                                                    :true-value="1"
                                                    autocomplete="new-password"
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Contract__issigned"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.poSigned') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-checkbox
                                                    :true-value="1"
                                                    autocomplete="new-password"
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Contract__isposigned"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1>{{ $t('message.excludeFscPefcReport') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-checkbox
                                                    :true-value="1"
                                                    autocomplete="new-password"
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Contract__remove_fscpefc_report"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.contractNotes') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-textarea
                                                    :rules="[...validationRules.paragraph]"
                                                    class="elevation-0 pt-3"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    rows="3"
                                                    solo
                                                    v-model="Contract__note"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel v-if="[1,5].includes(Contract__salestype_id)">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.finance') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.proformaInvoiceNumber') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-text-field
                                                    :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                                    :value="Contract__pi_no"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Contract__pi_no = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="Contract__salestype_id == 5">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.proformaInvoiceExchangeRate') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row align-center">
                                            <v-text-field
                                                    :rules="[...validationRules.amount]"
                                                    :value="Contract__pi_exrate"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Contract__pi_exrate = $event"
                                            />
                                            <span class="ml-2" v-if="servicedSalesExchangeRateLabel != null">{{ servicedSalesExchangeRateLabel }}</span>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="Contract__salestype_id == 5">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.proformaInvoiceExchangeRateDate') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row">
                                            <v-menu
                                                    ref="originalShippingDateMenu"
                                                    v-model="originalExRateDateMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    max-width="290px"
                                                    min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            :label="Contract__pi_exratedate ? '' : $t('message.required')"
                                                            :rules="[...validationRules.formattedDate]"
                                                            :value="computedExRateDateFormatted"
                                                            append-icon="event"
                                                            background-color="white"
                                                            class="force-text-left"
                                                            clearable
                                                            hide-details="auto"
                                                            hint="DD/MM/YY format"
                                                            persistent-hint
                                                            readonly
                                                            solo
                                                            v-on="on"
                                                            @click:clear="Contract__pi_exratedate = null"
                                                            @click:append="originalExRateDateMenu = true"
                                                    />
                                                </template>
                                                <v-date-picker
                                                        v-model="Contract__pi_exratedate"
                                                        @input="originalExRateDateMenu = false"
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel v-if="false">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.finance') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center" v-if="[1,2,5].includes(Contract__salestype_id)">{{ $t('message.buyerTerms') }}</v-flex>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center" v-if="[3,6].includes(Contract__salestype_id)">{{ $t('message.supplierTerms') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1 v-if="[1,2,5].includes(Contract__salestype_id)">
                                            <v-autocomplete
                                                    :items="allBuyerPaymentTerms"
                                                    :label="Contract__paymentstatement_id ? '' : $t('message.required')"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Paymentterm.id"
                                                    item-text="Paymentterm.title"
                                                    solo
                                                    v-model="Contract__paymentstatement_id"
                                            >
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-sm">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-sm">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                        <v-flex xs6 lg3 md5 py-1 v-if="[3,6].includes(Contract__salestype_id)">
                                            <v-autocomplete
                                                    :items="allSupplierPaymentTerms"
                                                    :label="Contract__paymentstatement_id ? '' : $t('message.required')"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Paymentterm.id"
                                                    item-text="Paymentterm.title"
                                                    solo
                                                    v-model="Contract__paymentstatement_id"
                                            >
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-sm">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-sm">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="Contract__salestype_id == 2">
                                        <v-flex xs6 lg2 md3 pl-5 py-1  class="align-center">{{ $t('message.commissionTerms') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-select
                                                    :items="commissionTerms"
                                                    :label="Contract__commission_term_id ? '' : $t('message.required')"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Contract__commission_term_id"
                                            >
                                            </v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="Contract__salestype_id == 2">
                                        <v-flex xs6 lg2 md3 pl-5 py-1  class="align-center">{{ $t('message.commissionSource') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-select
                                                    :items="commissionSources"
                                                    :label="Contract__commission_term_against_id ? '' : $t('message.required')"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Contract__commission_term_against_id"
                                            >
                                            </v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.bankAccount') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-select
                                                    :items="filteredBankAccounts"
                                                    clearable
                                                    dense
                                                    item-value="Bank.id"
                                                    item-text="Bank.title"
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Contract__bank_id"
                                            >
                                                <template v-slot:item="{item}">
                                                    <div class="d-flex flex-column py-2">
                                                        <span class="font-sm">{{ item.Bank.title }}</span>
                                                        <span class="font-weight-bold"> {{ item.Bank.account }} </span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{item}">
                                                    <div class="d-flex flex-column py-0">
                                                        <span class="font-sm">{{ item.Bank.title + ':' }}</span>
                                                        <span class="font-weight-bold">{{ item.Bank.account }} </span>
                                                    </div>
                                                </template>
                                            </v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.relatedFiles') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <div class="pb-3">
                                        <FileUploadList
                                            :file-name-prefix="fileNamePrefix"
                                            :files="files"
                                            :page-key="fileListPageKey"
                                            :updateMode="this.updateMode"
                                            module="contract"
                                            class="pt-3"
                                            @upload-file-done="addUploadedFile"
                                            @merge-setting-update-done="fileMergeSettingUpdated"
                                        />
                                    </div>
                                    <div class="mt-5" v-if="false">
                                        <v-row layout>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyerPoNo') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                                        :value="Contract__customer_pi_no"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Contract__customer_pi_no = $event"
                                                />
                                            </v-flex>
                                        </v-row>
                                        <v-row layout>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplierPiNo') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                                        :value="Contract__supplier_pi_no"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Contract__supplier_pi_no = $event"
                                                />
                                            </v-flex>
                                        </v-row>
                                    </div>
                                    <TmsContractDocumentsList :contract-id="Contract__id" v-if="Contract__version != '2.0'"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.printPreferences') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.showBankDetails') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-checkbox
                                                    :true-value="1"
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Contract__showbank"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.preferredSellerCaption') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                                    :value="Contract__sellertitle"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Contract__sellertitle = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center font-sm">{{ $t('message.preferredBuyerAndAddressCaption') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-select
                                                    :items=buyerCaptionTypes
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="id"
                                                    item-text="title"
                                                    solo
                                                    v-model="Contract__buyeraddresstype"
                                            >
                                                <template v-slot:item="{item}">
                                                    <v-row>
                                                        <v-col cols="5" class="font-xs pa-0"><span>{{ $t(item.title_first) }}</span><span class="float-right">|</span></v-col>
                                                        <v-col cols="7" class="font-xs pa-0 align-center text-right">{{ $t(item.title_last) }}</v-col>
                                                    </v-row>
                                                </template>
                                                <template v-slot:selection="{item}">
                                                    <span class="font-xs">{{ $t(item.title_first) + ' | ' + $t(item.title_last) }}</span>
                                                </template>
                                            </v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.preferredDeliveryAddress') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-select
                                                    :items=deliveryAddresses
                                                    :value="Contract__deliveryaddress_id"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Deliveryaddress.id"
                                                    item-text="Deliveryaddress.address"
                                                    solo
                                                    return-object
                                                    @input="deliveryAddressChanged($event)"
                                            >
                                                <template v-slot:item="{item}">
                                                    <div style="white-space: pre" :class="item.Deliveryaddress.address.length > 20 ? 'caption pb-2' : 'pb-2' ">{{ $t(item.Deliveryaddress.address) }}</div>
                                                </template>
                                                <template v-slot:selection="{item}">
                                                    <div style="white-space: pre" :class="item.Deliveryaddress.address.length > 20 ? 'caption' : '' ">{{ $t(item.Deliveryaddress.address) }}</div>
                                                </template>
                                            </v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.preferredFooterNotes') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-btn @click.stop="footerNotesDialog = true" small>{{ $t('message.manage') }}</v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.showCompanyStamp') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-checkbox
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    true-value="Y"
                                                    v-model="Contract__showsignature"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.printTwoPages') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-checkbox
                                                    :true-value="1"
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Contract__longform"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_contract')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <DocumentHistory asset="contracts" :asset_id="Contract__id"></DocumentHistory>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row px-3 pt-2 mb-3>
                            <v-flex d-flex class="align-center">
                                <v-btn
                                        class="ml-3"
                                        small
                                        @click.stop="previewDialog = true"
                                        v-if="false"
                                >{{ $t('message.previewContract') }}
                                </v-btn>
                                <PrintContractButtons
                                        :key="printButtonsKey"
                                        :update-mode="updateMode"
                                        :contract-id="Contract__id"
                                        :contract-currency-id="Contract__currency_id"
                                        :mergeable-docs="mergeableDocs"
                                        :office-currency-id="officeCurrencyId"
                                        :sales-status-id="Contract__salestatus_id"
                                        :sales-type-id="Contract__salestype_id"
                                        :contract-number="[Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-')"
                                />
                                <v-btn
                                        small
                                        class="ml-3 lighten-2"
                                        v-if="updateMode && [1,2,3,5,6].includes(Contract__salestype_id)"
                                        @click.stop="cloneThisContract()"
                                >
                                    <v-icon small>far fa-copy</v-icon>
                                    <span class="ml-1">{{ $t('message.clone') }}</span>
                                </v-btn>
                                <v-btn
                                        small
                                        class="ml-3 lighten-2"
                                        v-if="updateMode && [1,2,3,5,6].includes(Contract__salestype_id) && [1,2].includes(Contract__salestatus_id)"
                                        @click.stop="splitThisContract()"
                                >
                                    <v-icon small>fas fa-divide</v-icon>
                                    <span class="ml-1">{{ $t('message.createPartialShipment') }}</span>
                                </v-btn>
                                <v-btn
                                        :loading="loading.reset"
                                        @click="resetContractForm"
                                        class="ml-2"
                                        color="default"
                                        small
                                        v-if="addMode"
                                >
                                    <v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                </v-btn>
                                <v-btn
                                        :loading="loading.add"
                                        class="ml-3"
                                        color="info"
                                        small
                                        @click="addContract()"
                                        v-if="addMode == true && $can('create','Contract')"
                                >{{ $t('message.create') }}
                                </v-btn>
                                <v-btn
                                        :loading="loading.save"
                                        class="ml-3"
                                        color="info"
                                        small
                                        @click="saveContract"
                                        v-if="addMode == false && Contract__salestatus_id == 1 && $can('update','Contract')"
                                >{{ $t('message.save') }}
                                </v-btn>
                                <v-btn
                                        :loading="loading.cancel"
                                        class="ml-3"
                                        color="error"
                                        small
                                        v-if="addMode == false && Contract__salestatus_id == 1 && $can('delete','Contract')"
                                        @click="cancelContract(Contract__id)"
                                >{{ $t('message.cancel') }}
                                </v-btn>
                                <v-btn
                                        class="ml-3"
                                        color="success"
                                        small
                                        @click="openSendContractDialog"
                                        v-if="addMode == false && Contract__salestatus_id == 1 && $can('update','Contract')"
                                >{{ $t('message.sendContractToSales') }}</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
<!--        <v-dialog-->
<!--            overlay=false-->
<!--            scrollable-->
<!--            transition="dialog-bottom-transition"-->
<!--            v-model="previewDialog"-->
<!--            v-if="false"-->
<!--        >-->
<!--            <v-card>-->
<!--                <v-card-title class="success white&#45;&#45;text">{{ $t('message.contractPreview') }}</v-card-title>-->
<!--                <v-card-text class="pa-3">-->
<!--                    <ContractPreview/>-->
<!--                </v-card-text>-->
<!--                <v-card-actions class="pr-3 pt-3">-->
<!--                    <v-spacer></v-spacer>-->
<!--                    <v-btn-->
<!--                        class="ml-3"-->
<!--                        color="default"-->
<!--                        small-->
<!--                        @click="previewDialog = false"-->
<!--                    >{{ $t('message.backToEditing') }}-->
<!--                    </v-btn>-->
<!--                    <v-btn-->
<!--                        :loading="loading.add"-->
<!--                        class="ml-3"-->
<!--                        color="info"-->
<!--                        small-->
<!--                        @click="addContract()"-->
<!--                        v-if="addMode == true"-->
<!--                    >{{ $t('message.create') }}-->
<!--                    </v-btn>-->
<!--                    <v-btn-->
<!--                        :loading="loading.save"-->
<!--                        class="ml-3"-->
<!--                        color="info"-->
<!--                        small-->
<!--                        @click="saveContract()"-->
<!--                        v-if="addMode == false"-->
<!--                    >{{ $t('message.save') }}-->
<!--                    </v-btn>-->
<!--                </v-card-actions>-->
<!--            </v-card>-->
<!--        </v-dialog>-->
        <v-dialog
            persistent
            v-model="footerNotesDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.manageFooterNotes') }}</v-card-title>
                <v-card-text>
                    <v-list dense>
                        <v-list-item v-for="item in contractFooterNotes" :key="item.id" v-if="item.id != 10">
                            <v-list-item-action>
                                <v-checkbox
                                    :value="item.id"
                                    dense
                                    hide-details
                                    v-model="Contract__footer_notes"
                                />
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title class="font-sm wrap-text">{{ item.text }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="footerNotesDialog = false"
                        class="ml-3"
                        color="default"
                        small
                    >{{ $t('message.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <AlertMissingValues
            :alert_message="dialogs.error_message"
            :missing_values="errors.missing_values"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        />
        <CloneContract
            :dialog.sync="cloneDialog"
            @dialog-closed="cloneDialogClosed"
        />
        <SplitContract
            :dialog.sync="splitDialog"
            @dialog-closed="splitDialogClosed"
        />
        <EmailContractToSales
            :contract-id="contractId"
            :dialog.sync="sendContractDialog"
            :sales-colleague="salesColleague"
            :update-key="pdfComponentKey"
            @dialog-closed="sendContractDialogClosed"
        />
    </div>
</template>

<script>
    import { api } from "../../../api";
    import {
        formatDate,
        getCurrentWeek,
        getCurrentYear,
        getDateRangeOfWeek,
        getDateRangeOfWeekWithYear,
        getNextYear,
        log,
        scrollToFirstFormValidationError,
        yearHasExtraWeek
    } from "Helpers/helpers";
    import {mapGetters, mapActions} from "vuex";
    // import SalesItemListNewV2 from "Components/Appic/SalesItemListNewV2";
    import SalesItemListNewV3 from "Components/Appic/SalesItemListNewV3";
    import {mapFields} from "vuex-map-fields";
    import router from "../../../router";
    import {mapFieldsPrefixed} from  'Helpers/vuex-map-fields-prefixed'
    import { v4 as uuidv4 } from 'uuid';
    import ContractItemList from "Components/Appic/Loading/ContractItemList";
    import ContractGeneralInfo from "Components/Appic/Loading/ContractGeneralInfo";

    const AlertMissingValues = () => import("Components/Appic/AlertMissingValues");
    const CloneContract = () => import("Components/Appic/CloneContract");
    const ContractAlert = () => import("Components/Appic/ContractAlert");
    const ContractPreview = () => import("Components/Appic/ContractPreview");
    const DocumentHistory = () => import("Components/Appic/DocumentHistory");
    const EmailContractToSales = () => import("Components/Appic/EmailContractToSales");
    const FileUploadList = () => import("../../../components/Appic/FileUploadList");
    const SplitContract = () => import("../../../components/Appic/SplitContract");
    // const FabContract = () => import("Components/Appic/FabContract");
    const TmsContractDocumentsList = () => import("Components/Appic/TmsContractDocumentsList");
    const PrintContractButtons = () => import("Components/Appic/Printing/PrintContractButtons");

    export default {
        name: "AddContract",
        components: {
            ContractGeneralInfo,
            EmailContractToSales,
            // PrintContractHtml,
            AlertMissingValues,
            CloneContract,
            ContractAlert,
            ContractPreview,
            DocumentHistory,
            // FabContract,
            FileUploadList,
            PrintContractButtons,
            // SalesItemListNew,
            // SalesItemListNewV2,
            SalesItemListNewV3,
            SplitContract,
            TmsContractDocumentsList,
            ContractItemList
        },
        beforeRouteEnter (to, from, next) {
            next()
        },
        props: ['contractId'],
        title: 'Contract: ',
        data() {
            return {
                bank_account: {},
                cloneDialog: false,
                commissionTerms: [
                    {value: 0, text: 'CFR/CIF'},
                    {value: 1, text: 'FOB'},
                    {value: 2, text: 'EXW/FAS'},
                ],
                commissionSources: [
                    {value: 0, text: this.$t('message.supplier')},
                    {value: 1, text: this.$t('message.buyer')},
                ],
                contractDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
                contractDateMenu: false,
                contractIds: {},
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                errors: {
                    missing_values: []
                },
                fileListPageKey: 0,
                filters: {
                    offices: {
                        salestype_id: null,
                        country_id: null,
                    },
                    banks: {
                        office_id: null,
                        currency_id: null
                    }
                },
                enterContractNumberManually: false,
                footerNotesDialog: false,
                incotermports: [
                    {value: null, title: 'None'},
                    {value: 0, title: 'POD'},
                    {value: 1, title: 'POL'},
                    {value: 2, title: this.$t('message.doNotShow')},
                ],
                loader: false,
                loading: {
                    add: false,
                    contractPdf: false,
                    newContractNumber: false,
                    proformaInvoicePdf: false,
                    reset: false,
                    save: false
                },
                loadingActiveContract: false,
                mergeableDocs: [],
                office: {
                    id: 1,
                    country_id: 1
                },
                openSection: false,
                originalExRateDateMenu: false,
                originalShippingDateFormatted: null,
                originalShippingDateMenu: false,
                pageKey: Math.floor(Math.random() * 100),
                panel: [0,1],
                pdfComponentKey: 0,
                pdfProformaInvoice: false,
                previewDialog: false,
                printButtonsKey: 0,
                proformaInvoicePdf: false,
                sendContractDialog: false,
                showHide: [
                    {value: 0, text: this.$t('message.hide')},
                    {value: 1, text: this.$t('message.show')}
                ],
                splitDialog: false,
                thisSpecifications: [],
                thisContract: {},
                that: this,
                validForm: true
                // viewPdf: false
            }
        },
        asyncComputed: {
            async deliveryAddresses () {
                let buyerAddresses = await this.getBuyerDeliveryAddresses()
                let originalAddresses = this.deliveryAddressTypes
                return [...originalAddresses, ...buyerAddresses]
            },
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            // ...mapFieldsPrefixed('buyer',{
            //     Customer__doc_setting: 'Customer.doc_setting'
            // },'statePrefix'),
            ...mapFieldsPrefixed('contract',{
                Contract__id: 'Contract.id',
                Contract__bank_id: 'Contract.bank_id',
                Contract__Bank_id: 'Contract.Bank.id',
                Contract__Bank_title: 'Contract.Bank.title',
                Contract__Bank_account: 'Contract.Bank.account',
                Contract__Bank_swiftcode: 'Contract.Bank.swiftcode',
                Contract__Bank_beneficiary: 'Contract.Bank.beneficiary',
                Contract__buyeraddresstype: 'Contract.buyeraddresstype',
                Contract__currency_id: 'Contract.currency_id',
                Contract__commission_detail: 'Contract.commission_detail',
                Contract__commission_term_id: 'Contract.commission_term_id',
                Contract__commission_term_against_id:'Contract.commission_term_against_id',
                Contract__contractdate: 'Contract.contractdate',
                Contract__customer_id: 'Contract.customer_id',
                Contract__customer_pi_no: 'Contract.customer_pi_no',
                Contract__Customer__doc_setting: 'Contract.Customer.doc_setting',
                Contract__date_signed: 'Contract.date_signed',
                Contract__deliveryaddress_id: 'Contract.deliveryaddress_id',
                Contract__Deliveryaddress_id: 'Contract.Deliveryaddress.id',
                Contract__Deliveryaddress_address: 'Contract.Deliveryaddress_address',
                Contract__destinatione_id: 'Contract.destinatione_id',
                Contract__documents: 'Contract.documents',
                Contract__eutr_compliance_required: 'Contract.eutr_compliance_required',
                Contract__footer_notes: 'Contract.footer_notes',
                Contract__fsc: 'Contract.fsc',
                Contract__Invoice__id: 'Contract.Invoice.id',
                Contract__Invoice__invno: 'Contract.Invoice.invno',
                Contract__is_export: 'Contract.is_export',
                Contract__is_others: 'Contract.is_others',
                Contract__issigned: 'Contract.issigned',
                Contract__issplit: 'Contract.issplit',
                Contract__issplitcomplete: 'Contract.issplitcomplete',
                Contract__isposigned: 'Contract.isposigned',
                Contract__incoterm_id: 'Contract.incoterm_id',
                Contract__incotermport: 'Contract.incotermport',
                Contract__loadingport_id: 'Contract.loadingport_id',
                Contract__longform: 'Contract.longform',
                Contract__marketsegment_id: 'Contract.marketsegment_id',
                // Contract__note: 'Contract.note',
                Contract__ms_id: 'Contract.ms_id',
                Contract__office_id: 'Contract.office_id',
                Contract__partial_no: 'Contract.partial_no',
                Contract__paymentstatement_id: 'Contract.paymentstatement_id',
                Contract__paymentterm_id: 'Contract.paymentterm_id',
                Contract__pi_no: 'Contract.pi_no',
                Contract__pi_exrate: 'Contract.pi_exrate',
                Contract__pi_exratedate: 'Contract.pi_exratedate',
                Contract__productgroup_id: 'Contract.productgroup_id',
                Contract__productorigin_id: 'Contract.productorigin_id',
                Contract__po_date_signed: 'Contract.purchaseorder.date_signed',
                Contract__Purchaseorder__paymentstatement_id: 'Contract.Purchaseorder.paymentstatement_id',
                Contract__remove_fscpefc_report: 'Contract.remove_fscpefc_report',
                Contract__revision_no: 'Contract.revision_no',
                Contract__salescontact_id: 'Contract.salescontact_id',
                Contract__salestype_id: 'Contract.salestype_id',
                Contract__salestatus_id: 'Contract.salestatus_id',
                Contract__sellertitle: 'Contract.sellertitle',
                Contract__shippingport_id: 'Contract.shippingport_id',
                Contract__shipweekdate: 'Contract.shipweekdate',
                Contract__shippingweekstatement: 'Contract.shippingweekstatement',
                Contract__showbank: 'Contract.showbank',
                Contract__showsignature: 'Contract.showsignature',
                Contract__stampsignatured: 'Contract.stampsignatured',
                Contract__supplier_id: 'Contract.supplier_id',
                Contract__supplier_pi_no: 'Contract.supplier_pi_no',
                Contract__title: 'Contract.title',
                Contract__version: 'Contract.version'
            },'statePrefix'),
            ...mapFields('contract',{
                default_contractdate: 'default.contract.contractdate',
                default_currency_id: 'default.contract.currency_id',
                newContractSaved: 'newContractSaved',
                newContractUnsaved: 'newContractUnsaved',
                updatedContractSaved: 'updatedContractSaved',
                updatedContractUnsaved: 'updatedContractUnsaved',
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('bankaccount', {
                allBankAccounts: 'allBankAccounts',
                allSupplierBankAccounts: 'allSupplierBankAccounts',
                findBankAccountById: 'findBankAccountById',
                findBankAccountsByOfficeAndCurrency: 'findBankAccountsByOfficeAndCurrency',
                findBankAccountsBySupplier: 'findBankAccountsBySupplier',
            }),
            ...mapGetters('buyer', {
                allBuyers: 'allBuyers',
                findBuyerById: 'findBuyerById'
            }),
            ...mapGetters('contract',{
                allActiveContracts: 'allActiveContracts',
            }),
            ...mapGetters('country',{
                allCountries: 'allCountries'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('document', {
                allDocuments: 'allDocuments',
                // allNewContractDocuments: 'allNewContractDocuments',
                // allNewCommContractDocuments: 'allNewCommContractDocuments',
                // allContractDocuments: 'allContractDocuments',
                // allCommContractDocuments: 'allCommContractDocuments',
            }),
            ...mapGetters('incoterm', {
                contractIncoTerms: 'contractIncoTerms'
            }),
            ...mapGetters('paymentterm', {
                allBuyerPaymentTerms: 'allBuyerPaymentTerms',
                allSupplierPaymentTerms: 'allSupplierPaymentTerms'
            }),
            ...mapGetters('salescolleague', {
                allSalesColleagues: 'allSalesColleagues'
            }),
            ...mapGetters('shippingport', {
                allShippingLoadingPorts: 'allShippingLoadingPorts',
                allShippingDischargePorts: 'allShippingDischargePorts'
            }),
            ...mapGetters('supplier', {
                allSuppliers: 'allSuppliers',
                findSupplierById: 'findSupplierById'
            }),
            ...mapGetters([
                'appConstants',
                'buyerContractOffices',
                'buyerCaptionTypes',
                'calendarWeeks',
                'certifications',
                'contractFooterNotes',
                'currencies',
                'deliveryAddressTypes',
                'incoterms',
                'offices',
                'officeCountries',
                'otherSalesContacts',
                'salesTypes',
                'statuses',
                'productGroups',
                'validationRules'
            ]),
            addMode: function () {
                return this.$route.matched.some(({name}) => name === 'add_contract')
            },
            computedContractDateFormatted () {
                return formatDate(this.Contract__contractdate)
            },
            computedExRateDateFormatted () {
                return formatDate(this.Contract__pi_exratedate)
            },
            computedOriginalShippingDateFormatted () {
                return formatDate(this.Contract__shipweekdate)
            },
            contractNumberReadonly () {
                return [1,3,5].includes(this.Contract__salestype_id)
            },
            contractStatus () {
                let status = this.$t('message.open')
                switch(this.Contract__salestatus_id){
                    case null:
                        break
                    case 1:
                        status = this.$t('message.active')
                        break
                    case 2:
                        if(this.Contract__issplitcomplete == 1){
                            status = this.$t('message.splitted')
                        } else {
                            status = this.$t('message.completed')
                        }
                        break
                    case 10:
                        status = this.$t('message.cancelled')
                        break
                }
                if(this.addMode){
                    status = this.$t('message.new')
                }
                return status
            },
            fileNamePrefix() {
                let prefix = ''
                if(this.Contract__title != null && this.Contract__title != ''){
                    prefix = [this.Contract__title, this.Contract__revision_no , this.Contract__partial_no].filter(Boolean).join('-')
                }
                return prefix
            },
            files () {
                let files = [];
                if(this.addMode){
                    if([1,5].includes(this.Contract__salestype_id)){
                        files = this.allDocuments.NewContractDocuments
                    } else {
                        files = this.allDocuments.NewCommContractDocuments
                    }
                } else if(this.updateMode){
                    if([1,5].includes(this.Contract__salestype_id)){
                        files = this.allDocuments.ContractDocuments
                    } else {
                        files = this.allDocuments.CommContractDocuments
                    }
                }
                return files
            },
            filteredBankAccounts: function(){
                if(this.Contract__office_id != null && this.Contract__currency_id != null){
                    if(this.Contract__salestype_id == 2){
                        let officeAccounts = this.findBankAccountsByOfficeAndCurrency(this.Contract__office_id, this.Contract__currency_id)
                        let supplierAccounts = this.findBankAccountsBySupplier(this.Contract__supplier_id)
                        if(officeAccounts == null) officeAccounts = []
                        if(supplierAccounts == null) supplierAccounts = []
                        let accounts = []
                        if(officeAccounts.length > 0){
                            accounts.push( { header: this.appConstants.company } )
                            officeAccounts.forEach( acct => {
                                accounts.push(acct)
                            })
                        }
                        if(supplierAccounts.length > 0){
                            accounts.push( { header: this.$t('message.supplier') } )
                            supplierAccounts.forEach( acct => {
                                accounts.push(acct)
                            })
                        }
                        return accounts
                    } else if([1,3,5,6].includes(this.Contract__salestype_id)){
                        return this.findBankAccountsByOfficeAndCurrency(this.Contract__office_id, this.Contract__currency_id)
                    }
                } else {
                    return []
                }
            },
            filteredOffices: function () {
                // console.log(this.filters.offices.salestype_id,this.filters.offices.country_id)
                if( this.filters.offices.salestype_id != null && this.filters.offices.country_id != null && [1062,100,894,371].includes(this.Contract__customer_id) == false){
                    let buyerOffices = this.buyerContractOffices.filter(d => {
                        return d.salestype_id == this.filters.offices.salestype_id && d.country_id == this.filters.offices.country_id
                    }).map(d => d.office_id)
                    if (buyerOffices.length > 0) {
                        return this.offices.filter(d => {
                            return buyerOffices.indexOf(d.Office.id) !== -1
                        })
                    } else {
                        return this.offices
                    }
                } else {
                    return this.offices
                }
            },
            filteredSalesTypes: function () {
                return this.salesTypes.filter(salesType => [1,2,3,5].includes(salesType.Salestype.id))
            },
            officeCurrencyId: function() {
                const office = this.offices.find(o => o.Office.id == this.Contract__office_id)
                if(office) {
                    const officeCurrency = this.allCurrencies.find(c => c.Currency.id == office.Office.currency_id)
                    if(officeCurrency) return officeCurrency.Currency.id
                }
                return null
            },
            partialContractNumbers: function () {
                let items = []
                items.push({'text': this.$t('message.no'), 'value': null})
                let i
                for (i = 1; i <= 30; i++) {
                    let obj = {}
                    obj['text'] = '- ' + i
                    obj['value'] = i.toString()
                    items.push(obj)
                }
                return items
            },
            revisionNumbers: function () {
                let items = []
                items.push({'text': this.$t('message.none'), 'value': null})
                let i
                for (i = 1; i <= 10; i++) {
                    let obj = {}
                    obj['text'] = 'Rev' + i.toString()
                    obj['value'] = 'Rev' + i.toString()
                    items.push(obj)
                }
                return items
            },
            salesColleague() {
                let salesColleague = this.allSalesColleagues.find(s => s.Salesperson.id == this.Contract__salescontact_id)
                if(salesColleague){
                    return salesColleague
                }
                return null
            },
            servicedSalesExchangeRateLabel() {
                let label = []
                let contractCurrency = this.allCurrencies.find(c => c.Currency.id == this.Contract__currency_id)
                let office = this.offices.find(o => o.Office.id == this.Contract__office_id)
                let officeCurrency = this.allCurrencies.find(c => c.Currency.id == office.Office.currency_id)
                if(contractCurrency) label.push(contractCurrency.Currency.code)
                if(officeCurrency) label.push(officeCurrency.Currency.code)
                if(label.length > 0){
                    return label.filter(Boolean).join('-')
                }
                return null
            },
            sortedCalendarWeeks: function () {
                const currentWeek = getCurrentWeek();
                let nextWeeks = []
                let previousWeeks = []
                this.calendarWeeks.forEach(cw => {
                    if(cw.Calendarweek.id >= currentWeek){
                        nextWeeks.push(cw)
                    } else {
                        previousWeeks.push(cw)
                    }
                })

                //check if current year has week 53
                if(yearHasExtraWeek(getCurrentYear)){
                    const calendarObj = {Calendarweek: {id:53, title: 'WK 53', dateRange: ''}}
                    nextWeeks.push(calendarObj)
                }

                //generate date range values
                nextWeeks.forEach((item, idx) => {
                    nextWeeks[idx]['Calendarweek']['dateRange'] = getDateRangeOfWeek(item.Calendarweek.id)
                })
                previousWeeks.forEach((item, idx) => {
                    previousWeeks[idx]['Calendarweek']['dateRange'] = getDateRangeOfWeekWithYear(item.Calendarweek.id, getNextYear())
                })
                const weeks = [...nextWeeks, ...previousWeeks]
                return weeks
            },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_contract')
            },
        },
        methods: {
            ...mapActions('bankaccount', {
                resetAllBankAccounts: 'resetAllBankAccounts',
                getAllBankAccounts: 'getAllBankAccounts',
                getAllSupplierBankAccounts: 'getAllSupplierBankAccounts'
            }),
            ...mapActions('buyer', {
                resetAllBuyers: 'resetAllBuyers',
                getAllActiveBuyers: 'getAllActiveBuyers'
            }),
            ...mapActions('contract', {
                cancelContractById: 'cancelContractById',
                cloneContract: 'cloneContract',
                contractBuyerChanged: 'contractBuyerChanged',
                createContract: 'createContract',
                getContractToUpdateById: 'getContractToUpdateById',
                resetCurrentContract: 'resetCurrentContract',
                resetAllContracts: 'resetAllContracts',
                splitContract: 'splitContract',
                updateContract: 'updateContract',
            }),
            ...mapActions('country',{
                getAllCountries: 'getAllCountries'
            }),
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('document',{
                resetAllDocuments: 'resetAllDocuments',
                getContractDocumentsById: 'getContractDocumentsById',
                getStandardDocuments: 'getStandardDocuments',
                getAllStandardShippingDocuments: 'getAllStandardShippingDocuments'
            }),
            ...mapActions('incoterm', {
                getContractIncoTerms: 'getContractIncoTerms'
            }),
            ...mapActions('paymentterm', {
                getAllPaymentTerms: 'getAllPaymentTerms',
                getAllBuyerContractPaymentTerms: 'getAllBuyerContractPaymentTerms'
            }),
            ...mapActions('runtime',{
                removeOpenedTab: 'removeOpenedTab'
            }),
            ...mapActions('salescolleague', {
                getAllSalesColleagues: 'getAllSalesColleagues',
            }),
            ...mapActions('salesitem',{
                getSalesItemsByContract: 'getSalesItemsByContract',
                resetCurrentSalesItems: 'resetCurrentSalesItems',
                updateTotals: 'updateTotals'
            }),
            ...mapActions('supplier', {
                resetAllSuppliers: 'resetAllSuppliers',
                getAllSuppliers: 'getAllSuppliers'
            }),
            ...mapActions('shippingport', {
                getAllShippingPorts: 'getAllShippingPorts',
            }),
            addContract () {
                if(this.$refs.contractForm.validate()) {
                    this.loading.add = true
                    this.checkContractNumberIsAvailable()
                        .then(response => {
                            if (response == true) {
                                this.createContract()
                                    .then((response) => {
                                        if (response.status == 'success') {
                                            this.$toast.success(this.$t('message.successes.contractAdded'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'check_circle_outline'
                                                }
                                            )
                                            this.loading.add = false
                                            this.newContractUnsaved = false
                                            this.newContractSaved = true
                                            // reset new contract state
                                            this.resetCurrentContract().then(() => {
                                                //reset new salesitems state
                                                this.resetCurrentSalesItems().then(() => {
                                                    // reset all contracts to add new contract to list
                                                    this.resetAllContracts().then(() => {
                                                        // push new route
                                                        router.push({
                                                            name: 'update_contract',
                                                            params: {contractId: response.contract_id}
                                                        }).catch(() => {
                                                            console.log('error')
                                                        })
                                                    })
                                                })
                                            })
                                        } else {
                                            this.$toast.error(this.$t('message.errors.contractNotAdded'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                            this.loading.add = false
                                        }
                                    }).catch(() => {
                                        this.$toast.error(this.$t('message.errors.contractNotAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                        this.loading.add = false
                                    })
                            } else {
                                this.loading.add = false
                                this.$toast.error(this.$t('message.errors.contractNumberTaken'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.contractNotAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.add = false
                    scrollToFirstFormValidationError()
                }
            },
            addUploadedFile (fileObject){
                if(this.Contract__documents.length == 0){
                    this.Contract__documents.push({AssetDocument: fileObject.AssetDocument})
                    if(this.updateMode == true){
                        this.saveContract()
                    }
                } else {
                    //add/replace file in existing list
                    let doctype_id = fileObject.AssetDocument.doctype_id
                    let documents = []
                    this.Contract__documents.forEach((item, index)=>{
                        if(doctype_id != item.AssetDocument.doctype_id){
                            documents.push(item)
                        }
                    })
                    let newFile = {
                        AssetDocument: fileObject.AssetDocument
                    }
                    documents.push(newFile)
                    this.Contract__documents = []
                    this.Contract__documents = documents
                    if(this.updateMode == true){
                        this.saveContract()
                    }
                }
            },
            beforeUnloadHandler() {
                this.removeOpenedTab('contract_' + this.contractId)
            },
            buyerChanged () {
                let buyer = this.findBuyerById(this.Contract__customer_id)
                if(buyer != null){
                    this.loader = true
                    let payload = {
                        prefix: this.statePrefix,
                        buyer: buyer
                    }
                    this.contractBuyerChanged(payload)
                        .then(()=>{
                            if(this.officeCountries.includes(buyer.Country.id)){
                                this.filters.offices.country_id = buyer.Country.id
                            } else {
                                this.filters.offices.country_id = 999
                            }
                            // this.Contract__title = null
                            this.loader = false
                        })
                    // this.Contract__destinatione_id = buyer.Country.id
                    // this.Contract__marketsegment_id = buyer.Marketsegment.id
                    // this.Contract__salescontact_id = buyer.Salescontact.id
                    // if([1,5].includes(this.Contract__salestype_id)){
                    //     if(buyer.Customer.paymentstatement_id) {
                    //         this.Contract__paymentstatement_id = buyer.Customer.paymentstatement_id
                    //     }
                    // }
                } else {
                    this.filters.offices.country_id = null
                }
            },
            async cancelContract(val) {
                if(await this.$root.$confirm(this.$t('message.cancelContract'), this.$t('message.confirmations.continueContractCancelAction'), {color: 'orange'})){
                    this.loading.cancel = true
                    this.cancelContractById(val).then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.contractDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            router
                                .push({name: 'contract_listing'})
                                .catch(() => {
                                    console.log('error')
                                })
                            this.loading.cancel = false
                        } else {
                            this.$toast.error(this.$t('message.errors.contractNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                }
            },
            checkContractNumberIsAvailable() {
                let contractNumber = [this.Contract__title, this.Contract__revision_no, this.Contract__partial_no].filter(Boolean).join('')
                return new Promise((resolve, reject) => {
                    api
                        .get('/contracts/number-available/' + encodeURIComponent(contractNumber))
                        .then(response => {
                            // console.log(response.data.status)
                            if (response.data.status == 'success') {
                                resolve(true)
                            } else {
                                resolve(false)
                            }
                        })
                        .catch(() => {
                            resolve(false)
                        })
                })
            },
            cloneDialogClosed () {
                this.cloneDialog = false;
            },
            cloneThisContract () {
                this.cloneContract()
                    .then(()=>{
                        this.cloneDialog = true
                    })
            },
            deliveryAddressChanged (value) {
                this.Contract__deliveryaddress_id = value.Deliveryaddress.id
                this.Contract__Deliveryaddress_id = value.Deliveryaddress.id
                this.Contract__Deliveryaddress_address = value.Deliveryaddress.address
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            fileMergeSettingUpdated() {
                this.getContractDocumentsById(this.contractId)
                    .then((documents)=>{
                        this.Contract__documents = documents
                        this.getMergeableDocs()
                            .then(() => {
                                this.printButtonsKey += 1
                            })
                    })
                    .catch()
            },
            getBuyerDeliveryAddresses: async function (){
                let addresses = api.
                    get("/addresses", {
                        params: {
                            conditions: [
                                {
                                    field: 'deliveryaddresses.model',
                                    value: 'customer'
                                },
                                {
                                    field: 'deliveryaddresses.key_id',
                                    value: this.Contract__customer_id ? this.Contract__customer_id : null
                                }
                            ]
                        }
                    })
                    .then(response => {
                        return response.data.data
                    })
                    .catch(error => {

                    });
                return await addresses
            },
            getCurrentWeek,
            getCurrentYear,
            getMergeableDocs() {
                return new Promise((resolve, reject) => {
                    try {
                        if(typeof this.Contract__documents !== 'undefined' && Array.isArray(this.Contract__documents)){
                            this.Contract__documents.forEach(doc => {
                                if(doc.AssetDocument.merge_attachments_to_main_pdf == 1){
                                    this.mergeableDocs.push(doc.AssetDocument.location)
                                }
                            })
                        }
                        resolve('done')
                    } catch(e) {
                        reject(e)
                    }
                })
            },
            getNextYear,
            getDateRangeOfWeekWithYear,
            getDateRangeOfWeek,
            getNewContractNumber: function() {
                if(this.Contract__customer_id != null && this.Contract__salestype_id != null && this.Contract__office_id != null && this.Contract__supplier_id != null) {
                    this.loading.newContractNumber = true
                    return new Promise ((resolve, reject) => {
                        api
                            .get("/contracts/number",{
                                params: {
                                    contract_prefix: this.buyerContractOffices.find(d => d.salestype_id == this.filters.offices.salestype_id && d.country_id == this.filters.offices.country_id && d.office_id == this.Contract__office_id)?.contract_prefix,
                                    destinatione_id: this.Contract__destinatione_id,
                                    office_country_id: this.offices.find(office=>office.Office.country_id == this.Contract__office_id)?.Office?.country_id,
                                    productorigin_id: this.Contract__productorigin_id,
                                    salescontact_id: this.Contract__salescontact_id,
                                    salestype_id: this.Contract__salestype_id,
                                }
                            })
                            .then(response => {
                                if(response.data.status == 'success') {
                                    this.Contract__title = response.data.data
                                } else if(response.data.status == 'error'){
                                    // this.Contract__title = response.data.data
                                    this.enterContractNumberManually = true
                                }
                                this.loading.newContractNumber = false
                            })
                            .catch(error => {
                                reject(error);
                                this.$toast.error(this.$t('message.errors.noNewContractNo'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.newContractNumber = false
                            });
                    })
                } else {
                    this.errors.missing_values = [] //empty errors first
                    if(this.Contract__customer_id == null){
                        this.errors.missing_values.push(this.$t('message.buyer'));
                    }
                    if(this.Contract__salestype_id == null){
                        this.errors.missing_values.push(this.$t('message.salesType'));
                    }
                    if(this.Contract__office_id == null){
                        this.errors.missing_values.push(this.$t('message.appTimberCompany'));
                    }
                    if(this.Contract__supplier_id == null){
                        this.errors.missing_values.push(this.$t('message.supplier'));
                    }
                    this.dialogs.error_message = this.$t('message.errors.cannotGetNewContractNumber');
                    this.dialogs.error = true;
                }
            },
            incoTermChanged (val) {
                // log(val)
                if(val == 1) this.Contract__incotermport = 1
                if(val == 2) this.Contract__incotermport = 0
                if(val == 3) this.Contract__incotermport = 0
                return true
            },
            loadAnotherContract (val) {
                router
                    .push({name: 'update_contract', params: { contractId : val}})
                    .catch(() => {
                        console.log('error')
                    })
            },
            loadContractById (val) {
                let self = this
                this.loader = true
                this.getContractToUpdateById(val)
                    .then(()=>{
                        this.$title =  this.$t('message.titles.contract') +': '+ (this.Contract__version != null ? [this.Contract__title, this.Contract__revision_no , this.Contract__partial_no].filter(Boolean).join('-') : this.Contract__title)
                        const payload = {
                            contract_id: val,
                            prefix: self.statePrefix
                        }
                        //update PI number if null for serviced sales
                        if(this.Contract__salestype_id == 5){
                            const contractNumber = [this.Contract__title, this.Contract__revision_no, this.Contract__partial_no].filter(Boolean).join('-')
                            if(this.Contract__pi_no == null){
                                this.Contract__pi_no = contractNumber
                            }
                        }
                        this.getSalesItemsByContract(payload)
                            .then(()=>{
                                this.getContractDocumentsById(val)
                                    .then((documents)=>{
                                        this.Contract__documents = documents
                                        this.getMergeableDocs()
                                        this.filters.offices.salestype_id = this.Contract__salestype_id
                                        if(this.Contract__customer_id) {
                                            let buyer = this.findBuyerById(this.Contract__customer_id)
                                            if (this.officeCountries.includes(buyer.Country.id)) {
                                                this.filters.offices.country_id = buyer.Country.id
                                            } else {
                                                this.filters.offices.country_id = 999
                                            }
                                        }
                                        this.updatedContractUnsaved = false
                                        this.loader = false;
                                    })
                                    .catch(()=>{
                                        this.updatedContractUnsaved = false
                                        this.loader = false;
                                    })
                            })
                            .catch(()=>{
                                this.updatedContractUnsaved = false
                                this.loader = false;
                            })
                    })
                    .catch(()=>{
                        this.updatedContractUnsaved = false
                        this.loader = false;
                        router
                            .push({name: 'not_found_main', params: {resource: 'contract'}})
                            .catch(() => { console.log('Push error') })
                    })
            },
            officeChanged() {
                this.office.id = this.Contract__office_id
                this.office.country_id = this.offices.find(f => f.Office.id == this.office.id)?.Office?.country_id
                this.Contract__title = null
            },
            openSendContractDialog() {
                this.sendContractDialog = true
            },
            parseDate (date) {
                if (!date) return null
                const [day, month, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            portChanged(val) {
                if(val == 0) this.Contract__incoterm_id = 2
                if(val == 1) this.Contract__incoterm_id = 1
                return true
            },
            async resetContractForm() {
                if (await this.$root.$confirm(this.$t('message.resetContractForm'), this.$t('message.confirmations.continueContractFormReset'), {color: 'orange'})) {
                    this.loading.reset = true
                    this.resetCurrentContract()
                        .then(() => {
                            setTimeout(() => {
                                this.newContractSaved = false
                                this.newContractUnsaved = false
                                this.loading.reset = false
                                this.$toast.success(this.$t('message.contractFormResetNotification'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                            }, 500)
                        })
                }
            },
            resetViewVars() {
                this.loading.contractPdf = false
                this.loading.proformaInvoicePdf = false
                // this.viewPdf = false
                this.pdfProformaInvoice = false
            },
            saveContract () {
                if(this.$refs.contractForm.validate()) {
                    this.loading.save = true
                    this.updateContract(this.Contract__id)
                        .then((status) => {
                            if (status == 'done') {
                                let payload = {
                                    contract_id: this.Contract__id,
                                    prefix: this.statePrefix
                                }
                                this.getSalesItemsByContract(payload)
                                    .then(() => {
                                        this.getContractDocumentsById(this.Contract__id)
                                            .then((documents) => {
                                                this.Contract__documents = documents
                                                this.getMergeableDocs()
                                                this.$toast.success(this.$t('message.successes.contractUpdated'),
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'check_circle_outline'
                                                    }
                                                )
                                                this.loading.save = false
                                                this.updatedContractUnsaved = false
                                                this.previewDialog = false
                                                this.pdfComponentKey = Math.floor(Math.random() * 100)
                                            })
                                            .catch(()=>{
                                                this.updatedContractUnsaved = false
                                                this.loading.save = false
                                            })
                                    })
                                    .catch(() => {
                                        this.$toast.error(this.$t('message.errors.contractNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                        this.loading.save = false
                                    })
                            } else {
                                this.$toast.error(this.$t('message.errors.contractNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.save = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.contractNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.contractNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.save = false
                    scrollToFirstFormValidationError()
                }
            },
            sendContractDialogClosed() {
                this.sendContractDialog = false
            },
            supplierChanged () {
                let supplier = this.findSupplierById(this.Contract__supplier_id)
                if(supplier != null){
                    this.Contract__productorigin_id = supplier.Country.id
                }
            },
            salesTypeChanged () {
                this.filters.offices.salestype_id = this.Contract__salestype_id
                this.Contract__title = null
            },
            splitDialogClosed () {
                this.splitDialog = false;
            },
            splitThisContract () {
                this.splitContract()
                    .then(()=>{
                        this.splitDialog = true
                    })
            },
            viewPdf (type = null) {
                let document = encodeURIComponent(this.Contract__version != null ? [this.Contract__title, this.Contract__revision_no , this.Contract__partial_no].filter(Boolean).join('-') : this.Contract__title) + (type == 'proforma' ? '_PI' : '')
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + (type == 'proforma' ? '/v1/contracts/proforma/print/' : '/v1/contracts/print/')
                    + this.contractId
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        watch: {
            contractdate (val) {
                this.contractDateFormatted = formatDate(this.contractdate)
            },
            currentContract: {
                handler: function(to, from) {
                    console.log(from, to);
                },
                deep: true
            },
            Contract__bank_id (val) {
                let bank = this.findBankAccountById(val)
                this.Contract__Bank_id = bank?.Bank?.id;
                this.Contract__Bank_title = bank?.Bank?.title;
                this.Contract__Bank_account = bank?.Bank?.account;
                this.Contract__Bank_swiftcode = bank?.Bank?.swiftcode;
                this.Contract__Bank_beneficiary = bank?.Bank?.beneficiary;
            },
            Contract__paymentstatement_id (val){
                let paymentterm = this.allBuyerPaymentTerms.find(d => d.Paymentterm.id == val)?.Paymentterm?.paymentterm_id
                if(paymentterm) {
                    this.Contract__paymentterm_id = paymentterm
                }
            },
            Contract__pi_no () {
                let contractNumber = [this.Contract__title, this.Contract__revision_no, this.Contract__partial_no].filter(Boolean).join('-')
                if(this.Contract__pi_no == null){
                    this.Contract__pi_no = contractNumber
                }
            },
            Contract__title () {
                let contractNumber = [this.Contract__title, this.Contract__revision_no, this.Contract__partial_no].filter(Boolean).join('-')
                if(this.Contract__pi_no == null){
                    this.Contract__pi_no = contractNumber
                }
            },
            Contract__partial_no () {
                let contractNumber = [this.Contract__title, this.Contract__revision_no, this.Contract__partial_no].filter(Boolean).join('-')
                if(this.Contract__pi_no == null){
                    this.Contract__pi_no = contractNumber
                }
            },
            Contract__revision_no () {
                let contractNumber = [this.Contract__title, this.Contract__revision_no, this.Contract__partial_no].filter(Boolean).join('-')
                if(this.Contract__pi_no == null){
                    this.Contract__pi_no = contractNumber
                }
            },
            osd (val) {
                this.originalShippingDateFormatted = formatDate(this.osd)
            },
            top (val) {
                this.bottom = !val
            },
            right (val) {
                this.left = !val
            },
            bottom (val) {
                this.top = !val
            },
            left (val) {
                this.right = !val
            },
        },
        created() {
            window.addEventListener("beforeunload", this.beforeUnloadHandler)
            // TODO #remove this later
            // this.resetAllDocuments()
            if(this.allCountries.length == 0) this.getAllCountries()
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
            if(this.allBuyers.length == 0) this.getAllActiveBuyers()
            if(this.allShippingDischargePorts.length == 0 || this.allShippingLoadingPorts.length == 0) this.getAllShippingPorts()
            if(this.allSalesColleagues.length == 0) this.getAllSalesColleagues()
            if(this.allBuyerPaymentTerms.length == 0) this.getAllPaymentTerms()
            if(this.allBankAccounts.length == 0) this.getAllBankAccounts()
            if(this.allSupplierBankAccounts.length == 0) this.getAllSupplierBankAccounts()
            if(this.allSuppliers.length == 0) this.getAllSuppliers()
            if(this.contractIncoTerms.length == 0) this.getContractIncoTerms()
            if(this.allDocuments.NewContractDocuments.length == 0) {
                this.getAllStandardShippingDocuments()
                this.getStandardDocuments()
            }
            if(this.$route.matched.some(({name}) => name === 'update_contract')){
                this.loadContractById(this.contractId)
            } else if(this.$route.matched.some(({name}) => name === 'add_contract')){
                this.resetCurrentContract()
                    .then(() => {
                        this.resetCurrentSalesItems()
                    })

                //TODO: ## Check if this is necessary
                this.filters.offices.salestype_id = this.Contract__salestype_id
                if(this.Contract__customer_id) {
                    let buyer = this.findBuyerById(this.Contract__customer_id)
                    if (this.officeCountries.includes(buyer?.Country?.id)) {
                        this.filters.offices.country_id = buyer?.Country?.id
                    } else {
                        this.filters.offices.country_id = 999
                    }
                }
                this.newContractUnsaved = false
            }
        },
        mounted() {
            this.$store.watch(
                function(state) {
                    return state.appic.contract.update.Contract
                },
                () => {
                    this.updatedContractUnsaved = true
                },
                { deep: true }
            )
            this.$store.watch(
                function(state) {
                    return state.appic.contract.current.Contract
                },
                () => {
                    this.newContractUnsaved = true
                },
                { deep: true }
            )
            if(this.$route.matched.some(({name}) => name === 'add_contract')){
                this.Contract__Customer__doc_setting = null;
            }
        }
    }
</script>
<style>
    .force-text-left .v-text-field__slot input {
        text-align: left !important;
    }
    .v-dialog {
        min-width: 200px !important;
    }
    .wrap-text {
        white-space: normal;
    }
    .font-xs {
        font-size: small;
    }
    .no-left-padding > div.v-expansion-panel-content__wrap{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
</style>
