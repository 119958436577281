<template>
    <v-dialog
        persistent
        v-model="uploadDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
    >
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <v-stepper v-model="uploadWizard">
                    <v-stepper-header>
                        <v-stepper-step :complete="uploadWizard > 1" step="1">
                            {{ $t('message.readingFile') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="uploadWizard > 2" step="2">
                            {{ $t('message.checkItems') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="uploadWizard > 3" step="3">
                            {{ $t('message.addToList') }}
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <div class="d-flex flex-row pb-3">
                                <v-form lazy-validation ref="fileUploadForm" v-model="validForm">
                                    <div class="d-flex flex-column" style="min-width: 400px !important;">
                                        <v-checkbox
                                            :label="$t('message.separatedPages')"
                                            :true-value="true"
                                            :false-value="false"
                                            class="ml-0 black--text text--darken-1"
                                            dense
                                            hide-details="auto"
                                            v-model="separatedPages"
                                        ></v-checkbox>
                                        <div class="d-flex flex-row align-center pt-3">
                                            <span v-if="separatedPages">{{ '#1' }}</span>
                                            <v-file-input
                                                :placeholder="$t('message.selectTallySheetFile')"
                                                :error-messages="manualErrorState[0]"
                                                :rules="[...validationRules.required]"
                                                accept="application/pdf"
                                                dense
                                                hide-details="auto"
                                                solo
                                                v-model="tallySheetFile"
                                                v-if="hasFile.tallySheet"
                                                @change="checkFile(0 ,'pdf', $event)"
                                            />
                                        </div>
                                        <div class="d-flex flex-row align-center pt-3" v-if="separatedPages">
                                            <span v-if="separatedPages">{{ '#2' }}</span>
                                            <v-file-input
                                                :placeholder="$t('message.selectTallySheetFile')"
                                                :error-messages="manualErrorState[1]"
                                                :rules="[...validationRules.required]"
                                                accept="application/pdf"
                                                dense
                                                hide-details="auto"
                                                solo
                                                v-model="tallySheetFileTwo"
                                                v-if="hasFile.tallySheet"
                                                @change="checkFile(1 ,'pdf', $event)"
                                            />
                                        </div>
                                        <div class="d-flex flex-row align-center pt-3" v-if="separatedPages">
                                            <span v-if="separatedPages">{{ '#3' }}</span>
                                            <v-file-input
                                                :placeholder="$t('message.selectTallySheetFile')"
                                                :error-messages="manualErrorState[2]"
                                                accept="application/pdf"
                                                dense
                                                hide-details="auto"
                                                solo
                                                v-model="tallySheetFileThree"
                                                v-if="hasFile.tallySheet"
                                                @change="checkFile(2 ,'pdf', $event)"
                                            />
                                        </div>
                                    </div>
                                </v-form>
                                <div class="d-flex flex-column pl-10 mt-8">
                                    <v-btn
                                        class="mt-3"
                                        :disabled="tallySheetData === [] && tallySheetFile == null"
                                        :loading="uploadingFile"
                                        @click="extractTallySheet"
                                    >
                                        {{ tallySheetData.length > 0 ? $t('message.uploaded') : (separatedPages ? $t('message.readFiles') : $t('message.readFile')) }}
                                        <v-icon v-if="tallySheetData.length > 0" class="ml-2 success--text">mdi-check</v-icon>
                                    </v-btn>
                                </div>
                            </div>
<!--                            <div class="pt-5">{{ 'Test Response: ' + responseText.length }}</div>-->
                            <div class="d-flex flex-row pb-3">
                                <v-btn color="success" @click="uploadWizard = 2" :disabled="tallySheetData.length === 0">{{ $t('message.continue') }}</v-btn>
                                <v-btn class="ml-2" @click="uploadDialog = false">{{ $t('message.cancel') }}</v-btn>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <v-simple-table
                                :fixed-header="tallySheetData.length > 20"
                                :height="tallySheetData.length > 20 ? '400px' : null"
                                class="pb-3 appic-table-light specification-table"
                                dense
                                style="width: 1500px !important;"
                            >
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="blue-grey lighten-4 pl-1">#</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[0].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[1].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[2].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[3].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[4].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[5].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[6].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[7].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[8].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[9].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[10].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[11].text }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(bundle, index) in tallySheetData" v-bind:key="'row_' + index.toString()">
                                            <td class="text-start font-sm">{{ index + 1 }}</td>
                                            <td :class="'text-start font-sm ' + (bundle.container.error ? 'red--text' : '')">{{ bundle.container.text }}</td>
                                            <td :class="'text-start font-sm ' + (bundle.specification.error ? 'red--text' : '')">{{ bundle.specification.text }}</td>
                                            <td :class="'text-start font-sm ' + (bundle.grade.error ? 'red--text' : '')">{{ bundle.grade.text }}</td>
                                            <td :class="'text-start font-sm ' + (bundle.thickness.error ? 'red--text' : '')">
                                                <div class="d-flex flex-row align-center">
                                                    <v-text-field
                                                        :key="'thickness_' + index.toString()"
                                                        :value="bundle.thickness.text"
                                                        clearable
                                                        dense
                                                        solo
                                                        style="width: 100px"
                                                        hide-details="auto"
                                                        @change="updateTallySheetData(index, 'thickness', $event)"
                                                    ></v-text-field>
                                                    <span class="font-sm ml-2">{{ bundle.thicknessUnit.text }}</span>
                                                </div>
                                            </td>
                                            <td :class="'text-start font-sm ' + (bundle.width.error ? 'red--text' : '')">
                                                <div class="d-flex flex-row align-center">
                                                    <v-text-field
                                                        :key="'width_' + index.toString()"
                                                        :value="bundle.width.text"
                                                        clearable
                                                        dense
                                                        solo
                                                        style="width: 100px"
                                                        hide-details="auto"
                                                        @change="updateTallySheetData(index, 'width', $event)"
                                                    ></v-text-field>
                                                    <span class="font-sm ml-2">{{ bundle.widthUnit.text }}</span>
                                                </div>
                                            </td>
                                            <td :class="'text-start font-sm ' + (bundle.length.error ? 'red--text' : '')">
                                                <div class="d-flex flex-row align-center">
                                                    <v-text-field
                                                        :key="'length_' + index.toString()"
                                                        :value="bundle.length.text"
                                                        clearable
                                                        dense
                                                        solo
                                                        style="width: 100px"
                                                        hide-details="auto"
                                                        @change="updateTallySheetData(index, 'length', $event)"
                                                    ></v-text-field>
                                                    <span class="font-sm ml-2">{{ bundle.lengthUnit.text }}</span>
                                                </div>
                                            </td>
                                            <td :class="'text-start font-sm ' + (bundle.bundles.error ? 'red--text' : '')">
                                                <v-text-field
                                                    :key="'bundles_' + index.toString()"
                                                    :value="bundle.bundles.text"
                                                    clearable
                                                    dense
                                                    solo
                                                    style="width: 100px"
                                                    hide-details="auto"
                                                    @change="updateTallySheetData(index, 'bundles', $event)"
                                                ></v-text-field>
                                            </td>
                                            <td :class="'text-start font-sm ' + (bundle.pieces.error ? 'red--text' : '')">
                                                <v-text-field
                                                    :key="'pieces_' + index.toString()"
                                                    :value="bundle.pieces.text"
                                                    clearable
                                                    dense
                                                    solo
                                                    style="width: 100px"
                                                    hide-details="auto"
                                                    @change="updateTallySheetData(index, 'pieces', $event)"
                                                ></v-text-field>
                                            </td>
                                            <td :class="'text-start font-sm ' + (bundle.area.error ? 'red--text' : '')">
                                                <v-text-field
                                                    :key="'area_' + index.toString()"
                                                    :value="bundle.area.text"
                                                    clearable
                                                    dense
                                                    solo
                                                    style="width: 120px"
                                                    hide-details="auto"
                                                    @change="updateTallySheetData(index, 'area', $event)"
                                                ></v-text-field>
                                            </td>
                                            <td :class="'text-start font-sm ' + (bundle.areaUnit.error ? 'red--text' : '')">{{ bundle.areaUnit.text }}</td>
                                            <td :class="'text-start font-sm ' + (bundle.volume.error ? 'red--text' : '')">
                                                <v-text-field
                                                    :key="'volume_' + index.toString()"
                                                    :value="bundle.volume.text"
                                                    clearable
                                                    dense
                                                    solo
                                                    style="width: 120px"
                                                    hide-details="auto"
                                                    @change="updateTallySheetData(index, 'volume', $event)"
                                                ></v-text-field>
                                            </td>
                                            <td :class="'text-start font-sm ' + (bundle.volumeUnit.error ? 'red--text' : '')">{{ bundle.volumeUnit.text }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <div class="d-flex flex-row align-center py-4">
                                <span>{{ $t('message.netWeight') + ':' }}</span>
                                <v-text-field
                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                    :value="weights.net"
                                    class="ml-3"
                                    clearable
                                    dense
                                    solo
                                    style="max-width: 120px !important;"
                                    hide-details="auto"
                                    @change="weights.net = $event"
                                ></v-text-field>
                                <span class="ml-8">{{ $t('message.grossWeight') + ':' }}</span>
                                <v-text-field
                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                    :value="weights.gross"
                                    class="ml-3"
                                    clearable
                                    dense
                                    solo
                                    style="max-width: 120px !important;"
                                    hide-details="auto"
                                    @change="weights.gross = $event"
                                ></v-text-field>
                            </div>
                            <div class="d-flex flex-row pb-3">
                                <v-btn color="success" @click="uploadWizard = 3">{{ $t('message.continue') }}</v-btn>
                                <v-btn class="ml-2" @click="uploadDialog = false">{{ $t('message.cancel') }}</v-btn>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                            <div class="pb-3 font-weight-bold">{{ $t('message.add') + " " + tallySheetData.length + " " + $t('message.itemsToList') + '.' }}</div>
                            <div class="d-flex flex-row pb-3 justify-space-between">
                                <v-btn @click="addToPackingList" :loading="addingToPackingList" color="success">
                                    {{ this.addedToPackingList ? $t('message.added') : $t('message.add') }}
                                    <v-icon v-if="this.addedToPackingList" class="ml-2 white--text">mdi-check</v-icon>
                                </v-btn>
                                <v-checkbox
                                    :label="$t('message.clearCurrentBundles')"
                                    :true-value="true"
                                    :false-value="false"
                                    class="ml-1 black--text text--darken-1"
                                    dense
                                    hide-details="auto"
                                    v-model="resetCurrentList"
                                ></v-checkbox>
                                <v-spacer></v-spacer>
                                <v-btn @click="uploadDialog = false">{{ this.addedToPackingList ? $t('message.close') : $t('message.cancel') }}</v-btn>
                            </div>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {api} from "Api";

export default {
    name: "TallySheetToPackingList",
    props: [
        'dialog',
        'contractId',
        'contractNo',
        'updateMode',
        'packingListId'
    ],
    data() {
        return {
            addedToPackingList: false,
            addingToPackingList: false,
            combineIdenticalBundles: false,
            dialogs: {
                error: false,
                error_message: ""
            },
            enums: {
                containers: [],
                grades: [],
                specifications: [],
            },
            hasFile: {
                tallySheet: true
            },
            headers: [
                { id: 1, text: this.$t('message.container') },
                { id: 2, text: this.$t('message.specification') },
                { id: 3, text: this.$t('message.grade') },
                { id: 4, text: this.$t('message.thickness') },
                { id: 5, text: this.$t('message.width') },
                { id: 6, text: this.$t('message.length') },
                { id: 7, text: this.$t('message.bundles') },
                { id: 8, text: this.$t('message.pieces') },
                { id: 9, text: this.$t('message.area') },
                { id: 10, text: this.$t('message.unit') },
                { id: 11, text: this.$t('message.volume') },
                { id: 12, text: this.$t('message.unit') },
            ],
            loading: {
                add: false,
                get: false,
                save: false
            },
            manualErrorState: [null],
            resetCurrentList: false,
            separatedPages: false,
            tallySheetData: [],
            tallySheetFile: null,
            tallySheetFileTwo: null,
            tallySheetFileThree: null,
            tallySheetFileFour: null,
            upload_dialog: false,
            uploadingFile: false,
            uploadWizard: 1,
            validForm: false,

            weights: {
                net: 0,
                gross: 0
            },

            responseText: []
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('packinglist',{
            Pldo__grossweight: 'Pldo.grossweight',
            Pldo__netweight: 'Pldo.netweight'
        },'statePrefix'),
        ...mapFieldsPrefixed('packinglistitem',{
            PackingListItems: 'PackingListItems'
        },'statePrefix'),
        ...mapGetters('packinglistitem',{
            defaultPackingListItem: 'defaultPackingListItem'
        }),
        ...mapGetters([
            'offices',
            'uofmDimensions',
            'uofmQty',
            'validationRules'
        ]),
        uploadDialog: {
            get() {
                return this.upload_dialog;
            },
            set(value) {
                this.upload_dialog = value
                if(!value) {
                    this.addedToPackingList = false;
                    this.uploadWizard = 1;
                    this.manualErrorState = [null, null, null, null];
                    this.separatedPages = false;
                    this.tallySheetFile = null;
                    this.tallySheetFileTwo = null;
                    this.tallySheetFileThree = null;
                    this.tallySheetFileFour = null;
                    this.tallySheetData = [];
                    this.resetCurrentList = false;
                    this.uploadingFile = false;
                    this.validForm = false;
                    this.weights.net = 0;
                    this.weights.gross = 0;
                    this.$emit('dialog-closed');
                }
            }
        }
    },
    methods: {
        ...mapActions('packinglistitem',{
            clearPackingListItems: 'clearPackingListItems',
        }),
        addToPackingList() {
            this.addingToPackingList = true

            let i = 1
            if(this.resetCurrentList) {
                this.clearPackingListItems(this.statePrefix);
            } else {
                if (this.PackingListItems.length > 0) {
                    i = this.PackingListItems.length + 1
                }
            }

            let newPackingListItems = [];

            this.tallySheetData.forEach((row) => {
                let packingListItem = _.cloneDeep(this.defaultPackingListItem)

                packingListItem.index = i - 1;
                packingListItem.lineNumber = i;
                packingListItem.PackingListItem.area = row.area.value;
                packingListItem.PackingListItem.ameasurement_id = row.areaUnit.value;
                packingListItem.PackingListItem.bundles = row.bundles.value;
                packingListItem.PackingListItem.container = row.container.text;
                packingListItem.PackingListItem.container_id = row.container.value;
                packingListItem.PackingListItem.contract_id = this.contractId;
                packingListItem.PackingListItem.grade_id = row.grade.value;
                packingListItem.PackingListItem.length = row.length.value;
                packingListItem.PackingListItem.lmeasurement_id = row.lengthUnit.value;
                packingListItem.PackingListItem.pieces = row.pieces.value;
                packingListItem.PackingListItem.pldo_id = this.packingListId;
                packingListItem.PackingListItem.salesdescription_id = row.specification.value;
                packingListItem.PackingListItem.shippedmeasurement_id = row.volumeUnit.value
                packingListItem.PackingListItem.smeasurement_id = row.thicknessUnit.value;
                packingListItem.PackingListItem.thickness = row.thickness.text; //use the text not the value
                packingListItem.PackingListItem.type = null;
                packingListItem.PackingListItem.volume = row.volume.value;
                packingListItem.PackingListItem.width = row.width.value;
                packingListItem.PackingListItem.wmeasurement_id = row.widthUnit.value;

                packingListItem.Ameasurement.id = row.areaUnit.value
                packingListItem.Ameasurement.title = row.areaUnit.text

                packingListItem.Container.id = row.container.value
                packingListItem.Container.title = row.container.text
                packingListItem.Container.sealno = null

                packingListItem.Grade.id = row.grade.value
                packingListItem.Grade.title = row.grade.text

                packingListItem.Lmeasurement.id = row.lengthUnit.value
                packingListItem.Lmeasurement.title = row.lengthUnit.text

                packingListItem.Salesdescription.id = row.specification.value
                packingListItem.Salesdescription.title = row.specification.text
                packingListItem.Salesdescription.spec_id = null
                packingListItem.Salesdescription.species_id = null

                packingListItem.ShippedMeasurement.id = row.volumeUnit.value
                packingListItem.ShippedMeasurement.title = row.volumeUnit.text

                packingListItem.Smeasurement.id = row.thicknessUnit.value
                packingListItem.Smeasurement.title = row.thicknessUnit.text

                packingListItem.Wmeasurement.id = row.widthUnit.value
                packingListItem.Wmeasurement.title = row.widthUnit.text

                newPackingListItems.push(packingListItem)
                i++;
            })

            if(newPackingListItems.length > 0) {
                const originalPackingListItems = _.cloneDeep(this.PackingListItems);

                this.PackingListItems = [...newPackingListItems, ...originalPackingListItems]

                // console.log(this.PackingListItems)
            }

            this.Pldo__netweight = this.weights.net;
            this.Pldo__grossweight = this.weights.gross;

            if(i === this.PackingListItems.length + 1) {
                this.addingToPackingList = false
                this.addedToPackingList = true
                this.$toast.success(this.$t('message.successes.itemsAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'check_circle_outline'
                    }
                )
                this.$emit('upload-done');
            } else {
                this.addingToPackingList = false
                this.$toast.error(this.$t('message.errors.itemsNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        checkFile(idx, fileType, fileObj) {
            if(fileObj) {
                let allowedExtensions = null;
                let errorMessage = '';

                if(fileType === 'pdf') {
                    allowedExtensions = this.validationRules.validPdfFileExtensions
                    errorMessage = 'PDF files only'
                }
                if(fileType === 'image') {
                    allowedExtensions = this.validationRules.validImageFileExtensions
                    errorMessage = 'JPg, JPEG, PNG, GIF files only'
                }

                if(allowedExtensions) {
                    if (fileObj.type === "" || allowedExtensions.exec(fileObj.name) == null) {
                        this.manualErrorState[idx] = errorMessage
                    } else {
                        this.manualErrorState[idx] = '';
                    }
                } else {
                    this.manualErrorState[idx] = 'Cannot check the file.';
                }
            }
            this.tallySheetData = []; //reset
        },
        continueWizard(nextStep) {
            switch(nextStep){
                case 1:
                    break
                case 2:
                    break
                case 3:
                    break
            }
        },
        extractTallySheet() {
            if (this.$refs.fileUploadForm.validate()) {
                this.uploadingFile = true;

                const formData = new FormData();
                formData.append('tallySheet', this.tallySheetFile);
                formData.append('tallySheetTwo', this.tallySheetFileTwo);
                formData.append('tallySheetThree', this.tallySheetFileThree);
                formData.append('tallySheetFour', this.tallySheetFileFour);
                formData.append('contractId', this.contractId);

                api
                    .post('/ai/parse-packing-list', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.fillTable(response.data.data);
                            // this.fillEnums(response.data.enums)
                            this.responseText = response.data.data;
                        } else {
                            this.$toast.error(this.$t('message.errors.documentNotParsed'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.uploadingFile = false;
                        }
                    })
                    .catch((err) => {
                        this.$toast.error(this.$t('message.errors.documentNotParsed'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.uploadingFile = false;
                    })
            } else {
                this.$toast.error(this.$t('message.errors.documentNotParsed'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        fillEnums(enums) {
            this.enums.containers = enums.containers
            this.enums.grades = enums.grades
            this.enums.specifications = enums.specifications
        },
        fillTable(data) {
            const _this = this;

            const tallySheetData = data.list
            const weights = data.weights

            tallySheetData.forEach((row) => {
                let bundle = {
                    container: {
                        value: row['container_id'],
                        text: row['container'] ? row['container'] : _this.$t('message.empty'),
                        error: row['container'] == null
                    },
                    specification: {
                        value: row['salesdescription_id'],
                        text: row['salesdescription'] ? row['salesdescription'] : _this.$t('message.empty'),
                        error: row['salesdescription'] == null
                    },
                    grade: {
                        value: row['grade_id'],
                        text: row['grade'] ? row['grade'] : _this.$t('message.empty'),
                        error: row['grade'] == null
                    },
                    thickness: {
                        value: row['thickness_id'],
                        text: row['thickness'] ? row['thickness'] : _this.$t('message.empty'),
                        error: row['thickness'] == null
                    },
                    thicknessUnit: {
                        value: row['smeasurement_id'],
                        text: row['thicknessUnit'] ? row['thicknessUnit'] : _this.$t('message.empty'),
                        error: row['thicknessUnit'] == null
                    },
                    width: {
                        value: row['width'],
                        text: row['width'] ? row['width'] : _this.$t('message.empty'),
                        error: row['width'] == null
                    },
                    widthUnit: {
                        value: row['wmeasurement_id'],
                        text: row['widthUnit'] ? row['widthUnit'] : _this.$t('message.empty'),
                        error: row['widthUnit'] == null
                    },
                    length: {
                        value: row['length'],
                        text: row['length'] ? row['length'] : _this.$t('message.empty'),
                        error: row['length'] == null
                    },
                    lengthUnit: {
                        value: row['lmeasurement_id'],
                        text: row['lengthUnit'] ? row['lengthUnit'] : _this.$t('message.empty'),
                        error: row['lengthUnit'] == null
                    },
                    bundles: {
                        value: row['bundles'],
                        text: row['bundles'] ? row['bundles'] : _this.$t('message.empty'),
                        error: row['bundles'] == null
                    },
                    pieces: {
                        value: row['pieces'],
                        text: row['pieces'] ? row['pieces'] : _this.$t('message.empty'),
                        error: row['pieces'] == null
                    },
                    area: {
                        value: row['area'],
                        text: row['area'] ? row['area'] : null,
                        error: row['area'] == null
                    },
                    areaUnit: {
                        value: row['ameasurement_id'],
                        text: row['areaUnit'] ? row['quantity'] : _this.$t('message.na'),
                        error: row['areaUnit'] == null
                    },
                    volume: {
                        value: row['volume'],
                        text: row['volume'] ? row['volume'] : null,
                        error: row['volume'] == null
                    },
                    volumeUnit: {
                        value: row['shippedmeasurement_id'],
                        text: row['volumeUnit'] ? row['volumeUnit'] : _this.$t('message.na'),
                        error: row['volumeUnit'] == null
                    },
                }
                this.tallySheetData.push(bundle)
            })

            if(weights?.net) this.weights.net = weights.net
            if(weights?.gross) this.weights.gross = weights.gross

            this.uploadingFile = !this.uploadingFile;

            if(this.statePrefix === 'current') {
                this.resetCurrentList = true
            }
        },
        updateTallySheetData(index, field, newValue) {
            //only for width, length, pcs and qty
            this.tallySheetData[index][field]['text'] = newValue
            this.tallySheetData[index][field]['value'] = newValue
        }
    },
    watch: {
        dialog(value) {
            this.upload_dialog = value
            if (!value) {
                this.addedToPackingList = false;
                this.uploadWizard = 1;
                this.manualErrorState = [null, null, null, null];
                this.separatedPages = false;
                this.tallySheetFile = null;
                this.tallySheetFileTwo = null;
                this.tallySheetFileThree = null;
                this.tallySheetFileFour = null;
                this.tallySheetData = [];
                this.resetCurrentList = false;
                this.separatedPages = false;
                this.uploadingFile = false;
                this.validForm = false;
                this.weights.net = 0;
                this.weights.gross = 0;
            }
        }
    },
    created() {

    }
}
</script>

<style scoped>
.v-dialog__content::v-deep .v-dialog {
    min-width: 1000px !important;
}
</style>