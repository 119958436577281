import { api } from "Api";
import {getField, updateField} from "vuex-map-fields";
import {log} from "Helpers/helpers";
import _ from 'lodash';

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		index: 0,
		lineNumber: 1,
		Grade: {
			id: null,
			title: null,
		},
		Mc: {
			id: null,
			title: null,
		},
		Product: {
			id: null,
			title: null,
		},
		Salesdescription: {
			id: null,
			title: null,
		},
		Size: {
			id: null,
			title: null,
		},
		Soldmeasurement: {
			id: null,
			title: null,
		},
		Spec: {
			id: null,
			title: null,
		},
		Species: {
			id: null,
			title: null,
		},
		Stock: {
			id: null,
			bundleno: null,
			certification_id: 0,
			costprice: 0,
			delete_remark: null,
			description: null,
			forwardingcharge: 0,
			fsc: null,
			fsc_description: null,
			grade_id: null,
			importduty: 0,
			itemtaxid: null,
			length: null,
			lmeasurement_id: 4,
			margin: 0,
			mc_id: null,
			measurement_id: null,
			measurementrate: null,
			parent_id: null,
			piece: 0,
			productgroup_id: null,
			salesdescription: null,
			salesdescription_id: null,
			sellingprice: 0,
			size: null,
			size_id: null,
			smeasurement_id: 4,
			soldmeasurement_id: 1,
			spec_id: null,
			species_id: null,
			speciesgroup_id: null,
			split_remark: null,
			status: 'available',
			stock_country_id: 1,
			stockorder_id: null,
			stockstate_id: 3,
			thick: null,
			thickness_id: null,
			volume: 0,
			warehousecost: 0,
			width: null,
			wmeasurement_id: 4,
			Contract: {
				currency_id: 2,
			},
			StockOrder: {
				currency_id: 2,
				ms_id: 1,
				vmeasurement_id: null,
				Supplier: {
					code: null
				}
			}
		},
		Options: {
			grades: [],
			species: [],
			specs: []
		}
	}
}

const getDefaultStateWareHouse = () => {
	return {
		currentExchangeRates: {
			MY: 4.71,
			ID: 15571,
			lastUpdate: null
		},
		currentForwarderCosts: {
			hardwood: 30,
			hpl: 0.10,
			softwood: 20,
			mdf: 25,
			hdf: 30,
			particleBoard: 30,
			plywood: 18,
			veneer: 0.06
		},
		currentImportDuties: {
			MY: 5,
			ID: 5
		},
		currentWareHouseSalesMargins: {
			marginOne: 15,
			marginTwo: 20,
			marginThree: 25,
			marginFour: 30
		},
		currentWareHouseCost: 5
	}
}

const state = {
	current: {
		Bundles: [
			{
				index: 0,
				lineNumber: 1,
				Grade: {
					id: null,
					title: null,
				},
				Mc: {
					id: null,
					title: null,
				},
				Product: {
					id: null,
					title: null,
				},
				Salesdescription: {
					id: null,
					title: null,
				},
				Size: {
					id: null,
					title: null,
				},
				Soldmeasurement: {
					id: null,
					title: null,
				},
				Spec: {
					id: null,
					title: null,
				},
				Species: {
					id: null,
					title: null,
				},
				Stock: {
					id: null,
					bundleno: null,
					costprice: 0,
					forwardingcharge: 0,
					fsc: 'None',
					certification_id: 0,
					delete_remark: null,
					description: null,
					fsc_description: null,
					grade_id: null,
					importduty: 0,
					itemtaxid: null,
					length: null,
					lmeasurement_id: 4,
					margin: 0,
					mc_id: null,
					measurement_id: null,
					measurementrate: null,
					parent_id: null,
					piece: 0,
					productgroup_id: null,
					salesdescription: null,
					salesdescription_id: null,
					sellingprice: 0,
					size: null,
					size_id: null,
					smeasurement_id: 4,
					soldmeasurement_id: 1,
					species_id: null,
					spec_id: null,
					speciesgroup_id: null,
					split_remark: null,
					status: 'available',
					stock_country_id: 1,
					stockorder_id: null,
					stockstate_id: 3,
					thick: null,
					thickness_id: null,
					volume: 0,
					warehousecost: 0,
					width: null,
					wmeasurement_id: 4,
					StockOrder: {
						currency_id: 2,
						ms_id: 1,
						vmeasurement_id: null,
						Supplier: {
							code: null
						}
					}
				},
				Options: {
					grades: [],
					species: [],
					specs: []
				}
			}
		],
		Stock: {
			id: null,
			bundleno: null,
			certification_id: 0,
			delete_remark: null,
			description: null,
			costprice: 0,
			forwardingcharge: 0,
			fsc: null,
			fsc_description: null,
			grade_id: null,
			importduty: 0,
			itemtaxid: null,
			length: null,
			lmeasurement_id: 4,
			margin: 0,
			mc_id: null,
			measurement_id: null,
			measurementrate: null,
			parent_id: null,
			piece: 0,
			productgroup_id: null,
			salesdescription: null,
			salesdescription_id: null,
			sellingprice: 0,
			size: null,
			size_id: null,
			smeasurement_id: 4,
			split_remark: null,
			soldmeasurement_id: 1,
			spec_id: null,
			species_id: null,
			speciesgroup_id: null,
			status: 'available',
			stock_country_id: 1,
			stockorder_id: null,
			stockstate_id: 3,
			thick: null,
			thickness_id: null,
			volume: 0,
			warehousecost: 0,
			width: null,
			wmeasurement_id: 4,
			Contract: {
				currency_id: 2,
			},
			StockOrder: {
				currency_id: 2,
				ms_id: 1,
				vmeasurement_id: null,
				Supplier: {
					code: null
				}
			}
		}
	},
	split: {
		originalBundle: {
			id: null,
			bundleno: null,
			piece: 0,
			split_remark: null,
			volume: 0,
		},
		revisedBundle: {
			id: null,
			bundleno: null,
			piece: 0,
			split_remark: null,
			volume: 0,
		},
		newBundle: {
			id: null,
			bundleno: null,
			piece: 0,
			split_remark: null,
			volume: 0,
		},
	},
	update: {
		Bundles: [
			{
				index: 0,
				lineNumber: 1,
				Grade: {
					id: null,
					title: null,
				},
				Mc: {
					id: null,
					title: null,
				},
				Product: {
					id: null,
					title: null,
				},
				Salesdescription: {
					id: null,
					title: null,
				},
				Size: {
					id: null,
					title: null,
				},
				Soldmeasurement: {
					id: null,
					title: null,
				},
				Spec: {
					id: null,
					title: null,
				},
				Species: {
					id: null,
					title: null,
				},
				Stock: {
					id: null,
				},
				Options: {
					grades: [],
					species: [],
					specs: []
				}
			}
		],
		Stock: {
			id: null,
			bundleno: null,
			certification_id: 0,
			delete_remark: null,
			description: null,
			costprice: 0,
			forwardingcharge: 0,
			fsc: null,
			fsc_description: null,
			grade_id: null,
			itemtaxid: null,
			lmeasurement_id: 4,
			margin: 0,
			mc_id: null,
			measurement_id: null,
			measurementrate: null,
			parent_id: null,
			piece: 0,
			productgroup_id: null,
			salesdescription: null,
			salesdescription_id: null,
			sellingprice: 0,
			size: null,
			size_id: null,
			smeasurement_id: 4,
			soldmeasurement_id: 1,
			spec_id: null,
			species_id: null,
			speciesgroup_id: null,
			split_remark: null,
			status: 'available',
			stock_country_id: 1,
			stockorder_id: null,
			stockstate_id: 3,
			thick: null,
			thickness_id: null,
			volume: 0,
			warehousecost: 0,
			width: null,
			wmeasurement_id: 4,
			Contract: {
				currency_id: 2,
			},
			StockOrder: {
				id: null,
				vmeasurement_id: null,
				currency_id: 2,
				ms_id: 1
			}
		}
	},
	newStockSaved: false,
	newStockUnsaved: false,
	updatedStockUnsaved: false,
	updatedStockSaved: false,
	currentExchangeRates: {
		MY: 4.71,
		ID: 15571,
		lastUpdate: null
	},
	currentImportDuties: {
		MY: 5,
		ID: 5
	},
	currentForwarderCosts: {
		hardwood: 30,
		hpl: 0.10,
		softwood: 20,
		mdf: 25,
		hdf: 30,
		particleBoard: 30,
		plywood: 18,
		veneer: 0.06
	},
	currentWareHouseSalesMargins: {
		marginOne: 15,
		marginTwo: 20,
		marginThree: 25,
		marginFour: 30
	},
	currentWareHouseCost: 5
}

const getters = {
	getField,
	defaultBundle: () => {
		return getDefaultState()
	}
}

const mutations = {
	addBundleRow( state, payload) {
		let prefix = payload.prefix
		let item = getDefaultState()

		//set some fields by checking the last active item in bundle
		let Species = {id: null, title: null};
		let Grade = {id: null, title: null};
		let Product = {id: null, title: null};
		let productGroupId = null;
		let speciesGroupId = null;
		let lengthMeasurementId = null;
		let thicknessMeasurementId = null;
		let widthMeasurementId = null;
		let soldMeasurementId = null;
		let stockOrderId = null;
		let costPrice = 0;
		let forwardingCharge = 0;
		let warehouseCost = 0;

		state[prefix].Bundles.forEach((item) => {
			Grade = item.Grade
			Species = item.Species
			Product = item.Product
			productGroupId = item.Stock.productgroup_id
			speciesGroupId = item.Stock.speciesgroup_id
			lengthMeasurementId = item.Stock.lmeasurement_id
			thicknessMeasurementId = item.Stock.smeasurement_id
			widthMeasurementId = item.Stock.wmeasurement_id
			soldMeasurementId = item.Stock.soldmeasurement_id
			stockOrderId = item.Stock.stockorder_id
			costPrice = item.Stock.costprice
			forwardingCharge = item.Stock.forwardingcharge;
			warehouseCost = item.Stock.warehousecost;
		})

		item.Grade = Grade
		item.Stock.grade_id = Grade?.id
		item.Species = Species
		item.Stock.species_id = Species?.id
		item.Product = Product
		item.Stock.productgroup_id = Product?.id
		item.Stock.speciesgroup_id = speciesGroupId
		item.Stock.lmeasurement_id = lengthMeasurementId
		item.Stock.smeasurement_id = thicknessMeasurementId
		item.Stock.wmeasurement_id = widthMeasurementId
		item.Stock.soldmeasurement_id = soldMeasurementId
		item.Stock.stockorder_id = stockOrderId
		item.Stock.costprice = costPrice
		item.Stock.forwardingcharge = forwardingCharge
		item.Stock.warehousecost = warehouseCost

		console.log(item)

		state[prefix].Bundles.unshift(item)
		let i = 0;
		state[prefix].Bundles.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	clearBundles( state, prefix) {
		state[prefix].Bundles = [];
	},
	copyStockOrderBundleRow(state, payload) {
		let newItem = _.cloneDeep(state[payload.prefix].Bundles[payload.index])
		newItem['Stock']['id'] = null
		newItem['Stock']['bundleno'] = nulls
		state[payload.prefix].Bundles.unshift(newItem)
		let i = 0;
		state[payload.prefix].Bundles.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	deleteStockOrderBundleRow( state, payload ) {
		state[payload.prefix].Bundles.splice(payload.index, 1)
		let i = 0;
		state[payload.prefix].Bundles.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	resetBundles( state, prefix) {
		state[prefix].Bundles = []
		const itemObj = getDefaultState()
		state[prefix].Bundles.push(itemObj)
		let i = 0;
		state[prefix].Bundles.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	resetBundleToUpdate( state ) {
		state.update.Stock = getDefaultState().Stock
	},
	resetCurrentState ( state ) {
		log('Resetting stock order bundles...')
		state.current.Bundles = []
	},
	resetSplitBundle ( state ) {
		state.split.newBundle = {
			id: null,
			bundleno: null,
			split_remark: null,
			piece: 0,
			volume: 0,
		}
		state.split.revisedBundle = {
			id: null,
			bundleno: null,
			split_remark: null,
			piece: 0,
			volume: 0,
		}
		state.split.originalBundle = {
			id: null,
			bundleno: null,
			split_remark: null,
			piece: 0,
			volume: 0,
		}
	},
	resetState ( state, prefix ) {
		log('Resetting stock order bundles...')
		state[prefix].Bundles = []
	},
	resetWareHouseValues ( state ) {
		const defaultValues = getDefaultStateWareHouse()
		state.currentExchangeRates = defaultValues.currentExchangeRates
		state.currentForwarderCosts = defaultValues.currentForwarderCosts
		state.currentWareHouseSalesMargins = defaultValues.currentWareHouseSalesMargins
		state.currentWareHouseCost = defaultValues.currentWareHouseCost
		state.currentImportDuties = defaultValues.currentImportDuties
	},
	setBundleToUpdate ( state, bundle ){
		state.update.Stock = bundle.Stock
	},
	setBundleToSplit ( state, bundle ) {
		state['split'].originalBundle.id = bundle.Stock.id
		state['split'].originalBundle.bundleno = bundle.Stock.bundleno
		state['split'].originalBundle.piece = bundle.Stock.piece
		state['split'].originalBundle.volume = bundle.Stock.volume
		state['split'].revisedBundle.id = bundle.Stock.id
		state['split'].revisedBundle.bundleno = bundle.Stock.bundleno
		state['split'].revisedBundle.piece = bundle.Stock.piece
		state['split'].revisedBundle.volume = bundle.Stock.volume
		state['split'].newBundle.id = null
		state['split'].newBundle.bundleno = bundle.Stock.bundleno
		state['split'].newBundle.piece = 0
		state['split'].newBundle.volume = 0
	},
	setStockOrderBundles ( state, payload ) {
		// console.log(payload)
		state[payload.prefix].Bundles = payload.data
		let i = 0
		state[payload.prefix].Bundles.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			item['Options'] = {
				grades: [],
				species: [],
				specs: []
			}
			i++
		})
		// console.log(state[payload.prefix].Bundles)
	},
	updateBundles (state, payload){
		const keys = payload['field'].split('.')
		const index = payload.index
		const prefix = payload.prefix
		const value = payload.value
		state[prefix].Bundles[index][keys[0]][keys[1]] = value
	},
	updateBundleRow (state, payload) {
		const prefix = payload['prefix']
		const itemIndex = payload['index']
		const stock = payload['stock']
		const stockProperties = Object.keys(stock)

		const spec = payload['spec']
		const species = payload['species']
		const mc = payload['mc']
		const grade = payload['grade']
		const certification = payload['certification']
		const productGroup = payload['productGroup']

		stockProperties.forEach((stockProperty) => {
			state[prefix].Bundles[itemIndex]['Stock'][stockProperty] = stock[stockProperty]
		})
		if(spec.id != null) {
			state[prefix].Bundles[itemIndex]['Spec'] = spec
		}
		if(species.id != null) {
			state[prefix].Bundles[itemIndex]['Species'] = species
		}
		if(mc.id != null) {
			state[prefix].Bundles[itemIndex]['Mc'] = mc
		}
		if(grade.id != null) {
			state[prefix].Bundles[itemIndex]['Grade'] = grade
		}
		if(certification.id != null) {
			state[prefix].Bundles[itemIndex]['Certification'] = certification
		}
		if(productGroup.id != null) {
			state[prefix].Bundles[itemIndex]['Productgroup'] = productGroup
		}
	},
	updateBundleRows (state, payload) { //for bulk price update
		const itemIndexes = payload['indexes']
		const prefix = payload['prefix']
		const values = payload['values']
		const stockProperties = Object.keys(values)
		itemIndexes.forEach((itemIndex) => {
			if(state[prefix].Bundles[itemIndex]['Stock']['stockstate_id'] == 3) {
				stockProperties.forEach((stockProperty) => {
					state[prefix].Bundles[itemIndex]['Stock'][stockProperty] = values[stockProperty]
				})
			}
		})
	},
	updateField
}

const actions = {
	addBundleRow ( { commit }, prefix ) {
		const payload = {
			prefix: prefix
		}
		commit('addBundleRow',payload)
	},
	clearStockOrderBundles ({commit}, prefix) {
		return new Promise ((resolve, reject) => {
			try {
				commit('clearBundles',prefix)
				resolve('done')
			} catch (e){
				reject(e)
			}
		})
	},
	deleteStockOrderBundle ({commit}, payload) {
		return new Promise((resolve, reject) => {
			const stockOrderId = payload.stockOrderId
			const stockId = payload.itemId
			const deleteRemark = payload.deleteRemark
			api
				.delete('/stockorders/' + stockOrderId + '/bundles/' + stockId, {
					delete_reason: deleteReason
				})
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	deleteStockById ( {commit}, payload ) {
		const stockId = payload.stockId
		const deleteRemark = payload.deleteRemark
		return new Promise((resolve, reject) => {
			api
				.delete('/stocks/' + stockId , {
					data: {
						bundle: {
							delete_remark: deleteRemark
						}
					}
				})
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	getAllStocks ( {commit}, payload ) {
		const filterOptions = payload.filterOptions
		let conditions = []
		if(filterOptions.office) {
			const obj = {
				field: 'stockorders.stock_country_id',
				value: filterOptions.office
			}
			conditions.push(obj)
		}
		if(filterOptions.species) {
			const obj = {
				field: 'stocks.species_id',
				value: filterOptions.species
			}
			conditions.push(obj)
		}
		if(filterOptions.product) {
			const obj = {
				field: 'stocks.productgroup_id',
				value: filterOptions.product
			}
			conditions.push(obj)
		}
		if(filterOptions.supplier) {
			const obj = {
				field: 'contracts.supplier_id',
				value: filterOptions.supplier
			}
			conditions.push(obj)
		}
		if(filterOptions.claim) {
			const obj = {
				field: 'stocks.certification_id',
				value: filterOptions.claim
			}
			conditions.push(obj)
		}
		conditions.push({field: 'stocks.stockstate_id', value: 3})
		return new Promise((resolve, reject) => {
			api
				.get("/stocks",{
					params: {
						conditions: conditions,
						order: [
							{
								field: 'species.title',
								direction: 'ASC'
							},
							{
								field: 'stocks.bundleno',
								direction: 'ASC'
							}
						]
					}
				})
				.then(response => {
					let tableData = {
						rows: response.data.data,
						totalRows: response.data.totalRows,
						totalPieces: response.data.totalPieces,
						totalVolumes: response.data.totalVolumes
					}
					resolve(tableData)
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getBundlesByStockOrder( {commit}, payload ){
		const stockOrderId = payload.stockOrderId
		const prefix = payload.prefix
		commit('resetState', payload.prefix)
		return new Promise((resolve, reject) => {
			api
				.get("/stockorders/" + stockOrderId + "/bundles")
				.then(response => {
					const payload = {
						data: response.data.data,
						prefix: prefix
					}
					commit('setStockOrderBundles', payload);
					resolve('done');
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getBundleToSplitById ({commit}, bundleId) {
		return new Promise((resolve, reject) => {
			api
				.get('/stocks/' + bundleId )
				.then(response => {
					commit('setBundleToSplit', response.data.data[0])
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	getBundleToUpdateById ({commit}, bundleId) {
		return new Promise((resolve, reject) => {
			api
				.get('/stocks/' + bundleId )
				.then(response => {
					commit('setBundleToUpdate', response.data.data[0])
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	resetStockOrderBundles ({commit}, prefix) {
		return new Promise ((resolve, reject) => {
			try {
				commit('resetBundles',prefix)
				resolve('done')
			} catch (e){
				reject(e)
			}
		})
	},
	resetBundleToSplit ({commit}) {
		commit('resetSplitBundle')
		return true
	},
	resetBundleToUpdate ({commit}) {
		commit('resetBundleToUpdate')
		return true
	},
	resetWareHouseValues ({commit}) {
		return new Promise ((resolve, reject) => {
			try {
				commit('resetWareHouseValues')
				resolve('done')
			} catch (e){
				reject(e)
			}
		})
	},
	setBundlesByStockPo({commit}, bundles) {
		const prefix = 'current'
		const payload = {
			data: bundles,
			prefix: prefix
		}
		// commit('resetState', )
		commit('setStockOrderBundles', payload);
	},
	splitBundle ({commit}, payload) {
		return new Promise((resolve, reject) => {
			const stockId = payload.stockId
			const newBundle = payload.newBundle
			const revisedBundle = payload.revisedBundle
			api
				.put('/stocks/' + stockId + '/split',{
					newBundle: newBundle,
					revisedBundle: revisedBundle
				})
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	updateSplitBundleTotals () {

	},
	updateBundle ({state, rootState}){
		let stock = _.cloneDeep(state.update.Stock)
		//remove unneeded properties
		delete stock.Length
		// delete stock.Contract
		// delete stock.StockOrder
		delete stock.Thickness
		delete stock.Width

		return new Promise((resolve, reject) => {
			api
				.put("/stocks/" + state.update.Stock.id,{
					stock: stock
				})
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.then(()=>{
					resolve('done')
				});
		})
	},
	updateBundles ({commit}, payload) {
		let bundles = payload.bundles
		let bundleIds = []

		const fields = payload.fields

		bundles.forEach(b => {
			if(b.Stock.id != null){
				bundleIds.push(b.Stock.id)
			}
		})
		return new Promise((resolve, reject) => {
			api
				.put("/stocks/multiple", {
					stockIds: bundleIds,
					fields: fields
				})
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.then(()=>{
					resolve('done')
				});
		})
	},
	updateBundlesCost({commit}, payload) {
		let bundles = payload.bundles
		let bundleIds = []

		const fields = payload.fields

		bundles.forEach(b => {
			if(b.Stock.id != null){
				bundleIds.push(b.Stock.id)
			}
		})
		return new Promise((resolve, reject) => {
			api
				.put("/stocks/multiple-cost", {
					stockIds: bundleIds,
					fields: fields
				})
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				.then(()=>{
					resolve('done')
				});
		})
	},
	updateBundleStatus ({commit}, payload) {
		return new Promise((resolve, reject) => {
			const stockOrderId = payload.stockOrderId
			const itemId = payload.itemId
			const stock = {
				stockstate_id: payload.status
			}
			api
				.put('/stockorders/' + stockOrderId + '/bundles/' + itemId, {
					stock: stock,
				})
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
		})
	}
}

export const stock = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}