import {api} from "Api";
import {getField, updateField} from "vuex-map-fields";
import {isNumeric} from "Helpers/helpers";
import _ from 'lodash';

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        index: 0,
        lineNumber: 1,
        Ameasurement: {
            id: 5,
            title: 'M2'
        },
        Container: {
            id: null,
            sealno: null,
            title: null,
        },
        Grade: {
            id: '',
            title: null
        },
        Lmeasurement: {
            id: 8,
            title: 'M'
        },
        PackingListItem: {
            id: null,
            area: 0,
            ameasurement_id: 4,
            bundles: 0,
            container: null,
            container_id: null,
            contract_id: null,
            grade_id: null,
            length: 0,
            lmeasurement_id: 8,
            pieces: 0,
            pldo_id: null,
            salesdescription_id: null,
            shippedmeasurement_id: 1,
            smeasurement_id: 4,
            thickness: 0,
            type: null,
            volume: 0,
            width: 0,
            wmeasurement_id: 4,
        },
        Salesdescription: {
            id: null,
            spec_id: null,
            species_id: null,
            title: null,
        },
        Smeasurement: {
            id: 4,
            title: 'MM'
        },
        ShippedMeasurement: {
            id: 1,
            title: 'M3'
        },
        Wmeasurement: {
            id: 4,
            title: 'MM'
        }
    }
}

const state = {
    //current data. can either be a loaded existing contract or a new unsaved contract
    current: {
        PackingListItems: [
            {
                index: 0,
                lineNumber: 1,
                Ameasurement: {
                    id: 5,
                    title: 'M2'
                },
                Container: {
                    id: null,
                    sealno: null,
                    title: null,
                },
                Grade: {
                    id: '',
                    title: null
                },
                Lmeasurement: {
                    id: 8,
                    title: 'M'
                },
                PackingListItem: {
                    id: null,
                    area: 0,
                    ameasurement_id: null,
                    bundles: 0,
                    container: null,
                    container_id: null,
                    contract_id: null,
                    grade_id: null,
                    length: 0,
                    lmeasurement_id: 8,
                    pieces: 0,
                    pldo_id: null,
                    salesdescription_id: null,
                    shippedmeasurement_id: 1,
                    smeasurement_id: 4,
                    thickness: 0,
                    type: null,
                    volume: 0,
                    width: 0,
                    wmeasurement_id: 4,
                },
                Salesdescription: {
                    id: null,
                    spec_id: null,
                    species_id: null,
                    title: null,
                },
                Smeasurement: {
                    id: 4,
                    title: 'MM'
                },
                ShippedMeasurement: {
                    id: 1,
                    title: 'M3'
                },
                Wmeasurement: {
                    id: 4,
                    title: 'MM'
                }
            }
        ],
        itemsTotalBundles: 0,
        itemsTotalQty: 0,
        itemsTotalArea: [],
        itemsTotalVolume: [],
    },
    update: {
        PackingListItems: [],
        itemsTotalBundles: 0,
        itemsTotalQty: 0,
        itemsTotalArea: [],
        itemsTotalVolume: [],
    }
}

const getters = {
    getField,
    itemsTotalBundles: state => { return state.current.itemsTotalBundles },
    itemsTotalQty: state => { return state.current.itemsTotalQty },
    itemsTotalArea: state => { return state.current.itemsTotalArea },
    itemsTotalVolume: state => { return state.current.itemsTotalVolume },
    defaultPackingListItem: () => {
        return getDefaultState()
    }
}

const mutations = {
    addPackingListItemRow (state, payload) {
        //decide copy or add item
        let prefix = payload.prefix
        let containers = payload.containers
        let salesdescriptions = payload.saledescriptions
        let grades = payload.grades
        if(state[prefix].PackingListItems.length < 1) {
            //add new item row
            //add container to this new row
            let item = getDefaultState()
            // if (payload.container.constructor === Object) {
            //     item.Container = payload.container.Container
            //     item.PackingListItem.container_id = payload.container.Container.id
            // }
            //add salesdescription to this new row
            // if (payload.salesdescription.constructor === Object) {
            //     item.Salesdescription = payload.salesdescription.Salesdescription
            //     item.PackingListItem.salesdescription_id = payload.salesdescription.Salesdescription.id
            // }
            state[prefix].PackingListItems.push(item)
        } else {
            //copy last item row
            let item = getDefaultState()
            item.PackingListItem.container_id = state[prefix].PackingListItems[state[prefix].PackingListItems.length - 1].PackingListItem.container_id
            item.PackingListItem.container = state[prefix].PackingListItems[state[prefix].PackingListItems.length - 1].PackingListItem.container
            item.PackingListItem.salesdescription_id = state[prefix].PackingListItems[state[prefix].PackingListItems.length - 1].PackingListItem.salesdescription_id
            item.PackingListItem.grade_id = state[prefix].PackingListItems[state[prefix].PackingListItems.length - 1].PackingListItem.grade_id
            if(isNaN(item.PackingListItem.container_id)){
                item.Container = _.cloneDeep(containers.find(container => container.Container.title == item.PackingListItem.container_id)?.Container)
            } else {
                item.Container = _.cloneDeep(containers.find(container => container.Container.id == item.PackingListItem.container_id)?.Container)
            }
            item.Salesdescription = _.cloneDeep(salesdescriptions.find(salesdescription => salesdescription.Salesdescription.id === item.PackingListItem.salesdescription_id)?.Salesdescription)
            item.Grade = grades.find(grade => grade.Grade.id === item.PackingListItem.grade_id)?.Grade
            state[prefix].PackingListItems.push(item)
        }
        let i = 0;
        state[prefix].PackingListItems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    clearPackingList( state, prefix) {
        state[prefix].PackingListItems = [];
    },
    deletePackingListItemRow (state, payload) {
        let index = payload.index
        let prefix = payload.prefix
        state[prefix].PackingListItems.splice(index, 1)
        let i = 0;
        state[prefix].PackingListItems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    resetState (state,prefix){
        state[prefix].PackingListItems = []
        let item = getDefaultState()
        state[prefix].PackingListItems.push(item)
        state[prefix].itemsTotalQty = 0
        state[prefix].itemsTotalVolume = []
    },
    setPackingListItems (state,payload){
        state[payload.prefix].PackingListItems = payload.data
        let i = 0;
        state[payload.prefix].PackingListItems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    updateField,
    updatePackingListItems (state, payload){
        let keys = payload['field'].split('.')
        let index = payload.index
        let prefix = payload.prefix
        // console.log(index)
        state[prefix].PackingListItems[index][keys[0]][keys[1]] = payload.value
    },
    updateTotals (state, prefix) {
        let bundles = 0, quantities = 0, volumes = [], areas = []
        state[prefix].PackingListItems.forEach((item) => {
            let v_idx = volumes.findIndex(t => t.Total.uofm == item.ShippedMeasurement.title);
            let a_idx = areas.findIndex(t => t.Total.uofm == item.Ameasurement.title);
            if(parseFloat(item.PackingListItem.volume) > 0) {
                if (v_idx !== -1) {
                    volumes[v_idx]['Total']['volume'] += isNumeric(item.PackingListItem.volume) ? parseFloat(item.PackingListItem.volume) : 0;
                } else {
                    let v_obj = {'Total': {}}
                    v_obj['Total']['uofm'] = item.ShippedMeasurement.title
                    v_obj['Total']['volume'] = isNumeric(item.PackingListItem.volume) ? parseFloat(item.PackingListItem.volume) : 0;
                    volumes.push(v_obj)
                }
            }
            if(parseFloat(item.PackingListItem.area) > 0) {
                if (a_idx !== -1) {
                    areas[a_idx]['Total']['area'] += isNumeric(item.PackingListItem.area) ? parseFloat(item.PackingListItem.area) : 0;
                } else {
                    let v_obj = {'Total': {}}
                    v_obj['Total']['uofm'] = item.Ameasurement.title
                    v_obj['Total']['area'] = isNumeric(item.PackingListItem.area) ? parseFloat(item.PackingListItem.area) : 0;
                    areas.push(v_obj)
                }
            }
            quantities += isNumeric(item.PackingListItem.pieces) ? parseFloat(item.PackingListItem.pieces) : 0;
            bundles += isNumeric(item.PackingListItem.bundles) ? parseFloat(item.PackingListItem.bundles) : 0;
        })
        state[prefix].itemsTotalBundles = bundles
        state[prefix].itemsTotalQty = quantities
        state[prefix].itemsTotalArea = areas
        state[prefix].itemsTotalVolume = volumes
        console.log('updating totals...')
    }
}

const actions = {
    addPackingListItemRow ({commit, rootState},prefix) {
        let payload = {
            prefix: prefix,
            containers: rootState.appic.packinglist[prefix].Pldo.Containers,
            saledescriptions: rootState.appic.packinglist[prefix].Pldo.Salesdescriptions,
            grades: rootState.appic.grade.allGrades
        }
        commit('addPackingListItemRow',payload)
    },
    clearPackingListItems ({commit}, prefix) {
        return new Promise ((resolve, reject) => {
            try {
                commit('clearPackingList',prefix)
                resolve('done')
            } catch (e){
                reject(e)
            }
        })
    },
    deletePackingListItemRow ({commit},payload){
        commit('deletePackingListItemRow',payload)
    },
    deletePackingListItem ({commit},payload){
        return new Promise((resolve, reject) => {
            let packinglist_id = payload.packinglist_id
            let packinglistitem_id = payload.packinglistitem_id
            api
                .delete('/packinglists/' + packinglist_id + '/packinglistitems/' + packinglistitem_id)
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .finally(()=>{
                    resolve('done')
                })
        })
    },
    getSalesItemsByContract ({commit},payload) {
        let contract_id = payload.contract_id
        let prefix = payload.prefix
        let module = 'contracts'
        if(prefix == 'update'){
            module = 'packinglists'
        }
    },
    getItemsByPackingList ({commit},payload) {
        console.log('Getting PL items')
        let packinglist_id = payload.packinglist_id
        let prefix = payload.prefix
        return new Promise((resolve, reject) => {
            api
                .get("/packinglists/" + packinglist_id + "/packinglistitems")
                .then(response => {
                    let payload = {
                        data: response.data.data,
                        prefix: prefix
                    }
                    commit('setPackingListItems',payload);
                    resolve('done');
                })
                .catch(error => {
                    reject(error);
                })
                .finally(function(){
                    commit('updateTotals',prefix);
                    resolve('done');
                })
        })
    },
    resetPackingListItems ({commit},prefix) {
        console.log('Resetting PL items')
        return new Promise ((resolve, reject) => {
            commit('resetState',prefix)
            resolve('done')
        })
    },
    updateTotals ({commit}, prefix) {
        commit('updateTotals', prefix)
    }
}

export const packinglistitem = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}