<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <div class="d-flex flex-row align-center pt-3 col-12">
                    <v-text-field
                        :placeholder="$t('message.no')"
                        :value="searchValue"
                        autocomplete="new-password"
                        class="filter-width-190 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchValue = $event"
                        @click:append="searchEudrShipments()"
                        @click:clear="resetSearch()"
                        @keyup.enter.prevent="searchEudrShipments()"
                    />
                    <v-autocomplete
                        :items="filterItems.buyer"
                        :loading="loading.filterItems.buyer"
                        :placeholder="$t('message.buyer')"
                        class="filter-width-190 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Customer.title"
                        item-value="Customer.id"
                        solo
                        v-model="filterValues.buyer"
                        @change="filterEudrShipments()"
                    />
                    <v-autocomplete
                        :items="filterItems.supplier"
                        :loading="loading.filterItems.supplier"
                        :placeholder="$t('message.spl')"
                        class="filter-width-190 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Supplier.title"
                        item-value="Supplier.id"
                        solo
                        v-model="filterValues.supplier"
                        @change="filterEudrShipments()"
                    />
                    <v-select
                        :items="filterItems.docStatus"
                        :placeholder="$t('message.documentStatus')"
                        class="filter-width-190 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        solo
                        v-model="filterValues.docStatus"
                        @change="filterEudrShipments()"
                    ></v-select>
                    <v-btn :loading="loading.clear" class="ml-3 mt-1" @click="clearFilters"><v-icon>refresh</v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <ExportTableJson
                        :export-conditions="[]"
                        :export-data="eudrShipments"
                        :export-fields="headers"
                        :export-source="'eudr-document-status-report'"
                        class="mt-1"
                    />
                </div>
            </v-row>
            <v-row no-gutters pt-2 pb-0>
                <div class="d-flex flex-row align-center mt-3">
                    <div class="font-sm">
                        <v-icon small color="green lighten-1">fiber_manual_record</v-icon>{{ $t('message.contractOpen') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="orange lighten-3">fiber_manual_record</v-icon>{{ $t('message.contractCompleted') }}
                    </div>
                </div>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.eudrShipments"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
                showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="hideDefaultFooter"
            :items="eudrShipments"
            :options="tableOptions"
            :server-items-length="totalEudrShipments"
            calculate-widths
            class="mt-1 appic-table-light specification-table"
            dense
            id="eudrShipmentTable"
            item-key="Contract.id"
            @update:options="updateDataTable"
        >
            <template v-slot:item.Contract.id="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.moreActions') }}</span>
                        </v-tooltip>
                    </template>
                    <v-list dense>
                        <v-list-item class="font-sm" @click="updateShipment(item.Contract.id)" v-if="$can('update','Shipment')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateShipment') }}</v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <template v-slot:item.Contract.status="{ item }">
                <div class="pl-3">
                    <v-icon small color="green lighten-1" v-if="parseInt(item.Contract.status) === 1">fiber_manual_record</v-icon>
                    <v-icon small color="orange lighten-3" v-if="parseInt(item.Contract.status) === 2">fiber_manual_record</v-icon>
                </div>
            </template>
            <template v-slot:item.Contract.title="{ item }">
                <span class="font-weight-bold">{{ item.Contract.title }}</span>
            </template>
            <template v-slot:item.Contract.contractdate="{ item }">
                {{ item.Contract.contractdate !== '0000-00-00' ? formatDate(item.Contract.contractdate) : '' }}
            </template>
            <template v-slot:item.Contract.shipweekdate="{ item }">
                {{ item.Contract.shipweekdate !== '0000-00-00' ? formatDate(item.Contract.shipweekdate) : '' }}
            </template>
            <template v-slot:item.Contract.etddate="{ item }">
                {{ item.Contract.etddate !== '0000-00-00' ? formatDate(item.Contract.etddate) : '' }}
            </template>
            <template v-slot:item.Contract.etadate="{ item }">
                {{ item.Contract.etadate !== '0000-00-00' ? formatDate(item.Contract.etadate) : '' }}
            </template>
            <template v-slot:item.Eudr.complete="{ item }">
                <div class="pl-3 text-center">
                    <div class="font-weight-bold green--text darken--text-1 pr-4" v-if="item.Eudr.complete">
                        <v-icon small class="green--text darken--text-1">mdi-check</v-icon>
                        <span>{{ $t('message.yes') }}</span>
                    </div>
                    <div class="font-weight-bold red--text darken--text-1 pr-3" v-if="!item.Eudr.complete">{{ $t('message.no') }}</div>
                </div>
            </template>
            <template v-slot:item.Eudr.files="{ item }">
                <div v-if="item.Eudr.files.length > 0" class="d-flex flex-row align-center">
                    <v-badge class="pl-0 ml-0" color="grey" inline left :content="item.Eudr.files.length">
                        <v-btn small @click="showFiles(item.Contract.title, item.Eudr.files)">{{ $t('message.show') }}</v-btn>
                    </v-badge>
                </div>
                <div class="pl-3 text-center" v-if="item.Eudr.files.length === 0">
                    <div class="pr-3">{{ $t('message.na') }}</div>
                </div>
            </template>
            <template v-slot:item.Eudr.buyerNotified="{ item }">
                {{ item.Eudr.buyerNotified !== '0000-00-00' ? formatDate(item.Eudr.buyerNotified) : '' }}
            </template>
        </v-data-table>
        <v-dialog
            width="600px"
            persistent
            v-model="fileDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
        >
            <v-card>
                <v-card-title>{{ selectedContract }}</v-card-title>
                <v-card-text>
                    <v-simple-table
                        class="appic-table-light specification-tables"
                        dense
                    >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct">#</th>
                                    <th class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left">{{ $t('message.name') }}</th>
                                    <th class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left">{{ $t('message.file') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(file, index) in selectedFiles" v-bind:key="'row_' + file.title">
                                    <td class="text-start">{{ index + 1 }}</td>
                                    <td class="text-start">{{ file.title }}</td>
                                    <td class="text-start">
                                        <v-btn class="ml-0" icon @click="viewFile(file.location)">
                                            <v-icon style="color: darkgray !important;" small>fas fa-external-link-alt</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions class="pl-7">
                    <v-btn color="default" small @click="fileDialog = false" >{{$t('message.dismiss')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <SimpleAlert
            :alert_message.sync="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        ></SimpleAlert>
    </div>
</template>

<script>
import {formatDate} from "Helpers/helpers";
import {api} from "Api";
import {mapFields} from "vuex-map-fields";

const ExportTableJson = () => import("Components/Appic/ExportTableJson");
const SimpleAlert = () => import("Components/Appic/SimpleAlert");

export default {
    name: "EudrDocumentStatusList",
    components: {ExportTableJson, SimpleAlert},
    data() {
        return {
            currentFilterValues: null,
            currentSearchValue: null,
            dialogs: {
                error:  false,
                error_message: ""
            },
            eudrShipments: [],
            fileDialog: false,
            filterItems: {
                buyer: [],
                docStatus: [
                    {value: 1, text: this.$t('message.documentsCompleted') },
                    {value: 0, text: this.$t('message.documentsPending') }
                ],
                supplier: []
            },
            filterValues: {
                buyer: null,
                docStatus: null,
                supplier: null,
            },
            hideDefaultHeader: false,
            hideDefaultFooter: false,
            loading: {
                clear: false,
                eudrShipments: false,
                filter: {
                    buyers: false,
                    suppliers: false,
                },
                filterItems: {
                    buyer: null,
                    supplier: null,
                }
            },
            searching: false,
            searchValue: null,
            selectedContract: null,
            selectedFiles: [],
            tableOptions: {
                sortBy: ['Contract.etddate','Contract.shipweekdate'],
                sortDesc: [false, false]
            },
            totalEudrShipments: 0,
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Contract.id',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    sortable: false,
                    searchable: true
                },
                {
                    id: 2,
                    text: this.$t('message.contract'),
                    value: 'Contract.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 3,
                    text: this.$t('message.status'),
                    value: 'Contract.status',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.date'),
                    value: 'Contract.contractdate',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 5,
                    text: this.$t('message.type'),
                    value: 'Contract.type',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false,
                    searchable: true
                },
                {
                    id: 6,
                    text: this.$t('message.buyer'),
                    value: 'Contract.buyer',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 7,
                    text: this.$t('message.supplier'),
                    value: 'Contract.supplier',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 8,
                    text: this.$t('message.origin'),
                    value: 'Contract.origin',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 9,
                    text: this.$t('message.destination'),
                    value: 'Contract.destination',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 10,
                    text: this.$t('message.osd'),
                    value: 'Contract.shipweekdate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 11,
                    text: this.$t('message.etd'),
                    value: 'Contract.etddate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 12,
                    text: this.$t('message.eta'),
                    value: 'Contract.etadate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 13,
                    text: this.$t('message.eutrEudrDocumentsComplete'),
                    value: 'Eudr.complete',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 14,
                    text: this.$t('message.filesUploaded'),
                    value: 'Eudr.files',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 15,
                    text: this.$t('message.buyerNotified'),
                    value: 'Eudr.buyerNotified',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false,
                    searchable: false
                },
            ]
        },
        itemsPerPageOptions() {
            let options = [10,20,50,-1];
            if(window.screen.height >= 800){
                options = [15,30,50,-1];
            }
            return options;
        },
    },
    methods: {
        clearFilters() {
            this.loading.clear = true
            this.filterValues.buyer = null
            this.filterValues.supplier = null
            this.filterValues.docStatus = null
            this.searching = false
            this.searchValue = null
            this.currentSearchValue = null
            this.tableOptions.page = 1
            this.tableOptions.sortBy[0] = 'Contract.etddate'
            this.tableOptions.sortBy[1] = 'Contract.shipweekdate'
            this.tableOptions.sortDesc[0] = false
            this.tableOptions.sortDesc[1] = false
            this.loading.clear = false
            this.filterEudrShipments()
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        filterEudrShipments() {
            this.searchValues = null;

            let noValues = true;
            const keys = Object.keys(this.filterValues)
            keys.forEach((key, index) => {
                if(this.filterValues[key] != null) noValues = false
            })

            this.loading.eudrShipments = true;

            if(noValues === false) {
                let conditions = []
                if(this.filterValues.buyer != null) conditions.push({field: 'Contract.buyer', value: this.filterValues.buyer})
                if(this.filterValues.supplier != null) conditions.push({field: 'Contract.supplier', value: this.filterValues.supplier})
                if(this.filterValues.docStatus != null) conditions.push({field: 'Contract.docStatus', value: this.filterValues.docStatus})

                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                if(_.isEqual(this.currentFilterValues,this.filterValues) === false) {
                    this.tableOptions.page = 1
                    this.currentFilterValues = _.cloneDeep(this.filterValues)
                }

                if(_.has(this.tableOptions, 'sortBy') === false) {
                    this.tableOptions['sortBy'] = ['Contract.etddate','Contract.shipweekdate']
                }
                if(this.tableOptions.sortBy.length === 0){
                    this.tableOptions['sortBy'] = ['Contract.etddate','Contract.shipweekdate']
                }

                if(_.has(this.tableOptions, 'sortDesc') === false){
                    this.tableOptions['sortDesc'] = [false, false]
                }

                let order = [];

                this.tableOptions.sortBy.forEach((field, index) => {
                    order.push({field: field, direction: this.tableOptions.sortDesc[index] ? 'DESC' : 'ASC'})
                })

                const payload = {
                    tableOptions: this.tableOptions,
                    conditions: conditions,
                    order: order
                }

                this.searchAllEudrShipments(payload)
                    .then(() => {
                        this.loading.eudrShipments = false;
                    })
                    .catch(()=>{
                        this.loading.eudrShipments = false;
                    })
            } else {
                this.reloadEudrShipments()
                    .then(() => {
                        this.loading.eudrShipments = false;
                    })
                    .catch(() => {
                        this.loading.eudrShipments = false;
                    })
            }
        },
        formatDate,
        getAllEudrShipments(payload) {
            return new Promise((resolve, reject) => {
                if(payload.tableOptions.page != null) {
                    api
                        .get("/shipments/eutr-eudr", {
                            params: {
                                pagination: {
                                    skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                                    take: payload.tableOptions.itemsPerPage
                                },
                                order: payload.order
                            }
                        })
                        .then(response => {
                            this.eudrShipments = response.data.data
                            this.totalEudrShipments = response.data.totalRows
                            resolve('done')
                        })
                        .catch(error => {
                            reject(error);
                        });
                } else {
                    resolve('done')
                }
            })
        },
        loadEudrShipments() {
            if(!this.loading.eudrShipments) this.loading.eudrShipments = true;

            if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

            const payload = {
                tableOptions: this.tableOptions,
                // filterOptions: this.filterOptions
            }

            this.getAllEudrShipments(payload)
                .then(() => {
                    this.loading.eudrShipments = false;
                })
                .catch(() => {
                    this.loading.eudrShipments = false;
                })
        },
        loadFilterItems(filter) {
            return new Promise((resolve, reject) => {
                if(filter && filter.length > 0) {
                    this.loading.filterItems[filter] = true
                    api
                        .get('/shipments/eutr-eudr/filter-options/' + filter)
                        .then(response => {
                            if(response.data.status === 'success') {
                                this.filterItems[filter] = response.data.data
                                this.loading.filterItems[filter] = false
                                resolve('done')
                            } else {
                                this.loading.filterItems[filter] = false
                                reject('error')
                            }
                        })
                        .catch(error => {
                            this.loading.filterItems[filter] = false
                            reject(error)
                        })
                } else {
                    reject('Filter empty')
                }
            })
        },
        reloadEudrShipments() {
            return new Promise((resolve, reject) => {
                try {
                    if(!this.loading.eudrShipments) this.loading.eudrShipments = true;

                    if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                    if(_.has(this.tableOptions, 'sortBy') === false) {
                        this.tableOptions['sortBy'] = ['Contract.etddate','Contract.shipweekdate']
                    }
                    if(this.tableOptions.sortBy.length === 0){
                        this.tableOptions['sortBy'] = ['Contract.etddate','Contract.shipweekdate']
                    }

                    if(_.has(this.tableOptions, 'sortDesc') === false){
                        this.tableOptions['sortDesc'] = [false, false]
                    }

                    let order = [];

                    this.tableOptions.sortBy.forEach((field, index) => {
                        order.push({field: field, direction: this.tableOptions.sortDesc[index] ? 'DESC' : 'ASC'})
                    })

                    const payload = {
                        tableOptions: this.tableOptions,
                        order: order
                    }

                    this.getAllEudrShipments(payload)
                        .then(() => {
                            resolve('done')
                        })
                        .catch(() => {
                            reject('error')
                        })
                } catch (error){
                    reject('error')
                }
            })
        },
        resetSearch() {
            this.searching = false
            this.hideDefaultFooter = false
            this.filterValues.buyer = null
            this.filterValues.supplier = null
            this.filterValues.docStatus = null
            this.tableOptions.page = 1
            this.currentSearchValue = null;
            this.searchValue = null
            this.currentFilterValues = null

            this.tableOptions.sortBy = ['Contract.etddate','Contract.shipweekdate']
            this.tableOptions.sortDesc = [false, false]

            if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

            this.filterEudrShipments()
        },
        searchAllEudrShipments(payload) {
            return new Promise((resolve, reject) => {
                api
                    .get("/shipments/eutr-eudr",{
                        params: {
                            conditions: payload.conditions,
                            pagination: {
                                skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                                take: payload.tableOptions.itemsPerPage
                            },
                            order: payload.order
                        }
                    })
                    .then(response => {
                        this.eudrShipments = response.data.data
                        this.totalEudrShipments = response.data.totalRows
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .then(()=>{
                        resolve('done')
                    });
            })
        },
        searchEudrShipments() {
            if(this.searchValue == null || this.searchValue.length === 0){
                this.dialogs.error = true
                this.dialogs.error_message = this.$t('message.errors.noSearchTerm')
            } else {
                this.searching = true
                this.filterValues.buyer = null
                this.filterValues.supplier = null
                this.filterValues.docStatus = null

                this.loading.eudrShipments = true

                let payload = {}
                let order = []

                const fieldName = 'Contract.title';

                if (_.isEqual(this.currentSearchValue, this.searchValue) === false) {
                    //new search = new sort
                    this.tableOptions.sortBy[0] = fieldName
                    this.tableOptions.sortDesc[0] = false

                    order.push({field: fieldName, direction: 'ASC'})

                    this.currentSearchValue = _.cloneDeep(this.searchValue)

                    //new search new page
                    this.tableOptions.page = 1
                } else {
                    //same search new sort
                    if(this.tableOptions.sortBy.length === 0){
                        this.tableOptions.sortBy[0] = 'Contract.title'
                        this.tableOptions.sortBy[1] = 'Contract.shipweekdate'
                        this.tableOptions.sortDesc[0] = false
                        this.tableOptions.sortDesc[1] = false
                    }

                    this.tableOptions.sortBy.forEach((field, index) => {
                        order.push({field: field, direction: this.tableOptions.sortDesc[index] ? 'DESC' : 'ASC'})
                    })
                }


                payload = {
                    tableOptions: this.tableOptions,
                    conditions: [
                        {
                            field: fieldName,
                            value: this.searchValue
                        }
                    ],
                    order: order
                }

                this.searchAllEudrShipments(payload)
                    .then(() => {
                        this.loading.eudrShipments = false;
                    })
                    .catch(()=>{
                        this.loading.eudrShipments = false;
                    })
            }
        },
        showFiles(contract, files) {
            this.fileDialog = true;
            this.selectedContract = contract;
            this.selectedFiles = files
        },
        updateDataTable(options) {
            this.tableOptions = options
            if(this.searching === true)  {
                this.searchEudrShipments()
            } else {
                this.filterEudrShipments()
            }
        },
        updateShipment (val) {
            let tab = window.open('/v1/shipments/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        viewFile (file) {
            let tab = null;
            tab = window.open(file, '_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    watch: {
        fileDialog(value) {
            if (!value) {
                this.selectedFiles = [];
                this.selectedContract = null;
            }
        }
    },
    mounted() {
        this.loadEudrShipments()

        if(this.filterItems.buyer.length === 0) this.loadFilterItems('buyer')
        if(this.filterItems.supplier.length === 0) this.loadFilterItems('supplier')
    }
}
</script>

<style scoped>
.filter-width-150 {
    width: 150px !important;
    max-width: 150px !important;
}
.filter-width-190 {
    width: 190px !important;
    max-width: 190px !important;
}
</style>