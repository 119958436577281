<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? Pldo__title : ''" help-page-component="AddPackingList"></appic-page-title-bar>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <PackingListAlert
                    :alert="$t('message.closeAlert')"
                    mode="add"
                    type="warning"
                    class="mt-3"
                    v-if="addMode && newPackingListUnsaved"
                />
                <PackingListAlert
                    :alert="$t('message.closeAlert')"
                    mode="update"
                    type="warning"
                    class="mt-3"
                    v-if="updateMode && updatedPackingListUnsaved"
                />
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation ref="packingListForm" v-model="validForm">
                        <v-overlay
                            :value="loader"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    />
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                            accordion
                            v-model="panel"
                            multiple
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <PackingListGeneralInfo v-if="loader"/>
                                    <template v-if="!loader">
                                        <v-layout row pt-6 v-if="false">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadActivePackingList') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                    :items="allActivePackingLists"
                                                    :placeholder="$t('message.startTypingToSearch')"
                                                    dense
                                                    hide-details
                                                    item-value="Pldo.id"
                                                    item-text="Pldo.title"
                                                    required
                                                    solo
                                                    @change="loadAnotherActivePackingList($event)"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-1">
                                                            <span class="font-weight-bold">{{ item.Pldo.title }}</span>
                                                            <span class="font-xs">{{ $t('message.contract') + ': ' + (item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title) }}</span>
                                                            <span class="font-xs"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                            <span class="font-xs"> {{ $t('message.supplier') + ': ' + (item.Supplier.code ? item.Supplier.code : item.Supplier.otsname) }} </span>
                                                            <span><v-chip color="error" v-if="item.version != null" x-small class="w">{{ $t('message.' + item.version) }}</v-chip></span>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row pt-3 v-if="addMode === true">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.relatedShipment') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                    :items="allActiveContracts"
                                                    :placeholder="$t('message.startTypingToSearch')"
                                                    :value="Pldo__contract_id"
                                                    dense
                                                    hide-details
                                                    item-value="Contract.id"
                                                    item-text="Contract.title"
                                                    required
                                                    return-object
                                                    solo
                                                    @change="unShippedContract = $event"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-1">
                                                            <span class="font-weight-bold">{{ (item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title) }}</span>
                                                            <span class="font-sm blue--text text--darken-4 font-weight-bold">{{ $t('message.invoiceNo') + ': ' + (item.Invoice.invno != null ? item.Invoice.invno : '') }}</span>
                                                            <span class="font-sm"> {{ $t('message.supplier') + ': ' + item.Supplier.title }} </span>
                                                            <span class="font-sm"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{ item }">
                                                        <span>{{ $t('message.contract') + ': ' + (item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title) }}</span>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.invoiceNumber') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>{{ Pldo__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.contractNumber') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>{{ Pldo__Contract__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplier') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>{{ Pldo__Supplier__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>{{ Pldo__Customer__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.appTimberCompany') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>{{ Pldo__Office__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.plNumber') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-text-field
                                                    :label="Pldo__title ? '' : $t('message.required')"
                                                    :value="Pldo__title"
                                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Pldo__title = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.plDate') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-menu
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    max-width="290px"
                                                    min-width="290px"
                                                    ref="packingListDateMenu"
                                                    transition="scale-transition"
                                                    v-model="packingListDateMenu"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            :label="Pldo__pldodate ? '' : $t('message.required')"
                                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                            :value="computedPackingListDateFormatted"
                                                            class="force-text-left"
                                                            clearable
                                                            append-icon="event"
                                                            background-color="white"
                                                            hide-details="auto"
                                                            hint="DD/MM/YY format"
                                                            persistent-hint
                                                            readonly
                                                            solo
                                                            v-on="on"
                                                            @click:clear="Pldo__pldodate = null"
                                                            @click:append="packingListDateMenu = true"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                        v-model="Pldo__pldodate"
                                                        @input="packingListDateMenu = false"
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.items') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <Transition>
                                        <PackingListItemList v-if="loader"/>
                                    </Transition>
                                    <template v-if="!loader">
                                        <v-layout row pt-6>
                                            <v-flex xs6 lg2 md3 pl-3 py-1 class="align-center">{{ $t('message.containerCount') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-row class="px-3">
                                                    <v-col cols="5" class="pa-0 ma-0" v-if="[4,7].includes(Pldo__Contract__containersize_id) === false">
                                                        <v-text-field
                                                            :rules="[...validationRules.number, ...validationRules.minimumZero]"
                                                            :value="Pldo__Contract__containercount"
                                                            autocomplete="new-password"
                                                            class="force-text-left"
                                                            dense
                                                            hide-details
                                                            solo
                                                            @change="Pldo__Contract__containercount = $event"
                                                        />
                                                    </v-col>
                                                    <v-col cols="1" class="pa-0 ma-0 text-center" v-if="[4,7].includes(Pldo__Contract__containersize_id) === false">
                                                        <v-icon class="mt-2" small>mdi-close</v-icon>
                                                    </v-col>
                                                    <v-col cols="6" class="pa-0 ma-0">
                                                        <v-select
                                                            :items="containerSizes"
                                                            clearable
                                                            dense
                                                            hide-details
                                                            item-text="Containersize.title"
                                                            item-value="Containersize.id"
                                                            reverse
                                                            solo
                                                            v-model="Pldo__Contract__containersize_id"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-for="(item, index) in Pldo__Containers" :key="item.Container.id">
                                            <v-flex xs6 lg2 md3 pl-3 py-1 class="align-center">{{ $t('message.container') + ' #' + (index + 1) }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-row class="px-3">
                                                    <v-col cols="5" class="pa-0 my-3 mx-0">
                                                        <v-text-field
                                                            :placeholder="$t('message.containerNo')"
                                                            :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                                            :value="item.Container.title"
                                                            autocomplete="new-password"
                                                            class="force-text-left"
                                                            clearable
                                                            dense
                                                            hide-details
                                                            solo
                                                            @change="updateContainerRowHandle( item.index, 'Container.title',$event)"
                                                        />
                                                    </v-col>
                                                    <v-col cols="5" class="py-0 my-3 mx-0 pl-10">
                                                        <v-text-field
                                                            :placeholder="$t('message.sealNo')"
                                                            :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                                            :value="item.Container.sealno"
                                                            autocomplete="new-password"
                                                            class="force-text-left"
                                                            clearable
                                                            dense
                                                            hide-details
                                                            solo
                                                            @change="updateContainerRowHandle( item.index, 'Container.sealno',$event)"
                                                        />
                                                    </v-col>
                                                    <v-col cols="2" class="pt-4 px-0 ma-0 flex-row pb-0" v-if="Pldo__Containers.length === index + 1">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn icon small @click="deleteContainer({prefix: statePrefix, index: index})" v-if="Pldo__Containers.length != 1" v-on="on">
                                                                    <v-icon class="black--text text--darken-1">mdi-minus</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>{{ $t('message.deleteThisContainer') }}</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn icon small @click="addContainer(statePrefix)" v-on="on">
                                                                    <v-icon class="black--text text--darken-1">mdi-plus</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>{{ $t('message.addContainer') }}</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                    <v-col cols="2" class="pt-4 px-0 ma-0 flex-row pb-0" v-else>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn icon small @click="deleteContainer({prefix: statePrefix, index: index})" v-on="on">
                                                                    <v-icon class="black--text text--darken-1">mdi-minus</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>{{ $t('message.deleteThisContainer') }}</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-flex>
                                        </v-layout>
                                        <v-flex lg12 pl-0 mb-0 pb-0 mt-3 pt-0 v-if="Pldo__contract_id == null">
                                            <v-alert
                                                border="left"
                                                color="warning"
                                                dense
                                                icon="warning"
                                                text
                                                width="500px"
                                            >{{ $t('message.errors.noRelatedContractSelected') }}</v-alert>
                                        </v-flex>
                                        <v-layout v-if="Pldo__version == null && Pldo__converted == null">
                                            <v-flex>
                                                <h5>{{ $t('message.preAppicPackingList') }}</h5>
                                                <span class="d-flex flex-row align-center">
                                                <v-btn color="orange"
                                                   :loading="loading.convert"
                                                   @click="convertPackingList(Pldo__id)"
                                                >
                                                    {{ $t('message.convert') }}
                                                </v-btn>
                                                <v-progress-linear
                                                    :active="loading.convert"
                                                    :value="conversionProgress"
                                                    buffer-value="100"
                                                    class="ml-8"
                                                    height="10"
                                                    rounded
                                                    v-if="conversionError == null"
                                                />
                                                <span v-if="conversionError" class="ml-5"><v-icon class="red--text">fas fa-exclamation-triangle</v-icon> {{ conversionError }}</span>
                                            </span>
                                            </v-flex>
                                        </v-layout>
                                        <v-row no-gutters v-if="conversionSuccess" >
                                            <v-col><span><v-icon class="green--text">fas fa-check</v-icon> {{ conversionSuccess }}</span></v-col>
                                        </v-row>
                                        <PackingListItems :updateMode="this.updateMode" v-if="Pldo__contract_id != null && Pldo__version != null"/>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.operations') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.vesselName') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>{{ Pldo__Shipment__vessel }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.etd') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>{{ (Pldo__Shipment__etddate != null && Pldo__Shipment__etddate !== '0000-00-00') ? formatDate(Pldo__Shipment__etddate) : '' }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.eta') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>{{ (Pldo__Shipment__etadate != null && Pldo__Shipment__etadate !== '0000-00-00') ? formatDate(Pldo__Shipment__etadate) : '' }}</v-flex>
                                    </v-layout>
                                    <v-layout row v-if="[2,3,4].indexOf(Pldo__Contract__fsc) !== -1">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.fscNo') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>{{ Pldo__Fsc__no }}</v-flex>
                                    </v-layout>
                                    <v-layout row v-if="[2,3,4].indexOf(Pldo__Contract__fsc) !== -1">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.fscExpiryDate') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>{{ Pldo__Fsc__expiry_date }}</v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.printPreferences') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.documentTitle') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                :items="documentTitles"
                                                dense
                                                hide-details
                                                solo
                                                v-model="Pldo__pl_title"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.multiPagePrint') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-checkbox
                                                class="mt-0"
                                                dense
                                                hide-details
                                                required
                                                solo
                                                :true-value="1"
                                                :false-value="null"
                                                v-model="Pldo__longform"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name === 'add_packinglist')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <DocumentHistory asset="pldos" :asset_id="Pldo__id"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row>
                            <v-flex d-flex xs12>
                                <v-layout row px-3 pt-3 mb-3>
                                    <v-btn
                                            @click.stop="previewDialog = true"
                                            small
                                            class="ml-3"
                                    >{{ $t('message.preview') }}
                                    </v-btn>
                                    <PrintPackingListButton
                                            :update-mode="updateMode"
                                            :packing-list-id="Pldo__id"
                                            :list-mode="false"
                                    />
                                    <v-btn
                                            :loading="loading.reset"
                                            class="ml-3"
                                            color="default"
                                            small
                                            v-if="addMode == true"
                                            @click="resetPackingListForm"
                                    >
                                        <v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.add"
                                            class="ml-3"
                                            color="info"
                                            small
                                            v-if="addMode == true"
                                            @click="addPackingList"
                                    >{{ $t('message.create') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.save"
                                            class="ml-3"
                                            color="info"
                                            small
                                            v-if="addMode == false"
                                            @click="savePackingList"
                                    >
                                        {{ $t('message.save') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.cancel"
                                            class="ml-3"
                                            color="error"
                                            small
                                            v-if="addMode == false"
                                            @click="cancelPackingList(Pldo__id)"
                                    >{{ $t('message.cancel') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.upload"
                                            class="ml-3"
                                            color="default"
                                            small
                                            v-if="updateMode == true"
                                            @click="uploadPackingList()"
                                    >{{ $t('message.uploadShippingDocs') }}</v-btn>
                                    <DummyPackingList class="ml-3" :packing-list-id="packingListId" v-if="[1,21].includes(Pldo__Office__id)"/>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <v-dialog
                persistent
                v-model="previewDialog"
                transition="dialog-bottom-transition"
                overlay=false
                scrollable
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.packingListPreview') }}</v-card-title>
                <v-card-text class="pa-3">
                    <PackingListPreview/>
                </v-card-text>
                <v-card-actions class="pr-3 pt-0">
                    <v-spacer/>
                    <v-btn
                            class="ml-3"
                            color="default"
                            small
                            @click="previewDialog = false"
                    >{{ $t('message.backToEditing') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.add"
                            class="ml-3"
                            color="info"
                            small
                            v-if="addMode == true"
                            @click="addPackingList"
                    >{{ $t('message.create') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.save"
                            class="ml-3"
                            color="info"
                            small
                            v-if="addMode == false"
                            @click="savePackingList"
                    >{{ $t('message.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <SimpleAlert
            :alert_message="this.dialogs.error_message"
            v-bind:dialog.sync="dialogs.error"
        >
        </SimpleAlert>
<!--        <PrintPackingListBlob-->
<!--                :packing-list-id="packingListId"-->
<!--                @pdf-uploading="packingListUploading"-->
<!--                @pdf-uploaded="packingListUploaded"-->
<!--                @pdf-not-uploaded="packingListNotUploaded"-->
<!--                v-if="packingListPdf"-->
<!--        />-->
    </div>
</template>

<script>
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {formatDate, isObject} from "Helpers/helpers";
    import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "../../../helpers/vuex-map-fields-prefixed";
    import router from "../../../router";
    import {Timer} from 'easytimer.js'
    import { v4 as uuidv4 } from 'uuid';
    import {validationRules} from "@/store/modules/appic/constants";
    import {api} from "Api/index";

    const DocumentHistory = () => import("Components/Appic/DocumentHistory");
    const PackingListItems = () => import("Components/Appic/PackingListItems");
    const PackingListAlert = () => import("Components/Appic/PackingListAlert");
    const PackingListPreview = () => import("Components/Appic/PackingListPreview");
    // const PrintPackingListBlob = () => import("Views/v1/pldos/PrintPackingListBlob");
    const SimpleAlert = () => import("Components/Appic/SimpleAlert");
    const PackingListGeneralInfo = () => import('Components/Appic/Loading/PackingListGeneralInfo')
    const PackingListItemList = () => import('Components/Appic/Loading/PackingListItemList')
    const PrintPackingListButton = () => import('Components/Appic/Printing/PrintPackingListButton');
    const DummyPackingList = () => import("Components/Appic/Printing/DummyPackingList.vue");


    export default {
        name: "AddPackingList",
        components: {
            DummyPackingList,
            DocumentHistory,
            PackingListAlert,
            PackingListItems,
            PackingListPreview,
            // PrintPackingListBlob,
            PrintPackingListButton,
            PackingListGeneralInfo,
            PackingListItemList,
            SimpleAlert
        },
        props: ['packingListId','contractId'],
        data() {
            return {
                conversionError: null,
                conversionProgress: 10,
                conversionSuccess: null,
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                documentTitles: [
                    {value: 0, text: this.$t('message.packingList')},
                    {value: 1, text: this.$t('message.deliveryOrder')},
                ],
                loader: false,
                loading: {
                    add: false,
                    convert: false,
                    reset: false,
                    save: false,
                    upload: false
                },
                loadingRelatedContract: false,
                packingListDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
                packingListDateMenu: false,
                packingListPdf: false,
                panel: [0,1],
                previewDialog: false,
                unShippedContract: null,
                validForm: true
            }
        },
        computed: {
            validationRules() {
                return validationRules
            },
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('packinglist',{
                Pldo__Contract__id: 'Pldo.Contract.id',
                Pldo__Contract__containercount: 'Pldo.Contract.containercount',
                Pldo__Contract__containersize_id: 'Pldo.Contract.containersize_id',
                Pldo__Contract__fsc: 'Pldo.Contract.fsc',
                Pldo__Contract__partial_no: 'Pldo.Contract.partial_no',
                Pldo__Contract__revision_no: 'Pldo.Contract.revision_no',
                Pldo__Contract__title: 'Pldo.Contract.title',
                Pldo__Contract__version: 'Pldo.Contract.version',
                Pldo__Customer__id: 'Pldo.Customer.id',
                Pldo__Customer__title: 'Pldo.Customer.title',
                Pldo__Invoice__id: 'Pldo.Invoice.id',
                Pldo__Invoice__invno: 'Pldo.Invoice.invno',
                Pldo__Office__id: 'Pldo.Office.id',
                Pldo__Office__title: 'Pldo.Office.title',
                Pldo__Salesdescriptions: 'Pldo.Salesdescriptions',
                Pldo__Shipment__etadate: 'Pldo.Shipment.etadate',
                Pldo__Shipment__etddate: 'Pldo.Shipment.etddate',
                Pldo__Shipment__vessel: 'Pldo.Shipment.vessel',
                Pldo__Supplier__id: 'Pldo.Supplier.id',
                Pldo__Supplier__title: 'Pldo.Supplier.title',
                Pldo__converted: 'Pldo.converted',
                Pldo__id: 'Pldo.id',
                Pldo__contract_id: 'Pldo.contract_id',
                Pldo__grossweight: 'Pldo.grossweight',
                Pldo__gwmeasurement_id: 'Pldo.gwmeasurement_id',
                Pldo__longform: 'Pldo.longform',
                Pldo__netweight: 'Pldo.netweight',
                Pldo__nwmeasurement_id: 'Pldo.nwmeasurement_id',
                Pldo__pl_title: 'Pldo.pl_title',
                Pldo__pldodate: 'Pldo.pldodate',
                Pldo__title: 'Pldo.title',
                Pldo__version: 'Pldo.version'
            },'statePrefix'),
            ...mapFields('packinglist',{
                newPackingListSaved: 'newPackingListSaved',
                newPackingListUnsaved: 'newPackingListUnsaved',
                updatedPackingListSaved: 'newPackingListSaved',
                updatedPackingListUnsaved: 'newPackingListUnsaved',
            }),
            ...mapMultiRowFieldsPrefixed('packinglist',{
                Pldo__Containers: 'Pldo.Containers'
            },'statePrefix'),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('packinglist',{
                allActivePackingLists: 'allActivePackingLists',
            }),
            ...mapGetters('contract',{
                getContractById: 'getContractById',
                allActiveContracts: 'allActiveContracts',
                allUnShippedContracts: 'allUnShippedContracts'
            }),
            ...mapGetters([
                'certifications',
                'containerSizes',
                'uofmWeights',
                'validationRules'
            ]),
            addMode: function () {
                return this.$route.matched.some(({name}) => name === 'add_packinglist')
            },
            computedPackingListDateFormatted () {
                return formatDate(this.Pldo__pldodate)
            },
            console: () => console,
            formatDate: () => formatDate,
            Pldo__Fsc__no () {
                if( [2,3,4].indexOf(this.Pldo__Contract__fsc) != -1) {
                    return this.certifications.find((certification) => certification.Certification.id == this.Pldo__Contract__fsc)['Certification']['cert_no']
                } else {
                    return null
                }
            },
            Pldo__Fsc__expiry_date () {
                if( [2,3,4].indexOf(this.Pldo__Contract__fsc) != -1) {
                    return this.certifications.find((certification) => certification.Certification.id == this.Pldo__Contract__fsc)['Certification']['cert_expiry_date']
                } else {
                    return null
                }
            },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_packinglist')
            }
        },
        methods: {
            ...mapActions('contract',{
                getAllActiveContracts: 'getAllActiveContracts'
            }),
            ...mapActions('packinglist', {
                createPackingList: 'createPackingList',
                cancelPackingListById: 'cancelPackingListById',
                convertPackingListById: 'convertPackingListById',
                getAllActivePackingLists: 'getAllActivePackingLists',
                getContainersByShipment: 'getContainersByShipment',
                getPackingListToUpdateById: 'getPackingListToUpdateById',
                getSalesdescriptionsByShipment: 'getSalesdescriptionsByShipment',
                getShipmentInfo: 'getShipmentInfo',
                loadUnshippedContract: 'loadUnshippedContract',
                resetAllActivePackingLists: 'resetAllActivePackingLists',
                resetCurrentPackingList: 'resetCurrentPackingList',
                resetPackingList: 'resetPackingList',
                updatePackingList: 'updatePackingList'
            }),
            ...mapActions('packinglistitem',{
                addPackingListItemRow: 'addPackingListItemRow',
                getItemsByPackingList: 'getItemsByPackingList',
                resetPackingListItems: 'resetPackingListItems',
            }),
            ...mapMutations('packinglist',{
                addContainer: 'addContainer',
                deleteContainer: 'deleteContainer',
            }),
            addPackingList(){
                if(this.$refs.packingListForm.validate()) {
                    this.loading.add = true
                    this.createPackingList()
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.packingListAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.add = false
                                this.newPackingListUnsaved = false
                                this.newPackingListSaved = true
                                //reset new packing list state
                                this.resetCurrentPackingList().then(() => {
                                    this.resetPackingListItems(this.statePrefix).then(() => {
                                        this.resetAllActivePackingLists().then(() => {
                                            router.push({
                                                name: 'update_packinglist',
                                                params: {packingListId: response.packinglist_id}
                                            }).catch(err => {
                                            })
                                        })
                                    })
                                })
                            } else {
                                if(isObject(response.message)){
                                    //extract validation errors
                                    const errors = Object.values(response.message)
                                    errors.forEach(errorObj => {
                                        if (Array.isArray(errorObj)) {
                                            errorObj.forEach(error => {
                                                this.$toast.error(error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                        } else {
                                            this.$toast.error(this.$t('message.errors.packingListNotAdded'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        }
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.packingListNotAdded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                                this.loading.add = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.packingListNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        })
                        .finally(() => {
                            this.loading.add = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.packingListNotAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.add = false
                }
            },
            async cancelPackingList(val) {
                if(await this.$root.$confirm(this.$t('message.cancelPackingList'), this.$t('message.confirmations.continuePackingListCancelAction'), {color: 'orange'})){
                    this.cancelPackingListById(val)
                        .then((response) => {
                            if(response.status == 'success'){
                                this.$toast.success(this.$t('message.successes.packingListDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                router.push({name: 'shipping_documents_listing'}).catch(err => {})
                                this.loading.cancel = false
                            } else {
                                this.$toast.error(this.$t('message.errors.packingListNotDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(()=>{
                            this.$toast.error(this.$t('message.errors.packingListNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            },
            convertPackingList (val) {
                let self = this
                this.loading.convert = true
                this.conversionProgress = 10
                this.conversionError = null
                this.conversionSuccess = null
                const timer = new Timer()
                timer.start()
                timer.addEventListener('secondTenthsUpdated',function(e){
                    self.conversionProgress = timer.getTimeValues().seconds * 25
                })
                this.convertPackingListById (val)
                    .then((response)=>{
                        if(response.data.status == 'success') {
                            this.loader = true
                            let payload = {
                                contract_id: this.Pldo__contract_id,
                                packinglist_id: this.Pldo__id,
                                prefix: this.statePrefix
                            }
                            this.getItemsByPackingList(payload)
                                .then(()=>{
                                    this.loader = false
                                    this.updatedPackingListUnsaved = false
                                })
                                .catch(()=>{
                                    this.updatedPackingListUnsaved = false
                                })
                                .finally(()=>{
                                    this.updatedPackingListUnsaved = false
                                    this.Pldo__version = response.data.version
                                    this.Pldo__converted = response.data.converted
                                    this.conversionError = null
                                    this.conversionSuccess = this.$t('message.tmsPackingListConverted')
                                    this.conversionProgress = 100
                                    timer.stop()
                                    this.loading.convert = false
                                })

                        } else {
                            this.loading.convert = false
                            this.conversionError = this.$t('message.tmsPackingListNotConverted')
                        }
                    })
                    .catch(()=>{
                        this.loading.convert = false
                        this.conversionError = this.$t('message.tmsPackingListNotConverted')
                    })
                    .finally(()=>{
                        this.loading.convert = false
                    })
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            loadAnotherActivePackingList (val) {
                router.push({name: 'update_packinglist', params: { packingListId : val}}).catch(err => {})
            },
            loadPackingListById (val) {
                // let self = this
                this.loader = true
                this.getPackingListToUpdateById(val)
                    .then((response)=>{
                        if(response == 'done') {
                            this.$title = this.$t('message.titles.packingList') + ': ' + this.Pldo__title
                            let payload = {
                                contract_id: this.Pldo__contract_id,
                                packinglist_id: this.Pldo__id,
                                prefix: this.statePrefix
                            }
                            this.getSalesdescriptionsByShipment(payload)
                                .then(() => {
                                    //get packing lists items
                                    this.getItemsByPackingList(payload)
                                        .then(()=>{
                                            this.loader = false
                                            this.updatedPackingListUnsaved = false
                                        })
                                        .catch(()=>{
                                            this.loader = false
                                            this.updatedPackingListUnsaved = false
                                        })
                                        .finally(()=>{
                                            this.loader = false
                                            this.updatedPackingListUnsaved = false
                                        })
                                })
                        }
                    })
                    .catch(() => {
                        this.loader = false
                        this.updatedPackingListUnsaved = false
                        router
                                .push({name: 'not_found_main', params: {resource: 'packing list'}})
                                .catch(() => {
                                    console.log('error')
                                })
                    })
            },
            packingListNotUploaded() {
                this.packingListPdf = false
                this.loading.upload = false
                this.$toast.error(this.$t('message.errors.packingListNotUploaded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'check_circle_outline'
                    }
                )
            },
            packingListUploaded() {
                this.packingListPdf = false
                this.loading.upload = false
                this.$toast.success(this.$t('message.successes.packingListUploaded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'check_circle_outline'
                    }
                )
            },
            packingListUploading() {
                return true
            },
            async resetPackingListForm() {
                if (await this.$root.$confirm(this.$t('message.resetPackingListForm'), this.$t('message.confirmations.continuePackingListFormReset'), {color: 'orange'})) {
                    this.loading.reset = true
                    this.resetCurrentPackingList()
                        .then(() => {
                            setTimeout(() => {
                                this.newPackingListSaved = false
                                this.newPackingListUnsaved = false
                                this.loading.reset = false
                                this.$toast.success(this.$t('message.packingListFormResetNotification'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                            }, 1000)
                        })
                }
            },
            savePackingList(){
                if(this.$refs.packingListForm.validate()) {
                    // let self = this
                    this.loading.save = true
                    this.updatePackingList()
                        .then((response) => {
                            if (response.status == 'success') {
                                let payload = {
                                    packinglist_id: this.Pldo__id,
                                    prefix: this.statePrefix
                                }
                                this.getItemsByPackingList(payload)
                                    .then(() => {
                                        this.$toast.success(this.$t('message.successes.packingListUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'check_circle_outline'
                                            }
                                        )
                                        this.loading.save = false
                                        this.updatedPackingListUnsaved = false
                                    })
                            } else {
                                if(isObject(response.message)){
                                    //extract validation errors
                                    const errors = Object.values(response.message)
                                    errors.forEach(errorObj => {
                                        if (Array.isArray(errorObj)) {
                                            errorObj.forEach(error => {
                                                this.$toast.error(error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                        } else {
                                            this.$toast.error(this.$t('message.errors.packingListNotUpdated'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        }
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.packingListNotUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                                this.loading.save = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.packingListNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        })
                        .finally(() => {
                            this.loading.save = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.packingListNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.save = false
                }
            },
            updateContainerRowHandle(idx,field,value) {
                let payload = {
                    index: idx,
                    field: field,
                    prefix: this.statePrefix,
                    value: value,
                }
                this.$store.commit('packinglist/updateContainers', payload)
            },
            // uploadPackingList() {
            //     this.loading.upload = true
            //     this.packingListPdf = true
            // },
            uploadPackingList() {
                this.loading.upload = true
                api
                    .put('/packinglists/' + this.packingListId + '/pdf')
                    .then((response) => {
                        if (response.data.status == 'success') {
                            this.loading.upload = false
                        } else {
                            this.$toast.error(this.$t('message.errors.pdfError'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                })
                            this.loading.upload = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                        this.loading.upload = false
                    })
            },
            viewPdf(){
                let contractNumber = [this.Pldo__Contract__title, this.Pldo__Contract__revision_no , this.Pldo__Contract__partial_no].filter(Boolean).join('-')
                contractNumber = contractNumber.replace('.','')
                let document = encodeURIComponent(contractNumber + '_PL')
                // let document = encodeURIComponent('PL ' + this.Pldo__title)
                // let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/packinglists/print/'
                    + this.packingListId
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=3mm&pdf.margin.bottom=3mm&pdf.margin.left=8mm&pdf.margin.right=8mm&pdf.landscape=true&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        watch: {
            unShippedContract (val){
                console.log('Loading un-shipped contract')
                this.loader = true
                let self = this
                setTimeout(function(){
                    self.loadUnshippedContract(val)
                        .then(()=>{
                            let payload = {
                                contract_id: val.Contract.id,
                                prefix: self.statePrefix
                            }
                            self.getContainersByShipment(payload)
                                .then(()=>{
                                    self.getShipmentInfo(payload)
                                        .then(()=>{
                                            self.getSalesdescriptionsByShipment(payload).then(()=>{
                                                self.resetPackingListItems(self.statePrefix)
                                                self.loader = false
                                            })
                                        })
                                })
                        })
                },100)
            }
        },
        created() {
            if(this.allUnShippedContracts.length === 0) this.getAllActiveContracts()
            if(this.allActivePackingLists.length === 0) this.getAllActivePackingLists()

            if(this.$route.matched.some(({name}) => name === 'update_packinglist')) {
                this.loadPackingListById(this.packingListId)
            } else if(this.$route.matched.some(({name}) => name === 'add_packinglist')){
                this.newPackingListUnsaved = true;
                if(this.contractId != null){
                    this.loader = true
                    this.Pldo__Contract__id = this.contractId
                    let contract = this.allUnShippedContracts.find((d)=>{ return d.Contract.id == this.contractId})
                    if(contract) {
                        this.loadUnshippedContract(contract)
                        let payload = {
                            contract_id: contract.Contract.id,
                            prefix: this.statePrefix
                        }
                        this.getContainersByShipment(payload)
                            .then(()=>{
                                this.getShipmentInfo(payload)
                                    .then(()=>{
                                        this.getSalesdescriptionsByShipment(payload).then(()=>{
                                            this.resetPackingListItems(this.statePrefix)
                                            this.loader = false
                                        })
                                    })
                            })
                    } else {
                        alert('Packing list could not be initialised. Please contact APPIC developer for support.')
                    }
                }
            }
        },
        mounted () {
            this.$store.watch(
                function(state) {
                    return state.appic.packinglist.update.Pldo
                },
                () => {
                    this.updatedPackingListUnsaved = true
                },
                { deep: true }
            )
            this.$store.watch(
                function(state) {
                    return state.appic.packinglist.current.Pldo
                },
                () => {
                    this.newPackingListUnsaved = true
                },
                { deep: true }
            )
            if(this.$route.matched.some(({name}) => name === 'update_packinglist')) {
                let payload = {
                    contract_id: this.Pldo__contract_id,
                    packinglist_id: this.Pldo__id,
                    prefix: this.statePrefix
                }
            }
        }
    }
</script>
<style>
    .force-text-left.v-text-field input {
        text-align: left !important;
    }
    .v-dialog {
        min-width: 200px !important;
        max-width: 1200px !important;
    }
    .wrap-text {
        white-space: normal;
    }
    .font-xs {
        font-size: small;
    }
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }
    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
</style>