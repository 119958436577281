<template>
	<div>
		<appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? (Ipa__Contract__version != null ? [Ipa__Contract__title, Ipa__Contract__revision_no , Ipa__Contract__partial_no].filter(Boolean).join('-') : Ipa__Contract__title) : ''" help-page-component="AddIpa"></appic-page-title-bar>
		<app-section-loader :status="loader"></app-section-loader>
		<v-container fluid grid-list-xl pb-0 pt-3 px-3>
			<v-layout row wrap>
				<IpaAlert
                        :alert="$t('message.closeAlert')"
                        mode="add"
                        type="warning"
                        class="mt-3"
                        v-if="addMode && newIpaUnsaved"
				/>
				<IpaAlert
                        :alert="$t('message.closeAlert')"
                        mode="update"
                        type="warning"
                        class="mt-3"
                        v-if="updateMode && updatedIpaUnsaved"
				/>
				<app-card
                        :fullBlock="true"
                        colClasses="xl12 lg12 md12 sm12 xs12"
				>
                    <v-form lazy-validation ref="ipaForm" v-model="validForm">
                        <v-overlay
                                :value="loader"
                                absolute
                                opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                            color="primary"
                                            indeterminate
                                            size="40"
                                            width="6"
                                    />
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                                accordion
                                v-model="panel"
                                multiple
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-6 v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadActiveIpa') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items="allActiveIpas"
                                                    :loading="loadingAnotherIpa"
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Ipa.id"
                                                    item-text="Contract.title"
                                                    required
                                                    solo
                                                    @change="loadAnotherActiveIpa($event)"
                                            >
                                                <template v-slot:item="{item}">
                                                    <div class="d-flex flex-column py-1">
                                                        <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                        <span class="font-xs"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                        <span class="font-xs"> {{ $t('message.supplier') + ': ' + (item.Supplier.code ? item.Supplier.code : item.Supplier.otsname) }} </span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row pt-3 v-if="addMode == true">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.relatedContract') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items="allActiveContracts"
                                                    :placeholder="$t('message.startTypingToSearch')"
                                                    :value="Ipa__contract_id"
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Contract.id"
                                                    item-text="Contract.title"
                                                    required
                                                    return-object
                                                    solo
                                                    @change="loadContract($event)"
                                            >
                                                <template v-slot:item="{item}">
                                                    <div class="d-flex flex-column py-1">
                                                        <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                        <span class="font-sm"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                        <span class="font-sm"> {{ $t('message.supplier') + ': ' + item.Supplier.title }} </span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{item}">
                                                    <span>{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row pt-3 v-if="updateMode == true">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.contractNumber') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Ipa__Contract__version != null ? [Ipa__Contract__title, Ipa__Contract__revision_no , Ipa__Contract__partial_no].filter(Boolean).join('-') : Ipa__Contract__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.consignorName') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items=allSuppliers
                                                    :placeholder="$t('message.startTypingToSearch')"
                                                    :rules="[...validationRules.required]"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    required
                                                    item-value="Supplier.id"
                                                    item-text="Supplier.title"
                                                    v-model="Ipa__supplier_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.consignorAddress') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <div class="d-flex flex-column" v-if="Ipa__use_alt_address != 1">
                                                <span>{{ Ipa__Supplier__address1 }}</span>
                                                <span>{{ Ipa__Supplier__address2 }}</span>
                                                <span>{{ Ipa__Supplier__address3 }}</span>
                                                <span>{{(Ipa__Supplier__city ? Ipa__Supplier__city : '') + (Ipa__Supplier__postcode ? ' ' + Ipa__Supplier__postcode : '') }}</span>
                                                <span>{{ Ipa__Supplier__state }}</span>
                                                <span>{{ Ipa__Supplier__country }}</span>
                                            </div>
                                            <v-checkbox
                                                    :label="$t('message.useAlternateAddress')"
                                                    :true-value="1"
                                                    class="mt-0s"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Ipa__use_alt_address"
                                            />
                                            <v-textarea
                                                    :rules="[...validationRules.paragraph]"
                                                    :value="Ipa__alt_address"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-if="Ipa__use_alt_address == 1"
                                                    @change="Ipa__alt_address = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.branchForProcessing') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ ipaConstants.IpaBopMy }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.countryOfOrigin') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items=allCountries
                                                    :placeholder="$t('message.startTypingToSearch')"
                                                    :rules="[...validationRules.required]"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Country.id"
                                                    item-text="Country.name"
                                                    solo
                                                    required
                                                    v-model="Ipa__productorigin_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.placeOfOrigin') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-select
                                                    :items="exitPoints"
                                                    :placeholder="$t('message.startTypingToSearch')"
                                                    :rules="[...validationRules.required]"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Shippingport.title"
                                                    item-value="Shippingport.id"
                                                    solo
                                                    required
                                                    v-model="Ipa__loadingport_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.consignedFrom') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items=allCountries
                                                    :placeholder="$t('message.startTypingToSearch')"
                                                    :rules="[...validationRules.required]"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    required
                                                    item-value="Country.id"
                                                    item-text="Country.name"
                                                    v-model="Ipa__consignedfrom_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.traderRemarks') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Ipa__Contract__version != null ? [Ipa__Contract__title, Ipa__Contract__revision_no , Ipa__Contract__partial_no].filter(Boolean).join('-') : Ipa__Contract__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.methodOfDelivery') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ ipaConstants.IpaDeliveryMethod }}</v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.category') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ ipaConstants.IpaCategory }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.etd') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Ipa__Contract__etddate != null && Ipa__Contract__etddate != '0000-00-00' ? formatDate(Ipa__Contract__etddate) : '' }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.eta') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Ipa__Contract__etadate != null && Ipa__Contract__etadate != '0000-00-00' ? formatDate(Ipa__Contract__etadate) : '' }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.voyage') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Ipa__Contract__shipmentstatus_id == 6 ? Ipa__Contract__shipmentstatus_text : '' }}</v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.gmo') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ ipaConstants.IpaGmo}}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.entryPoint') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items="entryPoints"
                                                    :placeholder="$t('message.startTypingToSearch')"
                                                    :rules="[...validationRules.required]"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    required
                                                    item-value="Shippingport.id"
                                                    item-text="Shippingport.title"
                                                    v-model="Ipa__shippingport_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.typeOfPackaging') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ ipaConstants.IpaPackType }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.commonName') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-autocomplete
                                                    :items=allSpecies
                                                    :placeholder="$t('message.startTypingToSearch')"
                                                    :rules="[...validationRules.required]"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    required
                                                    item-value="Species.id"
                                                    item-text="Species.title"
                                                    v-model="Ipa__species_id"
                                                    @change="changeSpecies"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.scientificName') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Ipa__Species__latin_name }}</v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.suggestedTariffCode') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Ipa__Species__hscode }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.plantQuarantineQty') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1 class="d-flex flex-row align-center no-gutters">
                                            <span class="col-11">
                                                <v-text-field
                                                        :value="Ipa__quarantine_qty"
                                                        :rules="[...validationRules.required, ...validationRules.amount]"
                                                        autocomplete="new-password"
                                                        class="force-text-right"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        required
                                                        solo
                                                        @change="Ipa__quarantine_qty= $event"
                                                />
                                            </span>
                                            <span class="col-1 text-right">{{ ipaUofm }}</span>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.tariffQty') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1 class="d-flex flex-row align-center no-gutters">
                                            <span class="col-11">
                                                <v-text-field
                                                        :value="Ipa__tariff_qty"
                                                        :rules="[...validationRules.required, ...validationRules.amount]"
                                                        autocomplete="new-password"
                                                        class="force-text-right"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        required
                                                        solo
                                                        @change="Ipa__tariff_qty= $event"
                                                />
                                            </span>
                                            <span class="col-1 text-right">{{ ipaUofm }}</span>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_ipa')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card elevation="0"class="pt-3">
                                        <v-tabs
                                            background-color="grey lighten-3"
                                        >
                                            <v-tab key="email" href="#tab-email" @click="updateHistoryKey()"><v-icon small class="pr-3">send</v-icon>{{ $t('message.notifications') }}</v-tab>
                                            <v-tab key="audit_log" href="#tab-audit_log" @click="updateHistoryKey()"><v-icon small class="pr-3">list</v-icon>{{ $t('message.auditLog') }}</v-tab>
                                            <v-tab-item key="email" value="tab-email">
                                                <NotificationsLog asset="ipas" :asset_id="Ipa__id" :key="'nl' + historyKey"></NotificationsLog>
                                            </v-tab-item>
                                            <v-tab-item key="audit_log" value="tab-audit_log">
                                                <DocumentHistory asset="ipas" :asset_id="Ipa__id" :key="'dh' + historyKey"></DocumentHistory>
                                            </v-tab-item>
                                        </v-tabs>
                                    </v-card>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row>
                            <v-flex d-flex xs12>
                                <v-layout row px-3 pt-3 mb-3>
                                    <PrintImportPermitAdviceButton
                                            :import-permit-advice-id="Ipa__id"
                                            :list-mode="false"
                                            :update-mode="updateMode"
                                    />
                                    <v-btn
                                            :loading="loading.reset"
                                            @click="resetIpaForm()"
                                            class="ml-3"
                                            color="default"
                                            small
                                            v-if="addMode == true"
                                    >
                                        <v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.add"
                                            class="ml-3"
                                            color="info"
                                            small
                                            @click="addIpa()"
                                            v-if="addMode == true"
                                    >{{ $t('message.create') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.save"
                                            class="ml-3"
                                            color="info"
                                            small
                                            @click="saveIpa()"
                                            v-if="addMode == false"
                                    >
                                        {{ $t('message.saveChanges') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.cancel"
                                            class="ml-3"
                                            color="error"
                                            small
                                            v-if="addMode == false"
                                            @click="cancelIpa(Ipa__id)"
                                    >{{ $t('message.cancelIpa') }}
                                    </v-btn>
                                    <v-btn
                                            class="ml-3"
                                            small
                                            v-if="addMode == false"
                                            @click="sendPermitDialog = true"
                                    >{{ $t('message.sendToBuyer')}}
                                    </v-btn>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-form>
				</app-card>
			</v-layout>
		</v-container>
        <v-dialog
            persistent
            transition="dialog-bottom-transition"
            width="800px"
            v-model="sendPermitDialog"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.sendThisImportPermitAdviceToBuyer') }}</v-card-title>
            </v-card>
            <v-card-text class="white">
                <v-row no-gutters class="pt-3 align-center">
                    <v-col cols="12">
                        <h2>{{ $t('message.selectContacts') }}</h2>
                        <v-simple-table class="appic-table-light specification-tables" dense>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="grey lighten-3 pa-0"></th>
                                    <th class="grey lighten-3 pa-0 text-left">{{ $t('message.name')}}</th>
                                    <th class="grey lighten-3 pa-0 text-left">{{ $t('message.email')}}</th>
                                    <th class="grey lighten-3 pa-0 text-left">{{ $t('message.position')}}</th>
                                    <th class="grey lighten-3 pa-0 text-left">{{ $t('message.receiveNotifications')}}</th>
                                </tr>
                                </thead>
                                <tbody v-if="buyerPermitContacts.length > 0">
                                <tr v-for="contact in buyerPermitContacts" v-bind:key="contact.Contact.id">
                                    <td>
                                        <v-checkbox
                                            :value="contact.Contact.email"
                                            class="mt-1"
                                            dense
                                            hide-details
                                            v-model="selectedBuyerContacts"
                                        />
                                    </td>
                                    <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.name }}</td>
                                    <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.email }}</td>
                                    <td class="pt-2 pl-0 text-left">{{ contact.Contact.position }}</td>
                                    <td class="pt-2 pl-0 text-center">{{ contact.Contact.otsnotification == 1 ? $t('message.yes') : $t('message.no') }}</td>
                                </tr>
                                </tbody>
                                <tbody v-else>
                                <tr>
                                    <td colspan="5">{{ $t('message.noContactsFound') }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pr-3 pt-3 white">
                <v-spacer></v-spacer>
                <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="sendPermitDialog = false"
                >{{ $t('message.cancel') }}
                </v-btn>
                <v-btn
                        :loading="loading.send"
                        class="ml-3"
                        color="info"
                        small
                        @click="sendPermitToBuyer()"
                >{{ $t('message.send') }}
                </v-btn>
            </v-card-actions>
        </v-dialog>
        <SimpleAlert
                :alert_message="this.dialogs.error_message"
                v-bind:dialog.sync="dialogs.error"
        />
<!--        <PrintIpaNewBlob-->
<!--            :ipa-id="ipaId"-->
<!--            :key="updateKey"-->
<!--            @pdf-uploading="ipaUploading"-->
<!--            @pdf-uploaded="ipaUploaded"-->
<!--            @pdf-not-uploaded="ipaNotUploaded()"-->
<!--            v-if="hasIpa"-->
<!--        />-->
	</div>
</template>

<script>
	import { mapFields, mapMultiRowFields } from "vuex-map-fields";
	import { mapActions, mapGetters, mapMutations } from "vuex";
    import {formatDate, isObject, log, scrollToFirstFormValidationError} from "Helpers/helpers";
	import { mapFieldsPrefixed } from  'Helpers/vuex-map-fields-prefixed';
	import router from "../../../router";
    import {api} from "Api";
    // import { v4 as uuidv4 } from 'uuid';
    import PrintImportPermitAdviceButton from "../../../components/Appic/Printing/PrintImportPermitAdviceButton.vue";

    const DocumentHistory = () => import("../../../components/Appic/DocumentHistory");
    const IpaAlert = () => import("../../../components/Appic/IpaAlert");
    const NotificationsLog = () => import("Components/Appic/NotificationsLog");
    const SimpleAlert = () => import("../../../components/Appic/SimpleAlert");
    // const PrintIpaNewBlob = () => import("Views/v1/ipas/PrintIpaNewBlob");

	export default {
		name: "AddIpa",
		components: {
            DocumentHistory,
            IpaAlert,
            NotificationsLog,
            PrintImportPermitAdviceButton,
            // PrintIpaNewBlob,
            SimpleAlert
        },
		props: ['ipaId','contractId'],
        title: '',
		data() {
			return {
                buyerContacts: [],
				dialogs: {
					error:  false,
					error_message: ""
				},
				entryPoints: [],
				exitPoints: [],
                hasIpa: false,
                historyKey: '',
                ipaUrl: null,
                loader: false,
				loading: {
					add: false,
                    ipa: false,
					reset: false,
                    send: false,
					save: false
				},
				loadingAnotherIpa: false,
				panel: [0],
                selectedBuyerContacts: [],
                sendPermitDialog: false,
				unShippedContract: null,
                updateKey: 0,
                validForm: false
			}
		},
		computed: {
			statePrefix() {
				if (this.updateMode) {
					return 'update'
				} else {
					return 'current'
				}
			},
			...mapGetters('contract',{
				allUnShippedMyContracts: 'allUnShippedMyContracts',
                allActiveContracts: 'allActiveContracts'
			}),
			...mapGetters('country',{
				allCountries: 'allCountries'
			}),
			...mapFieldsPrefixed('ipa',{
                Ipa__alt_address: 'Ipa.alt_address',
			    Ipa__contract_id: 'Ipa.contract_id',
				Ipa__consignedfrom_id: 'Ipa.consignedfrom_id',
				Ipa__id: 'Ipa.id',
				Ipa__loadingport_id: 'Ipa.loadingport_id',
				Ipa__measurement_id: 'Ipa.measurement_id',
				Ipa__productorigin_id: 'Ipa.productorigin_id',
				Ipa__quarantine_qty: 'Ipa.quarantine_qty',
				Ipa__shippingport_id: "Ipa.shippingport_id",
				Ipa__species_id: 'Ipa.species_id',
				Ipa__supplier_id: 'Ipa.supplier_id',
				Ipa__tariff_qty: 'Ipa.tariff_qty',
                Ipa__use_alt_address: 'Ipa.use_alt_address',
				Ipa__Contract__destinatione_id: 'Ipa.Contract.destinatione_id',
				Ipa__Contract__etddate: 'Ipa.Contract.etddate',
				Ipa__Contract__etadate: 'Ipa.Contract.etadate',
				Ipa__Contract__partial_no: 'Ipa.Contract.partial_no',
				Ipa__Contract__revision_no: 'Ipa.Contract.revision_no',
				Ipa__Contract__shipmentstatus_id: 'Ipa.Contract.shipmentstatus_id',
				Ipa__Contract__shipmentstatus_text: 'Ipa.Contract.shipmentstatus_text',
				Ipa__Contract__title: 'Ipa.Contract.title',
				Ipa__Contract__version: 'Ipa.Contract.version',
                Ipa__Customer__company_id: 'Ipa.Customer.company_id',
				Ipa__Species__hscode: 'Ipa.Species.hscode',
				Ipa__Species__hscode_veneer: 'Ipa.Species.hscode_veneer',
                Ipa__Species__latin_name: 'Ipa.Species.latin_name',
				Ipa__Supplier__title: 'Ipa.Supplier.title',
				Ipa__Supplier__address1: 'Ipa.Supplier.address1',
				Ipa__Supplier__address2: 'Ipa.Supplier.address2',
				Ipa__Supplier__address3: 'Ipa.Supplier.address3',
				Ipa__Supplier__city: 'Ipa.Supplier.city',
				Ipa__Supplier__country: 'Ipa.Supplier.country',
				Ipa__Supplier__postcode: 'Ipa.Supplier.postcode',
				Ipa__Supplier__state: 'Ipa.Supplier.state',
			},'statePrefix'),
			...mapFields('ipa',{
				newIpaSaved: 'newIpaSaved',
				newIpaUnsaved: 'newIpaUnsaved',
				updatedIpaUnsaved: 'updatedIpaUnsaved',
				updatedIpaSaved: 'updatedIpaSaved'
			}),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
			...mapGetters('ipa',{
				allActiveIpas: 'allActiveIpas'
			}),
			...mapGetters('shippingport', {
				allShippingDischargePorts: 'allShippingDischargePorts',
				allShippingLoadingPorts: 'allShippingLoadingPorts'
			}),
			...mapGetters('species', {
				allSpecies: 'allSpecies',
				findSpeciesById: 'findSpeciesById'
			}),
			...mapGetters('supplier', {
				allSuppliers: 'allSuppliers',
				findSupplierById: 'findSupplierById'
			}),
			...mapGetters([
				'ipaConstants',
				'uofmQty',
                'validationRules'
			]),
			addMode: function () {
				return this.$route.matched.some(({name}) => name === 'add_ipa')
			},
			ipaUofm () {
				return 	this.uofmQty.find(uofm => uofm.Measurement.id == this.Ipa__measurement_id)?.Measurement?.title
			},
            buyerPermitContacts () {
                return this.buyerContacts.filter(contact => {
                    return contact?.Contact?.otsnotification == 1
                    // return contact?.Contact?.otsshipping == 1
                })
            },
			updateMode: function () {
				return this.$route.matched.some(({name}) => name === 'update_ipa')
			},
		},
		methods: {
		    ...mapActions('contract',{
                getAllActiveContracts: 'getAllActiveContracts'
            }),
			...mapActions('country',{
				getAllCountries: 'getAllCountries'
			}),
			...mapActions('ipa', {
				createIpa: 'createIpa',
				cancelIpaById: 'cancelIpaById',
				getAllActiveIpas: 'getAllActiveIpas',
				getIpaToUpdateById: 'getIpaToUpdateById',
				loadUnshippedContract: 'loadUnshippedContract',
				resetAllActiveIpas: 'resetAllActiveIpas',
				resetCurrentIpa: 'resetCurrentIpa',
                sendImportPermitToBuyer: 'sendImportPermitToBuyer',
				updateIpa: 'updateIpa'
			}),
			...mapActions('shippingport',{
				getAllShippingPorts: 'getAllShippingPorts'
			}),
			...mapActions('species', {
				getAllSpecies: 'getAllSpecies'
			}),
            ...mapActions('supplier',{
                getAllSuppliers: 'getAllSuppliers'
            }),
			addIpa () {
		        if(this.$refs.ipaForm.validate()) {
                    this.loading.add = true
                    this.createIpa()
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.ipaAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.add = false
                                this.newIpaUnsaved = false
                                this.newIpaSaved = true
                                //TODO reset new IPA state
                                this.resetCurrentIpa().then(() => {
                                    this.resetAllActiveIpas().then(() => {
                                        router.push({
                                            name: 'update_ipa',
                                            params: {ipaId: response.ipa_id}
                                        }).catch(err => {
                                        })
                                    })
                                })
                            } else {
                                if(isObject(response.message)){
                                    //extract validation errors
                                    const errors = Object.values(response.message)
                                    errors.forEach(errorObj => {
                                        if (Array.isArray(errorObj)) {
                                            errorObj.forEach(error => {
                                                this.$toast.error(error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                        } else {
                                            this.$toast.error(this.$t('message.errors.ipaNotAdded'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        }
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.ipaNotAdded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                                this.loading.add = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.ipaNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        })
                        .finally(() => {
                            this.loading.add = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.ipaNotAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    scrollToFirstFormValidationError()
                }
			},
			async cancelIpa (val) {
				if(await this.$root.$confirm(this.$t('message.cancelIpa'), this.$t('message.confirmations.continueIpaCancelAction'), {color: 'orange'})){
					this.cancelIpaById(val)
                        .then((response) => {
                            if(response.status == 'success'){
                                this.$toast.success(this.$t('message.successes.ipaDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                router.push({name: 'shipping_documents_listing'}).catch(err => {})
                                this.loading.cancel = false
                            } else {
                                this.$toast.error(this.$t('message.errors.ipaNotDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(()=>{
                            this.$toast.error(this.$t('message.errors.ipaNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                        // .finally(()=>{
                        //
                        // })
				}
			},
            changeSpecies() {
		        let species = this.allSpecies.find(species => species.Species.id == this.Ipa__species_id)
                if(species){
                    this.Ipa__Species__latin_name = species.Species.latin_name
                }
            },
			formatDate,
            getBuyerContacts () {
                let company_id = this.Ipa__Customer__company_id
                return new Promise((resolve, reject) => {
                    api
                        .get("/companies/" + company_id + "/contacts")
                        .then(response => {
                            this.buyerContacts = response.data.data
                            resolve('done');
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(() => {
                            resolve('done');
                        })
                })
            },
            ipaNotUploaded() {
                this.loading.send = false
            },
            ipaUploaded(url) {
                this.ipaUrl = url
                this.loading.ipa = false
                this.loading.send = false
            },
            ipaUploading() {
		        this.loading.send = true
            },
			loadContract (contract){
				this.loader = true
				let self = this
				// setTimeout(function(){
					this.loadUnshippedContract(contract)
						.then(() => {
							let supplier = this.findSupplierById(contract.Supplier.id)
                            if( supplier?.Supplier?.id ){
                                this.Ipa__supplier_id = supplier?.Supplier?.id
                                this.Ipa__Supplier__address1 = supplier?.Company?.address1
                                this.Ipa__Supplier__address2 = supplier?.Company?.address2
                                this.Ipa__Supplier__address3 = supplier?.Company?.address3
                                this.Ipa__Supplier__address3 = supplier?.Company?.address3
                                this.Ipa__Supplier__city = supplier?.Company?.city
                                this.Ipa__Supplier__postcode = supplier?.Company?.postcode
                                this.Ipa__Supplier__state = supplier?.Company?.state
                                this.Ipa__Supplier__country = this.allCountries.find(country=>country.Country.id == supplier?.Company.country_id).Country.name
							}
                            this.entryPoints = this.allShippingDischargePorts.filter(port => port.Country.id == this.Ipa__Contract__destinatione_id)
                            this.exitPoints = this.allShippingLoadingPorts.filter(port => port.Country.id == this.Ipa__productorigin_id)
						})
						.catch(()=>{
                            this.loader = false
						})
						.then(()=>{
                            this.loader = false
						})
				// },100)
			},
			loadAnotherActiveIpa (val) {
				router.push({name: 'update_ipa', params: { ipaId : val}}).catch(err => {})
			},
			loadIpaById (val) {
				this.loader = true
				this.getIpaToUpdateById(val)
					.then(()=>{
                        this.$title =  this.$t('message.titles.ipa') + ': ' + (this.Ipa__Contract__version != null ? [this.Ipa__Contract__title, this.Ipa__Contract__revision_no , this.Ipa__Contract__partial_no].filter(Boolean).join('-') : this.Ipa__Contract__title)
                        this.getBuyerContacts()
                            .then(()=>{
                                this.buyerPermitContacts.forEach(c => {
                                    this.selectedBuyerContacts.push(c.Contact.email)
                                })
                                this.loader = false
                                this.entryPoints = this.allShippingDischargePorts.filter(port => port.Country.id == this.Ipa__Contract__destinatione_id)
                                this.exitPoints = this.allShippingLoadingPorts.filter(port => port.Country.id == this.Ipa__productorigin_id)
                                this.updatedIpaUnsaved = false
                            })
					})
					.catch(()=>{
                        this.loader = false
						this.updatedIpaUnsaved = false
                        router
                                .push({name: 'not_found_main', params: {resource: 'IPA'}})
                                .catch(() => {
                                    console.log('error')
                                })
					})
			},
			async resetIpaForm() {
				if (await this.$root.$confirm(this.$t('message.resetIpaForm'), this.$t('message.confirmations.continueIpaFormReset'), {color: 'orange'})) {
					this.loading.reset = true
					this.resetCurrentIpa()
						.then(() => {
							this.newIpaSaved = false
							this.newIpaUnsaved = false
							this.loading.reset = false
							this.$toast.success(this.$t('message.ipaFormResetNotification'),
								{
                                    classes: ['icon-float-left'],
									icon: 'check_circle_outline'
								}
							)
						})
						.catch(()=>{
							this.loading.reset = false
						})
						.then(()=>{
							this.loading.reset = false
						})
				}
			},
			saveIpa () {
                if(this.$refs.ipaForm.validate()) {
                    this.loading.save = true
                    this.updateIpa()
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.ipaUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.save = false
                                this.updatedIpaUnsaved = false
                            } else {
                                if(isObject(response.message)){
                                    //extract validation errors
                                    const errors = Object.values(response.message)
                                    errors.forEach(errorObj => {
                                        if (Array.isArray(errorObj)) {
                                            errorObj.forEach(error => {
                                                this.$toast.error(error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                        } else {
                                            this.$toast.error(this.$t('message.errors.ipaNotUpdated'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        }
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.ipaNotUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                                this.loading.save = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.ipaNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        })
                        // .finally(() => {
                        //     this.loading.save = false
                        // })
                } else {
                    this.$toast.error(this.$t('message.errors.ipaNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    scrollToFirstFormValidationError()
                }
			},
            sendPermitToBuyer() {
                if(this.selectedBuyerContacts.length == 0){
                    this.dialogs.error_message = this.$t('message.errors.pleaseSelectOneContact');
                    this.dialogs.error = true
                } else {
                    this.loading.send = true
                    let payload = {
                        contacts: this.selectedBuyerContacts,
                        ipaId: this.Ipa__id,
                    }
                    this.sendImportPermitToBuyer (payload)
                        .then((status)=>{
                            if (status == 'done') {
                                this.$toast.success(this.$t('message.successes.importPermitAdviceSent'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                            } else {
                                this.$toast.error(this.$t('message.errors.importPermitAdviceNotSent'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.importPermitAdviceNotSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                        .finally(() => {
                            this.loading.send = false
                            this.selectedBuyerContacts = []
                            this.sendPermitDialog = false
                        })
                }
            },
            updateHistoryKey () {
                this.historyKey = Math.random() * (1000 - 1) + 1
            },
			// viewPdf () {
			// 	let document = encodeURIComponent((this.Ipa__Contract__version != null ? [this.Ipa__Contract__title, this.Ipa__Contract__revision_no , this.Ipa__Contract__partial_no].filter(Boolean).join('-') : this.Ipa__Contract__title) + '_IPA')
		    //     let user = JSON.parse(localStorage.getItem('user'))
            //     let tab = window.open(
			// 		process.env.VUE_APP_PDF_SERVER
            //         + '/api/render/'
            //         + document
            //         + '?url='
			// 		+ process.env.VUE_APP_BASE_URL
			// 		+ '/v1/ipas/print/'
			// 		+ this.ipaId
			// 		+ '/'
			// 		+ uuidv4()
			// 		+ '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
			// 		, "_blank");
            //     tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            //     this.openedTabs.push(tab.name)
			// }
		},
		watch: {
            sendPermitDialog(value) {
                // this.hasIpa = value
                if(value){
                    this.loading.send = true
                    api
                        .put('/ipas/' + this.ipaId + '/pdf')
                        .then((response) => {
                            if (response.data.status == 'success') {
                                this.loading.send = false
                            } else {
                                this.$toast.error(this.$t('message.errors.pdfError'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    })
                                this.loading.send = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.pdfError'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                })
                            this.loading.send = false
                        })
                }
            }
        },
		created () {
			if(this.allSpecies.length == 0) this.getAllSpecies()
			if(this.allCountries.length == 0) this.getAllCountries()
			if(this.allShippingLoadingPorts.length == 0) this.getAllShippingPorts()

			if(this.allUnShippedMyContracts.length == 0) this.getAllActiveContracts()
			if(this.allActiveIpas.length == 0) this.getAllActiveIpas()

            if(this.allSuppliers.length == 0) this.getAllSuppliers()

			this.entryPoints = this.allShippingDischargePorts.filter(port => port.Country.id == this.Ipa__Contract__destinatione_id)
			this.exitPoints = this.allShippingLoadingPorts.filter(port => port.Country.id == this.Ipa__productorigin_id)

			if(this.$route.matched.some(({name}) => name === 'update_ipa')) {
				this.loadIpaById(this.ipaId)
			} else if(this.$route.matched.some(({name}) => name === 'add_ipa')){
				this.newIpaUnsaved = true;
			}
		},
		mounted () {
			this.$store.watch(
				function(state) {
					return state.appic.ipa.update.Ipa
				},
				() => {
					this.updatedIpaUnsaved = true
				},
				{ deep: true }
			)
			this.$store.watch(
				function(state) {
					return state.appic.ipa.current.Ipa
				},
				() => {
					this.newIpaUnsaved = true
				},
				{ deep: true }
			)
		}
	}
</script>

<style>
	.force-text-right .v-text-field__slot input {
		text-align: right !important;
	}
</style>