<template>
    <v-dialog
        :max-width="'600px !important'"
        persistent
        v-model="newDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
    >
        <v-card>
            <v-card-title class="warning white--text justify-space-between">
                <span>{{ $t('message.newCreditNote') }}</span>
            </v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="newForm" v-model="validForm">
                    <v-layout row pt-3>
                        <p class="black--text pl-5 mt-3 pb-0">
                            {{ 'A credit note raised for an invoice not in the LHDN portal '
                                + 'will have the original e-invoice reference number set to "NA" in the portal.'
                            }}
                        </p>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.referenceInvoiceNumber') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :items="referenceInvoices"
                                :loading="loading.invoices"
                                autocomplete="new-password"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="invoice_number"
                                solo
                                required
                                return-object
                                v-model="reference_invoice_number"
                            >
                                <template v-slot:item="{item}">
                                    <div class="d-flex flex-column py-1" style="width: 100%">
                                        <div class="font-weight-bold">{{ item.invoice_number}}</div>
                                        <div class="d-flex flex-row">
                                            <span class="font-xs">{{ $t('message.reference') + ': ' + item.invoice_reference }}</span>
                                            <v-spacer></v-spacer>
                                            <span class="font-xs">{{ item.invoice_buyer }}</span>
                                        </div>
                                    </div>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.creditNoteNumber') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :loading="loading.creditNoteNumber"
                                :value="credit_note_number"
                                :label="credit_note_number ? '' : $t('message.required')"
                                :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                autocomplete="new-password"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                required
                                @change="credit_note_number = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.amount') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :value="amountToDeduct"
                                :label="amountToDeduct ? '' : $t('message.required')"
                                :rules="[...validationRules.required, ...validationRules.number, ...validationRules.minimumOne]"
                                autocomplete="new-password"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                type="number"
                                solo
                                required
                                @change="amountToDeduct = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.exchangeRate') + ' (MYR:USD)' }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :value="exchangeRate"
                                :label="exchangeRate ? '' : $t('message.required')"
                                :rules="[...validationRules.required, ...validationRules.number, ...validationRules.minimumOne]"
                                autocomplete="new-password"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                type="number"
                                solo
                                required
                                @change="exchangeRate = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.reasonForCreditNote') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-lazy>
                                <v-combobox
                                    :items="reasonOptions"
                                    :loading="loading.reasonOptions"
                                    :placeholder="$t('message.selectOptionOrFreeText')"
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                    :value="reasonToDeduct"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="reasonToDeduct = $event"
                                ></v-combobox>
                            </v-lazy>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="newDialog = false" >{{ $t('message.dismiss') }}</v-btn>
                <v-btn
                    :loading="loading.proceed"
                    color="info"
                    small
                    @click="createDocument()"
                >{{ $t('message.proceed') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {api} from "Api";
import {mapGetters} from "vuex";
import {numberFormat} from "Helpers/helpers";

export default {
    name: "NewCreditNote",
    props: ['dialog'],
    data() {
        return {
            amountToDeduct: 0,
            credit_note_number: null,
            exchangeRate: 1,
            loading: {
                creditNoteNumber: false,
                invoices: false,
                items: false,
                proceed: false,
                reasonOptions: false
            },
            new_dialog: false,
            reference_invoice_number: null,
            referenceInvoices: [],
            reasonOptions: [],
            reasonToDeduct: null,
            validForm: true
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        newDialog: {
            get() {
                return this.new_dialog;
            },
            set(value){
                this.new_dialog = value
                if(value === false) {
                    this.$emit('dialog-closed')
                }
            }
        },
        totalAmountValid() {
            return true;
        }
    },
    methods: {
        async createDocument() {
            if (this.totalAmountValid) {
                if (this.$refs.newForm.validate()) {
                    if (await this.$root.$confirm(this.$t('message.createLhdnCreditNote'), this.$t('message.confirmations.continueNewLhdnCreditNoteAction'), {color: 'orange'})) {
                        this.loading.proceed = true;
                        api
                            .post('/lhdn/document/manual-credit-note', {
                                credit_note_number: this.credit_note_number,
                                exchange_rate: this.exchangeRate,
                                reference_invoice_number: this.reference_invoice_number,
                                amount: this.amountToDeduct,
                                reason: this.reasonToDeduct
                            })
                            .then((response) => {
                                if (response.data.status === 'success') {
                                    this.$toast.success(this.$t('message.successes.lhdnCreditNoteCreated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                    this.loading.proceed = false;
                                    this.$emit('new-done')
                                    this.newDialog = false;
                                } else {
                                    this.$toast.error(this.$t('message.errors.lhdnCreditNoteNotCreated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    this.loading.proceed = false;
                                }
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('message.errors.lhdnCreditNoteNotCreated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.proceed = false;
                            })
                    }
                } else {
                    this.$toast.error(this.$t('message.errors.lhdnCreditNoteNotCreated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            } else {
                this.$toast.error(this.$t('message.errors.totalAmountMoreThanOriginalAmount'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        formatThisNumber(value, format) {
            return numberFormat(value, format);
        },
        getNextCreditNoteNumber() {
            this.loading.creditNoteNumber = true
            api
                .get('/lhdn/next-document-no/cn')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.credit_note_number = response.data.data
                    } else {
                        this.$toast.error(this.$t('message.errors.cannotGetNextCreditNoteNumber'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading.creditNoteNumber = false
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.cannotGetNextCreditNoteNumber'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.creditNoteNumber = false
                })
        },
        getReasonOptions() {
            this.loading.reasonOptions = true
            api
                .get('/lhdn/reason-options')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.reasonOptions = response.data.data
                    } else {
                        console.log('Reason options cannot be loaded')
                    }
                    this.loading.reasonOptions = false
                })
                .catch(() => {
                    console.log('Reason options cannot be loaded')
                    this.loading.reasonOptions = false
                })
        },
        getReferenceInvoices() {
            this.loading.invoices = true
            api
                .get('/lhdn/reference-invoices')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.referenceInvoices = response.data.data
                    } else {
                        console.log('Reference invoices cannot be loaded')
                    }
                    this.loading.invoices = false
                })
                .catch(() => {
                    console.log('Reference invoices cannot be loaded')
                    this.loading.invoices = false
                })
        },
    },
    watch: {
        dialog(value) {
            if(value) {
                this.credit_note_number = null;
                this.reference_invoice_number = null;
                this.reasonToDeduct = null;
                this.amountToDeduct = 0;
                this.exchangeRate = 1;
                this.getReferenceInvoices();
                this.getNextCreditNoteNumber();
                this.getReasonOptions();
            } else {
                this.items = [];
            }
            this.new_dialog = value;
        }
    },
}
</script>

<style scoped>

</style>